import dataRequestFactories from "state/global/factories/data_request_factories";
import { setHubspotDealStage, declineHubspotDealWithReason } from "api/map";

const actionTypePrefix = "SEND_DEAL_TO_HUBSPOT_COLUMN";

const statusActionTypes = dataRequestFactories.generateStatusActionTypes(
  actionTypePrefix,
);
const statusActions = dataRequestFactories.generateStatusActions(
  statusActionTypes,
  actionTypePrefix,
);

const actionTypes = { ...statusActionTypes };

const requestSuccessful = (dispatch, sale_listing_id, payload) => {
  dispatch(
    statusActions.requestSuccess({
      ...payload,
      listingId: sale_listing_id,
    }),
  );
};

const requestFailed = (dispatch, error, sale_listing_id) => {
  dispatch(
    statusActions.requestFailure(error, {
      error: error.nonFieldError,
      listingId: sale_listing_id,
    }),
  );
};

const processRequest = (promise, dispatch, sale_listing_id, getState) =>
  promise
    .then(requestResult => {
      const [payload, error, { isCanceled }] = requestResult;

      let resultPromise;

      if (!(error || isCanceled)) {
        requestSuccessful(dispatch, sale_listing_id, payload);
        resultPromise = Promise.resolve(getState());
      } else if (error) {
        requestFailed(dispatch, error, sale_listing_id);
        resultPromise = Promise.reject(error, isCanceled, getState());
      } else if (isCanceled) {
        dispatch(statusActions.requestCancelled(error, payload));
        resultPromise = Promise.reject(error, isCanceled, getState());
      } else {
        console.error({ requestResult });
        // eslint-disable-next-line no-throw-literal
        throw "Something went wrong with request processing";
      }

      return resultPromise;
    })
    .catch(error => {
      console.error(error);
    });

const sendToHubspotColumn = (() => {
  const actions = {
    ...statusActions,
    triggerRequest: (sale_listing_id, target_pipeline, data) => {
      return (dispatch, getState) => {
        dispatch(statusActions.requestInProgress());

        const requestPromise = setHubspotDealStage(
          sale_listing_id,
          target_pipeline,
          data,
        );

        return processRequest(
          requestPromise,
          dispatch,
          sale_listing_id,
          getState,
        );
      };
    },
    resetRequestStore: () => (dispatch, getState) =>
      dispatch(statusActions.resetRequestStatus()),
  };

  return {
    actionTypePrefix,
    actionTypes,
    actions,
  };
})();

const declineHubspotDeal = (() => {
  const actions = {
    ...statusActions,
    triggerRequest: (scraped_sale_listing_id, target_pipeline_id, data) => (
      dispatch,
      getState,
    ) => {
      dispatch(statusActions.requestInProgress());

      const requestPromise = declineHubspotDealWithReason(
        scraped_sale_listing_id,
        target_pipeline_id,
        data,
      );

      return processRequest(
        requestPromise,
        dispatch,
        scraped_sale_listing_id,
        getState,
      );
    },
    resetRequestStore: () => (dispatch, getState) =>
      dispatch(statusActions.resetRequestStatus()),
  };

  return { actionTypePrefix, actionTypes, actions };
})();

export { sendToHubspotColumn, declineHubspotDeal };
