import { fetchHubspotDealStages } from "api/map";
import snakeCase from "lodash/snakeCase";

export const fetchSalesDealStages = async salesDealStagesActions => {
  const [payload, error, { isCanceled }] = await fetchHubspotDealStages();

  if (!(error || isCanceled)) {
    if (payload !== null) {
      const results = payload.deal_stages;

      const formatted_results = results.map(pipeline => {
        const { stages } = pipeline;
        const hubspotStageNamesToHubspotStageInternalIDs = {};
        stages.forEach(stage => {
          hubspotStageNamesToHubspotStageInternalIDs[
            snakeCase(stage.label).toUpperCase()
          ] = stage.stageId;
        });

        return {
          ...pipeline,
          stages: hubspotStageNamesToHubspotStageInternalIDs,
        };
      });

      salesDealStagesActions.setRecords(formatted_results);
    }

    return true;
  }

  console.error(error);

  return false;
};
