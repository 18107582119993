import { createSelector } from "reselect";

import parentSelectors from "..";

const selectors = {
  selectShortletPropertyHullFilters: state =>
    parentSelectors.selectFilters(state).shortletPropertyHullFilters,
  selectLongletPropertyHullFilters: state =>
    parentSelectors.selectFilters(state).longletPropertyHullFilters,
  selectAmenityHullFilters: state =>
    parentSelectors.selectFilters(state).amenityHullFilters,
  selectLongletListingFilters: state =>
    parentSelectors.selectFilters(state).longletListingFilters,
  selectShortletListingFilters: state =>
    parentSelectors.selectFilters(state).shortletListingFilters,
  selectMapTypeFilters: state =>
    parentSelectors.selectFilters(state).mapTypeFilters,
  selectFilterInitializer: state =>
    parentSelectors.selectFilters(state).filterInitializer,
  selectSalesDealsFilters: state =>
    parentSelectors.selectFilters(state).salesDealsFilters,
  selectPropertyHullsDisabledFilters: state =>
    parentSelectors.selectFilters(state).propertyHullsDisabledFilters,
  selectAmenityHullsDisabledFilters: state =>
    parentSelectors.selectFilters(state).amenityHullsDisabledFilters,
  selectListingsDisabledFilters: state =>
    parentSelectors.selectFilters(state).listingsDisabledFilters,
  selectCompsFilters: state =>
    parentSelectors.selectFilters(state).compsFilters,
};

export default selectors;
