import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "components";
import previous_img from "assets/images/back.svg";
import next_img from "assets/images/next.svg";
import styles from "./pagination.module.css";

const Pagination = ({ next, prev, pageCount, page, className, style }) => {
  const { query, addQuery } = useQuery();

  function handlePrevPage() {
    if (query.page && String(query.page) !== String(page)) return;
    addQuery({ page: prev });
  }
  function handleNextPage() {
    if (query.page && String(query.page) !== String(page)) return;
    addQuery({ page: next });
  }
  return (
    <div className={`${styles.container} ${className || ""}`} style={style}>
      <div className={styles.pages}>
        <strong>{page}</strong>
        <span>{`/ ${pageCount}`}</span>
      </div>
      <div className={styles.buttons}>
        <button
          className={styles.button}
          onClick={handlePrevPage}
          disabled={!prev}
          type="button"
        >
          <img src={previous_img} alt="" />
        </button>
        <button
          className={styles.button}
          onClick={handleNextPage}
          disabled={!next}
          type="button"
        >
          <img src={next_img} alt="" />
        </button>
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  style: {},
  className: "",
  next: null,
  prev: null,
};

Pagination.propTypes = {
  next: PropTypes.number,
  prev: PropTypes.number,
  pageCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  style: PropTypes.shape(),
  className: PropTypes.string,
};

export default Pagination;
