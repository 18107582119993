import { createSelector } from "reselect";

const selectors = {
  // selectShortletListings: state => state.shortletListings,
  // selectHulls: state => state.hulls,
  // selectAmenityHulls: state => state.amenityHulls,
  // selectAmenityHullsEnabled: state => state.amenityHullsEnabled,
  // selectAmenitiesBands: state => state.amenityBands,
  // selectSelectedAmenityHull: state => state.selectedAmenityHull,
  // selecthighlightedAmenityType: state => state.highlightedAmenityType,
  //
  selectDataCollections: state => state.dataCollections,
  selectDataRequests: state => state.dataRequests,
  selectUiControl: state => state.uiControl,
  selectUserInput: state => state.userInput,
  selectUserSession: state => state.userSession,
};

export default selectors;
