import React from "react";
import matchSorter from "match-sorter";
import PropTypes from "prop-types";

import { Expansion, Checkbox, Chip } from "components";
import searchImg from "assets/images/search.svg";
import { getFontColor } from "utilities";
import handleListingFiltering from "../../filter_handlers/listings_filter";

import styles from "../index.module.css";

const getAmenityName = value => {
  return value.split("_").join(" ");
};

const handleAmenityTypesSearchClose = callback => {
  callback("");
};

const getColor = colorRGB => {
  if (!colorRGB) {
    return "rgb(252, 189, 1)";
  }
  return `rgb(${colorRGB[0]},${colorRGB[1]},${colorRGB[2]})`;
};

const handleAmenityTypesSearchChange = (e, callback) => {
  const {
    target: { value },
  } = e;
  callback(value.split(" ").join(""));
};

const AmenitiesTypeFilter = ({
  allAmenityTypes,
  disabled,
  shortletListingFiltersActions,
  shortletListingFiltersCurrent,
}) => {
  const [amenityTypesSearchList, setAmenityTypesSearchList] = React.useState(
    [],
  );
  const [
    amenityTypesSearchFilter,
    setAmenityTypesSearchFilter,
  ] = React.useState("");

  React.useEffect(() => {
    const amenityTypeCodes = allAmenityTypes.map(el => el.amenity);
    const filteredList = matchSorter(
      amenityTypeCodes,
      amenityTypesSearchFilter,
    );

    setAmenityTypesSearchList(filteredList);
  }, [amenityTypesSearchFilter, allAmenityTypes]);

  return (
    <>
      <Expansion
        onClose={() =>
          handleAmenityTypesSearchClose(setAmenityTypesSearchFilter)
        }
        controller={toggle => (
          <button
            type="button"
            disabled={disabled}
            className={styles.btn}
            onClick={toggle}
          >
            Amenities
            {` (${amenityTypesSearchList.length})`}
            <span />
          </button>
        )}
      >
        <div className={styles.listWrapper}>
          <div className={styles.search}>
            <img src={searchImg} alt="" />
            <input
              type="text"
              onChange={event =>
                handleAmenityTypesSearchChange(
                  event,
                  setAmenityTypesSearchFilter,
                )
              }
              placeholder="Search..."
            />
          </div>
          <div className={styles.list}>
            {amenityTypesSearchList.map(type => (
              <Checkbox
                key={type}
                className={styles.element}
                onClick={() =>
                  handleListingFiltering({
                    filterActions: shortletListingFiltersActions,
                    filterName: "listingAmenities",
                    value: type,
                  })
                }
                checked={shortletListingFiltersCurrent.listingAmenities.includes(
                  type,
                )}
                label={`${getAmenityName(type)}`}
              />
            ))}
            {!amenityTypesSearchList.length && (
              <div className={styles.empty}>No results</div>
            )}
          </div>
        </div>
      </Expansion>
      <div className={styles.chips}>
        {shortletListingFiltersCurrent.listingAmenities.map(name => {
          const amenityType = allAmenityTypes.find(el => el.amenity === name);
          let amenityHullColor;
          if (!amenityType) {
            return null;
          }
          if (amenityType) {
            amenityHullColor = getColor(amenityType.color);
          }
          return (
            <Chip
              key={name}
              onDelete={() =>
                handleListingFiltering({
                  filterActions: shortletListingFiltersActions,
                  filterName: "listingAmenities",
                  value: name,
                })
              }
              color={getFontColor(amenityHullColor)}
              background={amenityHullColor}
            >
              {getAmenityName(name)}
            </Chip>
          );
        })}
      </div>
    </>
  );
};

AmenitiesTypeFilter.propTypes = {
  allAmenityTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  shortletListingFiltersCurrent: PropTypes.shape({
    listingAmenities: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  shortletListingFiltersActions: PropTypes.shape({
    setFilterValue: PropTypes.func.isRequired,
    toggleCollectionFilter: PropTypes.func.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default AmenitiesTypeFilter;
