import { fundApiPaths } from "endpoints";
import { flattenErrors } from "utilities";
import axios from "axios";
import { tokenStorageField, tokenPrefix } from "configuration";

const { CancelToken } = axios;
const abortControllers = {};
/**
 * Api middleware.
 * Used to get developer-friendly syntax and provide default parameters
 * to requests
 * @param {{origin: string, headers: {}, url: string}} params
 */
export const fetchMiddleware = params => {
  return new Promise(async resolve => {
    const token = localStorage[tokenStorageField];
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers.Authorization = tokenPrefix + token;
    }
    // this line should be the last to overwrite default
    // headers by provided custom ones
    Object.assign(headers, params.headers);
    const requestOrigin =
      typeof params.origin === "string"
        ? params.origin
        : fundApiPaths.restEndpoint;

    // define abort controller for duplicate request canceling
    const abortName = requestOrigin + params.url.split("?")[0];

    if (!abortControllers[abortName]) {
      abortControllers[abortName] = CancelToken.source();
    } else {
      abortControllers[abortName].cancel("cancel");
      delete abortControllers[abortName];
    }

    const cancelToken = abortControllers[abortName]
      ? abortControllers[abortName].token
      : undefined;

    return axios({
      ...params,
      url: requestOrigin + params.url,
      headers,
      cancelToken,
    }).then(
      ({ data, status }) => {
        return resolve([data, null, { status }]);
      },
      err => {
        const { response, request } = err;
        let newStatus;
        if (response) {
          const { status } = response;
          newStatus = status;
        } else if (request) {
          const { status } = request;
          newStatus = status;
        } else {
          newStatus = undefined;
        }

        const error =
          err.response && typeof err.response.data === "object"
            ? flattenErrors(err.response.data)
            : {};
        const isCanceled = err.message && err.message === "cancel";

        return resolve([null, error, { status: newStatus, isCanceled }]);
      },
    );
  });
};

export default fetchMiddleware;
