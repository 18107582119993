const actionTypes = {
  RESET_FILTERS: "RESET_FILTERS",
};

const actions = {
  resetFilters: () => ({
    type: actionTypes.RESET_FILTERS,
    payload: {
      filterResetTimestamp: Date.now(),
    },
  }),
};

export { actionTypes, actions };
