const transformLongletRentalData = longletRentalListing => {
  if (!longletRentalListing) return longletRentalListing;

  return {
    ...longletRentalListing.longletHullListingsStatistics,
    all12MonthIncomes:
      longletRentalListing.longletHullListingsStatistics.hullListing
        .all12MonthIncomes,
    hullListing: {
      ...longletRentalListing.longletHullListingsStatistics.hullListing,
      locationPoint: longletRentalListing.location_point,
      price: parseInt(
        longletRentalListing.longletHullListingsStatistics.hullListing.price,
        10,
      ),
    },
    revenue: longletRentalListing.revenue,
  };
};

export default transformLongletRentalData;
