import { combineReducers } from "redux";

import dataCollectionsReducer from "./data_collections";
import dataRequestsReducer from "./data_requests";
import uiControlReducer from "./ui_control";
import userSessionReducer from "./user_session";
import userInputReducer from "./user_input";

export default combineReducers({
  dataCollections: dataCollectionsReducer,
  dataRequests: dataRequestsReducer,
  uiControl: uiControlReducer,
  userSession: userSessionReducer,
  userInput: userInputReducer,
});
