import { combineReducers } from "redux";

import amenityTypesReducer from "./amenity_types_reducer";
import shortletListingsReducer from "./shortlet/listings_reducer";
import shortletAmenityHullsReducer from "./shortlet/amenity_hulls_reducer";
import shortletPropertyHullsReducer from "./shortlet/property_hulls_reducer";
import longletListingsReducer from "./longlet/listings_reducer";
import longletAmenityHullsReducer from "./longlet/amenity_hulls_reducer";
import longletPropertyHullsReducer from "./longlet/property_hulls_reducer";
import salesDealsListingsReducer from "./sales/deals_listings_reducer";
import salesClustersInfoReducer from "./sales/sales_clusters_reducer";
import longletListingsClustersInfoReducer from "./longlet/listings_clusters_reducer";
import shortletListingsClustersInfoReducer from "./shortlet/listings_clusters_reducer";
import salesDealsDeclineReasonsReducer from "./sales/sales_deals_decline_reasons_reducer";
import hubspotPipelinesDealStagesReducer from "./sales/sales_deal_stages_reducer";

export default combineReducers({
  amenityTypes: amenityTypesReducer,
  shortletListings: shortletListingsReducer,
  shortletAmenityHulls: shortletAmenityHullsReducer,
  shortletPropertyHulls: shortletPropertyHullsReducer,
  longletListings: longletListingsReducer,
  longletAmenityHulls: longletAmenityHullsReducer,
  longletPropertyHulls: longletPropertyHullsReducer,
  salesDealsListings: salesDealsListingsReducer,
  salesClustersInfo: salesClustersInfoReducer,
  longletListingsClustersInfo: longletListingsClustersInfoReducer,
  shortletListingsClustersInfo: shortletListingsClustersInfoReducer,
  salesDealsDeclineReasons: salesDealsDeclineReasonsReducer,
  hubspotPipelinesDealStages: hubspotPipelinesDealStagesReducer,
});
