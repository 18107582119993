import activeMapTabFactories from "state/global/factories/active_map_tab_factories";

const actionTypePrefix = "ACTIVE_MAP_TAB";
const actionTypes = activeMapTabFactories.generateActionTypes(actionTypePrefix);
const actions = activeMapTabFactories.generateActions(
  actionTypes,
  actionTypePrefix,
);

export { actionTypePrefix, actionTypes, actions };
