import { createSelector } from "reselect";

import parentSelectors from "../root_selectors";

const selectors = {
  selectCurrentSession: state =>
    parentSelectors.selectUserSession(state).currentSession,
};

export default selectors;
