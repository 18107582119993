/* eslint-disable import/order */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React from "react";
import { Tooltip } from "react-tippy";
import PropTypes from "prop-types";

import SendToHubspotColumnModal from "../send_to_hubspot_column_modal/index";

import styles from "./index.module.css";
import DeclineHubspotDealModal from "../decline_hubspot_deal";

const SetHubSpotStageButton = React.memo(
  ({ listing, disabled, buttonIcon, decline, disabledText, columnName }) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    return (
      <>
        {decline ? (
          <DeclineHubspotDealModal
            isOpen={isModalOpen}
            handleClose={() => setIsModalOpen(!isModalOpen)}
            listing={listing}
            columnName={columnName}
          />
        ) : (
          <SendToHubspotColumnModal
            isOpen={isModalOpen}
            handleClose={() => setIsModalOpen(!isModalOpen)}
            columnName={columnName}
            listing={listing}
          />
        )}
        {disabled ? (
          <Tooltip title={disabledText}>
            <HubSpotButtonIcon icon={buttonIcon} disabled={disabled} />
          </Tooltip>
        ) : (
          <Tooltip
            title={`Send to HubSpot&apos;s &apos;${columnName}&apos; column`}
          >
            <HubSpotButtonIcon
              icon={buttonIcon}
              onClick={() => setIsModalOpen(!isModalOpen)}
            />
          </Tooltip>
        )}
      </>
    );
  },
);

SetHubSpotStageButton.defaultProps = { decline: false };

SetHubSpotStageButton.propTypes = {
  listing: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  decline: PropTypes.bool,
  buttonIcon: PropTypes.string.isRequired,
  disabledText: PropTypes.string.isRequired,
  columnName: PropTypes.string.isRequired,
};

const HubSpotButtonIcon = ({ onClick, disabled, icon }) => (
  <div className={styles["hubspot-button"]}>
    <button type="button" disabled={disabled}>
      <img
        src={icon}
        onClick={onClick}
        className={styles["hubspot-icon"]}
        alt="hubspot icon"
      />
    </button>
  </div>
);

export default SetHubSpotStageButton;
