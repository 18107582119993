import mapConfigs from "configs/map_configs";

import salesCluster from "assets/cluster/sales_cluster.png";
import shortletCluster from "assets/cluster/shortlet_cluster.png";
import longletCluster from "assets/cluster/longlet_cluster.png";

const {
  zoomLevels: { clusters: clustersZoomLevels },
} = mapConfigs;

const getClusterImageURL = listingType =>
  ({
    LONGLET_LISTING: longletCluster,
    SHORTLET_LISTING: shortletCluster,
    HUBSPOT_PUSHED_SALE_LISTING: salesCluster,
  }[listingType]);

const getClusterIcon = listingType => ({
  url: getClusterImageURL(listingType),
  scaledSize: new window.google.maps.Size(50, 50),
  anchor: new window.google.maps.Point(25, 25),
  labelOrigin: new window.google.maps.Point(25, 26),
});

const getClusterLabel = pointCount => ({
  text: `${pointCount}`,
  color: "black",
  fontSize: "11px",
  fontWeight: "bold",
});

const getCurrentBoundingBox = mapInstance => {
  const bounds = mapInstance.getBounds();
  const boundBox = {
    northLat: bounds.getNorthEast().lat(),
    eastLng: bounds.getNorthEast().lng(),
    southLat: bounds.getSouthWest().lat(),
    westLng: bounds.getSouthWest().lng(),
  };

  return [
    boundBox.westLng,
    boundBox.southLat,
    boundBox.eastLng,
    boundBox.northLat,
  ];
};

const superClusterOptions = {
  maxZoom: clustersZoomLevels.max,
  minPoints: 1,
  minZoom: clustersZoomLevels.min,
  radius: 120,
};

export default {
  getClusterLabel,
  getClusterIcon,
  getCurrentBoundingBox,
  superClusterOptions,
};
