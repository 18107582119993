import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import OutsideClickHandler from "react-outside-click-handler";
// import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import employee_img from "assets/images/employee.svg";
import boss_img from "assets/images/boss.svg";
import currentSessionSelectors from "state/global/selectors/user_session/current_session_selectors";
import styles from "./top_panel.module.css";

const handleCloseActionsMenu = setIsOpen => setIsOpen(false);
const toggleLogoutMenu = (setIsOpen, isOpen) => setIsOpen(!isOpen);

const MenuButton = ({ link, text, handleMoveTo, history, setIsOpen }) => (
  <button type="button" onClick={() => handleMoveTo(link, history, setIsOpen)}>
    {text}
  </button>
);

const UserInitialsButton = ({ userInitials, setIsOpen, isOpen }) => (
  <button
    className={styles.avatar}
    type="button"
    onClick={() => toggleLogoutMenu(setIsOpen, isOpen)}
  >
    {userInitials}
  </button>
);

const UserDetailsInfo = ({ userImg, imgBackground, username }) => (
  <div className={styles.user}>
    <img
      src={userImg}
      alt=""
      className={styles.userImg}
      style={imgBackground}
    />
    <div className={styles.username}>{username}</div>
  </div>
);

const userImg = user => (user && user.isSuperuser ? boss_img : employee_img);
const imgBackground = user =>
  user && user.isSuperuser ? { background: "#fcbd01b0" } : {};
const userInitials = user => (user ? user.username.substring(0, 2) : "--");
const username = user => (user ? user.username : "--");

const LogoutMenu = ({ handleMoveTo, setIsOpen, isOpen, userData }) => {
  const history = useHistory();
  // const user = useSelector(state => state.user.userData);

  return (
    <OutsideClickHandler
      onOutsideClick={() => handleCloseActionsMenu(setIsOpen, isOpen)}
    >
      <UserInitialsButton
        userInitials={userInitials(userData)}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
      {isOpen && (
        <div className={styles.options}>
          <UserDetailsInfo
            userImg={userImg(userData)}
            imgBackground={imgBackground(userData)}
            username={username(userData)}
          />
          <MenuButton
            link="/logout"
            text="Logout"
            handleMoveTo={handleMoveTo}
            history={history}
            setIsOpen={setIsOpen}
          />
        </div>
      )}
    </OutsideClickHandler>
  );
};

LogoutMenu.defaultProps = {
  userData: null,
};

LogoutMenu.propTypes = {
  handleMoveTo: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  userData: PropTypes.shape(),
};
MenuButton.propTypes = {
  handleMoveTo: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  history: PropTypes.shape().isRequired,
};
UserInitialsButton.propTypes = {
  userInitials: PropTypes.string.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
UserDetailsInfo.propTypes = {
  userImg: PropTypes.string.isRequired,
  imgBackground: PropTypes.shape().isRequired,
  username: PropTypes.string.isRequired,
};

function mapStateToProps(state, props) {
  return {
    userData: currentSessionSelectors.selectUserData(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LogoutMenu);
