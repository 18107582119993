import { createReducer } from "@reduxjs/toolkit";

import { actionTypes } from "state/global/actions/user_input/map/filters/filter_initializer_actions";

const initialState = {
  filterResetTimestamp: null,
};

const reducer = createReducer(initialState, {
  [actionTypes.RESET_FILTERS]: (draftState, action) => {
    return {
      ...draftState,
      filterResetTimestamp: action.payload.filterResetTimestamp,
    };
  },
});

export default reducer;
