/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from "react";
import PropTypes from "prop-types";

import styles from "./carousel_buttons.module.css";

const CarouselButtons = ({ showPrev, showNext, currentImagePosition }) => (
  <div className={styles["btn-container"]}>
    <div className={styles["image-buttons"]}>
      <div className={styles.prev} onClick={() => showPrev()} role="button">
        &lt;&nbsp;
      </div>
      <div className={styles.content}>{currentImagePosition}</div>
      <div className={styles.next} onClick={() => showNext()} role="button">
        &nbsp; &gt;
      </div>
    </div>
  </div>
);

CarouselButtons.propTypes = {
  showNext: PropTypes.func.isRequired,
  showPrev: PropTypes.func.isRequired,
  currentImagePosition: PropTypes.string.isRequired,
};

export default CarouselButtons;
