/* eslint-disable no-shadow */
import React, { useState } from "react";
import PropTypes from "prop-types";

import { SalesListingPanel } from "pages/map/left_panel/listings_panel";

import styles from "pages/map/left_panel/map_type_tabs/index.module.css";

const SalesTab = ({ tabDisabled }) => {
  const [tab, setTab] = useState(1);

  return (
    <div
      className={`${styles.container} ${
        tabDisabled ? styles["panel-disabled"] : ""
      }`}
    >
      <div className={`${styles.tabs} ${styles["sales-tab"]}`}>
        <button
          type="button"
          disabled={tabDisabled}
          className={getTabClassName(1, tab, tabDisabled)}
          onClick={() => setTab(1)}
        >
          Listings
        </button>
      </div>

      <div className={styles.items}>
        <div className={styles.spacer} />
      </div>
      <div className={styles.content}>
        <SalesListingPanel disabled={tabDisabled} />
      </div>
    </div>
  );
};

SalesTab.propTypes = {
  tabDisabled: PropTypes.bool.isRequired,
};

const getTabClassName = (value, tab, tabDisabled) => {
  if (value === tab && !tabDisabled) {
    return styles.active;
  }
  if (value === tab && tabDisabled) {
    return styles.selected;
  }
  return "";
};

export default SalesTab;
