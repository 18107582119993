/* eslint-disable no-shadow */
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import { actions as shortletListingFiltersActions } from "state/global/actions/user_input/map/filters/shortlet/listing_filters_actions";
import { actions as propertyHullEnabledFiltersActions } from "state/global/actions/user_input/map/filters/property_hulls_disabled_filters_actions";
import { actions as amenityHullFiltersActions } from "state/global/actions/user_input/map/filters/amenity_hull_filters_actions";
import { ShortletListingPanel } from "pages/map/left_panel/listings_panel";
import { ShortletHullsPanel } from "pages/map/left_panel/hulls_panel";

import styles from "pages/map/left_panel/map_type_tabs/index.module.css";

const ShortletTab = ({ tabDisabled }) => {
  const [tab, setTab] = useState(1);

  return (
    <div
      className={`${styles.container} ${
        tabDisabled ? styles["panel-disabled"] : ""
      }`}
    >
      <div className={styles.tabs}>
        <button
          type="button"
          disabled={tabDisabled}
          className={getTabClassName(1, tab, tabDisabled)}
          onClick={() => setTab(1)}
        >
          Listings
        </button>
        <button
          type="button"
          disabled={tabDisabled}
          className={getTabClassName(2, tab, tabDisabled)}
          onClick={() => setTab(2)}
        >
          Hulls
        </button>
      </div>

      <div className={styles.content}>
        {tab === 1 && <ShortletListingPanel disabled={tabDisabled} />}
        {tab === 2 && <ShortletHullsPanel disabled={tabDisabled} />}
      </div>
    </div>
  );
};

ShortletTab.propTypes = {
  filterInitializerActions: PropTypes.shape({
    resetFilters: PropTypes.func.isRequired,
  }).isRequired,
  shortletListingFiltersActions: PropTypes.shape({
    setFilterValue: PropTypes.func.isRequired,
  }).isRequired,
  propertyHullsEnabledFiltersActions: PropTypes.shape({
    setFilterValue: PropTypes.func.isRequired,
  }).isRequired,
  amenityHullFiltersActions: PropTypes.shape({
    setFilterValue: PropTypes.func.isRequired,
  }).isRequired,
  tabDisabled: PropTypes.bool.isRequired,
};

const getTabClassName = (value, tab, tabDisabled) => {
  if (value === tab && !tabDisabled) {
    return styles.active;
  }
  if (value === tab && tabDisabled) {
    return styles.selected;
  }
  return "";
};

const mapStateToProps = (_state, _props) => ({});

const mapDispatchToProps = dispatch => ({
  shortletListingFiltersActions: bindActionCreators(
    shortletListingFiltersActions,
    dispatch,
  ),
  propertyHullsEnabledFiltersActions: bindActionCreators(
    propertyHullEnabledFiltersActions,
    dispatch,
  ),
  amenityHullFiltersActions: bindActionCreators(
    amenityHullFiltersActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShortletTab);
