import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import graphql from "babel-plugin-relay/macro";
import fetchQuery from "relay/fetchQuery";

import Loader from "components/elements/loader/loader";
import { tokenStorageField } from "configuration";

import { actions as currentSessionActions } from "state/global/actions/user_session/current_session_actions";

import currentSessionSelectors from "state/global/selectors/user_session/current_session_selectors";

const query = graphql`
  query dataProviderQuery {
    me {
      id
      username
      firstName
      lastName
      email
      dateJoined
      isSuperuser
      dateJoined
      isActive
    }
  }
`;

const DataProvider = ({ children, userData }) => {
  const [fetching, setFetching] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [errorsCount, setErrorsCount] = useState(0);

  const dispatch = useDispatch();
  // const user = useSelector(state => state.user.userData);
  const token = localStorage.getItem(tokenStorageField);
  // const token = useState();
  const fetchesRetryCount = 4;

  useEffect(() => {
    function getLoggedUser() {
      return fetchQuery(query, null, { force: true })
        .then(payload => [payload, null])
        .catch(error => [null, error]);
    }
    async function fetchData() {
      setFetching(true);
      const [payload, error] = await getLoggedUser();
      if (payload) {
        dispatch(currentSessionActions.loginAction(payload.data.me));
      } else {
        setErrorsCount(errorsCount + 1);
        setFetching(false);
      }
    }

    if (!userData && token && !fetching && errorsCount < fetchesRetryCount) {
      fetchData();
    }
  }, [userData, token, dispatch, fetching, errorsCount]);

  let component = children;

  if (fetching && !loaderVisible) {
    component = <Loader absolute type="primary" size="big" />;
    setLoaderVisible(true);
  }

  return component;
};

DataProvider.propTypes = {
  children: PropTypes.shape(),
};

function mapStateToProps(state, props) {
  return {
    userData: currentSessionSelectors.selectUserData(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(DataProvider));
