import React from "react";
import PropTypes from "prop-types";
import { Autocomplete } from "@react-google-maps/api";
import styles from "../map.module.css";

const SearchBar = ({ map, handleSearch }) => {
  const search = React.useRef(null);

  function setSearchRef(searchBox) {
    search.current = searchBox;
  }

  function onChange() {
    const place = search.current.getPlace();
    if (place.geometry) {
      const {
        location: { lat, lng },
      } = place.geometry;
      const marker = { lat: lat(), lng: lng() };
      if (place) {
        handleSearch(9, marker, place.formatted_address);
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        }
      }
    }
  }

  return (
    <Autocomplete
      bounds={map.getBounds()}
      onLoad={setSearchRef}
      onPlaceChanged={onChange}
      controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
    >
      <input
        type="text"
        className={styles.searchBox}
        placeholder="Search location"
      />
    </Autocomplete>
  );
};

SearchBar.defaultProps = {
  map: null,
};

SearchBar.propTypes = {
  map: PropTypes.shape({
    fitBounds: PropTypes.func.isRequired,
    getBounds: PropTypes.func.isRequired,
  }),
  handleSearch: PropTypes.func.isRequired,
};

export default SearchBar;
