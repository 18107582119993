import {
  SUCCESS,
  REQUEST,
  FAILURE,
  OPEN_MODAL,
  CLOSE_MODAL,
  SEND_FORM,
  UPDATE_PRICES,
} from "state/local/action_types/statistics/statistics_details_action_types";

const mergeData = (statistics, userPrices) => {
  const mergedPayload = { ...statistics };

  if (userPrices) {
    Object.keys(userPrices).forEach(date => {
      const [year, month] = date.split("-");
      const monthIndex = mergedPayload.months.findIndex(
        details => details.month === month && details.year === year,
      );
      if (monthIndex !== -1) {
        if (mergedPayload.months[monthIndex].days[date]) {
          mergedPayload.months[monthIndex].days[date].userPrice =
            userPrices[date];
        } else {
          mergedPayload.months[monthIndex].days[date] = {
            userPrice: userPrices[date],
          };
        }
      }
    });
  }

  return mergedPayload;
};

const statisticsDetailsReducer = (
  state,
  { type, payload, statistics, userPrices, date, error },
) => {
  switch (type) {
    case REQUEST:
      return { ...state, fetching: true };
    case SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        status: null,
        details: mergeData(statistics, userPrices),
      };
    case FAILURE:
      return {
        ...state,
        fetching: false,
        error: error ? error.error : "",
        status: error ? error.status : "",
      };
    case OPEN_MODAL:
      return {
        ...state,
        isOpen: true,
        modalDate: date,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isOpen: false,
      };
    case SEND_FORM:
      return {
        ...state,
        isSending: payload,
      };
    case UPDATE_PRICES:
      return {
        ...state,
        fetching: false,
        error: null,
        details: mergeData(state.details, userPrices),
      };
    default:
      throw new Error("Unexpected action {{" + type + "}}");
  }
};

export default statisticsDetailsReducer;
