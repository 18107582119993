import dataCollectionsFactories from "state/global/factories/data_collections_factories";
import siblingSelectors from "..";

const selectors = {
  ...dataCollectionsFactories.generateCollectionSelectors(state =>
    siblingSelectors.selectSalesDealsDeclineReasons(state),
  ),
};

export default selectors;
