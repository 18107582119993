const sharedConfig = {
  listingTypes: {
    shortletListing: {
      type: "SHORTLET_LISTING",
    },
    longletListing: {
      type: "LONGLET_LISTING",
    },
    hubspotPushedSaleListing: {
      type: "HUBSPOT_PUSHED_SALE_LISTING",
    },
  },
  hullTypes: {
    shortletPropertyHulls: {
      type: "SHORTLET_PROPERTY_HULL",
    },
    longletPropertyHulls: {
      type: "LONGLET_PROPERTY_HULL",
    },
  },
  amenityHullTypes: {
    shortletAmenityHulls: {
      type: "SHORTLET_AMENITY_HULL",
    },
    longletAmenityHulls: {
      type: "LONGLET_AMENITY_HULL",
    },
  },
  pipelineIds: ["2142996", "5371897"],
};

export default {
  sharedConfig,
};
