/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { InfoWindow } from "@react-google-maps/api";

import { Carousel } from "components/";
import ListingDetails from "../listing_details/index";

import styles from "./index.module.css";

const InfoWindowWrapper = ({
  details,
  position,
  onClose,
  listingType,
  dealStageNamesToDealStageInternalIds,
}) => (
  <InfoWindow
    onCloseClick={onClose}
    position={position}
    options={{
      closeBoxURL: ``,
      enableEventPropagation: true,
    }}
  >
    <>
      <div className={styles.container}>
        <div className={styles.content} id={styles.content}>
          <Carousel images={details.image_urls} />
          <ListingDetails
            details={details}
            listingType={listingType}
            dealStageNamesToDealStageInternalIds={
              dealStageNamesToDealStageInternalIds
            }
          />
        </div>
      </div>
      <span className={styles.arrow} />
    </>
  </InfoWindow>
);

InfoWindowWrapper.propTypes = {
  onClose: PropTypes.func.isRequired,
  position: PropTypes.shape({
    lat: PropTypes.func.isRequired,
    lng: PropTypes.func.isRequired,
  }).isRequired,
  listingType: PropTypes.string.isRequired,
  dealStageNamesToDealStageInternalIds: PropTypes.object,
  details: PropTypes.shape({
    image_urls: PropTypes.array,
  }).isRequired,
};

InfoWindowWrapper.defaultProps = {
  dealStageNamesToDealStageInternalIds: {},
};

export default InfoWindowWrapper;
