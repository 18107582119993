import React from "react";
import PropTypes from "prop-types";

import { Segment, Carousel } from "components";
import {
  buildListingGoogleMapLink,
  buildAirBnBLink,
  buildListingExternalLink,
} from "pages/map/components/helpers/listing_external_links";
import ExternalLinkIcons from "./external_link_icons";

const HullListingDetails = ({ data: { hullListing, imageUrls } }) => {
  const images = imageUrls ?? hullListing.imageUrl;

  return (
    <div>
      <ExternalLinkIcons
        googleMapsUrl={buildListingGoogleMapLink(hullListing)}
        airbnbUrl={hullListing.airbnbId ? buildAirBnBLink(hullListing) : ""}
        listingLink={buildListingExternalLink({
          ...hullListing,
          sourceSiteCode: hullListing.isSourceZoopla ? "zoopla" : "rightmove",
        })}
      />
      <Carousel
        images={Array.isArray(images) ? images : [images]}
        imageHeight={232}
        imageWidth={349}
      />
      <Segment
        title="Post code"
        text={hullListing.postcode}
        info="The post code of the property."
      />
      <Segment
        title="Number of bedrooms"
        text={hullListing.bedrooms}
        info="The number of bedrooms the property has."
      />
      {hullListing.numSleeps && (
        <Segment
          title="Number of sleeps"
          text={hullListing.numSleeps}
          info="The maximum number of guests the property can accommodate."
        />
      )}
    </div>
  );
};

HullListingDetails.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default HullListingDetails;
