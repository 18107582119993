/* eslint-disable no-shadow */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { inRange } from "lodash";

import { RangeSlider, MinMaxFilter } from "components";
import useQuery from "components/hooks/use_query";

import { actions as salesDealsFiltersActions } from "state/global/actions/user_input/map/filters/sales/deals_filters_actions";
import { actions as salesDealsListingsActions } from "state/global/actions/data_collections/map/sales/deals_listings_actions";
import { actions as salesClustersActions } from "state/global/actions/data_collections/map/sales/sales_clusters_actions";
import { actions as listingsDisabledFiltersActions } from "state/global/actions/user_input/map/filters/listings_disabled_filters_actions";
import salesDealStagesSelectors from "state/global/selectors/data_collections/map/sales/sales_deal_stages_selectors";
import salesDealsFiltersSelectors from "state/global/selectors/user_input/map/filters/sales_deals_filter_selectors";
import filterConfigs from "configs/filter_configs";
import listingsDisabledFiltersSelectors from "state/global/selectors/user_input/map/filters/listings_disabled_selectors";

import { MapContext } from "state/local/stores/contexts/map_context_store";
import * as apiMapRequests from "api/map";
import { constructSalesListingsQueryParams } from "api/fetchers/sales/construct_sale_listings_query_params";
import mapConfigs from "configs/map_configs";
import fetchListings from "api/fetchers/listings";
import fetchListingsClusteringInfo from "api/fetchers/listings_clustering_info";
import useComponentDidUpdate from "components/hooks/use_component_did_update";
import { fetchWithDebounce } from "api/fetchers/utilities";
import {
  deserializeSaleClusters,
  deserializeSaleListings,
} from "api/deserializers";
import { ListingsVisibilitySwitch } from "./components";
import SalesDealStageFilterComponent from "../components/deal_stage_filter";
import ListingTypeFilter from "../components/listing_types_filter";
import handleListingFiltering from "../filter_handlers/listings_filter";

import styles from "./listings_panel.module.css";

const {
  mapTypeNames: { sales: salesMapType },
  zoomLevels: { clusters: clustersAppearZoom, listings: listingsAppearZoom },
  filterDebounce: { listings: debounceListings },
  sharedConfig: {
    pageSizes: { clusters: clustersPageSize, listings: listingsPageSizes },
  },
} = mapConfigs;

const ListingsPanel = React.memo(
  ({
    salesDealsFiltersDefault,
    salesDealsFiltersOptions,
    salesDealsFiltersCurrent,
    disabled,
    salesDealsListingsActions,
    salesClustersActions,
    hubspotPipelinesToDealStagesMapping,
    salesDealsFiltersActions,
    listingsDisabledFiltersCurrent,
    listingsDisabledFiltersActions,
  }) => {
    const { updateQueryString } = useQuery();
    const { mapInstance, setFetchingMapData } = React.useContext(MapContext);

    const zoom = mapInstance?.getZoom();
    const enableListingsFetching = zoom >= listingsAppearZoom;
    const enableClustersInfoFetching = inRange(
      zoom,
      clustersAppearZoom.min,
      clustersAppearZoom.max + 1,
    );

    useComponentDidUpdate(() => {
      fetchWithDebounce(handleOnApplyFilters, debounceListings);
    }, [salesDealsFiltersCurrent]);

    const handleOnApplyFilters = () => {
      const pageSizes =
        (enableListingsFetching && listingsPageSizes) ||
        (enableClustersInfoFetching && clustersPageSize);

      const queryParams = constructSalesListingsQueryParams(
        salesDealsFiltersCurrent,
        mapInstance,
        hubspotPipelinesToDealStagesMapping,
        pageSizes,
      );

      setFetchingMapData(true);

      const fetch =
        (enableListingsFetching &&
          (async () => {
            await fetchListings({
              listingsActions: salesDealsListingsActions,
              request: apiMapRequests.getLongletSalesDealsListings,
              queryParams,
              deserializer: deserializeSaleListings,
            });
          })) ||
        (enableClustersInfoFetching &&
          (async () => {
            await fetchListingsClusteringInfo({
              request: apiMapRequests.getSalesClusteringInfo,
              clustersActions: salesClustersActions,
              queryParams,
              deserializer: deserializeSaleClusters,
            });
          }));

      if (fetch) {
        fetch().then(() => setFetchingMapData(false));
      }
    };

    return (
      <div className={styles.container} data-testid="listings-panel">
        <div className={styles.heading}>
          <div className={styles.title}>Listing filters</div>
          <div className={styles.subtitle}>
            Use filters to change the shown listings
          </div>
          <div className={styles.filters}>
            <div className={styles.grid}>
              <ListingsVisibilitySwitch
                filterActions={listingsDisabledFiltersActions}
                filterSelector={listingsDisabledFiltersSelectors}
                filtersCurrent={listingsDisabledFiltersCurrent}
                mapTypeDisabled={disabled}
                mapType={salesMapType}
              />
            </div>
            <div className={styles.spacer} />
            <MinMaxFilter
              disabled={disabled}
              mapType={salesMapType}
              filterActions={salesDealsFiltersActions}
              filterName="listingBeds"
              filterSelectors={salesDealsFiltersSelectors}
              title="Bedrooms"
              currentValues={salesDealsFiltersCurrent}
              shouldUpdateQueryString
            />
            <SalesDealStageFilterComponent
              disabled={disabled}
              salesDealsFiltersActions={salesDealsFiltersActions}
              salesDealsFilterOptions={salesDealsFiltersOptions}
              salesDealsFiltersCurrent={salesDealsFiltersCurrent}
            />
            <ListingTypeFilter
              disabled={disabled}
              mapType={salesMapType}
              listingFilterSelector={salesDealsFiltersSelectors}
              listingFiltersActions={salesDealsFiltersActions}
              listingFiltersCurrent={salesDealsFiltersCurrent}
              listingFiltersOptions={salesDealsFiltersOptions}
            />
            <RangeSlider
              disabled={disabled}
              filtersCurrent={salesDealsFiltersCurrent}
              handleOnFilterChange={datesRange =>
                handleListingFiltering({
                  filterActions: salesDealsFiltersActions,
                  filterName: "freshness",
                  value: datesRange,
                  shouldReplaceFilterValue: true,
                  filterSelector: salesDealsFiltersSelectors,
                  updateQueryStringCallback: updateQueryString,
                  queryParamPrefix: salesMapType,
                })
              }
              title="Freshness"
              filterName="freshness"
              filterOptions={salesDealsFreshnessFilterOptions}
              mode={1}
            />
            <MinMaxFilter
              title="Price (£)"
              disabled={disabled}
              filterName="priceRange"
              filterActions={salesDealsFiltersActions}
              defaultValues={salesDealsFiltersDefault}
              filterSelectors={salesDealsFiltersSelectors}
              mapType={salesMapType}
              currentValues={salesDealsFiltersCurrent}
            />
            <MinMaxFilter
              disabled={disabled}
              title="Gross yield (%)"
              isPercentage
              defaultValues={salesDealsFiltersDefault}
              filterName="estimatedYieldRange"
              filterActions={salesDealsFiltersActions}
              filterSelectors={salesDealsFiltersSelectors}
              mapType={salesMapType}
              currentValues={salesDealsFiltersCurrent}
            />
            <MinMaxFilter
              disabled={disabled}
              title="Net initial yield (%)"
              filterName="netYieldRange"
              defaultValues={salesDealsFiltersDefault}
              isPercentage
              filterActions={salesDealsFiltersActions}
              filterSelectors={salesDealsFiltersSelectors}
              mapType={salesMapType}
              currentValues={salesDealsFiltersCurrent}
              shouldUpdateQueryString
            />
          </div>
        </div>
      </div>
    );
  },
);

ListingsPanel.propTypes = {
  salesDealsFiltersDefault: PropTypes.shape({
    listingBeds: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }).isRequired,
    listingTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  salesDealsFiltersOptions: PropTypes.shape({
    listingBeds: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }).isRequired,
    listingTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  salesDealsFiltersCurrent: PropTypes.shape({
    listingBeds: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }).isRequired,
    listingTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    listingsEnabled: PropTypes.bool.isRequired,
  }).isRequired,
  hubspotPipelinesToDealStagesMapping: PropTypes.objectOf(PropTypes.object)
    .isRequired,
  salesClustersActions: PropTypes.shape({
    setRecords: PropTypes.func.isRequired,
  }).isRequired,
  salesDealsListingsActions: PropTypes.shape({
    setRecords: PropTypes.func.isRequired,
  }).isRequired,
  salesDealsFiltersActions: PropTypes.shape({
    setFilterValue: PropTypes.func.isRequired,
    toggleCollectionFilter: PropTypes.func.isRequired,
  }).isRequired,
  listingsDisabledFiltersActions: PropTypes.shape({
    setFilterValue: PropTypes.func.isRequired,
    toggleCollectionFilter: PropTypes.func.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  listingsDisabledFiltersCurrent: PropTypes.shape({
    listingsDisabled: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const { salesDealsFreshnessFilterOptions } = filterConfigs;

const mapStateToProps = (state, props) => ({
  salesDealsFiltersDefault: salesDealsFiltersSelectors.selectDefaults(state),
  salesDealsFiltersOptions: salesDealsFiltersSelectors.selectOptions(state),
  salesDealsFiltersCurrent: salesDealsFiltersSelectors.selectCurrent(state),
  listingsDisabledFiltersCurrent: listingsDisabledFiltersSelectors.selectCurrent(
    state,
  ),
  hubspotPipelinesToDealStagesMapping: salesDealStagesSelectors.selectAllByKey(
    state,
  ),
});

const mapDispatchToProps = dispatch => ({
  salesDealsFiltersActions: bindActionCreators(
    salesDealsFiltersActions,
    dispatch,
  ),
  listingsDisabledFiltersActions: bindActionCreators(
    listingsDisabledFiltersActions,
    dispatch,
  ),
  salesClustersActions: bindActionCreators(salesClustersActions, dispatch),
  salesDealsListingsActions: bindActionCreators(
    salesDealsListingsActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListingsPanel);
