import { buildQueryParamsString } from "state/helpers/map_helpers";

async function fetchListingsClusteringInfo({
  request,
  clustersActions,
  queryParams,
  deserializer = serializedClusters => serializedClusters,
}) {
  const [payload, error, { isCanceled }] = await request(
    buildQueryParamsString(queryParams),
  );

  if (!(error || isCanceled)) {
    if (payload !== null) {
      const deserializedClusters = deserializer(payload.results);
      clustersActions.setRecords(deserializedClusters);
    }

    return true;
  }
  console.error(error);

  return false;
}

export default fetchListingsClusteringInfo;
