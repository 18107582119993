import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { withRouter } from "react-router-dom";

export const queryContext = React.createContext({});

export const QueryProvider = withRouter(({ children, location, history }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <queryContext.Provider
      value={{
        history,
        query: queryParams,
        querySearch: location.search,
        setQuery: query => setQueryParams({ history }, query),
        addQuery: query =>
          setQueryParams({ history }, { ...queryParams, ...query }),
        updateQueryString: updatedFields =>
          updateQueryString(history, queryParams, updatedFields),
      }}
    >
      {children}
    </queryContext.Provider>
  );
});

export function setQueryParams({ history }, query) {
  let newQuery = "";
  if (typeof query === "string") {
    newQuery = query;
    if (!query.includes("?")) newQuery = "?" + newQuery;
  } else {
    newQuery = queryString.stringify(query);
  }
  history.replace({
    search: newQuery,
  });
}

const updateQueryString = (history, query, updatedFields) => {
  const queryProxy = new Proxy(query, {
    get: (obj, prop) => (prop in obj ? obj[prop].split(",") : undefined),
  });

  return history.replace({
    search: queryString.stringify(
      {
        ...queryProxy,
        ...updatedFields,
      },
      { arrayFormat: "comma" },
    ),
  });
};

const useQuery = () => {
  return React.useContext(queryContext);
};

useQuery.propTypes = { history: PropTypes.shape().isRequired };

export default useQuery;
