import React from "react";
import { Segment } from "components";
import PropTypes from "prop-types";
import { priceFormatter, yieldFormatter } from "utilities";

const SaleListingIncomeStatistics = ({
  listing: {
    predicted_monthly_income,
    estimated_gross_yield,
    net_yield_completion_ast,
  },
}) => {
  return (
    <div>
      <Segment
        title="Ds Estimate Pcm"
        text={
          Number(predicted_monthly_income) !== 0
            ? `£${priceFormatter(predicted_monthly_income, true)}`
            : "n/a"
        }
      />
      <Segment
        title="Gross Yield"
        text={
          Number(estimated_gross_yield) !== 0
            ? yieldFormatter(estimated_gross_yield, 1)
            : "n/a"
        }
      />
      <Segment
        title="Net Initial Yield"
        text={
          Number(net_yield_completion_ast) !== 0
            ? yieldFormatter(net_yield_completion_ast, 1)
            : "n/a"
        }
      />
    </div>
  );
};

SaleListingIncomeStatistics.propTypes = {
  listing: PropTypes.shape({
    predicted_monthly_income: PropTypes.number,
    estimated_gross_yield: PropTypes.any,
    net_yield_completion_ast: PropTypes.number,
  }).isRequired,
};

export default SaleListingIncomeStatistics;
