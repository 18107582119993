/**
 * @flow
 * @relayHash 1149dad9a08c7f1f6171d708ce107493
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ScrapedSaleListingSourceSiteCode = "RIGHTMOVE" | "ZOOPLA" | "%future added value";
export type hubspotPushedQueriesSaleListingQueryVariables = {|
  id: string
|};
export type hubspotPushedQueriesSaleListingQueryResponse = {|
  +hubspotPushedSaleListings: ?{|
    +id: string,
    +hubspotDealStageInternalId: ?string,
    +propertyType: ?string,
    +hubspotId: string,
    +scrapedSaleListing: {|
      +id: string,
      +bedrooms: ?number,
      +price: ?number,
      +bathrooms: ?number,
      +address: string,
      +recepts: ?number,
      +propertyUrl: ?string,
      +sourceSiteCode: ScrapedSaleListingSourceSiteCode,
      +imageUrls: ?$ReadOnlyArray<string>,
    |},
  |}
|};
export type hubspotPushedQueriesSaleListingQuery = {|
  variables: hubspotPushedQueriesSaleListingQueryVariables,
  response: hubspotPushedQueriesSaleListingQueryResponse,
|};
*/


/*
query hubspotPushedQueriesSaleListingQuery(
  $id: ID!
) {
  hubspotPushedSaleListings(id: $id) {
    id
    hubspotDealStageInternalId
    propertyType
    hubspotId
    scrapedSaleListing {
      id
      bedrooms
      price
      bathrooms
      address
      recepts
      propertyUrl
      sourceSiteCode
      imageUrls
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "hubspotPushedSaleListings",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "HubspotPushedSaleListingNode",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hubspotDealStageInternalId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "propertyType",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hubspotId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "scrapedSaleListing",
        "storageKey": null,
        "args": null,
        "concreteType": "ScrapedSaleListingNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "bedrooms",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "price",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "bathrooms",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "address",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "recepts",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "propertyUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "sourceSiteCode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "imageUrls",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "hubspotPushedQueriesSaleListingQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "hubspotPushedQueriesSaleListingQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "hubspotPushedQueriesSaleListingQuery",
    "id": null,
    "text": "query hubspotPushedQueriesSaleListingQuery(\n  $id: ID!\n) {\n  hubspotPushedSaleListings(id: $id) {\n    id\n    hubspotDealStageInternalId\n    propertyType\n    hubspotId\n    scrapedSaleListing {\n      id\n      bedrooms\n      price\n      bathrooms\n      address\n      recepts\n      propertyUrl\n      sourceSiteCode\n      imageUrls\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c7fd781262bb72785d552d6ea7456bfb';
module.exports = node;
