import React from "react";
import { connect } from "react-redux";
import { Polyline } from "@react-google-maps/api";
import PropTypes from "prop-types";

import { getIncomeBandColor } from "utilities";
import propertyHullsDisabledFiltersSelectors from "state/global/selectors/user_input/map/filters/property_hulls_disabled_selectors";

const getStrokeColor = (hull, isSelected) => {
  const selectedColor = "#2ec700";
  const defaultColor = "#999999";
  const hullColor = getIncomeBandColor(hull.income_band);

  const strokeColor = hullColor || defaultColor;

  return isSelected ? selectedColor : strokeColor;
};

const PropertyHulls = React.memo(
  ({
    propertyHulls,
    showPropertyHulls,
    handleSelectPropertyHull,
    isPropertyHullVisible,
    isSelectedPropertyHull,
    coordsToGeometryTransformer,
    mapType,
    // ### mapStateToProps
    propertyHullsDisabled,
  }) => {
    const showPolygons = !propertyHullsDisabled && showPropertyHulls;

    return (
      showPolygons &&
      propertyHulls
        .filter(hull => isPropertyHullVisible(hull))
        .map(hull => {
          const isSelected = isSelectedPropertyHull(hull);
          const strokeColor = getStrokeColor(hull, isSelected);
          const strokeWeight = isSelected ? 9 : 7;
          const polygons = coordsToGeometryTransformer(hull);

          const options = {
            strokeWeight,
            strokeColor,
          };
          return polygons.map(polygon => (
            <Polyline
              key={polygon.id}
              onClick={() => handleSelectPropertyHull(hull)}
              options={options}
              path={polygon.path}
            />
          ));
        })
    );
  },
);

PropertyHulls.propTypes = {
  propertyHulls: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isPropertyHullVisible: PropTypes.func.isRequired,
  showPropertyHulls: PropTypes.bool.isRequired,
  propertyHullsDisabled: PropTypes.bool.isRequired,
  isSelectedPropertyHull: PropTypes.func.isRequired,
  handleSelectPropertyHull: PropTypes.func.isRequired,
  coordsToGeometryTransformer: PropTypes.func.isRequired,
  mapType: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => ({
  propertyHullsDisabled: propertyHullsDisabledFiltersSelectors
    .selectCurrent(state)
    .propertyHullsDisabled.includes(props.mapType),
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyHulls);
