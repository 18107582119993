import React from "react";
import PropTypes from "prop-types";
import graphql from "babel-plugin-relay/macro";
import { Button, InputField } from "components";
import { Formik, Form as FormikForm, Field } from "formik";
import {
  object as yupObject,
  string as yupString,
  boolean as yupBoolean,
  array as yupArray,
  number as yupNumber,
} from "yup";
import { commitMutation } from "react-relay";
import Environment from "relay/environment";
import styles from "./form.module.css";

const yup = {
  object: yupObject,
  string: yupString,
  boolean: yupBoolean,
  array: yupArray,
  number: yupNumber,
};

const postUser = graphql`
  mutation formPostQuery(
    $username: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
  ) {
    createUser(
      username: $username
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
    ) {
      user {
        id
      }
    }
  }
`;

const patchUser = graphql`
  mutation formPatchQuery($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        email
        id
        firstName
        lastName
      }
    }
  }
`;

function queryExecution(mutation, variables) {
  return new Promise(resolve => {
    commitMutation(Environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        return resolve([response, errors]);
      },
      onError: errors => {
        return resolve([null, errors]);
      },
    });
  });
}

function parseErrors(errors) {
  const error = errors
    .split("'")
    .join("")
    .split("[")
    .join("")
    .split("]")
    .join("")
    .split(",")[0];

  if (errors.includes("password")) return { password: error };
  return {
    message: error,
  };
}

function comparePasswords(comparedPassword) {
  return this.parent.password === comparedPassword;
}

const Form = ({ history, initialData, match }) => {
  const formType = initialData ? "edit" : "add";
  const validationSchema = yup.object().shape({
    username: yup.string().required("This field is required"),
    firstName: yup.string().required("This field is required"),
    lastName: yup.string().required("This field is required"),
    email: yup
      .string()
      .email()
      .required("This field is required"),
    password:
      formType === "edit"
        ? null
        : yup
            .string()
            .min(6)
            .required("This field is required"),
    passwordConfirm:
      formType === "edit"
        ? null
        : yup
            .string()
            .min(6)
            .test("passwordConfirm", "Passwords do not match", comparePasswords)
            .required("This field is required"),
  });

  const initialValues = {
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    passwordConfirm: "",
  };

  const handleSubmit = async (values, { setErrors, setSubmitting }) => {
    let data = {
      ...values,
    };

    delete data.passwordConfirm;

    if (formType === "edit") {
      delete data.password;
      // it filters out unchanged values
      Object.entries(data).forEach(([key, value]) => {
        if (initialData[key] === value) {
          delete data[key];
        }
      });
      data.id = match.params.userId;
      data = { input: data };
    }
    const submit = {
      edit: patchUser,
      add: postUser,
    }[formType];

    const [, errors] = await queryExecution(submit, data);

    if (errors) {
      setErrors(parseErrors(errors.source.errors[0].message));
      setSubmitting(false);
    } else {
      history.push("/users");
    }
  };

  return (
    <div className={styles.container}>
      <h5>
        {formType === "edit" ? "Edit " : "Add "}
        user
      </h5>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialData || initialValues}
        onSubmit={handleSubmit}
        render={({ isSubmitting, dirty, errors }) => {
          return (
            <div>
              {errors.message && (
                <div className={styles.error}>{errors.message}</div>
              )}
              <FormikForm autoComplete="off" className={styles.form}>
                <Field
                  component={InputField}
                  name="username"
                  placeholder="knownothing"
                  label="Username"
                />
                <Field
                  component={InputField}
                  name="firstName"
                  placeholder="Jon"
                  label="First name"
                />
                <Field
                  component={InputField}
                  name="lastName"
                  placeholder="Snow"
                  label="Last name"
                />
                <Field
                  component={InputField}
                  name="email"
                  placeholder="snow@skwire.com"
                  label="Email"
                />
                {formType === "add" && (
                  <>
                    <Field
                      component={InputField}
                      name="password"
                      type="password"
                      placeholder="•••••••••"
                      label="Password"
                    />
                    <Field
                      component={InputField}
                      name="passwordConfirm"
                      type="password"
                      placeholder="•••••••••"
                      label="Confirm password"
                    />
                  </>
                )}
                <br />
                <div className={styles.buttons}>
                  <Button
                    type="primary"
                    size="medium"
                    btnType="submit"
                    disabled={!dirty || isSubmitting}
                    padding="big"
                  >
                    Send
                  </Button>
                  <Button
                    type="secondary"
                    size="medium"
                    btnType="submit"
                    padding="big"
                    to="/users"
                  >
                    Back
                  </Button>
                </div>
              </FormikForm>
            </div>
          );
        }}
      />
    </div>
  );
};

Form.defaultProps = {
  initialData: null,
};

Form.propTypes = {
  history: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  initialData: PropTypes.shape(),
};
export default Form;
