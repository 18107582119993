import React from "react";
import PropTypes from "prop-types";
import RelayRenderer from "relay/relay_renderer";

import { withMapContextStore } from "state/local/stores/contexts/map_context_store";
import { FetchingError, Loader } from "components";
import queries from "../queries/index";
import {
  PropertyHullDetails,
  PriceStatistics,
  KeywordStatistics,
  GeneralStatistics,
} from "./components";

import sharedConfig from "../../../../configs/listing_configs";

import styles from "./hull_details.module.css";

const hullStatisticsQuery = hullType => {
  const {
    sharedConfig: {
      hullTypes: {
        longletPropertyHulls: { type: longletPropertyHullType },
        shortletPropertyHulls: { type: shortletPropertyHullType },
      },
    },
  } = sharedConfig;

  const {
    shortlet: { shortletHullStatisticsQuery },
    longlet: { longletHullStatisticsQuery },
  } = queries;

  switch (hullType) {
    case longletPropertyHullType:
      return longletHullStatisticsQuery;

    case shortletPropertyHullType:
      return shortletHullStatisticsQuery;

    default:
      console.error("Invalid property hull type");
      return null;
  }
};

const HullDetailsPanel = ({ id, selectedPropertyHullType }) => {
  const query =
    selectedPropertyHullType && hullStatisticsQuery(selectedPropertyHullType);

  return (
    <RelayRenderer query={query} variables={{ id }}>
      {(payload, loading) => {
        const data =
          payload?.shortletPropertyHullStatistics ??
          payload?.longletPropertyHullStatistics;

        return (
          <>
            {loading && <Loader type="primary" size="big" absolute />}
            {!loading && data && (
              <div className={styles.body}>
                <div className={styles.title}>Details</div>
                <PropertyHullDetails data={data} />
                <div className={styles.title}>Statistics</div>
                <GeneralStatistics data={data} />
                <PriceStatistics data={data} />
                <KeywordStatistics data={data} />
              </div>
            )}
            {!loading && !data && <FetchingError />}
          </>
        );
      }}
    </RelayRenderer>
  );
};

HullDetailsPanel.propTypes = {
  id: PropTypes.string.isRequired,
  selectedPropertyHullType: PropTypes.string,
};

HullDetailsPanel.defaultProps = {
  selectedPropertyHullType: null,
};

export default withMapContextStore(HullDetailsPanel);
