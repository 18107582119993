import fetch from "./middleware/api_middleware";

export const getToken = data =>
  fetch({
    method: "POST",
    url: "/token/",
    data,
  });

export const getRefreshToken = data =>
  fetch({
    method: "POST",
    url: "/token/refresh/",
    data,
  });
