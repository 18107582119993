import React from "react";
import PropTypes from "prop-types";

import calendarImg from "assets/images/calendar.svg";
import styles from "./form.module.css";

const handleFormatDate = date => date.replace(new RegExp("-", "g"), ".");

const DateLabel = ({ date }) => (
  <div className={styles.textHelper}>
    <img src={calendarImg} alt="" />
    {handleFormatDate(date)}
  </div>
);

const FormPickedDates = ({ range }) => {
  const from = range[0];
  const to = range[range.length - 1];
  const hasLastElement = range.length > 1;

  return (
    <div className={styles.text}>
      {from && <DateLabel date={from} />}
      {hasLastElement && <div className={styles.line} />}
      {hasLastElement && <DateLabel date={to} />}
    </div>
  );
};

FormPickedDates.propTypes = {
  range: PropTypes.arrayOf(PropTypes.string).isRequired,
};

DateLabel.propTypes = {
  date: PropTypes.string.isRequired,
};

export default FormPickedDates;
