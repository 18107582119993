import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import styles from "./collapse_list.module.css";

const CollapseList = ({ title, list, prefix, addIndex, formatter, hide }) => {
  if (hide && !list.length) return null;
  return (
    <Accordion allowZeroExpanded>
      <AccordionItem>
        <AccordionItemButton className={styles.button}>
          <AccordionItemState>
            {({ expanded }) => (
              <div className={styles.title}>
                <span>{expanded ? "- " : "+ "}</span>
                {title}
              </div>
            )}
          </AccordionItemState>
        </AccordionItemButton>
        <AccordionItemPanel>
          <div>
            {list &&
              list.map((el, index) => (
                <div className={styles.element} key={el + Math.random()}>
                  {addIndex && (
                    <span className={styles.index}>{`${index + 1}.`}</span>
                  )}
                  {prefix}
                  {formatter ? formatter(el) : el}
                </div>
              ))}
          </div>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

CollapseList.defaultProps = {
  formatter: el => el,
  prefix: null,
  addIndex: null,
  hide: null,
  list: [],
};

CollapseList.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  ),
  prefix: PropTypes.string,
  addIndex: PropTypes.bool,
  formatter: PropTypes.func,
  hide: PropTypes.bool,
};

export default CollapseList;
