/* eslint-disable */
import React, { useMemo } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

import styles from "./modal.module.css";

const handleCloseModal = (disabled, handleClose) => !disabled && handleClose();

const Modal = ({ isOpen, handleClose, children, disabled }) => {
  const container = useMemo(() => document.body, []);
  const component = useMemo(
    () => (
      <>
        <div
          className={styles.background}
          onClick={() => handleCloseModal(disabled, handleClose)}
        />
        <div className={styles.modal}>{children}</div>
      </>
    ),
    [children],
  );

  const modalChildren = isOpen ? component : null;
  return createPortal(modalChildren, container);
};

Modal.defaultProps = {
  disabled: false,
};
Modal.propTypes = {
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
};

export default Modal;
