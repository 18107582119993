const actionTypes = {
  SET_IS_MAP_LOADED: "SET_IS_MAP_LOADED",
  SET_SEARCH_MARKER: "SET_SEARCH_MARKER",
  DESELECT_ALL: "DESELECT_ALL",
  SELECT_LISTING: "SELECT_LISTING",
  DESELECT_LISTING: "DESELECT_LISTING",
  SELECT_PROPERTY_HULL: "SELECT_PROPERTY_HULL",
  DESELECT_PROPERTY_HULL: "DESELECT_PROPERTY_HULL",
  SELECT_AMENITY_HULL: "SELECT_AMENITY_HULL",
  DESELECT_AMENITY_HULL: "DESELECT_AMENITY_HULL",
  HIGHLIGHT_AMENITY_TYPE: "HIGHLIGHT_AMENITY_TYPE",
};

const actions = {
  setIsMapLoaded: booleanValue => ({
    type: actionTypes.SET_IS_MAP_LOADED,
    payload: !!booleanValue,
  }),
  setSearchMarker: markerObject => ({
    type: actionTypes.SET_SEARCH_MARKER,
    payload: markerObject,
  }),
  deselectAll: () => ({
    type: actionTypes.DESELECT_ALL,
    payload: {},
  }),
  selectListing: (listingId, listingType) => ({
    type: actionTypes.SELECT_LISTING,
    payload: {
      listingId,
      listingType,
    },
  }),
  deselectListing: () => ({
    type: actionTypes.DESELECT_LISTING,
    payload: {},
  }),
  selectPropertyHull: (propertyHullId, propertyHullType) => ({
    type: actionTypes.SELECT_PROPERTY_HULL,
    payload: {
      propertyHullId,
      propertyHullType,
    },
  }),
  deselectPropertyHull: () => ({
    type: actionTypes.DESELECT_PROPERTY_HULL,
    payload: {},
  }),
  selectAmenityHull: (amenityHullId, amenityHullType) => ({
    type: actionTypes.SELECT_AMENITY_HULL,
    payload: {
      amenityHullId,
      amenityHullType,
    },
  }),
  deselectAmenityHull: () => ({
    type: actionTypes.DESELECT_AMENITY_HULL,
    payload: {},
  }),
  highlightAmenityType: amenityType => ({
    type: actionTypes.HIGHLIGHT_AMENITY_TYPE,
    payload: { amenityType },
  }),
};

export { actionTypes, actions };
