import { flatMap, forIn, range } from "lodash";
import filterConfigs from "./filter_configs";
import { MARKER_SVG_COLORS } from "./marker_configs";

const {
  sharedConfig: { listingBeds },
} = filterConfigs;

const SalesCheckingStageNames = [
  "CHECKING",
  "APPROVING",
  "REVENUE_PREDICTING",
  "REVENUE_PREDICTED",
];

const extractStageIdsFromPipelines = (
  hubspotPipelinesToDealStagesMapping,
  stageName,
) => {
  const stageIds = [];

  forIn(hubspotPipelinesToDealStagesMapping, (value, _key) => {
    if (value.stages?.[stageName]) {
      stageIds.push(value.stages[stageName]);
    }
  });

  return stageIds;
};

const salesDealsMarkerMapping = hubspotPipelinesToDealStagesMapping => [
  {
    label: "Sourced",
    hubspot_deal_stage_internal_ids: extractStageIdsFromPipelines(
      hubspotPipelinesToDealStagesMapping,
      "SOURCED",
    ),
    color: MARKER_SVG_COLORS.SALES.light,
  },
  {
    label: "Appraising",
    hubspot_deal_stage_internal_ids: extractStageIdsFromPipelines(
      hubspotPipelinesToDealStagesMapping,
      "APPRAISING",
    ),
    color: MARKER_SVG_COLORS.SALES.light,
  },
  {
    label: "Checking",
    hubspot_deal_stage_internal_ids: (() =>
      flatMap(SalesCheckingStageNames, stageName =>
        extractStageIdsFromPipelines(
          hubspotPipelinesToDealStagesMapping,
          stageName,
        ),
      ))(),
    color: MARKER_SVG_COLORS.SALES.light,
  },
  {
    label: "Approved",
    hubspot_deal_stage_internal_ids: extractStageIdsFromPipelines(
      hubspotPipelinesToDealStagesMapping,
      "APPROVED",
    ),
    color: MARKER_SVG_COLORS.SALES.medium,
  },
  {
    label: "Offering",
    hubspot_deal_stage_internal_ids: extractStageIdsFromPipelines(
      hubspotPipelinesToDealStagesMapping,
      "OFFERING",
    ),
    color: MARKER_SVG_COLORS.SALES.medium,
  },
  {
    label: "Viewing",
    hubspot_deal_stage_internal_ids: extractStageIdsFromPipelines(
      hubspotPipelinesToDealStagesMapping,
      "VIEWING",
    ),
    color: MARKER_SVG_COLORS.SALES.medium,
  },
  {
    label: "Conveyancing",
    hubspot_deal_stage_internal_ids: extractStageIdsFromPipelines(
      hubspotPipelinesToDealStagesMapping,
      "CONVEYANCING",
    ),
    color: MARKER_SVG_COLORS.SALES.dark,
  },
  {
    label: "Completed",
    hubspot_deal_stage_internal_ids: extractStageIdsFromPipelines(
      hubspotPipelinesToDealStagesMapping,
      "COMPLETED",
    ),
    color: MARKER_SVG_COLORS.SALES.dark,
  },
  {
    label: "Forfeited",
    hubspot_deal_stage_internal_ids: extractStageIdsFromPipelines(
      hubspotPipelinesToDealStagesMapping,
      "FORFEITED",
    ),
    color: MARKER_SVG_COLORS.SALES.grey,
  },
  {
    label: "Rejected",
    hubspot_deal_stage_internal_ids: extractStageIdsFromPipelines(
      hubspotPipelinesToDealStagesMapping,
      "REJECTED",
    ),
    color: MARKER_SVG_COLORS.SALES.grey,
  },
  {
    label: "Declined",
    hubspot_deal_stage_internal_ids: extractStageIdsFromPipelines(
      hubspotPipelinesToDealStagesMapping,
      "DECLINED",
    ),
    color: MARKER_SVG_COLORS.SALES.grey,
  },
];

export { salesDealsMarkerMapping };
