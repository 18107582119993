import React from "react";
import PropTypes from "prop-types";
import camelCase from "lodash/camelCase";

import amenityHullsDisabledSelectors from "state/global/selectors/user_input/map/filters/amenity_hulls_disabled_selectors";
import { Switch } from "components/";
import useQuery from "components/hooks/use_query";
import handleAmenityHullFiltering from "pages/map/left_panel/filter_handlers/amenity_hulls_filter";

const HullsVisibilitySwitch = ({
  amenityHullsDisabledFiltersCurrent,
  amenityHullsDisabledFiltersActions,
  disabled,
  mapType,
}) => {
  const { updateQueryString } = useQuery();

  return (
    <Switch
      value={
        !amenityHullsDisabledFiltersCurrent.amenityHullsDisabled.includes(
          camelCase(mapType),
        ) && !disabled
      }
      onChange={() =>
        handleAmenityHullFiltering(
          updateQueryString,
          amenityHullsDisabledFiltersActions,
          amenityHullsDisabledSelectors,
          "amenityHullsDisabled",
          camelCase(mapType),
        )
      }
      label="Show amenity hulls"
    />
  );
};

HullsVisibilitySwitch.propTypes = {
  amenityHullsDisabledFiltersCurrent: PropTypes.shape({
    amenityHullsDisabled: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  mapType: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  amenityHullsDisabledFiltersActions: PropTypes.shape().isRequired,
};

export default HullsVisibilitySwitch;
