/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import errorIcon from "assets/images/error.svg";
import successIcon from "assets/images/success.svg";
import { Loader } from "components/";
import PropTypes from "prop-types";
import React from "react";
import styles from "../index.module.css";

export const closeOnSuccess = (
  handleClose,
  updateSingleRecord,
  listingId,
  hubspotStageNamesToHubspotStageInternalIDs,
  columnName,
) => {
  setTimeout(() => {
    updateSingleRecord(listingId, {
      hubspot_deal_stage_internal_id:
        hubspotStageNamesToHubspotStageInternalIDs[columnName.toUpperCase()],
    });
    handleClose();
  }, 1000);
};
const SendToHubspotColumnModalContent = ({
  columnName,
  handleClose,
  sendToHubspotColumn: { triggerRequest, resetRequestStore },
  sendToHubspotColumnRequestStatus: {
    requestInProgress,
    requestSuccessful,
    requestFailed,
    responseData,
  },
  updateSingleRecord,
  listing: { id: listingId, pipeline_id },
  hubspotPipelinesToDealStagesMapping,
}) => {
  const hubspotStageNamesToHubspotStageInternalIDs =
    hubspotPipelinesToDealStagesMapping[pipeline_id].stages;

  const handleOnClick = () => {
    triggerRequest(listingId, pipeline_id, {
      hubspot_deal_stage_internal_id:
        hubspotStageNamesToHubspotStageInternalIDs[columnName.toUpperCase()],
    });
  };

  React.useEffect(() => resetRequestStore, []);

  return (
    <div
      className={
        requestSuccessful
          ? `${styles.container} ${styles["container-hidden"]}`
          : styles.container
      }
    >
      {requestInProgress && <Loader size="small" type="primary" />}

      <div className={styles.content}>
        {!requestInProgress && !responseData && (
          <SetHubSpotStageMessage
            columnName={columnName}
            handleClose={handleClose}
            handleOnClick={handleOnClick}
          />
        )}
        {requestSuccessful && responseData.listingId === listingId && (
          <SetHubSpotStageSuccess
            closeModal={() =>
              closeOnSuccess(
                handleClose,
                updateSingleRecord,
                listingId,
                hubspotStageNamesToHubspotStageInternalIDs,
                columnName,
              )
            }
          />
        )}
        {requestFailed && responseData.listingId === listingId && (
          <SetHubSpotStageError error={responseData.error} />
        )}
      </div>
    </div>
  );
};

export const SetHubSpotStageError = ({ error }) => {
  console.error(error);

  return (
    <div className={styles["error-container"]}>
      <img
        src={errorIcon}
        alt="request failed"
        className={styles["error-icon"]}
      />
      <span>An error occured. Please contact the admin</span>
    </div>
  );
};

export const SetHubSpotStageSuccess = ({ closeModal }) => {
  React.useEffect(() => {
    closeModal();
  });

  return (
    <img
      src={successIcon}
      className={styles["success-icon"]}
      alt="request successfull"
    />
  );
};

const SetHubSpotStageMessage = ({ handleClose, handleOnClick, columnName }) => (
  <>
    <span className={styles.close} onClick={handleClose}>
      x
    </span>
    <div className={styles.title}>
      Send to HubSpot&apos;s &apos;
      {columnName}
      &apos; column?
    </div>
    <button
      type="button"
      onClick={handleOnClick}
      className={styles["send-button"]}
    >
      Send
    </button>
  </>
);

SendToHubspotColumnModalContent.propTypes = {
  columnName: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  sendToHubspotColumn: PropTypes.shape({
    triggerRequest: PropTypes.func.isRequired,
    resetRequestStore: PropTypes.func.isRequired,
  }).isRequired,
  sendToHubspotColumnRequestStatus: PropTypes.shape({
    requestInProgress: PropTypes.bool,
    requestSuccessful: PropTypes.bool,
    requestFailed: PropTypes.bool,
    responseData: PropTypes.shape({
      error: PropTypes.string,
      listingId: PropTypes.number.isRequired,
    }),
  }).isRequired,
  listing: PropTypes.shape({
    id: PropTypes.number.isRequired,
    pipeline_id: PropTypes.string.isRequired,
    hubspot_deal_stage_internal_id: PropTypes.string.isRequired,
  }).isRequired,
  hubspotPipelinesToDealStagesMapping: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      pipelineId: PropTypes.string.isRequired,
      stages: PropTypes.objectOf(PropTypes.string),
    }),
  ).isRequired,
  updateSingleRecord: PropTypes.func.isRequired,
};

export default SendToHubspotColumnModalContent;
