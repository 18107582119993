import React from "react";
import PropTypes from "prop-types";

import styles from "../hulls_panel.module.css";
import PropertyHullFilters from "./property_hull_filters";

const HullsPanel = ({ disabled }) => (
  <div className={styles.container} data-testid="longlet-hulls-panel">
    <PropertyHullFilters disabled={disabled} />
  </div>
);

HullsPanel.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default HullsPanel;
