import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import matchSorter from "match-sorter";

import { Expansion, Checkbox } from "components/";
import useQuery from "components/hooks/use_query";
import handleAmenityHullFiltering from "pages/map/left_panel/filter_handlers/amenity_hulls_filter";
import amenityHullFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/amenity_hull_filters_selectors";

import searchImg from "assets/images/search.svg";
import styles from "../../hulls_panel.module.css";

const AmenitiesExpansion = ({
  disabled,
  allAmenityTypes,
  amenityHullFiltersActions,
  amenityHullFiltersCurrent,
}) => {
  const [amenityTypesSearchList, setAmenityTypesSearchList] = useState([]);
  const [amenityTypesSearchFilter, setAmenityTypesSearchFilter] = useState("");

  useEffect(() => {
    const amenityTypeCodes = allAmenityTypes.map(el => el.amenity);
    const filteredList = matchSorter(
      amenityTypeCodes,
      amenityTypesSearchFilter,
    );

    setAmenityTypesSearchList(filteredList);
  }, [amenityTypesSearchFilter, allAmenityTypes]);

  const { updateQueryString } = useQuery();

  return (
    <Expansion
      onClose={() => handleAmenityTypesSearchClose(setAmenityTypesSearchFilter)}
      controller={toggle => (
        <button
          type="button"
          disabled={disabled}
          className={styles.btn}
          onClick={toggle}
        >
          Amenities
          {` (${amenityTypesSearchList.length})`}
          <span />
        </button>
      )}
    >
      <div className={styles.listWrapper}>
        <div className={styles.search}>
          <img src={searchImg} alt="" />
          <input
            type="text"
            onChange={event =>
              handleAmenityTypesSearchChange(event, setAmenityTypesSearchFilter)
            }
            placeholder="Search..."
          />
        </div>
        <AmenitiesList
          amenityHullFiltersActions={amenityHullFiltersActions}
          amenityHullFiltersCurrent={amenityHullFiltersCurrent}
          amenityTypesSearchList={amenityTypesSearchList}
        />
      </div>
    </Expansion>
  );
};

AmenitiesExpansion.propTypes = {
  disabled: PropTypes.bool.isRequired,
  allAmenityTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  amenityHullFiltersActions: PropTypes.shape().isRequired,
  amenityHullFiltersCurrent: PropTypes.shape({
    amenityTypes: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const AmenitiesList = ({
  amenityTypesSearchList,
  amenityHullFiltersActions,
  amenityHullFiltersCurrent,
}) => {
  const { updateQueryString } = useQuery();

  return (
    <div className={styles.list}>
      {amenityTypesSearchList.map(type => (
        <Checkbox
          key={type}
          className={styles.element}
          onClick={() =>
            handleAmenityHullFiltering(
              updateQueryString,
              amenityHullFiltersActions,
              amenityHullFiltersSelectors,
              "amenityTypes",
              type,
            )
          }
          checked={amenityHullFiltersCurrent.amenityTypes.includes(type)}
          label={`${getAmenityName(type)}`}
        />
      ))}
      {!amenityTypesSearchList.length && (
        <div className={styles.empty}>No results</div>
      )}
    </div>
  );
};

const handleAmenityTypesSearchChange = (
  { target: { value } },
  setAmenityTypesSearchFilter,
) => setAmenityTypesSearchFilter(value.split(" ").join(""));

const getAmenityName = value => value.split("_").join(" ");

const handleAmenityTypesSearchClose = setAmenityTypesSearchFilter => {
  setAmenityTypesSearchFilter("");
};

export default AmenitiesExpansion;
