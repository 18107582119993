import queryStringUpdateCallback from "pages/map/components/helpers/query_string_update";

const handlePropertyHullFiltering = (
  updateQueryString,
  propertyHullFiltersActions,
  filterSelector,
  filterName,
  value,
  queryParamPrefix = "",
  shouldReplaceValue,
) => {
  let togglePromise;

  if (shouldReplaceValue) {
    togglePromise = propertyHullFiltersActions.setFilterValue(
      filterName,
      value,
    );
  } else {
    togglePromise = propertyHullFiltersActions.toggleCollectionFilter(
      filterName,
      value,
    );
  }

  togglePromise.then(globalState =>
    queryStringUpdateCallback(
      globalState,
      filterSelector,
      filterName,
      updateQueryString,
      queryParamPrefix,
    ),
  );
};

export default handlePropertyHullFiltering;
