import React from "react";
import { Marker } from "@react-google-maps/api";
import PropTypes from "prop-types";

const SearchLocation = React.memo(({ marker }) => {
  const { lat, lng } = marker;

  return lat && lng ? (
    <Marker
      position={{
        lat,
        lng,
      }}
    />
  ) : null;
});

SearchLocation.defaultProps = {
  marker: null,
};

SearchLocation.propTypes = {
  marker: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

export default SearchLocation;
