import { format, eachDay, startOfDay, endOfDay } from "date-fns";

import { postPropertyStatisticUserPrice } from "api/properties";

export const dateFormat = "YYYY-MM-DD";

export const handleSetDates = ({
  dates,
  setStartValue,
  setValue,
  setRange,
}) => {
  setStartValue(null);
  setValue(dates);
  const uniqueDates = [...new Set(dates.map(date => format(date, dateFormat)))];

  if (uniqueDates.length > 1) {
    const rangeDays = eachDay(dates[0], dates[1]).map(date =>
      format(date, dateFormat),
    );
    setRange(rangeDays);
  } else {
    setRange(uniqueDates);
  }
};

export const getDayValues = (months, date) => {
  const [year, month] = date.split("-");
  const monthIndex = months.findIndex(
    element => element.month === month && element.year === year,
  );
  let monthValues = { algorithm_price: null, proportion_booked: null };

  if (monthIndex !== -1 && months[monthIndex].days[date]) {
    const dayValues = months[monthIndex].days[date];
    monthValues = {
      algorithm_price: dayValues.avgPrice || null,
      proportion_booked: dayValues.booked || null,
    };
  }
  return monthValues;
};

export const createDatesValues = (details, range) =>
  range.reduce((acc, date) => {
    acc[date] = getDayValues(details.months, date);
    return acc;
  }, {});

export const createFormData = (dates, username, user_price) => ({
  dates,
  username,
  user_price,
});

export const createNewPriceValues = (dates, values) =>
  Object.keys(dates).reduce((acc, date) => {
    acc[date] = Number(values.price);

    return acc;
  }, {});

export const handleSubmit = async (
  values,
  { setErrors, setSubmitting },
  { range, details, user, setSendForm, updatePrices, handleClose },
) => {
  const dates = createDatesValues(details, range);
  const formData = createFormData(dates, user.username, Number(values.price));

  const [, errors] = await postPropertyStatisticUserPrice(
    details.airbnb_id,
    formData,
  );

  if (errors) {
    setErrors(errors);
    setSendForm(false);
    setSubmitting(false);
  } else {
    const newPrices = createNewPriceValues(dates, values);
    updatePrices(newPrices);
    handleClose();
  }
};

export const handleSetSingleDate = (date, { setStartValue, setRange }) => {
  setStartValue([date]);
  setRange([format(date, dateFormat)]);
};

export const handleNextStep = ({ setStep, startValue, setValue }) => {
  setStep(2);

  if (startValue) {
    setValue([
      new Date(startOfDay(startValue)),
      new Date(endOfDay(startValue)),
    ]);
  }
};

export const formStepTitles = {
  1: "Select date range",
  2: "Set the price",
};
