import React from "react";
import PropTypes from "prop-types";
import styles from "./input.module.css";

const Input = ({
  value,
  field,
  touched,
  errors,
  error,
  label,
  placeholder,
  type,
  onClick,
  onChange,
  name,
  noLabel,
  noError,
}) => {
  const [fieldValue, setFieldValue] = React.useState(value || "");

  const controlled = value !== undefined || field !== undefined;

  const handleChange = e => {
    if (controlled) {
      onChange(e);
    } else {
      setFieldValue(e.target.value);
    }
  };

  const handleFormikErrors = () => {
    if (touched && errors && field) {
      return touched[field.name] && errors[field.name] && errors[field.name];
    }
    return null;
  };

  const inputValue = () => {
    if (field) {
      if (typeof field.value === "undefined") {
        return "";
      }
      return field.value;
    }
    return fieldValue;
  };

  const inputProps = {
    autoComplete: "off",
    onClick,
    type,
    name,
    ...field,
    value: inputValue(),
    onChange: handleChange,
    placeholder,
    className: styles.input,
  };

  return (
    <div className={styles.container}>
      {!noLabel && <span className={styles.label}>{label}</span>}
      <input className={styles.input} type="text" {...inputProps} />
      {!noError && (
        <div className={styles.error}>{error || handleFormikErrors()}</div>
      )}
    </div>
  );
};

Input.defaultProps = {
  value: undefined,
  field: undefined,
  touched: null,
  errors: null,
  error: null,
  label: null,
  placeholder: null,
  type: null,
  onClick: () => null,
  onChange: undefined,
  name: null,
  noLabel: null,
  noError: null,
};

Input.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  field: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
    name: PropTypes.string,
  }),
  touched: PropTypes.shape(),
  errors: PropTypes.shape(),
  error: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.string,
  noLabel: PropTypes.bool,
  noError: PropTypes.string,
};

export default Input;
