import { handleSetBoundingBox } from "state/helpers/map_helpers";
import { arrayFromMinMaxValues } from "utilities";

export const constructShortletListingsQueryParams = (
  currentFilters,
  mapContext,
  pageSize = 200,
) => {
  const { listingBeds, listingTypes: property_types } = currentFilters;

  const currentBounds = mapContext.getBounds();
  const polygonBounds = handleSetBoundingBox(
    currentBounds,
    currentBounds,
    true,
  );

  return {
    polygons: polygonBounds,
    currentBounds,
    options: {
      bedrooms: arrayFromMinMaxValues(listingBeds),
      page_size: pageSize,
      property_types,
    },
  };
};
