import mapFilterFactories from "state/global/factories/map_filter_factories";
import {
  actionTypes,
  actionTypePrefix,
} from "state/global/actions/user_input/map/filters/map_type_filters_actions";
import { snakeCaseToCamelCase } from "utilities";
import mapConfigs from "configs/map_configs";

const {
  mapTypeNames: { longlet, shortlet, sales },
} = mapConfigs;

const FILTER_OPTIONS = [longlet, shortlet, sales];
const FILTER_DEFAULTS = [longlet, sales];

const filterOptions = {
  mapTypes: FILTER_OPTIONS.map(type => snakeCaseToCamelCase(type)),
};
const filterDefaults = {
  mapTypes: FILTER_DEFAULTS.map(type => snakeCaseToCamelCase(type)),
};

const allowedFilterKeys = ["mapTypes"];

const reducer = mapFilterFactories.generateFilterReducer(
  actionTypes,
  actionTypePrefix,
  filterOptions,
  filterDefaults,
  allowedFilterKeys,
);

export default reducer;
