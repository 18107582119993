import React from "react";
import PropTypes from "prop-types";

import { Segment, CollapseList } from "components";
import { priceFormatter } from "utilities";

const toPercentage = value => {
  return (value || 0) * 100;
};

const PricesStatistics = ({ data, yield_per_price }) => {
  const {
    reviewToReservationRatio,
    average12MonthIncome,
    all12MonthIncomes,
  } = data;
  return (
    <div>
      {reviewToReservationRatio !== undefined && (
        <Segment
          title="Review to reservation ratio"
          text={toPercentage(reviewToReservationRatio).toFixed(0) + "%"}
          info="Proportion of reviews received by the property in relation to the total number of
                    reservations from the time the property was first listed."
        />
      )}
      <Segment
        title="Average 12 months incomes"
        text={"£" + priceFormatter(average12MonthIncome)}
        info="The average income obtained by the property over all consecutive 12 month
                    listing periods."
      />
      {all12MonthIncomes.length > 1 && (
        <CollapseList
          title="Show all months incomes"
          list={all12MonthIncomes}
          prefix="£"
          formatter={priceFormatter}
          addIndex
        />
      )}
      {yield_per_price()}
    </div>
  );
};

PricesStatistics.propTypes = {
  data: PropTypes.shape({
    maxPrice12: PropTypes.number,
    maxPrice12PercYield: PropTypes.number,
    maxPrice15PercYield: PropTypes.number,
    maxPrice18PercYield: PropTypes.number,
    maxPrice15: PropTypes.number,
    maxPrice18: PropTypes.number,
    average12MonthIncome: PropTypes.number.isRequired,
    reviewToReservationRatio: PropTypes.number,
    all12MonthIncomes: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.shape({}),
    ]).isRequired,
  }).isRequired,
  yield_per_price: PropTypes.func.isRequired,
};

export default PricesStatistics;
