import mapFilterFactories from "state/global/factories/map_filter_factories";

import {
  actionTypes,
  actionTypePrefix,
} from "state/global/actions/user_input/map/filters/comps_filters_actions";

const filterOptions = {};

const filterDefaults = {
  compsQuality: {
    min: 0,
    max: 100,
  },
};

const allowedFilterKeys = ["compsQuality"];

const reducer = mapFilterFactories.generateFilterReducer(
  actionTypes,
  actionTypePrefix,
  filterOptions,
  filterDefaults,
  allowedFilterKeys,
);
export default reducer;
