import React from "react";
import PropTypes from "prop-types";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { SliderRail, Handle, Track, Tick } from "./components";

import styles from "./slider.module.css";

const RangeSlider = ({
  handleOnFilterChange,
  filtersCurrent,
  filterOptions,
  mode,
  filterName,
  title,
  disabled,
  showTickLabels,
}) => {
  const options = Object.keys(filterOptions);
  const domain = [parseInt(options[0], 0), parseInt(options.slice(-1)[0], 0)];

  return (
    <div className={styles.container} data-testid="slider">
      <div className={styles.label}>
        {title}
        <span>{formatLabel(filtersCurrent, filterName, filterOptions)}</span>
      </div>
      <div className={styles.slider}>
        <Slider
          mode={mode}
          step={1}
          domain={domain}
          disabled={disabled}
          rootStyle={{ position: "relative", width: "100%" }}
          onSlideEnd={val =>
            val.length ? onChange(val, handleOnFilterChange, filterOptions) : []
          }
          values={initialFilterValues(
            filtersCurrent,
            filterName,
            filterOptions,
          )}
        >
          <Rail>
            {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
          </Rail>
          <Handles>{props => !disabled && RangeHandles(props, domain)}</Handles>
          <Tracks left={false} right={false}>
            {props => !disabled && RangeTracks(props)}
          </Tracks>
          <Ticks values={options}>
            {props => RangeTicks(props, filterOptions, showTickLabels)}
          </Ticks>
        </Slider>
      </div>
    </div>
  );
};

const RangeHandles = ({ handles, getHandleProps }, domain) => (
  <div className="slider-handles">
    {handles.map((handle, index) => (
      <Handle
        key={handle.id}
        handle={handle}
        domain={domain}
        getHandleProps={getHandleProps}
        testId={index ? "slider-right-handle" : "slider-left-handle"}
      />
    ))}
  </div>
);

const RangeTracks = ({ tracks, getTrackProps }) => (
  <div className="slider-tracks">
    {tracks.map(({ id, source, target }) => (
      <Track
        key={id}
        source={source}
        target={target}
        getTrackProps={getTrackProps}
      />
    ))}
  </div>
);

const RangeTicks = ({ ticks }, filterOptions, showTrackLabels) => {
  return (
    <div>
      {ticks.map(tick => (
        <Tick
          key={tick.id}
          tick={tick}
          count={ticks.length}
          format={value =>
            showTrackLabels ? formatTick(value, filterOptions) : null
          }
        />
      ))}
    </div>
  );
};

const formatTick = (value, filterOptions) => filterOptions[value].label;

const selectLabelFromFilterOptions = (bandValue, filterOptions) =>
  Object.values(filterOptions).find(option => option.value === bandValue).label;

const selectKeyFromFilterOptions = (bandValue, filterOptions) => {
  const option = Object.entries(filterOptions).find(
    value => value[1].value === bandValue,
  );

  return parseInt( option[0], 10);
};

const initialFilterValues = (filtersCurrent, filterName, filterOptions) => {
  if (filtersCurrent) {
    const { leftBand, rightBand } = filtersCurrent[filterName];

    return [
      selectKeyFromFilterOptions(leftBand, filterOptions),
      selectKeyFromFilterOptions(rightBand, filterOptions),
    ];
  }
  return [];
};

const formatLabel = (filtersCurrent, filterName, filterOptions) => {
  if (filtersCurrent) {
    const { leftBand, rightBand } = filtersCurrent[filterName];
    return `${selectLabelFromFilterOptions(
      leftBand,
      filterOptions,
    )} - ${selectLabelFromFilterOptions(rightBand, filterOptions)}`;
  }

  return null;
};

const onChange = (val, handleOnFilterChange, filterOptions) => {
  const [leftBand, rightBand] = val;
  const filterValue = {
    leftBand: filterOptions[leftBand].value,
    rightBand: filterOptions[rightBand].value,
  };
  handleOnFilterChange(filterValue);
};

RangeSlider.defaultProps = {
  showTickLabels: true,
};

RangeSlider.propTypes = {
  handleOnFilterChange: PropTypes.func.isRequired,
  filtersCurrent: PropTypes.shape().isRequired,
  filterOptions: PropTypes.shape().isRequired,
  mode: PropTypes.number.isRequired,
  filterName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showTickLabels: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
};

export default RangeSlider;
