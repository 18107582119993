import React from "react";
import useSupercluster from "use-supercluster";
import { Marker } from "@react-google-maps/api";

import { MapContext } from "state/local/stores/contexts/map_context_store";
import mapConfigs from "configs/map_configs";
import clusterUtilities from "./cluster_utilities";

const {
  zoomLevels: { maximumMapZoom, listings: listingsAppearZoom },
} = mapConfigs;

const ClusteredMarkers = ({ currentListingType, clustersInfo }) => {
  const { mapInstance } = React.useContext(MapContext);
  const zoom = mapInstance.getZoom();

  const { clusters, supercluster } = useSupercluster({
    zoom,
    options: clusterUtilities.superClusterOptions,
    points: clustersInfo,
    bounds: clusterUtilities.getCurrentBoundingBox(mapInstance),
  });

  return clusters.map(cluster => {
    const {
      geometry: {
        coordinates: [lng, lat],
      },
      properties: {
        point_count: pointCount,
        cluster_id: clusterId,
        cluster: isCluster,
      },
    } = cluster;

    const handleOnClick = () => {
      const expansionZoom =
        pointCount === 1 // single marker cluster
          ? listingsAppearZoom
          : Math.min(
              supercluster.getClusterExpansionZoom(clusterId),
              maximumMapZoom,
            );
      mapInstance.setZoom(expansionZoom);
      mapInstance.panTo({ lat, lng });
    };

    return (
      isCluster && (
        <Marker
          key={`cluster-${clusterId}`}
          position={{ lat, lng }}
          label={clusterUtilities.getClusterLabel(pointCount)}
          icon={clusterUtilities.getClusterIcon(currentListingType)}
          title={currentListingType}
          onClick={_event => handleOnClick()}
        />
      )
    );
  });
};

export default ClusteredMarkers;
