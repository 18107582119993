import React from "react";
import PropTypes from "prop-types";
import Calendar from "react-calendar/dist/entry.nostyle";

import { Button } from "components";

import styles from "./form.module.css";
import "./form_calendar.css";

const NextStepButton = ({ range, handleNextStep }) => (
  <Button
    btnType="submit"
    type="secondary"
    full
    disabled={!range.length}
    onClick={handleNextStep}
  >
    Next
  </Button>
);

const FormStepOne = ({
  handleSetDates,
  value,
  range,
  handleNextStep,
  handleSetSingleDate,
}) => {
  return (
    <div className={`react-calendar-form ${styles.date}`}>
      <Calendar
        onChange={handleSetDates}
        returnValue="range"
        selectRange
        maxDetail="month"
        minDetail="month"
        value={value}
        onClickDay={handleSetSingleDate}
      />
      <div className={styles.buttons}>
        <NextStepButton range={range} handleNextStep={handleNextStep} />
      </div>
    </div>
  );
};

FormStepOne.propTypes = {
  handleSetDates: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.any).isRequired,
  range: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleNextStep: PropTypes.func.isRequired,
  handleSetSingleDate: PropTypes.func.isRequired,
};
NextStepButton.propTypes = {
  range: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleNextStep: PropTypes.func.isRequired,
};

export default FormStepOne;
