import listingConfigs from "configs/listing_configs";
import { handleSetBoundingBox } from "state/helpers/map_helpers";
import {
  arrayFromMinMaxValues,
  convertFreshnessDaysToCalendarDate,
} from "utilities";
import utilities from "api/fetchers/utilities";

export const constructSalesListingsQueryParams = (
  currentFilters,
  mapInstance,
  hubspotPipelinesToDealStagesMapping,
  page_size = 200,
) => {
  const {
    dealStages,
    estimatedYieldRange: {
      min: estimated_gross_yield_min,
      max: estimated_gross_yield_max,
    },
    freshness,
    listingBeds,
    listingTypes: property_types,
    priceRange: { min: price_min, max: price_max },
    netYieldRange: { min: net_yield_min, max: net_yield_max },
  } = currentFilters;

  const {
    sharedConfig: { pipelineIds },
  } = listingConfigs;

  const currentBounds = mapInstance?.getBounds();
  const polygonBounds = handleSetBoundingBox(
    currentBounds,
    currentBounds,
    true,
  );

  return {
    polygons: polygonBounds,
    currentBounds,
    options: {
      bedrooms: arrayFromMinMaxValues(listingBeds),
      deal_stages: utilities.convertDealStageNamesToHubspotDealStageInternalIDs(
        dealStages,
        hubspotPipelinesToDealStagesMapping,
      ),
      estimated_gross_yield_max,
      estimated_gross_yield_min,
      listed_at_gte: convertFreshnessDaysToCalendarDate(freshness.leftBand),
      listed_at_lte: convertFreshnessDaysToCalendarDate(freshness.rightBand),
      property_types,
      price_min,
      price_max,
      page_size,
      hubspot_pipeline_ids: pipelineIds,
      net_yield_min,
      net_yield_max,
    },
  };
};
