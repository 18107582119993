import { combineReducers } from "redux";
import { createReducer } from "@reduxjs/toolkit";

import dataRequestFactories from "state/global/factories/data_request_factories";
import { fetchShortletAmenityHullsRequestActions } from "state/global/actions/data_requests/map_data_request_group_actions";

const statusReducer = dataRequestFactories.generateStatusReducer(
  fetchShortletAmenityHullsRequestActions.actionTypes,
  fetchShortletAmenityHullsRequestActions.actionTypePrefix,
);

const fetchShortletAmenityHullsRequestReducer = (state = {}, action) => {
  return {
    ...statusReducer(state, action),
  };
};

export default combineReducers({
  fetchShortletAmenityHullsRequest: fetchShortletAmenityHullsRequestReducer,
});
