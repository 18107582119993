import { handleSetBoundingBox } from "state/helpers/map_helpers";
import {
  arrayFromMinMaxValues,
  convertFreshnessDaysToCalendarDate,
} from "utilities";

export const constructLongletListingsQueryParams = (
  currentFilters,
  mapContext,
  compsFiltersCurrent,
) => {
  const {
    listingBeds,
    listingPrice: { min: price_min, max: price_max },
    listingTypes: property_types,
    freshness,
    predictedRevenue: { min: revenue_min, max: revenue_max },
    averageRevenue: { min: average_income_min, max: average_income_max },
    comps,
  } = currentFilters;

  const {
    compsQuality: {
      min: comparable_match_quality_min,
      max: comparable_match_quality_max,
    },
  } = compsFiltersCurrent;

  const currentBounds = mapContext.getBounds();
  const polygonBounds = handleSetBoundingBox(
    currentBounds,
    currentBounds,
    true,
  );

  return {
    comps: comps ? JSON.stringify(comps) : undefined,
    polygons: polygonBounds,
    currentBounds,
    options: {
      average_income_min,
      average_income_max,
      bedrooms: arrayFromMinMaxValues(listingBeds),
      comparable_match_quality_min:
        parseFloat(comparable_match_quality_min) / 100,
      comparable_match_quality_max:
        parseFloat(comparable_match_quality_max) / 100,
      revenue_min,
      revenue_max,
      price_min,
      price_max,
      property_types,
      published_after: convertFreshnessDaysToCalendarDate(freshness.leftBand),
      published_before: convertFreshnessDaysToCalendarDate(freshness.rightBand),
    },
  };
};
