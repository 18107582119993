import React from "react";
import PropTypes from "prop-types";

import { Expansion, Checkbox } from "components/";
import { getDealStageColor } from "utilities";
import handleListingFiltering from "../../filter_handlers/listings_filter";

import styles from "../index.module.css";

const DealStageFilter = ({
  disabled,
  salesDealsFilterOptions: { dealStages },
  salesDealsFiltersCurrent: { dealStages: currentDealStages },
  salesDealsFiltersActions,
}) => (
  <Expansion
    controller={toggle => (
      <button
        type="button"
        disabled={disabled}
        className={styles.btn}
        onClick={toggle}
      >
        Deal stage
        <span />
      </button>
    )}
  >
    <div className={styles.list}>
      {dealStages.map(dealStage => (
        <Checkbox
          key={dealStage}
          className={`${styles.element}`}
          background={getDealStageColor(dealStage)}
          onClick={() =>
            handleListingFiltering({
              filterActions: salesDealsFiltersActions,
              filterName: "dealStages",
              value: dealStage,
            })
          }
          checked={currentDealStages.includes(dealStage)}
          label={dealStage}
        />
      ))}
    </div>
  </Expansion>
);

DealStageFilter.propTypes = {
  disabled: PropTypes.bool.isRequired,
  salesDealsFiltersActions: PropTypes.shape({
    setFilterValue: PropTypes.func.isRequired,
    toggleCollectionFilter: PropTypes.func.isRequired,
  }).isRequired,
  salesDealsFilterOptions: PropTypes.shape({
    dealStages: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  salesDealsFiltersCurrent: PropTypes.shape({
    dealStages: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default DealStageFilter;
