import React from "react";
import PropTypes from "prop-types";

import salesListingsSelector from "state/global/selectors/data_collections/map/sales/deals_listings_selectors";
import longletListingsSelector from "state/global/selectors/data_collections/map/longlet/listings_selectors";
import shortletListingsSelector from "state/global/selectors/data_collections/map/shortlet/listings_selectors";
import { connect } from "react-redux";
import {
  SaleListingDetailsPanel,
  ShortletListingDetailsPanel,
  LongletListingDetailsPanel,
} from "./details_panels";

import sharedConfig from "../../../../configs/listing_configs";

const {
  sharedConfig: {
    listingTypes: {
      longletListing: { type: longletListingType },
      shortletListing: { type: shortletListingType },
      hubspotPushedSaleListing: { type: hubspotPushedSaleListingType },
    },
  },
} = sharedConfig;

const listingSelector = {
  [longletListingType]: longletListingsSelector,
  [shortletListingType]: shortletListingsSelector,
  [hubspotPushedSaleListingType]: salesListingsSelector,
};

const DetailsPanel = selectedListingType =>
  ({
    [longletListingType]: LongletListingDetailsPanel,
    [shortletListingType]: ShortletListingDetailsPanel,
    [hubspotPushedSaleListingType]: SaleListingDetailsPanel,
  }[selectedListingType]);

const ListingDetailsPanel = ({ id, selectedListingType, listing }) => {
  const [currentListing, setCurrentListing] = React.useState(listing);

  React.useEffect(() => {
    setCurrentListing(listing);
  }, [id, selectedListingType]);

  if (selectedListingType != null && currentListing) {
    return React.createElement(DetailsPanel(selectedListingType), {
      listing: currentListing,
    });
  }

  return null;
};

ListingDetailsPanel.defaultProps = {
  selectedListingType: null,
  listing: null,
};

ListingDetailsPanel.propTypes = {
  id: PropTypes.number.isRequired,
  selectedListingType: PropTypes.string,
  listing: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

const mapStateToProps = (state, props) => ({
  listing: listingSelector?.[props.selectedListingType]?.selectAllByKey(
    state,
  )?.[props.id],
});

export default connect(
  mapStateToProps,
  {},
)(ListingDetailsPanel);
