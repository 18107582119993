import React from "react";
import PropTypes from "prop-types";

import Keyword from "./keyword";

import styles from "../hull_details.module.css";

const KeywordsList = ({ data }) => {
  function getColorValue(value, target) {
    const alpha = value / target;
    if (!alpha) return "#dbdbdb";
    return `rgba(252, 189, 1, ${Math.max(alpha, 0.3)})`;
  }

  function normalizeKeywordsData(keywordsObject) {
    if (keywordsObject) {
      const values = Object.values(keywordsObject);
      const biggestValue = Math.max(...values);
      return Object.keys(keywordsObject)
        .sort((a, b) => keywordsObject[b] - keywordsObject[a])
        .map(key => ({
          name: key,
          color: getColorValue(keywordsObject[key], biggestValue),
          value: keywordsObject[key],
        }));
    }
    return [];
  }

  let keywordsListContent = "--";

  if (data && data[0]) {
    keywordsListContent = (
      <div className={styles.keywords}>
        {normalizeKeywordsData(data[0].keywords).map(el => (
          <Keyword key={el.name} data={el} />
        ))}
      </div>
    );
  }

  return keywordsListContent;
};

KeywordsList.defaultProps = {
  data: {},
};

KeywordsList.propTypes = {
  data: PropTypes.shape({ titleKeywordsWithIncomes: PropTypes.shape() }),
};

export default KeywordsList;
