import fetch from "./middleware/api_middleware";
import { refreshTokenMiddleware } from "./middleware/refresh_token_middleware";

const fetchUsingRefreshTokenMiddleware = refreshTokenMiddleware(fetch);

export const getShortletListings = (queryParams = "") =>
  fetchUsingRefreshTokenMiddleware({
    method: "GET",
    url: `/shortlet/hull-listings/${queryParams}`,
  });

export const getLongletListings = (queryParams = "") =>
  fetchUsingRefreshTokenMiddleware({
    method: "GET",
    url: `/data-views/longlet-rentals${queryParams}`,
  });

export const getLongletPropertyHulls = (queryParams = "") =>
  fetchUsingRefreshTokenMiddleware({
    method: "GET",
    url: `/longlet/property-hulls/${queryParams}`,
  });

export const getLongletSalesDealsListings = (queryParams = "") =>
  fetchUsingRefreshTokenMiddleware({
    method: "GET",
    url: `/sale-listings/hubspot-pushed/${queryParams}`,
  });

export const getSalesClusteringInfo = (queryParams = "") =>
  fetchUsingRefreshTokenMiddleware({
    method: "GET",
    url: `/clustering/sales/${queryParams}`,
  });

export const getLongletListingsClusteringInfo = (queryParams = "") =>
  fetchUsingRefreshTokenMiddleware({
    method: "GET",
    url: `/clustering/longlet/${queryParams}`,
  });

export const getShortletListingsClusteringInfo = (queryParams = "") =>
  fetchUsingRefreshTokenMiddleware({
    method: "GET",
    url: `/clustering/shortlet/${queryParams}`,
  });

export const getAllHulls = (queryParams = "") =>
  fetchUsingRefreshTokenMiddleware({
    method: "GET",
    url: `/shortlet/property-hulls/${queryParams}`,
  });

export const getAllAmenities = (queryParams = "") =>
  fetchUsingRefreshTokenMiddleware({
    method: "GET",
    url: `/shortlet/amenity-hulls/${queryParams}`,
  });

export const getAmenityTypes = () =>
  fetchUsingRefreshTokenMiddleware({
    method: "GET",
    url: `/shortlet/amenities/`,
  });

export const setHubspotDealStage = (sale_listing_id, target_pipeline, data) =>
  fetchUsingRefreshTokenMiddleware({
    method: "PUT",
    url: `/hubspot-deals/set-dealstage/${sale_listing_id}/${target_pipeline}`,
    data,
  });

export const fetchHubspotDeclineReasons = () =>
  fetchUsingRefreshTokenMiddleware({
    method: "GET",
    url: `/hubspot-deals/fetch-declined-reasons`,
  });

export const declineHubspotDealWithReason = (
  scraped_sale_listing_id,
  target_pipeline_id,
  data,
) =>
  fetchUsingRefreshTokenMiddleware({
    method: "PUT",
    url: `/hubspot-deals/decline-hubspot-deal/${scraped_sale_listing_id}/${target_pipeline_id}`,
    data,
  });

export const fetchHubspotDealStages = () =>
  fetchUsingRefreshTokenMiddleware({
    method: "GET",
    url: "/hubspot-deals/fetch-deal-stages",
  });
