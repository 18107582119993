import React from "react";
import PropTypes from "prop-types";

import { priceFormatter } from "utilities";

import bookmarkImg from "assets/images/bookmark.svg";

const hideDecimal = true;

const getCalendarPrice = value => {
  return typeof value === "number" && priceFormatter(value, hideDecimal);
};

const getUserPriceStyle = (price, userPrice) => {
  const priceOverStyle = price < userPrice ? "react-price-over" : "";
  const priceUnderStyle = price > userPrice ? "react-price-under" : "";

  return priceOverStyle || priceUnderStyle;
};
const getBookmarkStyle = booked => {
  const hasBookedValue = typeof booked === "number";
  const inactiveStyle = hasBookedValue ? "" : "react-bookmark-inactive";

  return `react-bookmark ${inactiveStyle}`;
};

const Tile = ({ price, booked, userPrice }) => {
  const priceValue = getCalendarPrice(price);
  const userPriceValue = getCalendarPrice(userPrice);
  const className = getBookmarkStyle(booked);
  const userPriceStyle = getUserPriceStyle(price, userPrice);

  return (
    <>
      <div className="react-calendar-tile-value">
        {priceValue ? `£${priceValue}` : "-"}
      </div>
      <div className="react-calendar-tile-user-value">
        {userPriceValue && (
          <>
            <div>User price:</div>
            <span className={userPriceStyle}>{`£${userPriceValue}`}</span>
          </>
        )}
      </div>
      <img src={bookmarkImg} alt="" className={className} />
    </>
  );
};

Tile.defaultProps = {
  price: null,
  booked: null,
  userPrice: null,
};

Tile.propTypes = {
  price: PropTypes.number,
  booked: PropTypes.number,
  userPrice: PropTypes.number,
};

export default Tile;
