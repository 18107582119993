/* eslint-disable no-shadow */
import React, { useMemo, useState, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Loader,
  Button,
  confirmWindow,
  FetchingError,
  NoContent,
} from "components";
import { format } from "date-fns";
import graphql from "babel-plugin-relay/macro";
import matchSorter from "match-sorter";
import PropTypes from "prop-types";

import { actions as fetchUsersRequestActions } from "state/global/actions/data_requests/fetch_users_request_actions";

import { actions as usersCollectionActions } from "state/global/actions/data_collections/users_actions";

import usersCollectionSelectors from "state/global/selectors/data_collections/users_selectors";
import dataRequestSelectors from "state/global/selectors/data_requests";

import fetchQuery from "relay/fetchQuery";

import edit_img from "assets/images/edit.svg";
import delete_img from "assets/images/delete.svg";
import boss_img from "assets/images/boss.svg";
import employee_img from "assets/images/employee.svg";
import search_img from "assets/images/search.svg";
import styles from "./users.module.css";

const query = graphql`
  query usersQuery {
    users {
      id
      username
      firstName
      lastName
      email
      dateJoined
      isSuperuser
      dateJoined
      isActive
    }
  }
`;

const Users = ({
  // ### mapStateToProps
  users,
  usersRequestStatus,

  // ### mapDispatchToProps
  fetchUsersRequestActions,
  usersCollectionActions,
}) => {
  // const [state, dispatch] = useReducer(userListReducer, initialState);
  // const { request, failure, success, setValue } = useMemo(
  //   () => userListActions(dispatch),
  //   [dispatch],
  // );

  const [userSearchString, setUserSearchString] = useState("");

  useEffect(() => {
    async function fetchData() {
      fetchUsersRequestActions.requestInProgress();
      const [payload, error] = await getUsers();
      if (error) {
        fetchUsersRequestActions.requestFailure(
          error || "Error occurred. Content cannot be loaded",
        );
      } else {
        fetchUsersRequestActions.requestSuccess(payload);
        usersCollectionActions.updateRecords(payload.data.users);
      }
    }
    fetchData();
  }, [query]);

  function getUsers() {
    return fetchQuery(query, null, { force: true })
      .then(payload => [payload, null])
      .catch(error => [null, error]);
  }

  function handleDelete() {
    confirmWindow({
      text: "Are you sure?",
      okText: "Yes",
      onClick: () => null,
    });
  }

  function handleSearch(e) {
    setUserSearchString(e.target.value);
  }

  const usersFiltered = matchSorter(users, userSearchString, {
    keys: ["firstName", "lastName", "email"],
  });

  const disabled = true;

  return (
    <div className="container">
      {!users.length && usersRequestStatus.requestInProgress && (
        <Loader type="primary" size="big" absolute />
      )}
      {!users.length && usersRequestStatus.requestFailed && <FetchingError />}
      {!usersRequestStatus.requestInProgress &&
        !usersRequestStatus.requestFailed &&
        !users.length && <NoContent />}
      {Boolean(users.length) && (
        <>
          <div className={styles.usersTitle}>Users list</div>
          <div className={styles.head}>
            <div className={styles.search}>
              <div className={styles.group + " input-group"}>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <img src={search_img} alt="" />
                  </span>
                </div>
                <input
                  type="text"
                  className={styles.input + " form-control"}
                  placeholder="Search by name or email"
                  onChange={handleSearch}
                />
              </div>
            </div>

            <Button type="primary" padding="big" to="/users/add">
              Add user
            </Button>
          </div>
          <div className={`${styles.table} table-responsive`}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">User</th>
                  <th scope="col">Created</th>
                  <th scope="col">Email</th>
                  <th scope="col">Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {usersFiltered.map(user => (
                  <tr key={user.id}>
                    <td className={styles.user}>
                      <img
                        src={user.isSuperuser ? boss_img : employee_img}
                        style={
                          user.isSuperuser ? { background: "#fcbd01b0" } : null
                        }
                        alt=""
                      />
                      <div className={styles.name}>
                        {user.firstName}
                        <span className={styles.space} />
                        {user.lastName}
                      </div>
                      <div>
                        <span>
                          <b>{user.username}</b>
                        </span>
                        <span className={styles.dot} />
                        <span>{user.isSuperuser ? "Admin" : "Member"}</span>
                      </div>
                    </td>
                    <td className={styles.date}>
                      {format(user.dateJoined, "DD/MM/YYYY")}
                    </td>
                    <td className={styles.name}>{user.email}</td>
                    <td>
                      <h6>
                        <span
                          className={`badge ${
                            user.isActive ? styles.success : "badge-secondary"
                          }`}
                        >
                          {user.isActive ? "Active" : "Inactive"}
                        </span>
                      </h6>
                    </td>
                    <td>
                      <Button to={`/users/${user.id}/edit`} type="secondary">
                        <img src={edit_img} alt="" />
                      </Button>
                      <Button
                        disabled={disabled}
                        onClick={() => handleDelete(user.id)}
                        type="primary"
                      >
                        <img src={delete_img} alt="" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

Users.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  usersRequestStatus: PropTypes.shape({
    requestFailed: PropTypes.bool.isRequired,
    requestInProgress: PropTypes.bool.isRequired,
  }).isRequired,
  fetchUsersRequestActions: PropTypes.shape({
    requestInProgress: PropTypes.func.isRequired,
    requestFailure: PropTypes.func.isRequired,
    requestSuccess: PropTypes.func.isRequired,
  }).isRequired,
  usersCollectionActions: PropTypes.shape({
    updateRecords: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state, props) {
  return {
    users: usersCollectionSelectors.selectAll(state),
    usersRequestStatus: dataRequestSelectors.selectFetchUsersRequest(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUsersRequestActions: bindActionCreators(
      fetchUsersRequestActions,
      dispatch,
    ),
    usersCollectionActions: bindActionCreators(
      usersCollectionActions,
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Users);
