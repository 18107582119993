/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Modal } from "components/";
import { sendToHubspotColumnRequestSelectors } from "state/global/selectors/data_requests/send_to_hubspot_column_request_selector";
import salesDealStagesSelectors from "state/global/selectors/data_collections/map/sales/sales_deal_stages_selectors";
import { sendToHubspotColumn } from "state/global/actions/data_requests/send_to_hubspot_request_actions";
import { actions as salesActions } from "state/global/actions/data_collections/map/sales/deals_listings_actions";
import SendToHubspotColumnModalContent from "./components/send_to_hubspot_column_modal_content";

const SendToHubspotColumnModal = ({
  columnName,
  isOpen,
  handleClose,
  sendToHubspotColumnRequestStatus,
  sendToHubspotColumn,
  updateSingleRecord,
  listing,
  hubspotPipelinesToDealStagesMapping,
}) => (
  <Modal isOpen={isOpen} handleClose={handleClose}>
    <SendToHubspotColumnModalContent
      columnName={columnName}
      updateSingleRecord={updateSingleRecord}
      sendToHubspotColumnRequestStatus={sendToHubspotColumnRequestStatus}
      handleClose={handleClose}
      sendToHubspotColumn={sendToHubspotColumn}
      listing={listing}
      hubspotPipelinesToDealStagesMapping={hubspotPipelinesToDealStagesMapping}
    />
  </Modal>
);

SendToHubspotColumnModal.propTypes = {
  columnName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  sendToHubspotColumnRequestStatus: PropTypes.shape({}).isRequired,
  listing: PropTypes.shape({}).isRequired,
  sendToHubspotColumn: PropTypes.shape().isRequired,
  updateSingleRecord: PropTypes.func.isRequired,
  hubspotPipelinesToDealStagesMapping: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      pipelineId: PropTypes.string.isRequired,
      stages: PropTypes.objectOf(PropTypes.string),
    }),
  ).isRequired,
};

const mapDispatchToProps = dispatch => ({
  sendToHubspotColumn: bindActionCreators(
    sendToHubspotColumn.actions,
    dispatch,
  ),
  updateSingleRecord: (recordId, updateDetails) =>
    dispatch(salesActions.updateSingleRecord(recordId, updateDetails)),
});

const mapStateToProps = (state, props) => ({
  sendToHubspotColumnRequestStatus: sendToHubspotColumnRequestSelectors.selectAll(
    state,
  ),
  hubspotPipelinesToDealStagesMapping: salesDealStagesSelectors.selectAllByKey(
    state,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SendToHubspotColumnModal);
