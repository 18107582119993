import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { string, object } from "yup";
import { InputField, Button } from "components";
import loginBackground from "assets/images/login.jpg";
import logo from "assets/images/skwire-Logo-2.2.svg";
import Environment from "relay/environment";
import styles from "./reset_password_confirm.module.css";

const mutation = graphql`
  mutation resetPasswordConfirmMutation(
    $token: String!
    $uid: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    passwordReset(
      token: $token
      uid: $uid
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      message
    }
  }
`;

/**
 * Sends mutation to graphql
 * @param {string} email
 */
function reset(values) {
  return new Promise(resolve => {
    const variables = values;

    commitMutation(Environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        return resolve([response, errors]);
      },
      onError: err => {
        console.error(err);
        return resolve([null, err]);
      },
    });
  });
}

const initialValues = { password: "", passwordConfirmation: "" };

const validation = object().shape({
  password: string().required("This field is required"),
  passwordConfirmation: string()
    .test("password-match", "Passwords do not match", function test(value) {
      return this.parent.password === value;
    })
    .required("This field is required"),
});

const ResetPasswordConfirm = ({ history, match }) => {
  async function handleReset(values, { setErrors, setSubmitting }) {
    const { token, uid } = match.params;
    const data = { ...values, token, uid };
    const [, error] = await reset(data);
    if (error) {
      setErrors({ message: JSON.parse(error[0].message) });
      setSubmitting(false);
    } else {
      history.push("/login");
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <img className={styles.background} src={loginBackground} alt="" />
        <img className={styles.logo} src={logo} alt="skwire logo" />
      </div>
      <div className={styles.panel}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleReset}
          validationSchema={validation}
        >
          {({ errors, isSubmitting, dirty }) => (
            <Form className={styles.form}>
              <img className={styles.logo} src={logo} alt="skwire logo" />
              <div className={styles.title}>Reset password</div>
              <div className={styles.error}>{errors.message}</div>
              <div>
                <Field
                  component={InputField}
                  name="password"
                  placeholder="New password"
                  type="password"
                  noLabel
                />
                <Field
                  component={InputField}
                  name="passwordConfirmation"
                  placeholder="Confirm password"
                  type="password"
                  noLabel
                />
                <div className={styles.buttons}>
                  <Button
                    btnType="submit"
                    type="primary"
                    disabled={isSubmitting || !dirty}
                    loading={isSubmitting}
                  >
                    Confirm
                  </Button>
                  <Button to="/login" type="secondary">
                    Back
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

ResetPasswordConfirm.defaultProps = {
  errors: null,
};

ResetPasswordConfirm.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ token: PropTypes.string, uid: PropTypes.string }),
  }).isRequired,
  errors: PropTypes.shape({
    message: PropTypes.string,
    email: PropTypes.string,
  }),
};

export default ResetPasswordConfirm;
