import * as apiMapRequests from "api/map";

import { handleSetBoundingBox } from "state/helpers/map_helpers";
import mapConfigs from "configs/map_configs";
import deserializeLongletRentals from "api/deserializers/longlet_rentals";
import fetchPropertyHulls from "../property_hulls";
import fetchListingsClusteringInfo from "../listings_clustering_info";
import fetchListings from "../listings";
import { constructLongletListingsQueryParams } from "./construct_longlet_listings_query_params";
import constructHullsQueryParams from "../property_hulls/construct_hull_query_params";

async function fetchLongletElements(
  {
    listings: {
      longletListingsFiltersCurrent,
      areListingsEnabled,
      longletListingsActions,
    },
    clusters: { areClustersEnabled, longletListingsClustersActions },
    hulls: { areHullsEnabled, longletPropertyHullsActions },
    compsFiltersCurrent,
  },
  mapContext,
  setPreviousMapBounds,
) {
  let req1;
  let req2;
  let req3;

  const currentBounds = mapContext.getBounds();

  const {
    sharedConfig: {
      pageSizes: { hulls: hullsPageSize },
    },
  } = mapConfigs;

  if (areListingsEnabled) {
    req1 = fetchListings({
      listingsActions: longletListingsActions,
      request: apiMapRequests.getLongletListings,
      queryParams: constructLongletListingsQueryParams(
        longletListingsFiltersCurrent,
        mapContext,
        compsFiltersCurrent,
      ),
      deserializer: deserializeLongletRentals,
    });
  }

  if (areHullsEnabled) {
    req2 = fetchPropertyHulls({
      hullActions: longletPropertyHullsActions,
      request: apiMapRequests.getLongletPropertyHulls,
      queryParams: constructHullsQueryParams(mapContext, {
        page_size: hullsPageSize,
      }),
    });
  }

  if (areClustersEnabled) {
    req3 = fetchListingsClusteringInfo({
      request: apiMapRequests.getLongletListingsClusteringInfo,
      clustersActions: longletListingsClustersActions,
      queryParams: constructLongletListingsQueryParams(
        longletListingsFiltersCurrent,
        mapContext,
      ),
    });
  }

  const filteredRequests = [req1, req2, req3].filter(Boolean);
  if (!filteredRequests.length) return;

  const [res1] = await Promise.all(filteredRequests);

  if (req1 && res1) {
    setPreviousMapBounds(currentBounds);
  }
}

export default fetchLongletElements;
