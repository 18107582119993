import { buildQueryParamsString } from "state/helpers/map_helpers";

const fetchListings = async ({
  listingsActions,
  request,
  queryParams,
  deserializer = serializedListings => serializedListings,
}) => {
  const [payload, error, { isCanceled }] = await request(
    buildQueryParamsString(queryParams),
  );
  if (!error && !isCanceled) {
    if (payload !== null) {
      const deserializedListings = deserializer(payload.results);
      listingsActions.setRecords(deserializedListings);
    }
    return true;
  }
  console.error(error);
  return false;
};

export default fetchListings;
