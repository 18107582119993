/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import PropertyAttributes from "./property_attributes";

import mapImg from "assets/images/placeholder.svg";
import styles from "./list_element.module.css";

const ShowMapButton = ({ address }) => (
  <Link className={styles.overlay} to={`/?address=${address}`}>
    <img src={mapImg} alt="" />
  </Link>
);

const ListElement = ({ property }) => {
  const { airbnb_id, city, address } = property;
  return (
    <div className={styles.element}>
      <Link className={styles.overlay} to={`/statistics/${airbnb_id}`} />
      <div className={styles.block}>
        <div className={styles.city}>{city}</div>
        <div className={styles.info}>
          <div className={styles.address}>{address}</div>
          <PropertyAttributes details={property} />
        </div>
      </div>
      <div className={styles.spacer} />
      <div className={styles.link}>
        <ShowMapButton address={address} />
      </div>
    </div>
  );
};

ListElement.propTypes = {
  property: PropTypes.shape().isRequired,
};

export default ListElement;
