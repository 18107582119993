import { combineReducers } from "redux";

import dataRequestFactories from "state/global/factories/data_request_factories";
import { actionTypes } from "state/global/actions/data_requests/fetch_users_request_actions";

const statusReducer = dataRequestFactories.generateStatusReducer(
  actionTypes,
  "FETCH_USERS",
);

export default statusReducer;
