import { getLongletSalesDealsListings, getSalesClusteringInfo } from "api/map";

import mapConfigs from "configs/map_configs";
import { deserializeSaleListings } from "api/deserializers";
import fetchListings from "../listings";
import { constructSalesListingsQueryParams } from "./construct_sale_listings_query_params";
import fetchListingsClusteringInfo from "../listings_clustering_info";

async function fetchSalesDealsElements(
  {
    listings: {
      salesDealsListingsFiltersCurrent,
      areListingsEnabled,
      salesDealsListingsActions,
    },
    clusters: { areClustersEnabled, salesClustersActions },
  },
  mapContext,
  setPreviousMapBounds,
  hubspotPipelinesToDealStagesMapping,
) {
  let req1;
  let req2;

  const currentBounds = mapContext.getBounds();
  const {
    sharedConfig: {
      pageSizes: { clusters: clustersPageSizes, listings: listingsPageSizes },
    },
  } = mapConfigs;

  if (areListingsEnabled) {
    req1 = fetchListings({
      listingsActions: salesDealsListingsActions,
      request: getLongletSalesDealsListings,
      queryParams: constructSalesListingsQueryParams(
        salesDealsListingsFiltersCurrent,
        mapContext,
        hubspotPipelinesToDealStagesMapping,
        listingsPageSizes,
      ),
      deserializer: deserializeSaleListings,
    });
  }

  if (areClustersEnabled) {
    req2 = fetchListingsClusteringInfo({
      request: getSalesClusteringInfo,
      clustersActions: salesClustersActions,
      queryParams: constructSalesListingsQueryParams(
        salesDealsListingsFiltersCurrent,
        mapContext,
        hubspotPipelinesToDealStagesMapping,
        clustersPageSizes,
      ),
    });
  }

  const filteredRequests = [req1, req2].filter(Boolean);
  if (!filteredRequests.length) return;

  const [res1] = await Promise.all(filteredRequests);

  if (req1 && res1) {
    setPreviousMapBounds(currentBounds);
  }
}

export default fetchSalesDealsElements;
