/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { tokenStorageField } from "configuration";

import TopPanel from "components/ui/top_panel";

const AuthRoute = ({ component: Component, withTopPanel, ...rest }) => {
  const isValidRoute = localStorage.getItem(tokenStorageField);
  
  const elementRenderer = props => {
    
    return isValidRoute ? (
      <>
        <TopPanel />
        <Component {...props} />
      </>
    ) : (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: props.location },
        }}
      />
    )
  };
        
  return (
    <Route
      {...rest}
      render={elementRenderer}
    />
  );
};

AuthRoute.defaultProps = {
  location: null,
};

AuthRoute.propTypes = {
  location: PropTypes.shape(),
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AuthRoute;
