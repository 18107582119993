import {
  LISTING_ID,
  HULL_ID,
  AMENITY_ID,
  RESET_IDS,
} from "state/local/action_types/map/map_details_action_types";

const mapDetailsActions = dispatch => ({
  setListingId: (id, initialState) =>
    dispatch({ type: LISTING_ID, id, initialState }),
  setHullId: (id, initialState) =>
    dispatch({ type: HULL_ID, id, initialState }),
  setAmenityId: (id, initialState) =>
    dispatch({ type: AMENITY_ID, id, initialState }),
  resetIDs: initialState => dispatch({ type: RESET_IDS, initialState }),
});

export default mapDetailsActions;
