import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import styles from "./filters.module.css";

const Filters = ({ children, filters, disabled, reverse }) => {
  return (
    <Accordion allowZeroExpanded>
      <AccordionItem>
        <div className={`${styles.bar} ${reverse ? styles.reverse : ""}`}>
          <AccordionItemButton
            className={`${styles.btn} ${disabled ? styles.disabled : ""}`}
          >
            Filters
          </AccordionItemButton>
          {children}
        </div>
        <AccordionItemPanel>{!disabled && filters}</AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

Filters.defaultProps = {
  disabled: false,
  reverse: false,
  filters: null,
};

Filters.propTypes = {
  children: PropTypes.shape().isRequired,
  filters: PropTypes.shape(),
  disabled: PropTypes.bool,
  reverse: PropTypes.bool,
};

export default Filters;
