import React from "react";
import PropTypes from "prop-types";

import { Info } from "components";

import styles from "../hull_details.module.css";

const Keyword = ({ data }) => {
  function getPercentage(value) {
    if (!value) return 0;
    return (value * 100).toFixed(0);
  }

  const keyword = (
    <div className={styles.keyword} style={{ background: data.color }}>
      <span>{data.name}</span>
    </div>
  );

  return (
    <div className={styles.keywordContainer}>
      <Info
        info={`Score: ${getPercentage(data.value)}%`}
        value={keyword}
        offset={0}
        position="top-start"
      />
    </div>
  );
};

Keyword.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.shape(), PropTypes.number]),
  }).isRequired,
};

export default Keyword;
