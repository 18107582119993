import React from "react";
import PropTypes from "prop-types";
import RelayRenderer from "relay/relay_renderer";
import { Loader, FetchingError } from "components";
import queries from "../../queries/index";

import { transformShortletListingData } from "../data_transformations";

import {
  HullListingDetails,
  ShortletPricesStatistics,
  SeasonStatistics,
  ShortletListingYieldsPerPrice,
} from "../components";

import styles from "../listing_details.module.css";

const ShortletListingDetailsPanel = ({
  listing: { hulllistingstatistic_id },
}) => (
  <RelayRenderer
    query={queries.shortlet.shortletListingStatisticsQuery}
    variables={{ id: hulllistingstatistic_id }}
  >
    {(payload, loading) => {
      return (() => {
        const data = transformShortletListingData(
          payload?.hullListingsStatistics,
        );
        return (
          <>
            {loading && <Loader type="primary" size="big" absolute />}
            {!loading && data && (
              <div className={styles.body}>
                <HullListingDetails data={data} />
                <div className={styles.title}>Statistics</div>
                <ShortletPricesStatistics
                  data={data}
                  yield_per_price={() => ShortletListingYieldsPerPrice(data)}
                />
                <SeasonStatistics data={data} />
              </div>
            )}
            {!loading && !data && <FetchingError />}
          </>
        );
      })();
    }}
  </RelayRenderer>
);

ShortletListingDetailsPanel.propTypes = {
  listing: PropTypes.shape({
    hulllistingstatistic_id: PropTypes.string.isRequired,
  }).isRequired,
};

export default ShortletListingDetailsPanel;
