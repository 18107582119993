import { buildQueryParamsString } from "state/helpers/map_helpers";

const fetchPropertyHulls = async ({ hullActions, request, queryParams }) => {
  const [payload, error, { isCanceled }] = await request(
    buildQueryParamsString(queryParams),
  );

  if (!error && !isCanceled) {
    if (payload !== null) {
      hullActions.updateRecords(payload.results);
    }

    return true;
  }
  console.error(error);

  return false;
};

export default fetchPropertyHulls;
