/* eslint-disable no-shadow */
import camelCase from "lodash/camelCase";
import { inRange } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import fetchListings from "api/fetchers/listings";
import * as apiMapRequests from "api/map";
import { constructLongletListingsQueryParams } from "api/fetchers/longlet/construct_longlet_listings_query_params";
import { fetchWithDebounce } from "api/fetchers/utilities";
import filterConfigs from "configs/filter_configs";
import mapConfigs from "configs/map_configs";
import fetchListingsClusteringInfo from "api/fetchers/listings_clustering_info";

import { actions as listingsDisabledFiltersActions } from "state/global/actions/user_input/map/filters/listings_disabled_filters_actions";
import { actions as longletListingsClustersActions } from "state/global/actions/data_collections/map/longlet/listings_clusters_actions";
import { actions as longletListingsActions } from "state/global/actions/data_collections/map/longlet/listings_actions";
import { actions as compsFiltersActions } from "state/global/actions/user_input/map/filters/comps_filters_actions";
import { actions as longletListingFiltersActions } from "state/global/actions/user_input/map/filters/longlet/listing_filters_actions";
import listingsDisabledFiltersSelectors from "state/global/selectors/user_input/map/filters/listings_disabled_selectors";
import compsFiltersSelectors from "state/global/selectors/user_input/map/filters/comps_selectors";
import longletListingFiltersSelectors from "state/global/selectors/user_input/map/filters/longlet/listing_filters_selectors";
import propertyHullFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/property_hull_filters_selectors";

import useQuery from "components/hooks/use_query";
import { MapContext } from "state/local/stores/contexts/map_context_store";
import useComponentDidUpdate from "components/hooks/use_component_did_update";

import { RangeSlider, MinMaxFilter } from "components";
import deserializeLongletRentals from "api/deserializers/longlet_rentals";
import ListingTypeFilter from "../components/listing_types_filter";
import handleListingFiltering from "../filter_handlers/listings_filter";
import { ListingsVisibilitySwitch } from "./components";

import styles from "./listings_panel.module.css";

const {
  mapTypeNames: { longlet: longletMapType },
  zoomLevels: { listings: listingsAppearZoom, clusters: clustersAppearZoom },
  filterDebounce: { listings: debounceListings },
} = mapConfigs;

const { longletFreshnessFilterOptions } = filterConfigs;

const ListingsPanel = React.memo(
  ({
    longletListingFiltersCurrent,
    longletListingFiltersActions,
    longletListingFiltersOptions,
    disabled,
    longletListingsClustersActions,
    longletListingsActions,
    listingsDisabledFiltersCurrent,
    listingsDisabledFiltersActions,
    compsFiltersActions,
    compsFiltersCurrent,
  }) => {
    const { updateQueryString } = useQuery();
    const { mapInstance, setFetchingMapData } = React.useContext(MapContext);

    const zoom = mapInstance?.getZoom();
    const enableListingsFetching = zoom >= listingsAppearZoom;
    const enableClustersInfoFetching = inRange(
      zoom,
      clustersAppearZoom.min,
      clustersAppearZoom.max + 1,
    );

    useComponentDidUpdate(() => {
      fetchWithDebounce(handleOnApplyFilters, debounceListings);
    }, [longletListingFiltersCurrent, compsFiltersCurrent]);

    const handleOnApplyFilters = () => {
      setFetchingMapData(true);

      const queryParams = constructLongletListingsQueryParams(
        longletListingFiltersCurrent,
        mapInstance,
        compsFiltersCurrent,
      );
      const fetch =
        (enableListingsFetching &&
          (async () => {
            await fetchListings({
              listingsActions: longletListingsActions,
              request: apiMapRequests.getLongletListings,
              queryParams,
              deserializer: deserializeLongletRentals,
            });
          })) ||
        (enableClustersInfoFetching &&
          (async () => {
            await fetchListingsClusteringInfo({
              request: apiMapRequests.getLongletListingsClusteringInfo,
              queryParams,
              clustersActions: longletListingsClustersActions,
              deserializer: deserializeLongletRentals,
            });
          }));

      if (fetch) {
        fetch().then(() => setFetchingMapData(false));
      }
    };

    return (
      <div className={styles.container} data-testid="longlet-listings-panel">
        <div className={styles.heading}>
          <div className={styles.title}>Listing filters</div>
          <div className={styles.subtitle}>
            Use filters to change the shown listings
          </div>
          <div className={styles.filters}>
            <div className={styles.grid}>
              <ListingsVisibilitySwitch
                filterActions={listingsDisabledFiltersActions}
                filterSelector={listingsDisabledFiltersSelectors}
                filtersCurrent={listingsDisabledFiltersCurrent}
                mapTypeDisabled={disabled}
                mapType={longletMapType}
              />
            </div>
            <div className={styles.spacer} />
            <MinMaxFilter
              disabled={disabled}
              title="Bedrooms"
              mapType={camelCase(longletMapType)}
              filterActions={longletListingFiltersActions}
              filterName="listingBeds"
              currentValues={longletListingFiltersCurrent}
              shouldUpdateQueryString
              filterSelectors={longletListingFiltersSelectors}
            />
            <ListingTypeFilter
              disabled={disabled}
              mapType={longletMapType}
              listingFilterSelector={longletListingFiltersSelectors}
              listingFiltersActions={longletListingFiltersActions}
              listingFiltersCurrent={longletListingFiltersCurrent}
              listingFiltersOptions={longletListingFiltersOptions}
            />
            <div className={styles.spacer} />
            <MinMaxFilter
              disabled={disabled}
              title="DS estimate pcm"
              mapType={camelCase(longletMapType)}
              filterActions={longletListingFiltersActions}
              filterName="predictedRevenue"
              currentValues={longletListingFiltersCurrent}
              filterSelectors={longletListingFiltersSelectors}
            />
            <div className={styles.spacer} />
            <MinMaxFilter
              disabled={disabled}
              title="Asking pcm"
              mapType={camelCase(longletMapType)}
              filterActions={longletListingFiltersActions}
              filterName="averageRevenue"
              currentValues={longletListingFiltersCurrent}
              filterSelectors={longletListingFiltersSelectors}
            />
            <RangeSlider
              disabled={disabled}
              filtersCurrent={longletListingFiltersCurrent}
              filterName="freshness"
              title="Freshness"
              handleOnFilterChange={datesRange =>
                handleListingFiltering({
                  filterActions: longletListingFiltersActions,
                  filterName: "freshness",
                  value: datesRange,
                  shouldReplaceFilterValue: true,
                  filterSelector: longletListingFiltersSelectors,
                  updateQueryStringCallback: updateQueryString,
                  queryParamPrefix: camelCase(longletMapType),
                })
              }
              filterOptions={longletFreshnessFilterOptions}
              mode={1}
            />
            <div className={styles.spacer} />
            <MinMaxFilter
              disabled={disabled}
              title="Comparable match quality (%)"
              filterActions={compsFiltersActions}
              filterName="compsQuality"
              currentValues={compsFiltersCurrent}
              filterSelectors={compsFiltersSelectors}
              isPercentage
              shouldUpdateQueryString
            />
          </div>
        </div>
      </div>
    );
  },
);

ListingsPanel.propTypes = {
  longletListingFiltersCurrent: PropTypes.shape({
    listingsEnabled: PropTypes.bool,
    listingPrice: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  longletListingFiltersActions: PropTypes.shape({
    setFilterValue: PropTypes.func.isRequired,
  }).isRequired,
  longletListingsActions: PropTypes.shape({
    setRecords: PropTypes.func.isRequired,
  }).isRequired,
  longletListingFiltersOptions: PropTypes.shape({
    listingTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  propertyHullFiltersCurrent: PropTypes.shape({
    hullBeds: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }).isRequired,
    hullTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  listingsDisabledFiltersActions: PropTypes.shape({
    setFilterValue: PropTypes.func.isRequired,
    toggleCollectionFilter: PropTypes.func.isRequired,
  }).isRequired,
  listingsDisabledFiltersCurrent: PropTypes.shape().isRequired,
  longletListingsClustersActions: PropTypes.shape({
    setRecords: PropTypes.func.isRequired,
  }).isRequired,
  compsFiltersActions: PropTypes.shape({
    setFilterValue: PropTypes.func.isRequired,
    toggleCollectionFilter: PropTypes.func.isRequired,
  }).isRequired,
  compsFiltersCurrent: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state, props) => ({
  longletListingFiltersOptions: longletListingFiltersSelectors.selectOptions(
    state,
  ),
  longletListingFiltersCurrent: longletListingFiltersSelectors.selectCurrent(
    state,
  ),
  propertyHullFiltersCurrent: propertyHullFiltersSelectors.selectCurrent(state),
  listingsDisabledFiltersCurrent: listingsDisabledFiltersSelectors.selectCurrent(
    state,
  ),
  compsFiltersCurrent: compsFiltersSelectors.selectCurrent(state),
});

const mapDispatchToProps = dispatch => ({
  longletListingFiltersActions: bindActionCreators(
    longletListingFiltersActions,
    dispatch,
  ),
  listingsDisabledFiltersActions: bindActionCreators(
    listingsDisabledFiltersActions,
    dispatch,
  ),
  longletListingsActions: bindActionCreators(longletListingsActions, dispatch),
  longletListingsClustersActions: bindActionCreators(
    longletListingsClustersActions,
    dispatch,
  ),
  compsFiltersActions: bindActionCreators(compsFiltersActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListingsPanel);
