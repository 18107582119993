import { Loader, Modal } from "components/";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions as salesActions } from "state/global/actions/data_collections/map/sales/deals_listings_actions";
import { declineHubspotDeal } from "state/global/actions/data_requests/send_to_hubspot_request_actions";
import salesDealStagesSelectors from "state/global/selectors/data_collections/map/sales/sales_deal_stages_selectors";
import { declineHubspotDealRequestSelectors } from "state/global/selectors/data_requests/decline_hubspot_deal_selector";
import {
  closeOnSuccess,
  SetHubSpotStageError,
  SetHubSpotStageSuccess,
} from "../send_to_hubspot_column_modal/components/send_to_hubspot_column_modal_content";
import DeclineSalesDealModalContent from "./components/modal_content";

import styles from "../send_to_hubspot_column_modal/index.module.css";
import decline_styles from "./index.module.css";

const DeclineHubspotDealModal = props => {
  const {
    listing: { id: listingId, pipeline_id },
    handleClose,
    isOpen,
    declineHubspotDeal: { triggerRequest },
  } = props;

  const handleOnSend = reason => {
    triggerRequest(listingId, pipeline_id, {
      decline_reason_hubspot_internal_value: reason,
    });
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <ModalContent {...{ ...props, handleOnSend }} />
    </Modal>
  );
};

const ModalContent = ({
  declinehubspotDealRequestStatus: {
    requestSuccessful,
    requestFailed,
    requestInProgress,
    responseData,
  },
  declineHubspotDeal: { resetRequestStore },
  updateSingleRecord,
  listing: { id: listingId, pipeline_id },
  handleClose,
  handleOnSend,
  columnName,
  hubspotPipelinesToDealStagesMapping,
}) => {
  React.useEffect(() => resetRequestStore, []);
  const hubspotStageNamesToHubspotStageInternalIDs =
    hubspotPipelinesToDealStagesMapping[pipeline_id].stages;

  return (
    <div
      className={
        requestSuccessful
          ? `${decline_styles.container} ${styles["container-hidden"]}`
          : decline_styles.container
      }
    >
      {requestInProgress && <Loader size="small" type="primary" />}

      <div className={decline_styles.content}>
        {!requestInProgress && !responseData && (
          <DeclineSalesDealModalContent
            columnName={columnName}
            handleClose={handleClose}
            handleOnSend={handleOnSend}
          />
        )}
        {requestSuccessful && responseData.listingId === listingId && (
          <SetHubSpotStageSuccess
            closeModal={() =>
              closeOnSuccess(
                handleClose,
                updateSingleRecord,
                listingId,
                hubspotStageNamesToHubspotStageInternalIDs,
                columnName,
              )
            }
          />
        )}
        {requestFailed && responseData.listingId === listingId && (
          <SetHubSpotStageError error={responseData.error} />
        )}
      </div>
    </div>
  );
};

DeclineHubspotDealModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  columnName: PropTypes.string.isRequired,
  declinehubspotDealRequestStatus: PropTypes.shape({
    requestSuccessful: PropTypes.bool,
    requestFailed: PropTypes.bool,
    requestInProgress: PropTypes.bool,
    responseData: PropTypes.shape(),
  }).isRequired,
  declineHubspotDeal: PropTypes.shape({
    triggerRequest: PropTypes.func.isRequired,
    resetRequestStore: PropTypes.func.isRequired,
  }).isRequired,
  updateSingleRecord: PropTypes.func.isRequired,
  listing: PropTypes.shape({
    id: PropTypes.number.isRequired,
    pipeline_name: PropTypes.string.isRequired,
    pipeline_id: PropTypes.string.isRequired,
  }).isRequired,
  hubspotPipelinesToDealStagesMapping: PropTypes.objectOf(
    PropTypes.shape({
      pipelineId: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      stages: PropTypes.objectOf(PropTypes.string),
    }),
  ).isRequired,
};

const mapStateToProps = state => ({
  declinehubspotDealRequestStatus: declineHubspotDealRequestSelectors.selectAll(
    state,
  ),
  hubspotPipelinesToDealStagesMapping: salesDealStagesSelectors.selectAllByKey(
    state,
  ),
});

const mapDispatchToProps = dispatch => ({
  declineHubspotDeal: bindActionCreators(declineHubspotDeal.actions, dispatch),
  updateSingleRecord: (recordId, updateDetails) =>
    dispatch(salesActions.updateSingleRecord(recordId, updateDetails)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeclineHubspotDealModal);
