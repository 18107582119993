import { combineReducers } from "redux";

import fetchUsersRequestReducer from "./fetch_users_request_reducer";
import mapDataRequestGroupReducer from "./map_data_request_group_reducer";
import sendToHubspotColumnRequestReducer from "./send_to_hubspot_request_reducer";
import declineHubspotDealRequestReducer from "./decline_hubspot_deal_request_reducer";

export default combineReducers({
  fetchUsersRequest: fetchUsersRequestReducer,
  mapDataRequestGroup: mapDataRequestGroupReducer,
  sendToHubspotColumnRequest: sendToHubspotColumnRequestReducer,
  declineHubspotDealRequest: declineHubspotDealRequestReducer,
});
