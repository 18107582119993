/**
 * @flow
 * @relayHash 9a8cca84afdc516bc4675cd9c701624d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type longletQueriesHullStatisticsQueryVariables = {|
  id: string
|};
export type longletQueriesHullStatisticsQueryResponse = {|
  +longletPropertyHullStatistics: ?{|
    +id: string,
    +propertyHull: {|
      +bedrooms: number,
      +propertyType: string,
      +district: string,
      +incomeBand: string,
    |},
    +averageIncome: number,
    +numberOfProperties: ?number,
    +titleKeywordsWithIncomes: ?any,
    +descriptionKeywordsWithIncomes: ?any,
    +previousMonthsAvgSoldPrices: ?any,
    +previousMonthsNumPropertiesSold: ?any,
  |}
|};
export type longletQueriesHullStatisticsQuery = {|
  variables: longletQueriesHullStatisticsQueryVariables,
  response: longletQueriesHullStatisticsQueryResponse,
|};
*/


/*
query longletQueriesHullStatisticsQuery(
  $id: ID!
) {
  longletPropertyHullStatistics(id: $id) {
    id
    propertyHull {
      bedrooms
      propertyType
      district
      incomeBand
      id
    }
    averageIncome
    numberOfProperties
    titleKeywordsWithIncomes
    descriptionKeywordsWithIncomes
    previousMonthsAvgSoldPrices
    previousMonthsNumPropertiesSold
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "bedrooms",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "propertyType",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "district",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "incomeBand",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "averageIncome",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "numberOfProperties",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "titleKeywordsWithIncomes",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "descriptionKeywordsWithIncomes",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "previousMonthsAvgSoldPrices",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "previousMonthsNumPropertiesSold",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "longletQueriesHullStatisticsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "longletPropertyHullStatistics",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LongletPropertyHullStatisticNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "propertyHull",
            "storageKey": null,
            "args": null,
            "concreteType": "LongletPropertyHullNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ]
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "longletQueriesHullStatisticsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "longletPropertyHullStatistics",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "LongletPropertyHullStatisticNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "propertyHull",
            "storageKey": null,
            "args": null,
            "concreteType": "LongletPropertyHullNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/)
            ]
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "longletQueriesHullStatisticsQuery",
    "id": null,
    "text": "query longletQueriesHullStatisticsQuery(\n  $id: ID!\n) {\n  longletPropertyHullStatistics(id: $id) {\n    id\n    propertyHull {\n      bedrooms\n      propertyType\n      district\n      incomeBand\n      id\n    }\n    averageIncome\n    numberOfProperties\n    titleKeywordsWithIncomes\n    descriptionKeywordsWithIncomes\n    previousMonthsAvgSoldPrices\n    previousMonthsNumPropertiesSold\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4a5ae2de394167a0a7dc5a13f33cf873';
module.exports = node;
