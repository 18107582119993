import activeTabFactories from "state/global/factories/active_map_tab_factories";
import {
  actionTypePrefix,
  actionTypes,
} from "state/global/actions/ui_control/map/active_tab_actions";
import { camelCase } from "lodash";
import mapConfigs from "configs/map_configs";

const {
  mapTypeNames: { longlet: longletMap, shortlet: shortletMap, sales: salesMap },
} = mapConfigs;

const ACTIVE_MAP_TABS_OPTIONS = [salesMap, shortletMap, longletMap];

const mapTabsOptions = {
  activeTabOptions: ACTIVE_MAP_TABS_OPTIONS.map(option => camelCase(option)),
};

const mapTabsDefault = camelCase(salesMap);

const reducer = activeTabFactories.generateReducer(
  actionTypes,
  actionTypePrefix,
  mapTabsOptions,
  mapTabsDefault,
);

export default reducer;
