import React from "react";
import PropTypes from "prop-types";

import empty_img from "assets/images/empty_list.svg";
import styles from "./no_content.module.css";

const NoContent = ({ image, title, subtitle, className }) => {
  const topMessage = title || "No results";
  const bottomMessage = subtitle || "There is no content you are looking for.";

  return (
    <div className={[styles.container, className].join(" ")}>
      <img className={styles.image} src={image || empty_img} alt="" />
      <div className={[styles.title, "title"].join(" ")}>{topMessage}</div>
      <div className={[styles.subtitle, "subtitle"].join(" ")}>
        {bottomMessage}
      </div>
    </div>
  );
};

NoContent.defaultProps = {
  image: null,
  title: null,
  subtitle: null,
  className: "",
};

NoContent.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
};

export default NoContent;
