/* eslint-disable import/order */
import React, { useReducer, useMemo, useEffect } from "react";
import PropTypes from "prop-types";

import { Loader, NoContent, FetchingError, Modal, Button } from "components";
import Form from "./components/form";
import { handleFetches } from "utilities";
import {
  getPropertyStatistics,
  getPropertyStatisticsUserPrices,
} from "api/properties";
import statisticsDetailsActions from "state/local/actions/statistics/statistics_details_actions";
import statisticsDetailsReducer from "state/local/reducers/statistics/statistics_details_reducer";
import { Calendar, PropertyAttributes } from "./components";

import emptyCalendarImg from "assets/images/calendar_empty.svg";
import styles from "./statistics.module.css";

const title = "Months list is empty";
const subtitle = "There are no calculated months for this property";

const initialState = {
  details: null,
  fetching: true,
  error: null,
  status: null,
  isOpen: false,
  modalDate: null,
  isSending: false,
};

const isCalendarPrimary = (month, year) => {
  const currentDate = new Date();
  return (
    currentDate.getMonth() + 1 === Number(month) &&
    currentDate.getFullYear() === Number(year)
  );
};

const LinkBackToStatisticsList = () => (
  <Button type="secondary" size="tiny" to="/statistics">
    Go back
  </Button>
);

const PriceModalButton = ({ openModal }) => (
  <Button type="primary" size="tiny" onClick={() => openModal(new Date())}>
    Add Price
  </Button>
);

const PropertyAddress = ({ details }) => (
  <div className={styles.address}>
    {`${details.city},
    ${details.address}`}
  </div>
);

const Statistics = ({ match }) => {
  const { propertyId } = match.params;

  const [state, dispatch] = useReducer(statisticsDetailsReducer, initialState);
  const {
    request,
    success,
    failure,
    openModal,
    closeModal,
    setSendForm,
    updatePrices,
  } = useMemo(() => statisticsDetailsActions(dispatch), [dispatch]);

  useEffect(() => {
    async function fetchData() {
      request();
      const [
        [statistics, userPrices],
        error,
        { isCanceled, status },
      ] = await handleFetches([
        () => getPropertyStatistics(propertyId),
        () => getPropertyStatisticsUserPrices(propertyId),
      ]);

      if (isCanceled) return;

      if (!error) {
        success(statistics, userPrices);
      } else {
        failure({ error, status });
      }
    }

    fetchData();
  }, [propertyId, request, failure, success]);

  const {
    fetching,
    details,
    error,
    isOpen,
    modalDate,
    isSending,
    status,
  } = state;

  const formProps = {
    handleClose: closeModal,
    modalDate,
    setSendForm,
    isSending,
    details,
    updatePrices,
  };

  const noContentProps = {
    className: styles.noContent,
    image: emptyCalendarImg,
    title,
    subtitle,
  };

  const showNoContent =
    !(fetching || error) && details && !details.months.length;

  return (
    <div className={`${styles.container} container`}>
      {details && (
        <>
          <div className={styles.property}>Property statistics</div>
          <div className={styles.topPanel}>
            <PropertyAddress details={details} />
            <PropertyAttributes details={details} styles={styles} />
            <div className={styles.buttons}>
              <LinkBackToStatisticsList />
              <PriceModalButton openModal={openModal} />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.leftPanel}>
              {details.months.map(month => (
                <Calendar
                  year={month.year}
                  month={month.month}
                  primary={isCalendarPrimary(month.month, month.year)}
                  days={month.days}
                  key={month.year + month.month}
                  onClickDay={openModal}
                />
              ))}
              {showNoContent && <NoContent {...noContentProps} />}
            </div>
          </div>
          <Modal isOpen={isOpen} handleClose={closeModal} disabled={isSending}>
            <Form {...formProps} />
          </Modal>
        </>
      )}
      {fetching && <Loader absolute type="primary" size="big" />}
      {error && !details && <FetchingError error={error} status={status} />}
    </div>
  );
};

Statistics.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ propertyId: PropTypes.string.isRequired })
      .isRequired,
  }).isRequired,
};

PriceModalButton.propTypes = {
  openModal: PropTypes.func.isRequired,
};
PropertyAddress.propTypes = {
  details: PropTypes.shape().isRequired,
};

export default Statistics;
