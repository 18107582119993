import React from "react";

import PropTypes from "prop-types";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";

const LightBox = ({
  toggler,
  onMoveNext,
  onMovePrev,
  images,
  currentImage,
  onClose,
  activeImageIndex,
}) => {
  const prevSrc =
    images[(activeImageIndex + images.length - 1) % images.length];
  const nextSrc = images[(activeImageIndex + 1) % images.length];

  return (
    toggler && (
      <div>
        <Lightbox
          mainSrc={currentImage}
          nextSrc={nextSrc}
          prevSrc={prevSrc}
          enableZoom
          onCloseRequest={onClose}
          onMovePrevRequest={onMovePrev}
          onMoveNextRequest={onMoveNext}
          reactModalStyle={{
            overlay: {
              zIndex: "100000",
              position: "fixed",
              inset: "0px",
              backgroundColor: "transparent",
            },
          }}
        />
      </div>
    )
  );
};

LightBox.proptypes = {
  toggler: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  onMoveNext: PropTypes.func.isRequired,
  onMovePrev: PropTypes.func.isRequired,
  currentImage: PropTypes.string.isRequired,
  activeImageIndex: PropTypes.number.isRequired,
};

export default LightBox;
