import {
  SUCCESS,
  REQUEST,
  FAILURE,
} from "state/local/action_types/statistics/statistics_list_action_types";

const statisticsListActions = dispatch => ({
  request: () => dispatch({ type: REQUEST }),
  success: payload => dispatch({ type: SUCCESS, payload }),
  failure: error => dispatch({ type: FAILURE, error }),
});

export default statisticsListActions;
