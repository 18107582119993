import React from "react";
import { Segment } from "components";
import { priceFormatter } from "utilities";
import PropTypes from "prop-types";

const ShortletListingYieldsPerPrice = data => {
  return (
    <>
      <Segment
        title="Max price - 12% yield"
        text={"£" + priceFormatter(data.maxPrice12)}
        info="The maximum price that can be paid in order to
                  achieve a 12% yield."
      />
      <Segment
        title="Max price - 15% yield"
        text={"£" + priceFormatter(data.maxPrice15)}
        info="The maximum price that can be paid in order to
                  achieve a 15% yield."
      />
      <Segment
        title="Max price - 18% yield"
        text={"£" + priceFormatter(data.maxPrice18)}
        info="The maximum price that can be paid in order to
                  achieve a 18% yield."
      />
    </>
  );
};

ShortletListingYieldsPerPrice.propTypes = {
  data: PropTypes.shape({
    maxPrice12: PropTypes.number,
    maxPrice15: PropTypes.number,
    maxPrice18: PropTypes.number,
  }).isRequired,
};

export default ShortletListingYieldsPerPrice;
