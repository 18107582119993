import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { string, object } from "yup";
import { InputField, Button } from "components";
import loginBackground from "assets/images/login.jpg";
import logo from "assets/images/skwire-Logo-2.2.svg";
import Environment from "relay/environment";
import styles from "./reset_password.module.css";

const mutation = graphql`
  mutation resetPasswordMutation($email: String!) {
    sendResetEmail(email: $email) {
      message
    }
  }
`;

/**
 * Sends mutation to graphql
 * @param {string} email
 */
function reset(email) {
  return new Promise(resolve => {
    const variables = {
      email,
    };

    commitMutation(Environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        return resolve([response, errors]);
      },
      onError: err => {
        console.error(err);
        return resolve([null, err]);
      },
    });
  });
}

const initialValues = { email: "" };

const validation = object().shape({
  email: string()
    .email("This field must be a vaild email")
    .required("Email is required"),
});

const ResetPassword = () => {
  const [success, setSuccess] = React.useState("");

  async function handleReset(values, { setErrors, setSubmitting }) {
    const [payload, error] = await reset(values.email);
    if (error || payload.errors) {
      const message = error[0]
        ? error[0].message
        : "Server error. Unable to reset password";
      const text = message || payload.errors[0].message;
      setSuccess("");
      setErrors({
        message: text,
      });
    } else {
      setSuccess(payload.sendResetEmail.message);
    }
    setSubmitting(false);
  }

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <img className={styles.background} src={loginBackground} alt="" />
        <img className={styles.logo} src={logo} alt="skwire logo" />
      </div>
      <div className={styles.panel}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleReset}
          validationSchema={validation}
        >
          {({ errors, isSubmitting, dirty }) => (
            <Form className={styles.form}>
              <img className={styles.logo} src={logo} alt="skwire logo" />
              <div className={styles.title}>Reset password</div>
              <div
                className={[styles.error, success ? styles.success : ""].join(
                  " ",
                )}
              >
                {errors.message || success}
              </div>
              <div>
                <div className={styles.message} />
                <Field
                  component={InputField}
                  name="email"
                  placeholder="Email"
                  noLabel
                />
                <div className={styles.buttons}>
                  <Button
                    btnType="submit"
                    type="primary"
                    disabled={isSubmitting || !dirty}
                    loading={isSubmitting}
                  >
                    Reset
                  </Button>
                  <Button to="/login" type="secondary">
                    Back
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

ResetPassword.defaultProps = {
  errors: null,
};

ResetPassword.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  errors: PropTypes.shape({
    message: PropTypes.string,
    email: PropTypes.string,
  }),
};

export default ResetPassword;
