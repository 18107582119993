import { createReducer } from "@reduxjs/toolkit";

import mapFilterFactories from "state/global/factories/map_filter_factories";
import {
  actionTypes,
  actionTypePrefix,
} from "state/global/actions/user_input/map/filters/shortlet/listing_filters_actions";

import filterConfigs from "configs/filter_configs";

const filterOptions = {
  reservationRatio: [0, 100],
  avgReservations: [0, 365],
  avgReservationsDays: [0, 365],
  avgBlocked: [0, 365],
  avgAvailableDays: [0, 365],
  listingTypes: filterConfigs.sharedConfig.propertyTypes,
  listingBeds: filterConfigs.sharedConfig.listingBeds,
  listingPrice: [0, 150000],
  listingAmenities: [],
};

const filterDefaults = {
  reservationRatio: [0, 100],
  avgReservations: [0, 365],
  avgReservationsDays: [0, 365],
  avgBlocked: [0, 365],
  avgAvailableDays: [0, 365],
  listingTypes: filterConfigs.sharedConfig.propertyTypes,
  listingBeds: filterConfigs.sharedConfig.listingBeds,
  listingPrice: [0, 150000],
  listingAmenities: [],
};

const allowedFilterKeys = [
  "reservationRatio",
  "avgReservations",
  "avgReservationsDays",
  "avgBlocked",
  "avgAvailableDays",
  "listingTypes",
  "listingBeds",
  "listingPrice",
  "listingAmenities",
];

const reducer = mapFilterFactories.generateFilterReducer(
  actionTypes,
  actionTypePrefix,
  filterOptions,
  filterDefaults,
  allowedFilterKeys,
);

export default reducer;
