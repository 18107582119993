import graphql from "babel-plugin-relay/macro";

export const hullStatisticsQuery = graphql`
  query longletQueriesHullStatisticsQuery($id: ID!) {
    longletPropertyHullStatistics(id: $id) {
      id
      propertyHull {
        bedrooms
        propertyType
        district
        incomeBand
      }
      averageIncome
      numberOfProperties
      titleKeywordsWithIncomes
      descriptionKeywordsWithIncomes
      previousMonthsAvgSoldPrices
      previousMonthsNumPropertiesSold
    }
  }
`;

export const listingStatisticsQuery = graphql`
  query longletQueriesHullListingStatisticsQuery($id: ID!) {
    longletHullListingsStatistics(id: $id) {
      id
      hullListing {
        id
        propertyUrl
        isSourceZoopla
        locationPoint
        bedrooms
        previousSalePrices
        postcode
      }
      maxPrice12PercYield
      maxPrice18PercYield
      maxPrice15PercYield
      all12MonthIncomes
      imageUrls
      average12MonthIncome
    }
  }
`;
