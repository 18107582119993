import React from "react";
import PropTypes from "prop-types";

import { Chip } from "components/";
import useQuery from "components/hooks/use_query";
import { getFontColor } from "utilities";
import handleAmenityHullFiltering from "pages/map/left_panel/filter_handlers/amenity_hulls_filter";
import amenityHullFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/amenity_hull_filters_selectors";

import styles from "../../hulls_panel.module.css";

const SelectedAmenitiesChips = ({
  amenityHullFiltersActions,
  amenityHullFiltersCurrent,
  allAmenityTypes,
  mapContentViewActions,
  allShortletAmenityHullsTypes,
  mapContentViewState,
}) => {
  const { updateQueryString } = useQuery();

  return (
    <div className={styles.chips}>
      {amenityHullFiltersCurrent.amenityTypes.map(amenityTypeCode => {
        const amenityTypeRecord = allAmenityTypes.find(
          el => el.amenity === amenityTypeCode,
        );

        const amenityHullColor = getAmenityHullColor(amenityTypeRecord);

        return amenityHullColor ? (
          <Chip
            key={amenityTypeCode}
            onDelete={() =>
              handleAmenityHullFiltering(
                updateQueryString,
                amenityHullFiltersActions,
                amenityHullFiltersSelectors,
                "amenityTypes",
                amenityTypeCode,
              )
            }
            color={getFontColor(amenityHullColor)}
            background={amenityHullColor}
            onClick={() =>
              mapContentViewActions.highlightAmenityType(amenityTypeRecord)
            }
            disabled={isAmenityTypeDisabled(
              allShortletAmenityHullsTypes,
              amenityTypeRecord,
            )}
            selected={isAmenityTypeSelected(
              amenityTypeRecord,
              mapContentViewState,
            )}
          >
            {getAmenityName(amenityTypeCode)}
          </Chip>
        ) : null;
      })}
    </div>
  );
};

const getColor = colorRGB =>
  !colorRGB
    ? "rgb(252, 189, 1)"
    : `rgb(${colorRGB[0]},${colorRGB[1]},${colorRGB[2]})`;

const getAmenityHullColor = amenityTypeRecord =>
  amenityTypeRecord ? getColor(amenityTypeRecord.color) : null;

const isAmenityTypeDisabled = (allShortletAmenityHullsTypes, amenity) => {
  const recordNotFound = !allShortletAmenityHullsTypes.includes(
    amenity.amenity,
  );

  return recordNotFound;
};

const getAmenityName = value => value.split("_").join(" ");

const isAmenityTypeSelected = (amenityTypeRecord, { highlightedAmenityType }) =>
  highlightedAmenityType && highlightedAmenityType === amenityTypeRecord;

SelectedAmenitiesChips.propTypes = {
  amenityHullFiltersActions: PropTypes.shape().isRequired,
  amenityHullFiltersCurrent: PropTypes.shape({
    amenityTypes: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  allAmenityTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mapContentViewActions: PropTypes.shape().isRequired,
  allShortletAmenityHullsTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  mapContentViewState: PropTypes.shape().isRequired,
};

export default SelectedAmenitiesChips;
