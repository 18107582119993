import React from "react";
import { Tooltip } from "react-tippy";
import PropTypes from "prop-types";

import { priceFormatter } from "utilities";
import { MISSING_PRICE_PLACEHOLDER } from "pages/map/components/helpers/standardize_listing_payload";

import styles from "./index.module.css";

const LastSalePrice = ({ date, price }) => (
  <>
    <Tooltip title={date} size="small">
      <span className={styles["last-sale-price"]}>last sale price:</span>
    </Tooltip>
    <span className={styles.price}>
      {price !== "n/a" ? `£${priceFormatter(price, true)}` : price}
    </span>
  </>
);

const IncomeInfo = ({ info, lastSaleDateWithPrice }) => {
  return (
    <div>
      {info.map(({ label, value, valuePrefix }) => (
        <div className={styles["monthly-income-data"]} key={label}>
          <span>{`${label}:`}</span>
          <span className={styles.price}>
            {valuePrefix}
            {typeof value === "number" ? priceFormatter(value, true) : value}
          </span>
        </div>
      ))}
      <hr className={styles.hr} />
      <div className={styles["monthly-income-data"]}>
        <LastSalePrice
          date={
            lastSaleDateWithPrice.date
              ? lastSaleDateWithPrice.date
              : MISSING_PRICE_PLACEHOLDER
          }
          price={
            lastSaleDateWithPrice.price
              ? lastSaleDateWithPrice.price
              : MISSING_PRICE_PLACEHOLDER
          }
        />
      </div>
    </div>
  );
};

IncomeInfo.propTypes = {
  info: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  lastSaleDateWithPrice: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
};

IncomeInfo.defaultProps = {
  lastSaleDateWithPrice: {},
};

LastSalePrice.propTypes = {
  date: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default IncomeInfo;
