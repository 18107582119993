import {
  SUCCESS,
  REQUEST,
  FAILURE,
  OPEN_MODAL,
  CLOSE_MODAL,
  SEND_FORM,
  UPDATE_PRICES,
} from "state/local/action_types/statistics/statistics_details_action_types";

const statisticsDetailsActions = dispatch => ({
  request: () => dispatch({ type: REQUEST }),
  success: (statistics, userPrices) =>
    dispatch({ type: SUCCESS, statistics, userPrices }),
  failure: error => dispatch({ type: FAILURE, error }),
  openModal: date => dispatch({ type: OPEN_MODAL, date }),
  closeModal: () => dispatch({ type: CLOSE_MODAL }),
  setSendForm: payload => dispatch({ type: SEND_FORM, payload }),
  updatePrices: userPrices => dispatch({ type: UPDATE_PRICES, userPrices }),
});

export default statisticsDetailsActions;
