import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";

import googleMapsIcon from "assets/images/external_links_icons/google_maps_icon.png";
import airbnbIcon from "assets/images/external_links_icons/airbnb_icon.png";
import styles from "./index.module.css";

const ExternalLinkIcons = ({
  googleMapsUrl,
  listingLink,
  airbnbUrl,
  hubspotLink,
}) => (
  <div className={styles.container}>
    <div className={styles.title}>Details</div>
    <div className={styles["link-icons"]}>
      {airbnbUrl ? (
        <AirBnBIcon url={airbnbUrl} />
      ) : (
        listingLink.url && <ListingExternalIcon {...listingLink} />
      )}
      <GoogleMapsIcon url={googleMapsUrl} />
      <ListingExternalIcon {...hubspotLink} />
    </div>
  </div>
);

ExternalLinkIcons.defaultProps = {
  listingLink: {},
  airbnbUrl: null,
  hubspotLink: {},
};

ExternalLinkIcons.propTypes = {
  googleMapsUrl: PropTypes.string.isRequired,
  listingLink: PropTypes.shape({
    url: PropTypes.string.isRequired,
    displayText: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }),
  airbnbUrl: PropTypes.string,
  hubspotLink: PropTypes.shape({}),
};

const ListingExternalIcon = ({ url, displayText, icon }) => (
  <button type="button">
    <Tooltip title={displayText}>
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <img src={icon} alt={displayText} />
      </a>
    </Tooltip>
  </button>
);

const GoogleMapsIcon = ({ url }) => (
  <button type="button">
    <Tooltip title="Open in Google Maps">
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <img src={googleMapsIcon} alt="open in google maps" />
      </a>
    </Tooltip>
  </button>
);

const AirBnBIcon = ({ url }) => (
  <button type="button">
    <Tooltip title="Open in AirBnB">
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <img src={airbnbIcon} alt="AirBnB link" />
      </a>
    </Tooltip>
  </button>
);

export default ExternalLinkIcons;
