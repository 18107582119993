/* eslint-disable import/order */
import React, { useReducer } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { useDidUpdate, useWillUnmount } from "react-hooks-lib";

import ListingDetailsPanel from "./listing_details";
import HullDetailsPanel from "./hull_details";
import AmenityDetailsPanel from "./amenities_details";
import { useMapContextStore, NoContent } from "components";
import mapDetailsActions from "state/local/actions/map/map_details_actions";
import mapDetailsReducer from "state/local/reducers/map/map_details_reducer";

import closeImg from "assets/images/close.png";
import styles from "./container.module.css";

import { actions as mapContentViewActions } from "state/global/actions/ui_control/map/map_content_view_actions";

import shortletListingsSelectors from "state/global/selectors/data_collections/map/shortlet/listings_selectors";

import longletListingsSelectors from "state/global/selectors/data_collections/map/longlet/listings_selectors";

import salesDealsListingsSelectors from "state/global/selectors/data_collections/map/sales/deals_listings_selectors";

import shortletPropertyHullsSelectors from "state/global/selectors/data_collections/map/shortlet/property_hulls_selectors";

import shortletAmenityHullsSelectors from "state/global/selectors/data_collections/map/shortlet/amenity_hulls_selectors";

import longletPropertyHullsSelectors from "state/global/selectors/data_collections/map/longlet/property_hulls_selectors";

import longletAmenityHullsSelectors from "state/global/selectors/data_collections/map/longlet/amenity_hulls_selectors";

import mapContentViewSelectors from "state/global/selectors/ui_control/map/map_content_view_selectors";

import listingConfigs from "configs/listing_configs";

const DetailsPanel = ({
  // ### mapStateToProps
  selectedListingId,
  selectedListingType,
  selectedPropertyHullId,
  selectedPropertyHullType,
  selectedAmenityHullId,
  selectedAmenityHullType,
  shortletListingsByKey,
  longletListingsByKey,
  salesDealsListingsByKey,
  shortletPropertyHullsByKey,
  shortletAmenityHullsByKey,
  longletPropertyHullsByKey,
  longletAmenityHullsByKey,

  // ### mapDispatchToProps

  // eslint-disable-next-line no-shadow
  mapContentViewActions,
}) => {
  const [{ title, listingId, hullId, amenityId }, dispatch] = useReducer(
    mapDetailsReducer,
    initialState,
  );
  const { setListingId, setHullId, setAmenityId, resetIDs } = mapDetailsActions(
    dispatch,
  );

  useDidUpdate(() => {
    if (selectedListingId && selectedListingType) {
      const { listingTypes } = listingConfigs.sharedConfig;

      const listingRecords = {
        [listingTypes.shortletListing.type]:
          shortletListingsByKey[selectedListingId],
        [listingTypes.longletListing.type]:
          longletListingsByKey[selectedListingId],
        [listingTypes.hubspotPushedSaleListing.type]:
          salesDealsListingsByKey[selectedListingId],
      };

      const selectedListing = listingRecords[selectedListingType];

      if (selectedListing) {
        setListingId(selectedListing.id, initialState);
      }
    }

    if (selectedPropertyHullId && selectedPropertyHullType) {
      const { hullTypes } = listingConfigs.sharedConfig;

      const propertyHullRecords = {
        [hullTypes.shortletPropertyHulls.type]:
          shortletPropertyHullsByKey[selectedPropertyHullId],
        [hullTypes.longletPropertyHulls.type]:
          longletPropertyHullsByKey[selectedPropertyHullId],
      };

      const selectedPropertyHull =
        propertyHullRecords[selectedPropertyHullType];

      if (selectedPropertyHull) {
        setHullId(selectedPropertyHull.propertyhullstatistic, initialState);
      }
    }

    if (selectedAmenityHullId && selectedAmenityHullType) {
      const { amenityHullTypes } = listingConfigs.sharedConfig;

      const amenityHullRecords = {
        [amenityHullTypes.shortletAmenityHulls.type]:
          shortletAmenityHullsByKey[selectedAmenityHullId],
        [amenityHullTypes.longletAmenityHulls.type]:
          longletAmenityHullsByKey[selectedAmenityHullId],
      };

      const selectedAmenityHull = amenityHullRecords[selectedAmenityHullType];

      if (selectedAmenityHull) {
        setHullId(selectedAmenityHull.amenityhullstatistic, initialState);
      }
    }
    // Close all panels TODO: When mapType changes details needs to be hidden
    // resetIDs(initialState);
  }, [selectedListingId, selectedPropertyHullId, selectedAmenityHullId]);

  useWillUnmount(() => {
    mapContentViewActions.deselectAll();
  });

  const isActive = Boolean(listingId) || Boolean(hullId) || Boolean(amenityId);
  const emptyId = hasEmptyStatisticsId(listingId, hullId, amenityId);
  const activeStyles = isActive || emptyId ? styles.active : "";

  const handleCloseDetails = () => {
    resetIDs(initialState);
    mapContentViewActions.deselectAll();
  };

  return (
    <div
      className={[styles.panel, activeStyles].join(" ")}
      data-testid={listingId}
    >
      <div className={styles.heading}>
        <span className={styles.title}>{title}</span>
        <CloseDetailsButton onClick={handleCloseDetails} />
      </div>
      <div className={styles.wrapper}>
        {listingId && (
          <ListingDetailsPanel
            id={listingId}
            selectedListingType={selectedListingType}
          />
        )}
        {hullId && (
          <HullDetailsPanel
            selectedPropertyHullType={selectedPropertyHullType}
            id={hullId}
          />
        )}
        {amenityId && <AmenityDetailsPanel id={amenityId} />}
        {emptyId && <NoContent />}
      </div>
    </div>
  );
};

DetailsPanel.defaultProps = {
  selectedListingId: null,
  selectedPropertyHullId: null,
  selectedAmenityHullId: null,
  selectedListingType: null,
  selectedPropertyHullType: null,
  selectedAmenityHullType: null,
};

DetailsPanel.propTypes = {
  selectedListingId: PropTypes.number,
  selectedListingType: PropTypes.string,
  selectedPropertyHullId: PropTypes.number,
  selectedPropertyHullType: PropTypes.string,
  selectedAmenityHullId: PropTypes.string,
  selectedAmenityHullType: PropTypes.string,
  shortletListingsByKey: PropTypes.shape().isRequired,
  longletListingsByKey: PropTypes.shape().isRequired,
  salesDealsListingsByKey: PropTypes.shape().isRequired,
  shortletPropertyHullsByKey: PropTypes.shape().isRequired,
  shortletAmenityHullsByKey: PropTypes.shape().isRequired,
  longletPropertyHullsByKey: PropTypes.shape().isRequired,
  longletAmenityHullsByKey: PropTypes.shape().isRequired,
  mapContentViewActions: PropTypes.shape({
    deselectAll: PropTypes.func.isRequired,
  }).isRequired,
};

const CloseDetailsButton = ({ onClick }) => (
  <button type="button" className={styles.back} onClick={onClick}>
    <img src={closeImg} alt="" />
  </button>
);

CloseDetailsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const initialState = {
  title: "",
  listingId: false,
  hullId: false,
  amenityId: false,
};

const hasEmptyStatisticsId = (listingId, hullId, amenityId) => {
  return listingId === null || hullId === null || amenityId === null;
};

const mapStateToProps = (state, props) => ({
  selectedListingId: mapContentViewSelectors.selectSelectedListingId(state),
  selectedListingType: mapContentViewSelectors.selectSelectedListingType(state),
  selectedPropertyHullId: mapContentViewSelectors.selectSelectedPropertyHullId(
    state,
  ),
  selectedPropertyHullType: mapContentViewSelectors.selectSelectedPropertyHullType(
    state,
  ),
  selectedAmenityHullId: mapContentViewSelectors.selectSelectedAmenityHullId(
    state,
  ),
  selectedAmenityHullType: mapContentViewSelectors.selectSelectedAmenityHullType(
    state,
  ),
  shortletListingsByKey: shortletListingsSelectors.selectAllByKey(state),
  longletListingsByKey: longletListingsSelectors.selectAllByKey(state),
  salesDealsListingsByKey: salesDealsListingsSelectors.selectAllByKey(state),
  shortletPropertyHullsByKey: shortletPropertyHullsSelectors.selectAllByKey(
    state,
  ),
  shortletAmenityHullsByKey: shortletAmenityHullsSelectors.selectAllByKey(
    state,
  ),
  longletPropertyHullsByKey: longletPropertyHullsSelectors.selectAllByKey(
    state,
  ),
  longletAmenityHullsByKey: longletAmenityHullsSelectors.selectAllByKey(state),
});

const mapDispatchToProps = dispatch => ({
  mapContentViewActions: bindActionCreators(mapContentViewActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DetailsPanel);
