/**
 * @flow
 * @relayHash ccf8d0531c05a91da0505d529174ae1e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type shortletQueriesAmenityStatisticsQueryVariables = {|
  id: string
|};
export type shortletQueriesAmenityStatisticsQueryResponse = {|
  +amenityHullStatistic: ?{|
    +amenityHull: {|
      +incomeBand: string,
      +city: string,
      +amenity: string,
    |},
    +numberProperties: ?number,
    +averageIncome: ?number,
  |}
|};
export type shortletQueriesAmenityStatisticsQuery = {|
  variables: shortletQueriesAmenityStatisticsQueryVariables,
  response: shortletQueriesAmenityStatisticsQueryResponse,
|};
*/


/*
query shortletQueriesAmenityStatisticsQuery(
  $id: ID!
) {
  amenityHullStatistic(id: $id) {
    amenityHull {
      incomeBand
      city
      amenity
      id
    }
    numberProperties
    averageIncome
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "incomeBand",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "city",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "amenity",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "numberProperties",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "averageIncome",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "shortletQueriesAmenityStatisticsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "amenityHullStatistic",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AmenityHullStatisticNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "amenityHull",
            "storageKey": null,
            "args": null,
            "concreteType": "AmenityHullNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "shortletQueriesAmenityStatisticsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "amenityHullStatistic",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AmenityHullStatisticNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "amenityHull",
            "storageKey": null,
            "args": null,
            "concreteType": "AmenityHullNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v7/*: any*/)
            ]
          },
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "shortletQueriesAmenityStatisticsQuery",
    "id": null,
    "text": "query shortletQueriesAmenityStatisticsQuery(\n  $id: ID!\n) {\n  amenityHullStatistic(id: $id) {\n    amenityHull {\n      incomeBand\n      city\n      amenity\n      id\n    }\n    numberProperties\n    averageIncome\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c7909dacfd96edec32e15eb88201adb4';
module.exports = node;
