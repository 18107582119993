import React from "react";
import { connect } from "react-redux";
import { Polyline } from "@react-google-maps/api";
import PropTypes from "prop-types";

import amenityHullsDisabledFiltersSelectors from "state/global/selectors/user_input/map/filters/amenity_hulls_disabled_selectors";
import mapContentHelpers from "./helpers/map_content_helpers";

const getStrokeColor = (color, isSelectedAmenityHull) => {
  const selectedColor = "#2ec700";
  const defaultColor = "#999999";
  const amenityHullColor =
    color && `rgba(${color[0]},${color[1]},${color[2]}, 0.7)`;

  const strokeColor = amenityHullColor || defaultColor;

  return isSelectedAmenityHull ? selectedColor : strokeColor;
};

const AmenityHulls = React.memo(
  ({
    amenityHulls,
    showAmenityHulls,
    isAmenityHullVisible,
    isSelectedAmenityHull,
    handleSelectAmenityHull,
    mapType,
    // ### mapStateToProps
    amenityHullsDisabled,
  }) => {
    const showPolygons = !amenityHullsDisabled && showAmenityHulls;

    return (
      showPolygons &&
      amenityHulls.map(hull => {
        const isSelected = isSelectedAmenityHull(hull);
        const strokeColor = getStrokeColor(hull.color, isSelected);
        const strokeWeight = isSelected ? 9 : 5;
        const polygons = mapContentHelpers.transformCoordsToPolygons(hull);

        const options = {
          strokeWeight,
          strokeColor,
        };

        return polygons.map(polygon => (
          <Polyline
            key={polygon.id}
            onClick={() => handleSelectAmenityHull(hull)}
            options={options}
            path={polygon.path}
          />
        ));
      })
    );
  },
);

AmenityHulls.propTypes = {
  showAmenityHulls: PropTypes.bool.isRequired,
  amenityHulls: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleSelectAmenityHull: PropTypes.func.isRequired,
  isAmenityHullVisible: PropTypes.func.isRequired,
  isSelectedAmenityHull: PropTypes.func.isRequired,
  amenityHullsDisabled: PropTypes.bool.isRequired,
  mapType: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => ({
  amenityHullsDisabled: amenityHullsDisabledFiltersSelectors
    .selectCurrent(state)
    .amenityHullsDisabled.includes(props.mapType),
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AmenityHulls);
