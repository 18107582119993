import { useState } from "react";
import { useDispatch } from "react-redux";

import { actions as mapContentViewActions } from "state/global/actions/ui_control/map/map_content_view_actions";
import mapContentHelpers from "../helpers/map_content_helpers";

const useMapContentLogic = ({
  mapContentViewState,
  propertyHullFiltersCurrent,
  amenityHullFiltersCurrent,
}) => {
  const {
    selectedListingId,
    selectedListingType,
    selectedPropertyHullId,
    selectedPropertyHullType,
    selectedAmenityHullId,
    selectedAmenityHullType,
    highlightedAmenityType,
  } = mapContentViewState;

  const dispatch = useDispatch();

  const checkIfPropertyHullVisible = propertyHull => {
    return mapContentHelpers.isPropertyHullVisible(
      propertyHull,
      propertyHullFiltersCurrent,
    );
  };

  const checkIfAmenityHullVisible = amenityHull => {
    return mapContentHelpers.isAmenityHullVisible(
      amenityHull,
      amenityHullFiltersCurrent,
    );
  };

  const checkIfSelectedListing = (listing, currentListingType) => {
    const isIdMatching = selectedListingId && listing.id === selectedListingId;
    const isTypeMatching = selectedListingType === currentListingType;

    return isIdMatching && isTypeMatching;
  };

  const checkIfSelectedPropertyHull = (
    propertyHull,
    currentPropertyHullType,
  ) => {
    let isSelected = false;

    const isIdMatching =
      selectedPropertyHullId && propertyHull.id === selectedPropertyHullId;
    const isTypeMatching = selectedPropertyHullType === currentPropertyHullType;

    if (isIdMatching && isTypeMatching) isSelected = true;

    return isSelected;
  };

  const checkIfSelectedAmenityHull = (amenityHull, currentAmenityHullType) => {
    let isSelected = false;

    const isIdMatching =
      selectedAmenityHullId && amenityHull.id === selectedAmenityHullId;
    const isTypeMatching = selectedAmenityHullType === currentAmenityHullType;

    if (isIdMatching && isTypeMatching) isSelected = true;

    if (
      highlightedAmenityType &&
      highlightedAmenityType === amenityHull.amenity
    )
      isSelected = true;

    return isSelected;
  };

  const handleSelectAmenityHull = (amenityHull, amenityHullType) => {
    dispatch(mapContentViewActions.deselectAll());
    dispatch(
      mapContentViewActions.selectAmenityHull(amenityHull.id, amenityHullType),
    );
  };

  const handleSelectPropertyHull = (propertyHull, propertyHullType) => {
    dispatch(mapContentViewActions.deselectAll());
    dispatch(
      mapContentViewActions.selectPropertyHull(
        propertyHull.id,
        propertyHullType,
      ),
    );
  };

  const createParameterizedWrapper = (functionToWrap, ...params) => {
    return (...callParams) => {
      return functionToWrap(...callParams, ...params);
    };
  };

  return {
    checkIfPropertyHullVisible,
    checkIfAmenityHullVisible,
    checkIfSelectedListing,
    createParameterizedWrapper,
    checkIfSelectedPropertyHull,
    checkIfSelectedAmenityHull,
    handleSelectAmenityHull,
    handleSelectPropertyHull,
  };
};

export default useMapContentLogic;
