import React, {
  useReducer,
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import { connect, shallowEqual, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import Geocode from "react-geocode";
import PropTypes from "prop-types";

// import { mapTypes } from "state/local/stores/map_context_store";
import { Loader, useQuery, FetchingError } from "components";
import { googleApiKey, googleLibraries } from "configuration";
// import mapContentViewActions from "state/local/actions/map/map_content_view_actions";
// import mapContentViewReducer from "state/local/reducers/map/map_content_view_reducer";
import { actions as mapContentViewActions } from "state/global/actions/ui_control/map/map_content_view_actions";
import mapStateSelectors from "state/global/selectors/ui_control/map";
import { MapContext } from "state/local/stores/contexts/map_context_store";
import DetailsPanels from "./details_panels";
import LeftPanelContainer from "./left_panel/container";
import MapInitializer from "./map_initializer";
import MapElements from "./map_elements";
import styles from "./map.module.css";

Geocode.setApiKey(googleApiKey);

const UK_COORDINATES = { lat: 53.4083714, lng: -2.9915726 };

let timerQuery = null;
const debounceQuery = 350;

// const initialState = {
//   map: null,
//   searchMarker: null,
//   mapLoaded: null,
// };

const getMapOptions = () => ({
  mapTypeControl: true,
  streetViewControl: true,
  fullscreenControl: true,
  mapTypeControlOptions: {
    position: window.google.maps.ControlPosition.TOP_RIGHT,
  },
  disableDoubleClickZoom: true,
});

const MapComponent = ({
  // eslint-disable-next-line no-shadow
  mapContentViewActions,
  searchMarker,
  mapContentView,
  ...props
}) => {
  // const [state, dispatch] = useReducer(mapContentViewReducer, initialState);

  // const { mapType, setMapType } = useMapContextStore();
  // const { searchMarker, mapLoaded } = state;

  // const [ isMapLoaded, setIsMapLoaded ] = useState(false);
  // const [ searchMarker, setSearchMarker ] = useState(null);

  // const { setMap, setSearchMarker, setMapLoaded } = useMemo(
  // const { setMapLoaded } = useMemo(
  //   () => mapContentViewActions(dispatch),
  //   [dispatch],
  // );

  const [mapInstance, setMapInstance] = useState(null);
  const [fetchingMapData, setFetchingMapData] = useState(false);

  const mapContext = useMemo(
    () => ({
      mapInstance,
      fetchingMapData,
      setFetchingMapData,
    }),
    [mapInstance, fetchingMapData],
  );

  const { query, addQuery } = useQuery();

  const defaultZoom = useRef(Number(query.zoom || 17));
  const defaultCenter = useRef({
    lat: Number(query.lat || UK_COORDINATES.lat),
    lng: Number(query.lng || UK_COORDINATES.lng),
  });

  const scriptsLoadingResult = useLoadScript({
    googleMapsApiKey: googleApiKey,
    libraries: googleLibraries,
  });

  function handleZoom() {
    const currentZoom = mapInstance.getZoom();
    addQuery({
      zoom: currentZoom,
    });
  }

  function handleCenter() {
    const currentCenter = mapInstance.getCenter();
    const coords = {
      lat: currentCenter.lat(),
      lng: currentCenter.lng(),
    };
    addQuery({
      ...coords,
    });
  }

  function handleSearch(zoomValue, centerValues, address) {
    mapContentViewActions.setSearchMarker(centerValues);
    addQuery({
      ...centerValues,
      zoom: zoomValue,
      address,
    });
  }

  function setQueryMapDetail() {
    if (mapInstance) {
      clearTimeout(timerQuery);
      timerQuery = null;
      timerQuery = setTimeout(() => {
        const currentZoom = mapInstance.getZoom();
        const currentCenter = mapInstance.getCenter();
        const coords = {
          lat: currentCenter.lat(),
          lng: currentCenter.lng(),
        };
        addQuery({
          ...coords,
          zoom: currentZoom,
        });
      }, debounceQuery);
    }
  }

  const setViewport = React.useCallback(
    viewport => {
      if (mapInstance && viewport) {
        const primaryZoom = mapInstance.getZoom();
        const searchBounds = new window.google.maps.LatLngBounds();
        searchBounds.extend(viewport.northeast);
        searchBounds.extend(viewport.southwest);
        if (!query.lat && !query.lng) {
          mapInstance.fitBounds(searchBounds);
        }
        mapInstance.setZoom(primaryZoom);
      }
    },
    [mapInstance, query.lat, query.lng],
  );

  React.useEffect(() => {
    if (mapInstance && query.address && !searchMarker) {
      Geocode.fromAddress(query.address).then(response => {
        const { lat, lng } = response.results[0].geometry.location;
        const { viewport } = response.results[0].geometry;
        mapContentViewActions.setSearchMarker({ lat, lng });
        setViewport(viewport);
      }, console.error);
    }
  }, [mapInstance, query.address, searchMarker, setViewport]);

  function handleOnIdle(something) {
    mapContentViewActions.setIsMapLoaded(true);
  }

  function handleOnLoad(newMapInstance) {
    setMapInstance(newMapInstance);
  }

  return (
    <div className={styles.flex}>
      <MapContext.Provider value={mapContext}>
        <MapInitializer />
        <LeftPanelContainer />
        <DetailsPanels />
        <div className={styles.block}>
          {scriptsLoadingResult.isLoaded && (
            <GoogleMap
              id="map"
              data-testId="map"
              mapContainerClassName={styles.map}
              zoom={defaultZoom.current}
              center={defaultCenter.current}
              // onLoad={setMap}
              onLoad={handleOnLoad}
              onIdle={handleOnIdle}
              onUnmount={() => {
                mapContentViewActions.setIsMapLoaded(false);
              }}
              onZoomChanged={setQueryMapDetail}
              onDragEnd={setQueryMapDetail}
              options={getMapOptions()}
            >
              {mapContentView.isMapLoaded && mapInstance && (
                <MapElements
                  {...props}
                  handleZoom={handleZoom}
                  setViewport={setViewport}
                  handleCenter={handleCenter}
                  handleSearch={handleSearch}
                  searchMarker={mapContentView.searchMarker}
                  setSearchMarker={mapContentViewActions.setSearchMarker}
                />
              )}
            </GoogleMap>
          )}
          {!(mapContentView.isMapLoaded && mapInstance) && (
            <Loader absolute size="big" type="primary" />
          )}
          {scriptsLoadingResult.loadError && <FetchingError />}
        </div>
      </MapContext.Provider>
    </div>
  );
};

MapComponent.propTypes = {
  mapContentView: PropTypes.shape({
    searchMarker: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }).isRequired,
    isMapLoaded: PropTypes.bool.isRequired,
  }).isRequired,
  mapContentViewActions: PropTypes.shape({
    setSearchMarker: PropTypes.func.isRequired,
    setIsMapLoaded: PropTypes.func.isRequired,
  }).isRequired,
};

function mapStateToProps(state, props) {
  return {
    mapContentView: mapStateSelectors.selectMapContentView(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    mapContentViewActions: bindActionCreators(mapContentViewActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MapComponent);
