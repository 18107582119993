import mapFilterFactories from "state/global/factories/map_filter_factories";

import {
  actionTypes,
  actionTypePrefix,
} from "state/global/actions/user_input/map/filters/amenity_hulls_disabled_filters_actions";
import camelCase from "lodash/camelCase";
import mapConfigs from "configs/map_configs";

const {
  mapTypeNames: { longlet: longletMap, shortlet: shortletMap },
} = mapConfigs;

const FILTER_OPTIONS = [shortletMap, longletMap];

const filterOptions = {
  amenityHullsDisabled: FILTER_OPTIONS.map(type => camelCase(type)),
};

const filterDefaults = {
  amenityHullsDisabled: [],
};

const allowedFilterKeys = ["amenityHullsDisabled"];

const reducer = mapFilterFactories.generateFilterReducer(
  actionTypes,
  actionTypePrefix,
  filterOptions,
  filterDefaults,
  allowedFilterKeys,
);

export default reducer;
