/* eslint-disable no-shadow */
import React from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import camelCase from "lodash/camelCase";

import { actions as mapContentViewActions } from "state/global/actions/ui_control/map/map_content_view_actions";
import { actions as shortletListingsActions } from "state/global/actions/data_collections/map/shortlet/listings_actions";
import shortletListingsSelectors from "state/global/selectors/data_collections/map/shortlet/listings_selectors";
import shortletPropertyHullsSelectors from "state/global/selectors/data_collections/map/shortlet/property_hulls_selectors";
import shortletClustersSelectors from "state/global/selectors/data_collections/map/shortlet/listings_clusters_selectors";
import shortletAmenityHullsSelectors from "state/global/selectors/data_collections/map/shortlet/amenity_hulls_selectors";
import shortletListingFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/listing_filters_selectors";
import propertyHullFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/property_hull_filters_selectors";
import listingsDisabledFiltersSelectors from "state/global/selectors/user_input/map/filters/listings_disabled_selectors";
import amenityHullFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/amenity_hull_filters_selectors";
import mapContentViewSelectors from "state/global/selectors/ui_control/map/map_content_view_selectors";

import listingConfigs from "configs/listing_configs";
import useComponentDidUpdate from "components/hooks/use_component_did_update";
import fetchListings from "api/fetchers/listings";
import { getShortletListings } from "api/map";
import shouldRefetchListings from "api/fetchers/utilities";
import { MapContext } from "state/local/stores/contexts/map_context_store";
import { constructShortletListingsQueryParams } from "api/fetchers/shortlet/construct_shortlet_listings_query_params";
import mapConfigs from "configs/map_configs";
import useMapContentLogic from "../hooks/use_map_content_logic";
import AmenityHulls from "../amenity_hulls";
import PropertyHulls from "../property_hulls";
import VisibleChips from "../visible_chips";
import mapContentHelpers from "../helpers/map_content_helpers";

const getPosition = listing => ({
  lat: listing.point.coordinates[1],
  lng: listing.point.coordinates[0],
});

const ShortletComponents = ({
  // ### params
  enabled,
  showListings,
  showPropertyHulls,
  showAmenityHulls,

  // ### mapStateToProps
  listings,
  clustersInfo,
  propertyHulls,
  amenityHulls,
  shortletListingFiltersCurrent,
  mapContentViewState,
  propertyHullFiltersCurrent,
  amenityHullFiltersCurrent,
  listingsDisabledFiltersCurrent,

  // ### mapDispatchToProps
  mapContentViewActions,
  shortletListingsActions,
}) => {
  const {
    checkIfPropertyHullVisible,
    checkIfAmenityHullVisible,
    checkIfSelectedListing,
    checkIfSelectedPropertyHull,
    checkIfSelectedAmenityHull,
    handleSelectAmenityHull,
    handleSelectPropertyHull,
    createParameterizedWrapper,
  } = useMapContentLogic({
    mapContentViewState,
    propertyHullFiltersCurrent,
    amenityHullFiltersCurrent,
  });

  const { mapInstance } = React.useContext(MapContext);

  useComponentDidUpdate(() => {
    const {
      fetch,
      bedroomsToFetch,
    } = shouldRefetchListings.shouldRefetchListings(
      listings,
      shortletListingFiltersCurrent.listingBeds,
    );

    if (fetch) {
      (async () => {
        await fetchListings({
          allListings: listings,
          listingsActions: shortletListingsActions,
          queryParams: constructShortletListingsQueryParams(
            shortletListingFiltersCurrent,
            mapInstance,
          ),
          request: getShortletListings,
        });
      })();
    }
  }, [shortletListingFiltersCurrent.listingBeds]);

  const checkIfListingVisible = listing => {
    return mapContentHelpers.isShortletListingVisible(
      listing,
      shortletListingFiltersCurrent,
    );
  };

  const handleSelectListing = listing => {
    mapContentViewActions.deselectAll();
    mapContentViewActions.selectListing(listing.id, currentListingType);
  };

  const visibleAmenityHulls = amenityHulls.filter(hull =>
    checkIfAmenityHullVisible(hull),
  );

  return (
    enabled && (
      <>
        <VisibleChips
          showListings={showListings}
          listings={listings}
          getPosition={getPosition}
          handleSelectListing={handleSelectListing}
          listingsDisabled={listingsDisabledFiltersCurrent.listingsDisabled.includes(
            camelCase(shortletMapType),
          )}
          clustersInfo={clustersInfo}
          isListingVisible={checkIfListingVisible}
          isSelectedListing={createParameterizedWrapper(
            checkIfSelectedListing,
            currentListingType,
          )}
          currentListingType={currentListingType}
        />
        <PropertyHulls
          showPropertyHulls={showPropertyHulls}
          propertyHulls={propertyHulls}
          handleSelectPropertyHull={createParameterizedWrapper(
            handleSelectPropertyHull,
            currentPropertyHullType,
          )}
          coordsToGeometryTransformer={
            mapContentHelpers.transformCoordsToMultiPolygons
          }
          mapType={camelCase(shortletMapType)}
          isPropertyHullVisible={checkIfPropertyHullVisible}
          isSelectedPropertyHull={createParameterizedWrapper(
            checkIfSelectedPropertyHull,
            currentPropertyHullType,
          )}
        />
        <AmenityHulls
          showAmenityHulls={showAmenityHulls}
          amenityHulls={visibleAmenityHulls}
          handleSelectAmenityHull={createParameterizedWrapper(
            handleSelectAmenityHull,
            currentAmenityHullType,
          )}
          isAmenityHullVisible={checkIfAmenityHullVisible}
          isSelectedAmenityHull={createParameterizedWrapper(
            checkIfSelectedAmenityHull,
            currentAmenityHullType,
          )}
          mapType={camelCase(shortletMapType)}
        />
      </>
    )
  );
};

ShortletComponents.propTypes = {
  showListings: PropTypes.bool.isRequired,
  showPropertyHulls: PropTypes.bool.isRequired,
  showAmenityHulls: PropTypes.bool.isRequired,
  listings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  propertyHulls: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  amenityHulls: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const {
  sharedConfig: {
    listingTypes: {
      shortletListing: { type: currentListingType },
    },
    hullTypes: {
      shortletPropertyHulls: { type: currentPropertyHullType },
    },
    amenityHullTypes: {
      shortletAmenityHulls: { type: currentAmenityHullType },
    },
  },
} = listingConfigs;

const {
  mapTypeNames: { shortlet: shortletMapType },
} = mapConfigs;

const mapStateToProps = (state, props) => ({
  listings: shortletListingsSelectors.selectAll(state),
  propertyHulls: shortletPropertyHullsSelectors.selectAll(state),
  amenityHulls: shortletAmenityHullsSelectors.selectAll(state),

  mapContentViewState: mapContentViewSelectors.selectAll(state),
  propertyHullFiltersCurrent: propertyHullFiltersSelectors.selectCurrent(state),
  amenityHullFiltersCurrent: amenityHullFiltersSelectors.selectCurrent(state),

  shortletListingFiltersCurrent: shortletListingFiltersSelectors.selectCurrent(
    state,
  ),
  listingsDisabledFiltersCurrent: listingsDisabledFiltersSelectors.selectCurrent(
    state,
  ),
  clustersInfo: shortletClustersSelectors.selectAll(state),
});

const mapDispatchToProps = dispatch => ({
  mapContentViewActions: bindActionCreators(mapContentViewActions, dispatch),
  shortletListingsActions: bindActionCreators(
    shortletListingsActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShortletComponents);
