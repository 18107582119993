import listingConfigs from "configs/listing_configs";
import { salesDealsMarkerMapping } from "configs/sales_deals_maker_mapping";
import { pick } from "lodash";
import difference from "lodash/difference";

import { arrayFromMinMaxValues } from "utilities";

const {
  sharedConfig: { pipelineIds: fetchedPipelineIds },
} = listingConfigs;

let timer = null;

const shouldRefetchListings = (fetchedListings, bedrooms) => {
  const fetchedBedrooms = [
    ...new Set(
      fetchedListings.map(({ bedrooms: bedroomsCount }) =>
        bedroomsCount.toString(),
      ),
    ),
  ];
  const currentBedrooms = arrayFromMinMaxValues(bedrooms);

  const missingBedrooms = difference(currentBedrooms, fetchedBedrooms);
  const bedroomsToFetch = {
    min: [...missingBedrooms].shift(),
    max: [...missingBedrooms].pop(),
  };

  return {
    fetch: !!(missingBedrooms.length && currentBedrooms.length),
    bedroomsToFetch,
  };
};

export const fetchWithDebounce = (callback, debounce = 800) => {
  clearTimeout(timer);
  timer = null;
  timer = setTimeout(() => {
    callback();
  }, debounce);
};

const shouldRefetchMissingClustersInfo = (
  fetchedClustersInfo,
  currentDealStages,
) => {
  const fetchDealStageInternalIDs = [
    ...new Set(
      fetchedClustersInfo.map(
        ({ hubspot_deal_stage_internal_id }) => hubspot_deal_stage_internal_id,
      ),
    ),
  ];

  const unfetchedStages = difference(
    currentDealStages,
    fetchDealStageInternalIDs,
  );

  return {
    fetch: !!(unfetchedStages.length && currentDealStages.length),
    unfetchedStages,
  };
};

const convertDealStageNamesToHubspotDealStageInternalIDs = (
  dealStages,
  hubspotPipelinesToDealStagesMapping,
) =>
  dealStages.flatMap(
    stage =>
      salesDealsMarkerMapping(
        pick(hubspotPipelinesToDealStagesMapping, fetchedPipelineIds),
      ).find(mapping => mapping.label === stage)
        .hubspot_deal_stage_internal_ids,
  );

export default {
  shouldRefetchListings,
  shouldRefetchMissingClustersInfo,
  convertDealStageNamesToHubspotDealStageInternalIDs,
};
