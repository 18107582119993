import { inRange } from "lodash";
import {
  compsQueryParamsFilter,
  translateCompsQueryParamsNames,
} from "state/helpers/comparable_helpers";

const { MARKER_SVG_COLORS } = require("./marker_configs");

const formatMarkerLabel = (listing, compsQuery) => {
  const { comps, comparable_match_quality } = listing;
  const matchQualityPercentage = Math.round(comparable_match_quality * 100);
  const compsQueryParams = translateCompsQueryParamsNames(compsQuery);

  return `
    Comparable match quality: ${matchQualityPercentage}%
            bathrooms: ${comps.bathrooms}
            driveway: ${comps.driveway}
            garden_or_patio: ${comps.garden_or_patio}
            new_build: ${comps.new_build}
            newly_refurbished: ${comps.newly_refurbished}
            recepts: ${comps.recepts}

    Looking for:
            bathrooms: ${compsQueryParams.bathrooms}
            driveway: ${compsQueryParams.driveway}
            garden_or_patio: ${compsQueryParams.garden_or_patio}
            new_build: ${compsQueryParams.new_build}
            newly_refurbished: ${compsQueryParams.newly_refurbished}
            recepts: ${compsQueryParams.recepts}
      `;
};

const colorToComparableMatchQualityRange = {
  [MARKER_SVG_COLORS.LONG_LET.perfect]: {
    from: 1.0,
    to: 1.0 + Number.EPSILON,
  },
  [MARKER_SVG_COLORS.LONG_LET.very_high]: {
    from: 0.8,
    to: 1.0,
  },
  [MARKER_SVG_COLORS.LONG_LET.high]: {
    from: 0.6,
    to: 0.8,
  },
  [MARKER_SVG_COLORS.LONG_LET.medium]: {
    from: 0.4,
    to: 0.6,
  },
  [MARKER_SVG_COLORS.LONG_LET.low]: {
    from: 0.2,
    to: 0.4,
  },
  [MARKER_SVG_COLORS.LONG_LET.very_low]: {
    from: 0.0,
    to: 0.2,
  },
};

const longletMarkerConfigOptions = (listing, queryParams) => {
  const longletColor = Object.keys(colorToComparableMatchQualityRange).find(
    key => {
      const range = colorToComparableMatchQualityRange[key];

      return inRange(listing.comparable_match_quality, range.from, range.to);
    },
  );

  const compsQuery = compsQueryParamsFilter(queryParams);

  return {
    longletColor,
    label: formatMarkerLabel(listing, compsQuery),
  };
};

export default longletMarkerConfigOptions;
