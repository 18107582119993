import { createReducer } from "@reduxjs/toolkit";

import filterHelpers from "state/helpers/filter_helpers";

const generateFilterActionTypes = actionTypePrefix => {
  return {
    [`${actionTypePrefix}_ACTION_INITIALIZE_FILTER`]: `${actionTypePrefix}_ACTION_INITIALIZE_FILTER`,
    [`${actionTypePrefix}_ACTION_SET_FILTER_VALUE`]: `${actionTypePrefix}_ACTION_SET_FILTER_VALUE`,
    [`${actionTypePrefix}_ACTION_TOGGLE_COLLECTION_FILTER`]: `${actionTypePrefix}_ACTION_TOGGLE_COLLECTION_FILTER`,
    [`${actionTypePrefix}_ACTION_TOGGLE_COLLECTION_FILTERS`]: `${actionTypePrefix}_ACTION_TOGGLE_COLLECTION_FILTERS`,
  };
};

const generateFilterActions = (actionTypes, actionTypePrefix) => {
  const actions = {
    initializeFilters: filtersData => ({
      type: actionTypes[`${actionTypePrefix}_ACTION_INITIALIZE_FILTER`],
      payload: { ...filtersData },
    }),
    setFilterValue: (filterKey, filterValue) => {
      return (dispatch, getState) => {
        const actionObject = {
          type: actionTypes[`${actionTypePrefix}_ACTION_SET_FILTER_VALUE`],
          payload: { filterKey, filterValue },
        };

        dispatch(actionObject);

        return Promise.resolve(getState());
      };
    },
    toggleCollectionFilters: (filterKey, filterValues) => {
      return (dispatch, getState) => {
        const actionObject = {
          type:
            actionTypes[`${actionTypePrefix}_ACTION_TOGGLE_COLLECTION_FILTERS`],
          payload: { filterKey, filterValues },
        };

        dispatch(actionObject);

        return Promise.resolve(getState());
      };
    },
    toggleCollectionFilter: (filterKey, filterValue) => {
      return (dispatch, getState) => {
        const actionObject = {
          type:
            actionTypes[`${actionTypePrefix}_ACTION_TOGGLE_COLLECTION_FILTER`],
          payload: { filterKey, filterValue },
        };

        dispatch(actionObject);

        return Promise.resolve(getState());
      };
    },
  };

  return actions;
};

const generateFilterReducer = (
  actionTypes,
  actionTypePrefix,
  filterOptions,
  filterDefaults,
  allowedFilterKeys,
) => {
  const initialState = {
    options: {
      ...filterOptions,
    },
    defaults: {
      ...filterDefaults,
    },
    current: {
      ...filterDefaults,
    },
  };

  const reducer = createReducer(initialState, {
    [actionTypes[`${actionTypePrefix}_ACTION_INITIALIZE_FILTER`]]: (
      draftState,
      action,
    ) => {
      return {
        ...initialState,
        current: {
          ...initialState.current,
          ...action.payload,
        },
      };
    },
    [actionTypes[`${actionTypePrefix}_ACTION_SET_FILTER_VALUE`]]: (
      draftState,
      action,
    ) => {
      filterHelpers.validateFilterKey(allowedFilterKeys, action.payload.filterKey);

      const newState = filterHelpers.setFilterValue(
        draftState.current,
        action.payload.filterKey,
        action.payload.filterValue,
      );

      return {
        ...draftState,
        current: {
          ...draftState.current,
          ...newState,
        },
      };
    },
    [actionTypes[`${actionTypePrefix}_ACTION_TOGGLE_COLLECTION_FILTERS`]]: (
      draftState,
      action,
    ) => {
      filterHelpers.validateFilterKey(allowedFilterKeys, action.payload.filterKey);

      const newState = filterHelpers.toggleCollectionFilters(
        draftState.current,
        action.payload.filterKey,
        action.payload.filterValues,
      );

      return {
        ...draftState,
        current: {
          ...draftState.current,
          ...newState,
        },
      };
    },
    [actionTypes[`${actionTypePrefix}_ACTION_TOGGLE_COLLECTION_FILTER`]]: (
      draftState,
      action,
    ) => {
      filterHelpers.validateFilterKey(allowedFilterKeys, action.payload.filterKey);

      const newState = filterHelpers.toggleCollectionFilter(
        draftState.current,
        action.payload.filterKey,
        action.payload.filterValue,
      );

      return {
        ...draftState,
        current: {
          ...draftState.current,
          ...newState,
        },
      };
    },
  });

  return reducer;
};

const generateFilterSelectors = filterStateSelector => {
  const selectors = {
    selectOptions: state => filterStateSelector(state).options,
    selectDefaults: state => filterStateSelector(state).defaults,
    selectCurrent: state => filterStateSelector(state).current,
  };

  return selectors;
};

export default {
  generateFilterActionTypes,
  generateFilterActions,
  generateFilterReducer,
  generateFilterSelectors,
};
