import useQuery from "components/hooks/use_query";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { isEqual } from "lodash";
import handleListingFiltering from "pages/map/left_panel/filter_handlers/listings_filter";
import PropTypes from "prop-types";
import React from "react";
import * as Yup from "yup";
import styles from "./index.module.css";

const handleSubmit = ({
  filterActions,
  filterName,
  filterSelectors,
  prevValues: { min, max },
  newValues: { minValue, maxValue },
  updateQueryString,
  mapType,
}) => {
  const changedValues = {
    min: minValue,
    max: maxValue,
  };

  const newFilterValues = { ...changedValues };

  //  fetch only when values have changed
  if (!isEqual({ min, max }, changedValues)) {
    handleListingFiltering({
      filterActions,
      filterName,
      value: newFilterValues,
      shouldReplaceFilterValue: true,
      filterSelector: filterSelectors,
      updateQueryStringCallback: updateQueryString,
      queryParamPrefix: mapType,
    });
  }
};

const numberValidator = () =>
  Yup.number()
    .typeError("invalid number")
    .min(0, "0 is minimum allowed value")
    .required("Required");

const schema = isPercentage =>
  Yup.object({
    min: numberValidator().test({
      name: "less than max",
      message: "cannot be less than max",
      test(value) {
        return value <= this.parent.max;
      },
      params: {},
      exclusive: false,
    }),
    max: numberValidator()
      .test({
        name: "max",
        message: "cannot be greater than min",
        params: {},
        exclusive: false,
        test(value) {
          return value >= this.parent.min;
        },
      })
      .max(
        isPercentage ? 100 : Number.MAX_SAFE_INTEGER,
        "cannot be greater than 100",
      ),
  });

const MinMaxFilter = ({
  title,
  disabled,
  currentValues,
  shouldUpdateQueryString,
  filterName,
  isPercentage,
  filterActions,
  filterSelectors,
  mapType,
}) => {
  const { updateQueryString } = useQuery();
  const initialValues = currentValues[filterName];
  return (
    <div className={styles.container}>
      <h6 className={styles.title}>{title}</h6>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={({ min: minValue, max: maxValue }) =>
          handleSubmit({
            filterActions,
            filterName,
            filterSelectors,
            newValues: {
              minValue,
              maxValue,
            },
            updateQueryString: shouldUpdateQueryString
              ? updateQueryString
              : undefined,
            mapType,
            prevValues: initialValues,
          })
        }
        validationSchema={schema(isPercentage)}
        validateOnBlur
      >
        {({ submitForm }) => (
          <Form
            className={styles.form}
            autoComplete="off"
            onBlur={() => submitForm()}
          >
            <div className={styles.inputs}>
              <Field name="min" placeholder="min" disabled={disabled} />
              <ErrorMessage
                component="span"
                name="min"
                className={styles.error}
              />
            </div>
            <span>—</span>
            <div className={styles.inputs}>
              <Field name="max" placeholder="max" disabled={disabled} />
              <ErrorMessage
                component="span"
                className={styles.error}
                name="max"
              />
            </div>
            <button hidden type="submit" />
          </Form>
        )}
      </Formik>
    </div>
  );
};
MinMaxFilter.defaultProps = {
  shouldUpdateQueryString: false,
  isPercentage: false,
  mapType: "",
};

MinMaxFilter.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  filterActions: PropTypes.shape().isRequired,
  filterSelectors: PropTypes.shape().isRequired,
  /* used for separating longLet/sales filters of the same type;
  (empty) means the filter is common for the whole map */
  mapType: PropTypes.string,
  currentValues: PropTypes.shape().isRequired,
  filterName: PropTypes.string.isRequired,
  isPercentage: PropTypes.bool,
  shouldUpdateQueryString: PropTypes.bool,
};

export default MinMaxFilter;
