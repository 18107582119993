import React from "react";

import { SHORTLET_LISTING_SVG_PATH } from "configs/marker_configs";

import styles from "./listing_income_icon_factory.modules.css";

const ListingIncomeIconFactory = ({ color, beds }) => (
  <svg
    height="20"
    viewBox="-16 -16 32 32"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="listing-income-image"
    className={styles["accordion-button-img"]}
  >
    <g>
      <path d={SHORTLET_LISTING_SVG_PATH} fill={color} opacity={1} />
      <text
        fontSize="1.25rem"
        fill="white"
        textAnchor="middle"
        stroke="black"
        dominantBaseline="middle"
        strokeWidth=".06"
      >
        {beds}
      </text>
    </g>
  </svg>
);

export default ListingIncomeIconFactory;
