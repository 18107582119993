import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import logo_img from "assets/images/skwire-Logo-2.2.svg";
import styles from "./top_panel.module.css";

const Logo = ({ handleMoveTo, setIsOpen }) => {
  const history = useHistory();

  return (
    <button
      type="button"
      className={styles.logo}
      onClick={() => handleMoveTo("/", history, setIsOpen)}
    >
      <img src={logo_img} alt="" />
    </button>
  );
};

Logo.propTypes = {
  handleMoveTo: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default Logo;
