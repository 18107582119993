import React from "react";
import PropTypes from "prop-types";

import { Segment, CollapseList } from "components";
import { priceFormatter } from "utilities";

const toPercentage = value => {
  return (value || 0) * 100;
};

const PricesStatistics = ({
  data: {
    reviewToReservationRatio,
    average12MonthIncome,
    all12MonthIncomes,
    revenue,
  },
  yield_per_price,
}) => (
  <div>
    {reviewToReservationRatio !== undefined && (
      <Segment
        title="Review to reservation ratio"
        text={toPercentage(reviewToReservationRatio).toFixed(0) + "%"}
        info="Proportion of reviews received by the property in relation to the total number of
                    reservations from the time the property was first listed."
      />
    )}
    <Segment
      title="Asking Pcm"
      text={"£" + priceFormatter(average12MonthIncome, true)}
      info="The average income obtained by the property over all consecutive 12 month
                    listing periods."
    />
    <Segment
      title="Ds estimate pcm"
      text={"£" + priceFormatter(revenue, true)}
      info="Estimated gross yield per calendar month"
    />
    {all12MonthIncomes.length > 1 && (
      <CollapseList
        title="Show all months incomes"
        list={all12MonthIncomes}
        prefix="£"
        formatter={priceFormatter}
        addIndex
      />
    )}
    {yield_per_price()}
  </div>
);

PricesStatistics.propTypes = {
  data: PropTypes.shape({
    maxPrice12: PropTypes.number,
    maxPrice12PercYield: PropTypes.number,
    maxPrice15PercYield: PropTypes.number,
    maxPrice18PercYield: PropTypes.number,
    maxPrice15: PropTypes.number,
    maxPrice18: PropTypes.number,
    revenue: PropTypes.number,
    average12MonthIncome: PropTypes.number.isRequired,
    reviewToReservationRatio: PropTypes.number,
    all12MonthIncomes: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.shape({}),
    ]).isRequired,
  }).isRequired,
  yield_per_price: PropTypes.func.isRequired,
};

export default PricesStatistics;
