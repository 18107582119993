import { Switch, useQuery } from "components/";
import React from "react";
import { camelCase } from "lodash";
import PropTypes from "prop-types";

import handleListingFiltering from "../../filter_handlers/listings_filter";

const ListingsVisibilitySwitch = ({
  filtersCurrent,
  mapType,
  filterActions,
  filterSelector,
  mapTypeDisabled,
}) => {
  const mapTypeName = camelCase(mapType);
  const switchValue = () =>
    !filtersCurrent.listingsDisabled.includes(mapTypeName) && !mapTypeDisabled;

  const { updateQueryString: updateQueryStringCallback } = useQuery();

  return (
    <Switch
      value={switchValue()}
      onChange={() =>
        handleListingFiltering({
          filterActions,
          filterName: "listingsDisabled",
          value: mapTypeName,
          shouldReplaceFilterValue: false,
          filterSelector,
          updateQueryStringCallback,
        })
      }
      label="Show listings"
    />
  );
};

ListingsVisibilitySwitch.propTypes = {
  mapType: PropTypes.string.isRequired,
  filtersCurrent: PropTypes.shape({
    listingsDisabled: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  filterActions: PropTypes.shape({
    setFilterValue: PropTypes.func.isRequired,
  }).isRequired,
  filterSelector: PropTypes.shape({}).isRequired,
  mapTypeDisabled: PropTypes.bool.isRequired,
};

export default ListingsVisibilitySwitch;
