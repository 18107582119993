/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";

import { CarouselButtons } from "./components";
import LightBox from "../lightbox";

import styles from "./index.module.css";

const NO_IMAGE_LINK =
  "https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg";

const showNext = (activeImage, images, setActiveImage) => {
  setActiveImage((activeImage + 1) % images.length);
};

const showPrev = (activeImage, setActiveImage, images) => {
  setActiveImage((activeImage + images.length - 1) % images.length);
};

const Carousel = ({ images, imageHeight, imageWidth }) => {
  const [activeImage, setActiveImage] = React.useState(0);
  const imageRef = React.useRef(null);
  const [toggler, setToggler] = React.useState(false);
  const currentImage = images?.[activeImage] ?? NO_IMAGE_LINK;
  const currentImagePosition = `${activeImage + 1}/${images?.length ?? 1}`;

  return (
    <div
      className={styles.image}
      style={{ height: imageHeight, width: imageWidth }}
    >
      <img
        ref={imageRef}
        onError={() => {
          imageRef.current.src = NO_IMAGE_LINK;
        }}
        src={currentImage}
        alt="listing"
        onClick={() => setToggler(!toggler)}
      />
      <LightBox
        onMoveNext={() => showNext(activeImage, images, setActiveImage)}
        onMovePrev={() => showPrev(activeImage, setActiveImage, images)}
        toggler={toggler}
        onClose={() => setToggler(!toggler)}
        currentImage={currentImage}
        images={images}
        activeImageIndex={activeImage}
      />
      <CarouselButtons
        currentImagePosition={currentImagePosition}
        showPrev={() => showPrev(activeImage, setActiveImage, images)}
        showNext={() => showNext(activeImage, images, setActiveImage)}
      />
    </div>
  );
};

Carousel.defaultProps = {
  images: [NO_IMAGE_LINK],
  imageHeight: null,
  imageWidth: null,
};

Carousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string.isRequired),
  imageHeight: PropTypes.number,
  imageWidth: PropTypes.number,
};

export default Carousel;
