import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./components.module.css";

export default function SliderRail({ getRailProps }) {
  return (
    <Fragment>
      <div
        className={styles.railOuterStyle}
        {...getRailProps()}
        data-testid="slider-rail"
      />
      <div className={styles.railInnerStyle} />
    </Fragment>
  );
}

SliderRail.propTypes = {
  getRailProps: PropTypes.func.isRequired,
};
