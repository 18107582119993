/* eslint-disable no-shadow */
import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { camelCase } from "lodash";

import { actions as propertyHullFiltersActions } from "state/global/actions/user_input/map/filters/shortlet/property_hull_filters_actions";
import { actions as shortletPropertyHullsActions } from "state/global/actions/data_collections/map/shortlet/property_hulls_actions";
import { actions as propertyHullsDisabledFiltersActions } from "state/global/actions/user_input/map/filters/property_hulls_disabled_filters_actions";
import propertyHullFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/property_hull_filters_selectors";
import shortletListingFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/listing_filters_selectors";
import propertyHullsDisabledSelectors from "state/global/selectors/user_input/map/filters/property_hulls_disabled_selectors";

import mapConfigs from "configs/map_configs";
import * as apiMapRequests from "api/map";

import { MinMaxFilter } from "components/";
import useComponentDidUpdate from "components/hooks/use_component_did_update";
import fetchPropertyHulls from "api/fetchers/property_hulls";
import constructHullsQueryParams from "api/fetchers/property_hulls/construct_hull_query_params";
import { MapContext } from "state/local/stores/contexts/map_context_store";
import HullsVisibilitySwitch from "../components/property_hull_filters/hulls_visibility_switch";
import HullsIcomeBandExpansion from "../components/property_hull_filters/hulls_income_band_expansion";
import HullsTypesExpansion from "../components/property_hull_filters/hulls_types_expansion";

import styles from "../hulls_panel.module.css";

const {
  mapTypeNames: { shortlet: shortletMapType },
  sharedConfig: {
    pageSizes: { hulls: hullPageSize },
  },
} = mapConfigs;

const PropertyHullFilters = props => {
  const {
    disabled,
    propertyHullFiltersActions,
    propertyHullFiltersCurrent,
    shortletListingFiltersCurrent,
    propertyHullsDisabledFiltersActions,
    propertyHullsDisabledFiltersCurrent,
    shortletPropertyHullsActions,
  } = props;

  const { mapInstance, setFetchingMapData } = React.useContext(MapContext);

  useComponentDidUpdate(() => {
    const hullEnabled = !propertyHullsDisabledFiltersCurrent.propertyHullsDisabled.includes(
      camelCase(shortletMapType),
    );

    if (hullEnabled) {
      setFetchingMapData(true);

      const fetchShortletHulls = () =>
        fetchPropertyHulls({
          hullActions: shortletPropertyHullsActions,
          request: apiMapRequests.getAllHulls,
          queryParams: constructHullsQueryParams(mapInstance, {
            page_size: hullPageSize,
          }),
        });

      fetchShortletHulls().then(() => setFetchingMapData(false));
    }
  }, [propertyHullsDisabledFiltersCurrent]);

  return (
    <div className={styles.heading}>
      <div className={styles.title}>Property hull filters</div>
      <div className={styles.subtitle}>
        Use filters to change the shown property hulls
      </div>
      <div className={styles.filters}>
        <div className={styles.grid}>
          <HullsVisibilitySwitch
            disabled={disabled}
            propertyHullsDisabledFiltersActions={
              propertyHullsDisabledFiltersActions
            }
            propertyHullsDisabledFiltersCurrent={
              propertyHullsDisabledFiltersCurrent
            }
            mapType={shortletMapType}
          />
        </div>
        <div className={styles.spacer} />
        <MinMaxFilter
          disabled={disabled}
          title="Bedrooms"
          filterName="hullBeds"
          filterActions={propertyHullFiltersActions}
          filterSelectors={propertyHullFiltersSelectors}
          currentValues={propertyHullFiltersCurrent}
          shouldUpdateQueryString
          mapType={camelCase(shortletMapType)}
        />
        <Expansions {...props} />
      </div>
    </div>
  );
};

PropertyHullFilters.propTypes = {
  shortletListingFiltersCurrent: PropTypes.shape({
    listingBeds: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }).isRequired,
    listingTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  propertyHullFiltersOptions: PropTypes.shape({
    hullBands: PropTypes.arrayOf(PropTypes.string).isRequired,
    hullTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    hullBeds: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }).isRequired,
  }).isRequired,
  propertyHullFiltersCurrent: PropTypes.shape({
    hullBeds: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }).isRequired,
    hullTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    hullBands: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  propertyHullFiltersActions: PropTypes.shape({
    setFilterValue: PropTypes.func.isRequired,
    toggleCollectionFilter: PropTypes.func.isRequired,
  }).isRequired,
  propertyHullsDisabledFiltersActions: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
  propertyHullsDisabledFiltersCurrent: PropTypes.shape({
    propertyHullsDisabled: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  shortletPropertyHullsActions: PropTypes.shape({
    updateRecords: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state, props) => ({
  shortletListingFiltersCurrent: shortletListingFiltersSelectors.selectCurrent(
    state,
  ),
  propertyHullFiltersOptions: propertyHullFiltersSelectors.selectOptions(state),
  propertyHullFiltersCurrent: propertyHullFiltersSelectors.selectCurrent(state),
  propertyHullsDisabledFiltersCurrent: propertyHullsDisabledSelectors.selectCurrent(
    state,
  ),
});

const mapDispatchToProps = dispatch => ({
  propertyHullFiltersActions: bindActionCreators(
    propertyHullFiltersActions,
    dispatch,
  ),
  propertyHullsDisabledFiltersActions: bindActionCreators(
    propertyHullsDisabledFiltersActions,
    dispatch,
  ),
  shortletPropertyHullsActions: bindActionCreators(
    shortletPropertyHullsActions,
    dispatch,
  ),
});

const Expansions = ({
  disabled,
  propertyHullFiltersActions,
  propertyHullFiltersCurrent,
  propertyHullFiltersOptions,
}) => (
  <>
    <HullsIcomeBandExpansion
      propertyHullFiltersActions={propertyHullFiltersActions}
      propertyHullFiltersCurrent={propertyHullFiltersCurrent}
      disabled={disabled}
      mapType={shortletMapType}
      propertyHullFiltersOptions={propertyHullFiltersOptions}
      propertyHullFiltersSelectors={propertyHullFiltersSelectors}
    />
    <HullsTypesExpansion
      propertyHullFiltersActions={propertyHullFiltersActions}
      propertyHullFiltersCurrent={propertyHullFiltersCurrent}
      propertyHullFiltersOptions={propertyHullFiltersOptions}
      disabled={disabled}
      mapType={shortletMapType}
      propertyHullFiltersSelectors={propertyHullFiltersSelectors}
    />
  </>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyHullFilters);
