import dataRequestFactories from "state/global/factories/data_request_factories";
import siblingSelectors from ".";

const declineHubspotDealRequestSelectors = {
  ...dataRequestFactories.generateStatusSelectors(
    state =>
      siblingSelectors.selectDeclineHubspotDealRequest(state)
        .declineHubspotDeal,
  ),
};

export { declineHubspotDealRequestSelectors };
