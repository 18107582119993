import mapFilterFactories from "state/global/factories/map_filter_factories";

const actionTypePrefix = "COMPS_QUALITY";
const actionTypes = mapFilterFactories.generateFilterActionTypes(
  actionTypePrefix,
);
const actions = mapFilterFactories.generateFilterActions(
  actionTypes,
  actionTypePrefix,
);

export { actionTypePrefix, actionTypes, actions };
