/* eslint-disable no-shadow */
import React, { useEffect, useReducer, useMemo, useState } from "react";
import { isNil } from "lodash";
import { connect, shallowEqual, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { spaceSeparatedCamelCase, validateUrlParams } from "utilities";
import { useQuery } from "components";
import camelCase from "lodash/camelCase";

import { actions as longletListingFiltersActions } from "state/global/actions/user_input/map/filters/longlet/listing_filters_actions";
import { actions as shortletListingFiltersActions } from "state/global/actions/user_input/map/filters/shortlet/listing_filters_actions";
import { actions as salesDealsFiltersActions } from "state/global/actions/user_input/map/filters/sales/deals_filters_actions";
import { actions as shortLetPropertyHullFiltersActions } from "state/global/actions/user_input/map/filters/shortlet/property_hull_filters_actions";
import { actions as longletPropertyHullFiltersActions } from "state/global/actions/user_input/map/filters/longlet/property_hull_filters_actions";
import { actions as amenityHullFiltersActions } from "state/global/actions/user_input/map/filters/amenity_hull_filters_actions";
import { actions as mapTypeFiltersActions } from "state/global/actions/user_input/map/filters/map_type_filters_actions";
import { actions as activeTabActions } from "state/global/actions/ui_control/map/active_tab_actions";
import { actions as compsFiltersActions } from "state/global/actions/user_input/map/filters/comps_filters_actions";
import { actions as propertyHullsDisabledFiltersActions } from "state/global/actions/user_input/map/filters/property_hulls_disabled_filters_actions";
import { actions as listingsDisabledFiltersActions } from "state/global/actions/user_input/map/filters/listings_disabled_filters_actions";
import { actions as amenityHullsDisabledFiltersActions } from "state/global/actions/user_input/map/filters/amenity_hulls_disabled_filters_actions";

import shortletListingFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/listing_filters_selectors";
import longletListingFiltersSelectors from "state/global/selectors/user_input/map/filters/longlet/listing_filters_selectors";
import compsFiltersSelectors from "state/global/selectors/user_input/map/filters/comps_selectors";
import shortLetPropertyHullFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/property_hull_filters_selectors";
import longletPropertyHullFiltersSelectors from "state/global/selectors/user_input/map/filters/longlet/property_hull_filters_selectors";
import amenityHullFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/amenity_hull_filters_selectors";
import mapTypeFiltersSelectors from "state/global/selectors/user_input/map/filters/map_type_filters_selectors";
import activeMapTabSelectors from "state/global/selectors/ui_control/map/active_tab_selectors";
import propertyHullsDisabledFiltersSelectors from "state/global/selectors/user_input/map/filters/property_hulls_disabled_selectors";
import listingsDisabledFiltersSelectors from "state/global/selectors/user_input/map/filters/listings_disabled_selectors";
import amenityHullsDisabledFiltersSelectors from "state/global/selectors/user_input/map/filters/amenity_hulls_disabled_selectors";
import filterInitializerSelectors from "state/global/selectors/user_input/map/filters/filter_initializer_selectors";
import salesDealsFiltersSelectors from "state/global/selectors/user_input/map/filters/sales_deals_filter_selectors";
import mapConfigs from "configs/map_configs";
import { availableLongletRentalComps } from "configs/available_longlet_rental_comps";
import { translateCompsQueryParamsNames } from "state/helpers/comparable_helpers";

const {
  mapTypeNames: { longlet: longletMap, shortlet: shortletMap, sales: salesMap },
} = mapConfigs;

const MapFiltersInitializer = ({
  // ### props,
  compsQueryParams,

  // ### mapStateToProps

  shortletListingFiltersDefaults,
  shortletListingFiltersOptions,
  longletListingFiltersDefaults,
  activeTabDefault,
  activeTabOptions,
  longletListingFiltersOptions,
  salesDealsFilterDefaults,
  salesDealsFilterOptions,
  shortLetPropertyHullFiltersDefaults,
  longletPropertyHullFiltersDefaults,
  amenityHullFiltersDefaults,
  mapTypeFiltersDefaults,
  filterInitializerState,
  propertyHullsDisabledDefaults,
  listingsDisabledDefaults,
  amenityHullsDisabledDefaults,
  mapTypeFiltersOptions,
  compsFiltersDefaults,

  // ### mapDispatchToProps

  longletListingFiltersActions,
  shortletListingFiltersActions,
  salesDealsFiltersActions,
  shortLetPropertyHullFiltersActions,
  longletPropertyHullFiltersActions,
  amenityHullFiltersActions,
  mapTypeFiltersActions,
  activeTabActions,
  compsFiltersActions,

  propertyHullsDisabledFiltersActions,
  listingsDisabledFiltersActions,
  amenityHullsDisabledFiltersActions,
}) => {
  const { query } = useQuery();

  const normalizeQueryValue = value => {
    if (value === "false") return false;
    if (value === "true") return true;
    if ((Array.isArray(value), !value.split)) return value;
    if (Number.isInteger(parseInt(value, 10))) return value;
    return value.split(",");
  };

  const selectFilterValue = (value, defaultValue) => {
    const rawValue = value || defaultValue;
    const newValue = isNil(rawValue) ? null : normalizeQueryValue(rawValue);

    return newValue;
  };

  const getInitialShortletListingFilters = queryObject => {
    validateUrlParams(
      queryObject,
      "shortLetListingTypes",
      shortletListingFiltersOptions.listingTypes,
    );

    return {
      listingBeds: {
        min: selectFilterValue(
          queryObject.shortLetListingBedsMin,
          shortletListingFiltersDefaults.listingBeds.min,
        ),
        max: selectFilterValue(
          queryObject.shortLetListingBedsMax,
          shortletListingFiltersDefaults.listingBeds.max,
        ),
      },
      listingPrice: selectFilterValue(
        queryObject.listingPrice,
        shortletListingFiltersDefaults.listingPrice,
      ),
      listingTypes: selectFilterValue(
        queryObject.shortLetListingTypes,
        shortletListingFiltersDefaults.listingTypes,
      ),
      listingAmenities: selectFilterValue(queryObject.listingAmenities, []),
      reservationRatio: selectFilterValue(
        queryObject.reservationRatio,
        shortletListingFiltersDefaults.reservationRatio,
      ),
      avgReservations: selectFilterValue(
        queryObject.avgReservations,
        shortletListingFiltersDefaults.avgReservations,
      ),
      avgReservationsDays: selectFilterValue(
        queryObject.avgReservationsDays,
        shortletListingFiltersDefaults.avgReservationsDays,
      ),
      avgBlocked: selectFilterValue(
        queryObject.avgBlocked,
        shortletListingFiltersDefaults.avgBlocked,
      ),
      avgAvailableDays: selectFilterValue(
        queryObject.avgAvailableDays,
        shortletListingFiltersDefaults.avgAvailableDays,
      ),
    };
  };

  const getInitialLongletListingFilters = queryObject => {
    validateUrlParams(
      queryObject,
      "longLetListingTypes",
      longletListingFiltersOptions.listingTypes,
    );

    return {
      comps: translateCompsQueryParamsNames(compsQueryParams),
      listingBeds: {
        min: selectFilterValue(
          queryObject.longLetListingBedsMin,
          longletListingFiltersDefaults.listingBeds.min,
        ),
        max: selectFilterValue(
          queryObject.longLetListingBedsMax,
          longletListingFiltersDefaults.listingBeds.max,
        ),
      },
      listingTypes: selectFilterValue(
        queryObject.longLetListingTypes,
        longletListingFiltersDefaults.listingTypes,
      ),
      freshness: {
        leftBand: selectFilterValue(
          parseInt(queryObject.longLetFreshnessMin, 10),
          longletListingFiltersDefaults.freshness.leftBand,
        ),
        rightBand: selectFilterValue(
          parseInt(queryObject.longLetFreshnessMax, 10),
          longletListingFiltersDefaults.freshness.rightBand,
        ),
      },
    };
  };

  const getInitialCompsFilters = queryObject => ({
    compsQuality: {
      min: selectFilterValue(
        queryObject.compsQualityMin,
        compsFiltersDefaults.compsQuality.min,
      ),
      max: selectFilterValue(
        queryObject.compsQualityMax,
        compsFiltersDefaults.compsQuality.max,
      ),
    },
  });

  const getInitialSalesDealsFilters = queryObject => {
    validateUrlParams(
      queryObject,
      "salesListingTypes",
      salesDealsFilterOptions.listingTypes,
    );

    return {
      listingBeds: {
        min: selectFilterValue(
          queryObject.salesListingBedsMin,
          salesDealsFilterDefaults.listingBeds.min,
        ),
        max: selectFilterValue(
          queryObject.salesListingBedsMax,
          salesDealsFilterDefaults.listingBeds.max,
        ),
      },
      listingTypes: selectFilterValue(
        queryObject.salesListingTypes,
        salesDealsFilterDefaults.listingTypes,
      ),
      freshness: {
        leftBand: selectFilterValue(
          parseInt(queryObject.salesFreshnessMin, 10),
          salesDealsFilterDefaults.freshness.leftBand,
        ),
        rightBand: selectFilterValue(
          parseInt(queryObject.salesFreshnessMax, 10),
          salesDealsFilterDefaults.freshness.rightBand,
        ),
      },
      netYieldRange: {
        min: selectFilterValue(
          queryObject.salesNetYieldRangeMin,
          salesDealsFilterDefaults.netYieldRange.min,
        ),
        max: selectFilterValue(
          queryObject.salesNetYieldRangeMax,
          salesDealsFilterDefaults.netYieldRange.max,
        ),
      },
    };
  };

  const getInitialPropertyHullFilters = (
    mapType,
    propertyHullFiltersDefaults,
    queryObject,
  ) => ({
    hullBands: selectFilterValue(
      queryObject[`${mapType}HullBands`],
      propertyHullFiltersDefaults.hullBands,
    ),
    hullTypes: selectFilterValue(
      queryObject[`${mapType}HullTypes`],
      propertyHullFiltersDefaults.hullTypes,
    ),
    hullBeds: {
      min: selectFilterValue(
        queryObject[`${mapType}HullBedsMin`],
        propertyHullFiltersDefaults.hullBeds.min,
      ),
      max: selectFilterValue(
        queryObject[`${mapType}HullBedsMax`],
        propertyHullFiltersDefaults.hullBeds.max,
      ),
    },
  });

  const getInitialPropertyHullsDisabledFilters = queryObject => ({
    propertyHullsDisabled: selectFilterValue(
      queryObject.propertyHullsDisabled,
      propertyHullsDisabledDefaults.propertyHullsDisabled,
    ),
  });

  const getInitialAmenityHullsDisabledFilters = queryObject => ({
    amenityHullsDisabled: selectFilterValue(
      queryObject.amenityHullsDisabled,
      amenityHullsDisabledDefaults.amenityHullsDisabled,
    ),
  });

  const getInitialListingsDisabledFilters = queryObject => ({
    listingsDisabled: selectFilterValue(
      queryObject.listingsDisabled,
      listingsDisabledDefaults.listingsDisabled,
    ),
  });

  const getInitialAmenityHullFilters = queryObject => ({
    amenityTypes: selectFilterValue(queryObject.amenityTypes, []),
    amenityBands: selectFilterValue(
      queryObject.amenityBands,
      amenityHullFiltersDefaults.amenityBands,
    ),
  });

  const getInitialMapTypeFilters = queryObject => {
    validateUrlParams(
      queryObject,
      "mapTypes",
      mapTypeFiltersOptions.mapTypes.map(type => camelCase(type)),
    );

    return {
      mapTypes: selectFilterValue(
        queryObject?.mapTypes
          ?.split(",")
          .map(type => spaceSeparatedCamelCase(type)),
        mapTypeFiltersDefaults.mapTypes,
      ),
    };
  };

  const getInitialActiveTab = queryObject => {
    validateUrlParams(queryObject, "activeTab", activeTabOptions);

    const value = queryObject?.activeTab || activeTabDefault;

    return {
      activeTab: value,
    };
  };

  useEffect(() => {
    const queryObject = query;

    longletListingFiltersActions.initializeFilters(
      getInitialLongletListingFilters(queryObject),
    );

    shortletListingFiltersActions.initializeFilters(
      getInitialShortletListingFilters(queryObject),
    );

    salesDealsFiltersActions.initializeFilters(
      getInitialSalesDealsFilters(queryObject),
    );

    propertyHullsDisabledFiltersActions.initializeFilters(
      getInitialPropertyHullsDisabledFilters(queryObject),
    );

    amenityHullsDisabledFiltersActions.initializeFilters(
      getInitialAmenityHullsDisabledFilters(queryObject),
    );

    listingsDisabledFiltersActions.initializeFilters(
      getInitialListingsDisabledFilters(queryObject),
    );

    shortLetPropertyHullFiltersActions.initializeFilters(
      getInitialPropertyHullFilters(
        camelCase(shortletMap),
        shortLetPropertyHullFiltersDefaults,
        queryObject,
      ),
    );

    longletPropertyHullFiltersActions.initializeFilters(
      getInitialPropertyHullFilters(
        camelCase(longletMap),
        longletPropertyHullFiltersDefaults,
        queryObject,
      ),
    );

    amenityHullFiltersActions.initializeFilters(
      getInitialAmenityHullFilters(queryObject),
    );

    mapTypeFiltersActions.initializeFilters(
      getInitialMapTypeFilters(queryObject),
    );

    activeTabActions.initializeValue(getInitialActiveTab(queryObject));
    compsFiltersActions.initializeFilters(getInitialCompsFilters(queryObject));
  }, [filterInitializerState.filterResetTimestamp]);

  // eslint-disable-next-line react/jsx-filename-extension
  return <React.Fragment></React.Fragment>;
};

MapFiltersInitializer.defaultProps = {
  // fooBar: null,
  compsQueryParams: {},
};

const warnAboutMalformedCompsParams = (props, propName) => {
  const availableCompsParamsNames = Object.keys(availableLongletRentalComps);
  const unacceptableParamNames = Object.keys(props[propName]).filter(
    name => !availableCompsParamsNames.includes(name),
  );

  if (unacceptableParamNames.length > 0) {
    return new Error(`unacceptable param: ${unacceptableParamNames.join(", ")}.
      It should be one of: ${availableCompsParamsNames.join(", ")}.`);
  }
  return null;
};

MapFiltersInitializer.propTypes = {
  shortletListingFiltersDefaults: PropTypes.shape({
    listingBeds: PropTypes.shape({
      min: PropTypes.oneOfType([PropTypes.number.isRequired]),
      max: PropTypes.oneOfType([PropTypes.number.isRequired]),
    }).isRequired,
    listingPrice: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    listingTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    reservationRatio: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    avgReservations: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    avgReservationsDays: PropTypes.arrayOf(PropTypes.number.isRequired)
      .isRequired,
    avgBlocked: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    avgAvailableDays: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  }).isRequired,
  shortletListingFiltersOptions: PropTypes.shape({
    listingTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  longletListingFiltersOptions: PropTypes.shape({
    listingTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  longletListingFiltersDefaults: PropTypes.shape({
    listingBeds: PropTypes.shape({
      min: PropTypes.oneOfType([PropTypes.number.isRequired]),
      max: PropTypes.oneOfType([PropTypes.number.isRequired]),
    }).isRequired,
    freshness: PropTypes.shape({
      rightBand: PropTypes.oneOfType([PropTypes.number.isRequired]),
      leftBand: PropTypes.oneOfType([PropTypes.number.isRequired]),
    }),
    listingTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  salesDealsFilterDefaults: PropTypes.shape({
    listingBeds: PropTypes.shape({
      min: PropTypes.oneOfType([PropTypes.number.isRequired]),
      max: PropTypes.oneOfType([PropTypes.number.isRequired]),
    }).isRequired,
    freshness: PropTypes.shape({
      rightBand: PropTypes.oneOfType([PropTypes.number.isRequired]),
      leftBand: PropTypes.oneOfType([PropTypes.number.isRequired]),
    }),
    listingTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    netYieldRange: PropTypes.shape({
      min: PropTypes.oneOfType([PropTypes.number.isRequired]),
      max: PropTypes.oneOfType([PropTypes.number.isRequired]),
    }).isRequired,
  }).isRequired,
  salesDealsFilterOptions: PropTypes.shape({
    listingTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  shortLetPropertyHullFiltersDefaults: PropTypes.shape({
    hullBands: PropTypes.arrayOf(PropTypes.string).isRequired,
    hullTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    hullBeds: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }),
  }).isRequired,
  longletPropertyHullFiltersDefaults: PropTypes.shape({
    hullBands: PropTypes.arrayOf(PropTypes.string).isRequired,
    hullTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    hullBeds: PropTypes.shape({
      min: PropTypes.oneOfType([PropTypes.number.isRequired]),
      max: PropTypes.oneOfType([PropTypes.number.isRequired]),
    }),
  }).isRequired,
  amenityHullFiltersDefaults: PropTypes.shape({
    amenityBands: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  mapTypeFiltersDefaults: PropTypes.shape().isRequired,
  mapTypeFiltersOptions: PropTypes.shape({
    mapTypes: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  filterInitializerState: PropTypes.shape({
    filterResetTimestamp: PropTypes.number,
  }).isRequired,
  longletListingFiltersActions: PropTypes.shape({
    initializeFilters: PropTypes.func.isRequired,
  }).isRequired,
  shortletListingFiltersActions: PropTypes.shape({
    initializeFilters: PropTypes.func.isRequired,
  }).isRequired,
  salesDealsFiltersActions: PropTypes.shape({
    initializeFilters: PropTypes.func.isRequired,
  }).isRequired,
  shortLetPropertyHullFiltersActions: PropTypes.shape({
    initializeFilters: PropTypes.func.isRequired,
  }).isRequired,
  longletPropertyHullFiltersActions: PropTypes.shape({
    initializeFilters: PropTypes.func.isRequired,
  }).isRequired,
  amenityHullFiltersActions: PropTypes.shape({
    initializeFilters: PropTypes.func.isRequired,
  }).isRequired,
  mapTypeFiltersActions: PropTypes.shape({
    initializeFilters: PropTypes.func.isRequired,
  }).isRequired,
  propertyHullsDisabledFiltersActions: PropTypes.shape(
    PropTypes.func.isRequired,
  ).isRequired,
  compsFiltersActions: PropTypes.shape(PropTypes.func.isRequired).isRequired,
  compsFiltersDefaults: PropTypes.shape({
    compsQuality: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }),
  }).isRequired,
  propertyHullsDisabledDefaults: PropTypes.shape({
    propertyHullsDisabled: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  listingsDisabledFiltersActions: PropTypes.shape(PropTypes.func.isRequired)
    .isRequired,
  listingsDisabledDefaults: PropTypes.shape({
    listingsDisabled: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  amenityHullsDisabledDefaults: PropTypes.shape({
    amenityHullsDisabled: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  amenityHullsDisabledFiltersActions: PropTypes.shape(PropTypes.func.isRequired)
    .isRequired,
  activeTabOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeTabDefault: PropTypes.string.isRequired,
  compsQueryParams: warnAboutMalformedCompsParams,
};

const mapStateToProps = (state, props) => ({
  shortletListingFiltersDefaults: shortletListingFiltersSelectors.selectDefaults(
    state,
  ),
  shortletListingFiltersOptions: shortletListingFiltersSelectors.selectOptions(
    state,
  ),
  longletListingFiltersDefaults: longletListingFiltersSelectors.selectDefaults(
    state,
  ),
  longletListingFiltersOptions: longletListingFiltersSelectors.selectOptions(
    state,
  ),
  shortLetPropertyHullFiltersDefaults: shortLetPropertyHullFiltersSelectors.selectDefaults(
    state,
  ),
  longletPropertyHullFiltersDefaults: longletPropertyHullFiltersSelectors.selectDefaults(
    state,
  ),
  amenityHullFiltersDefaults: amenityHullFiltersSelectors.selectDefaults(state),
  salesDealsFilterDefaults: salesDealsFiltersSelectors.selectDefaults(state),
  salesDealsFilterOptions: salesDealsFiltersSelectors.selectOptions(state),
  mapTypeFiltersDefaults: mapTypeFiltersSelectors.selectDefaults(state),
  mapTypeFiltersOptions: mapTypeFiltersSelectors.selectOptions(state),
  filterInitializerState: filterInitializerSelectors.selectAll(state),
  propertyHullsDisabledDefaults: propertyHullsDisabledFiltersSelectors.selectDefaults(
    state,
  ),
  listingsDisabledDefaults: listingsDisabledFiltersSelectors.selectDefaults(
    state,
  ),
  amenityHullsDisabledDefaults: amenityHullsDisabledFiltersSelectors.selectDefaults(
    state,
  ),
  activeTabDefault: activeMapTabSelectors.selectDefaults(state)
    .activeTabDefault,
  activeTabOptions: activeMapTabSelectors.selectOptions(state).activeTabOptions,
  compsFiltersDefaults: compsFiltersSelectors.selectDefaults(state),
});

const mapDispatchToProps = dispatch => ({
  longletListingFiltersActions: bindActionCreators(
    longletListingFiltersActions,
    dispatch,
  ),
  shortletListingFiltersActions: bindActionCreators(
    shortletListingFiltersActions,
    dispatch,
  ),
  salesDealsFiltersActions: bindActionCreators(
    salesDealsFiltersActions,
    dispatch,
  ),
  shortLetPropertyHullFiltersActions: bindActionCreators(
    shortLetPropertyHullFiltersActions,
    dispatch,
  ),
  longletPropertyHullFiltersActions: bindActionCreators(
    longletPropertyHullFiltersActions,
    dispatch,
  ),
  amenityHullFiltersActions: bindActionCreators(
    amenityHullFiltersActions,
    dispatch,
  ),
  mapTypeFiltersActions: bindActionCreators(mapTypeFiltersActions, dispatch),
  propertyHullsDisabledFiltersActions: bindActionCreators(
    propertyHullsDisabledFiltersActions,
    dispatch,
  ),
  listingsDisabledFiltersActions: bindActionCreators(
    listingsDisabledFiltersActions,
    dispatch,
  ),
  amenityHullsDisabledFiltersActions: bindActionCreators(
    amenityHullsDisabledFiltersActions,
    dispatch,
  ),
  activeTabActions: bindActionCreators(activeTabActions, dispatch),
  compsFiltersActions: bindActionCreators(compsFiltersActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MapFiltersInitializer);
