import React from "react";
import PropTypes from "prop-types";

import { Expansion, Checkbox } from "components/";
import useQuery from "components/hooks/use_query";
import handleAmenityHullFiltering from "pages/map/left_panel/filter_handlers/amenity_hulls_filter";
import amenityHullFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/amenity_hull_filters_selectors";

import styles from "../../hulls_panel.module.css";

const HullsIncomeBandExpansion = ({
  disabled,
  amenityHullFiltersOptions,
  amenityHullFiltersCurrent,
  amenityHullFiltersActions,
}) => {
  const { updateQueryString } = useQuery();

  return (
    <Expansion
      controller={toggle => (
        <button
          type="button"
          disabled={disabled}
          className={styles.btn}
          onClick={toggle}
        >
          Income bands
          <span />
        </button>
      )}
    >
      <div className={styles.list}>
        {amenityHullFiltersOptions.amenityBands.map(band => (
          <Checkbox
            key={band}
            className={styles.element}
            onClick={() =>
              handleAmenityHullFiltering(
                updateQueryString,
                amenityHullFiltersActions,
                amenityHullFiltersSelectors,
                "amenityBands",
                band,
              )
            }
            checked={amenityHullFiltersCurrent.amenityBands.includes(band)}
            label={`${band}`}
          />
        ))}
      </div>
    </Expansion>
  );
};

HullsIncomeBandExpansion.propTypes = {
  amenityHullFiltersActions: PropTypes.shape().isRequired,
  amenityHullFiltersCurrent: PropTypes.shape({
    amenityBands: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  amenityHullFiltersOptions: PropTypes.shape({
    amenityBands: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default HullsIncomeBandExpansion;
