import React from "react";
import PropTypes from "prop-types";
import { FetchingError, NoContent, Loader } from "components";
import fetchQuery from "relay/fetchQuery";
import graphql from "babel-plugin-relay/macro";
import Form from "./form";

const query = graphql`
  query editDataProviderQuery($id: ID!) {
    user(id: $id) {
      id
      username
      firstName
      lastName
      email
    }
  }
`;

const EditDataProvider = props => {
  const [fetching, setFetching] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [errors, setErrors] = React.useState(false);

  const { match } = props;
  const { userId } = match.params;

  React.useEffect(() => {
    async function fetchData() {
      setFetching(true);
      const [payload, error] = await getUser(userId);
      if (error) {
        setErrors(error || "Error occurred. Content cannot be loaded");
      } else {
        setUser(payload.data.user);
      }
      setFetching(false);
    }
    fetchData();
  }, [userId]);

  function getUser(id) {
    const variables = {
      id,
    };
    return fetchQuery(query, variables, { force: true })
      .then(data => [data, null])
      .catch(error => [null, error]);
  }

  let component = <NoContent />;

  if (errors) component = <FetchingError />;
  if (user) component = <Form {...props} initialData={user} />;
  if (fetching) component = <Loader type="primary" size="big" absolute />;
  return component;
};

EditDataProvider.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ userId: PropTypes.string }),
  }).isRequired,
};

export default EditDataProvider;
