import { Variables } from "react-relay";
import { RequestNode } from "relay-runtime";
import RelayQueryResponseCache from "relay-runtime/lib/RelayQueryResponseCache";
import { fundApiPaths } from "endpoints";
import { tokenStorageField, tokenPrefix } from "configuration";

const cache = new RelayQueryResponseCache({ size: 250, ttl: 60 * 5 * 1000 });

// Define a function that fetches the results of a request (query/mutation/etc)
// and returns its results as a Promise:
const fetchQuery = async (
  request: RequestNode,
  variables: Variables,
  cacheConfig,
) => {
  let operation = request;

  if (typeof request === "function") {
    operation = request().params;
  }

  const body = JSON.stringify({
    name: operation.name, // used by graphql mock on tests
    query: operation.text, // GraphQL text from input
    variables,
  });
  const token = localStorage.getItem(tokenStorageField);
  const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
  };
  if (token) {
    headers.Authorization = tokenPrefix + token;
  }

  const queryID = operation.name;
  const cachedData = cache.get(queryID, variables);

  if (cachedData !== null && !cacheConfig.force) return cachedData;

  const response = await fetch(fundApiPaths.graphqlEndpoint + "/", {
    method: "POST",
    headers,
    body,
  });
  const res = await response.json();
  if (operation.operationKind !== "mutation") {
    cache.set(queryID, variables, res);
  }

  return res;
};

export default fetchQuery;
