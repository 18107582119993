import dataRequestFactories from "state/global/factories/data_request_factories";

const actionTypePrefix = "FETCH_USERS";
const actionTypes = dataRequestFactories.generateStatusActionTypes(
  actionTypePrefix,
);
const actions = dataRequestFactories.generateStatusActions(
  actionTypes,
  actionTypePrefix,
);

export { actionTypes, actions };
