/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import salesDealStagesSelectors from "state/global/selectors/data_collections/map/sales/sales_deal_stages_selectors";

import InfoWindowWrapper from "./components/info_windows_wrapper";
import queries from "./queries/index";

import { standardizeListingPayload } from "../helpers/standardize_listing_payload";

const ChipHover = props => {
  const {
    onClose,
    position,
    listingType,
    listing,
    hubspotPipelinesToDealStagesMapping,
  } = props;

  const dealStageNamesToDealStageInternalIds =
    hubspotPipelinesToDealStagesMapping[listing.pipeline_id]?.stages;

  const standardizedPayload = standardizeListingPayload({
    payload: listing,
    listingType,
    revenue: listing?.revenue,
    dealStageNamesToDealStageInternalIds,
  });

  return (
    <InfoWindowWrapper
      details={standardizedPayload}
      position={position}
      onClose={onClose}
      listingType={listingType}
      dealStageNamesToDealStageInternalIds={
        dealStageNamesToDealStageInternalIds
      }
    />
  );
};

ChipHover.propTypes = {
  onClose: PropTypes.func.isRequired,
  position: PropTypes.shape({
    lat: PropTypes.func.isRequired,
    lng: PropTypes.func.isRequired,
  }).isRequired,
  listingType: PropTypes.string.isRequired,
  listing: PropTypes.shape({
    id: PropTypes.number.isRequired,
    pipeline_name: PropTypes.string,
    pipeline_id: PropTypes.string,
    hubspot_deal_stage_internal_id: PropTypes.string,
  }).isRequired,
  hubspotPipelinesToDealStagesMapping: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      pipelineId: PropTypes.string.isRequired,
      stages: PropTypes.objectOf(PropTypes.string),
    }),
  ).isRequired,
};

const mapStateToProps = state => ({
  hubspotPipelinesToDealStagesMapping: salesDealStagesSelectors.selectAllByKey(
    state,
  ),
});

export default connect(
  mapStateToProps,
  {},
)(ChipHover);
