import { createReducer } from "@reduxjs/toolkit";

import dataCollectionFactories from "state/global/factories/data_collections_factories";
import {
  actionTypes,
  actionTypePrefix,
} from "state/global/actions/data_collections/map/longlet/amenity_hulls_actions";

const reducer = dataCollectionFactories.generateCollectionReducer(
  actionTypes,
  actionTypePrefix,
);

export default reducer;
