import { tokenStorageField } from "configuration";
import jwt_decode from "jwt-decode";
import { getRefreshToken } from "../authentication";

const isAccessTokenUnexpired = () => {
  const accessToken = localStorage[tokenStorageField];
  const payload = jwt_decode(accessToken);
  const nowTimestamp = Math.floor(Date.now() / 1000);

  return payload.exp > nowTimestamp;
};

const fetchNewAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");

  return new Promise(async resolve => {
    const response = await getRefreshToken({ refresh: refreshToken });
    resolve(response);
  });
};

const refreshAccessToken = () =>
  new Promise(async resolve => {
    const response = await fetchNewAccessToken();
    const [payload] = response;

    if (payload) {
      localStorage.setItem(tokenStorageField, payload.access);
    }
    resolve();
  });

export const refreshTokenMiddleware = apiConnector => async params => {
  if (isAccessTokenUnexpired()) {
    return new Promise(async resolve => {
      const res = await apiConnector(params);
      const [, , { status }] = res;

      if (status === 401) {
        await refreshAccessToken();

        return apiConnector(params);
      }
      return resolve(res);
    });
  }
  await refreshAccessToken();

  return apiConnector(params);
};
