import React from "react";
import { Segment } from "components";
import { priceFormatter } from "utilities";
import PropTypes from "prop-types";

const LongletListingYieldsPerPrice = hullListing => {
  return Object.entries(hullListing.maxPriceGivenYield).map(([key, value]) => {
    return (
      <Segment
        key={key}
        title={`Max price - ${key}% yield`}
        text={"£" + priceFormatter(value)}
        info={`The maximum price that can be paid in order to
                      achieve a ${key}% yield.`}
      />
    );
  });
};

LongletListingYieldsPerPrice.propTypes = {
  hullListing: PropTypes.shape({
    maxPriceGivenYield: PropTypes.shape({}),
  }).isRequired,
};

export default LongletListingYieldsPerPrice;
