/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";

import { priceFormatter, yieldFormatter } from "utilities";
import RoomCountIconWithTooltip from "../room_count_icon_with_tooltip";
import listingConfigs from "configs/listing_configs";
import {
  isListingSourced,
  isListingDeclinable,
} from "state/helpers/map_helpers";
import SetHubSpotStageButton from "../set_hubspot_stage_button";
import IncomeInfo from "../income_info";

import bedroomsIcon from "assets/images/assets_icons/bedrooms.png";
import bathroomsIcon from "assets/images/assets_icons/bathrooms.png";
import receptionsIcon from "assets/images/assets_icons/reception-rooms.png";
import sendToHubspotAppraisalColumnIcon from "assets/images/hubspot_approve.png";
import sendToHubspotDeclinedColumnIcon from "assets/images/hubspot_decline.png";

import {
  buildListingExternalLink,
  buildHubspotLink,
} from "pages/map/components/helpers/listing_external_links";

import styles from "./index.module.css";

const {
  sharedConfig: {
    listingTypes: {
      hubspotPushedSaleListing: { type: salesListingType },
      longletListing: { type: longletListingType },
    },
  },
} = listingConfigs;
const ListingDetails = ({
  details,
  listingType,
  dealStageNamesToDealStageInternalIds,
}) => (
  <div className={styles.details}>
    <Description
      numberOfBedrooms={details.bedrooms}
      propertyType={details.property_type}
      address={details.address}
    />
    <div className={styles.utils}>
      <RoomCountIconWithTooltip
        count={details.bedrooms}
        icon={bedroomsIcon}
        roomName="bedroom"
      />
      <RoomCountIconWithTooltip
        count={details.bathrooms}
        icon={bathroomsIcon}
        roomName="bathroom"
      />
      <RoomCountIconWithTooltip
        count={details.receptions}
        icon={receptionsIcon}
        roomName="reception room"
      />
    </div>
    <div className={styles["price-stats"]}>
      {listingType === salesListingType && (
        <>
          <span>{`£${priceFormatter(details.price, true)}`}</span>
          <AdditionalHoverInfo
            additionalInfo={details.additionalInfo}
            pipeline={details.pipeline_name}
          />
        </>
      )}
      {listingType === longletListingType && (
        <IncomeInfo
          info={details.monthlyIncomeInfo}
          lastSaleDateWithPrice={details.previousSalePrices[0]}
        />
      )}
      {listingType === salesListingType && (
        <>
          <PredictedIncome value={details.predicted_monthly_income} />
          <EstimatedYield value={details.estimated_gross_yield} />
          <NetInitialYield value={details.net_yield_completion_ast} />
        </>
      )}
    </div>
    <div className={styles["details-button"]}>
      <HoverHubSpotButtons
        listing={details}
        listingType={listingType}
        dealStageNamesToDealStageInternalIds={
          dealStageNamesToDealStageInternalIds
        }
      >
        <div className="d-flex">
          <ExternalLinkButton
            listingLink={buildListingExternalLink({
              sourceSiteCode: details.source_site_code,
              propertyUrl: details.property_url,
            })}
          />
          {details.hubspot_id && (
            <div className="ml-1">
              <ExternalLinkButton
                listingLink={buildListingExternalLink({
                  sourceSiteCode: "HubSpot",
                  propertyUrl: buildHubspotLink(details.hubspot_id),
                })}
              />
            </div>
          )}
        </div>
      </HoverHubSpotButtons>
    </div>
  </div>
);

const AdditionalHoverInfo = ({
  additionalInfo: { value, valuePrefix },
  pipeline,
}) => (
  <div className={styles["average-stats"]}>
    <span>{pipeline}</span>
    <span>
      {valuePrefix}
      {typeof value === "number" ? priceFormatter(value, true) : value}
    </span>
  </div>
);

const Description = ({ numberOfBedrooms, propertyType, address }) => (
  <div className={styles.description} id={styles.description}>
    <span>{`${numberOfBedrooms} bedroom ${propertyType}`}</span>
    <span className={styles.address} id={styles.address}>
      <Tooltip title={address}>{address}</Tooltip>
    </span>
  </div>
);

const EstimatedYield = ({ value }) => (
  <div className={styles["average-stats"]}>
    <span>Gross yield </span>
    <span>{Number(value) !== 0 ? yieldFormatter(value, 1) : "n/a"}</span>
  </div>
);

const PredictedIncome = ({ value }) => (
  <div className={styles["average-stats"]}>
    <span>DS estimate pcm</span>
    <span>
      {Number(value) !== 0 ? `£${priceFormatter(value, true)}` : "n/a"}
    </span>
  </div>
);

const NetInitialYield = ({ value }) => (
  <>
    <div className={styles["average-stats"]}>
      <span>Net initial yield</span>
      <span>{Number(value) !== 0 ? yieldFormatter(value, 1) : "n/a"}</span>
    </div>
  </>
);

const ExternalLinkButton = ({ listingLink: { url, displayText, icon } }) => (
  <Tooltip title={displayText}>
    {url && <ButtonIconWithLink url={url} icon={icon} />}
  </Tooltip>
);

const ButtonIconWithLink = ({ url, icon }) => (
  <div className={styles["button-with-link"]}>
    <button type="button">
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <img src={icon} alt="external-link-icon" />
      </a>
    </button>
  </div>
);

const HoverHubSpotButtons = ({
  listingType,
  listing,
  children,
  dealStageNamesToDealStageInternalIds,
}) => (
  <>
    {listingType === salesListingType && (
      <SetHubSpotStageButton
        listing={listing}
        disabled={
          !isListingDeclinable(listing, dealStageNamesToDealStageInternalIds)
        }
        decline
        buttonIcon={sendToHubspotDeclinedColumnIcon}
        columnName="Declined"
        disabledText="Deal not declinable"
      />
    )}
    {React.Children.only(React.cloneElement(children, { listing }))}
    {listingType === salesListingType && (
      <SetHubSpotStageButton
        listing={listing}
        disabled={
          !isListingSourced(listing, dealStageNamesToDealStageInternalIds)
        }
        columnName="Appraising"
        buttonIcon={sendToHubspotAppraisalColumnIcon}
        disabledText="Only for 'sourced' deals."
      />
    )}
  </>
);

ListingDetails.defaultProps = {
  dealStageNamesToDealStageInternalIds: {},
};

ListingDetails.propTypes = {
  details: PropTypes.shape({
    bedrooms: PropTypes.number,
    bathrooms: PropTypes.number,
    address: PropTypes.string,
    receptions: PropTypes.number,
    previousSalePrices: PropTypes.arrayOf(PropTypes.shape()),
    price: PropTypes.number,
    additionalInfoLabel: PropTypes.string,
    additionalInfo: PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      valuePrefix: PropTypes.string,
    }),
    property_type: PropTypes.string,
    pipeline_name: PropTypes.string,
    predicted_monthly_income: PropTypes.number,
    externalId: PropTypes.any,
    source_site_code: PropTypes.string,
    property_url: PropTypes.string,
    estimated_gross_yield: PropTypes.number,
    net_yield_completion_ast: PropTypes.number,
    hubspot_id: PropTypes.string,
    monthlyIncomeInfo: PropTypes.arrayOf(PropTypes.shape),
  }).isRequired,
  listingType: PropTypes.string.isRequired,
  dealStageNamesToDealStageInternalIds: PropTypes.object,
};

export default ListingDetails;
