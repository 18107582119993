import React from "react";
import { Tooltip } from "react-tippy";
import PropTypes from "prop-types";

import styles from "./index.module.css";

const tooltipTitle = (number, name) =>
  number === 1 ? `${number} ${name}` : `${number ?? "unknown"} ${name}s`;

const prettifyCount = count =>
  count ||
  {
    0: 0,
    null: "?",
    undefined: "?",
    NaN: "?",
  }[count];

const RoomCountIconWithTooltip = ({ count, icon, roomName }) => (
  <Tooltip position="bottom" size="small" title={tooltipTitle(count, roomName)}>
    <RoomCountIcon icon={icon} roomName={roomName} count={count} />
  </Tooltip>
);

RoomCountIconWithTooltip.propTypes = {
  count: PropTypes.number,
  icon: PropTypes.string.isRequired,
  roomName: PropTypes.string.isRequired,
};

RoomCountIconWithTooltip.defaultProps = {
  count: null,
};
const RoomCountIcon = ({ roomName, count, icon }) => (
  <div className={styles["single-room-count-container"]}>
    <img src={icon} alt={roomName} />
    <span>{prettifyCount(count)}</span>
  </div>
);

RoomCountIcon.propTypes = {
  roomName: PropTypes.string.isRequired,
  count: PropTypes.number,
  icon: PropTypes.string.isRequired,
};

RoomCountIcon.defaultProps = {
  count: null,
};

export default RoomCountIconWithTooltip;
