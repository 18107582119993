/* eslint-disable import/order */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { routes } from "routing_configuration";
import LinksSection from "./links_section";
import HamburgerButton from "./hamburger_button";
import LogoutMenu from "./logout_menu";

import styles from "./top_panel.module.css";
import Logo from "./logo";

const handleMoveTo = (link, history, setIsOpen) => {
  history.push(link);
  setIsOpen(false);
};

const handleToggleMenu = (setIsMenuOpen, isMenuOpen) => {
  setIsMenuOpen(!isMenuOpen);
};

const currentRoute = location =>
  routes.find(route => route.path === location.pathname);

const isVisible = location =>
  Boolean(currentRoute(location) ? currentRoute(location).withTopPanel : true);

const TopPanel = () => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  return (
    isVisible(location) && (
      <div className={styles.topPanel + " navbar navbar-expand-lg"}>
        <div className={styles.menuContainer}>
          <Logo setIsOpen={setIsOpen} handleMoveTo={handleMoveTo} />
          <LinksSection isMenuOpen={isMenuOpen} />
          <HamburgerButton
            isMenuOpen={isMenuOpen}
            handleToggleMenu={() => handleToggleMenu(setIsMenuOpen, isMenuOpen)}
          />
        </div>
        <div className={styles.actions}>
          <LogoutMenu
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            handleMoveTo={handleMoveTo}
          />
        </div>
      </div>
    )
  );
};

export default TopPanel;
