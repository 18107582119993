import { combineReducers } from "redux";

import shortletListingFiltersReducer from "./shortlet/listing_filters_reducer";
import longletListingFiltersReducer from "./longlet/listing_filters_reducer";
import shortletPropertyHullFiltersReducer from "./shortlet/property_hull_filters_reducer";
import longletPropertyHullFiltersReducer from "./longlet/property_hull_filters_reducer";
import amenityHullFiltersReducer from "./shortlet/amenity_hull_filters_reducer";
import mapTypeFiltersReducer from "./map_type_filters_reducer";
import filterInitializerReducer from "./filter_initializer_reducer";
import salesDealsFiltersReducer from "./sales/deals_filter_reducer";
import propertyHullsDisabledReducer from "./property_hulls_disabled_filters_reducer";
import amenityHullsDisabledReducer from "./amenity_hulls_disabled_filters_reducer";
import listingsDisabledReducer from "./listings_disabled_filters_reducer";
import compsReducer from "./comps_reducer";

export default combineReducers({
  shortletListingFilters: shortletListingFiltersReducer,
  longletListingFilters: longletListingFiltersReducer,
  salesDealsFilters: salesDealsFiltersReducer,
  shortletPropertyHullFilters: shortletPropertyHullFiltersReducer,
  longletPropertyHullFilters: longletPropertyHullFiltersReducer,
  amenityHullFilters: amenityHullFiltersReducer,
  mapTypeFilters: mapTypeFiltersReducer,
  filterInitializer: filterInitializerReducer,
  propertyHullsDisabledFilters: propertyHullsDisabledReducer,
  amenityHullsDisabledFilters: amenityHullsDisabledReducer,
  listingsDisabledFilters: listingsDisabledReducer,
  compsFilters: compsReducer,
});
