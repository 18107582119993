import memoize from "lodash/memoize";

const formatSpiderfiableTopMarkerInCluster = (marker, clusterCount) => {
  const {
    markerOptions: { label },
  } = marker;

  marker.setLabel({
    ...label,
    text: `${clusterCount}`,
    color: "white",
  });
  marker.setTitle("Click to Expand");
};

const formatSpiderfiableMarker = marker => {
  const {
    markerOptions: { spiderfiableIcon },
  } = marker;

  marker.setIcon(spiderfiableIcon);
};

const formatSpiderfiedOrUnspiderfiedMarker = marker => {
  const {
    markerOptions: { label, title, selected, icon, selectedIcon },
  } = marker;

  marker.setLabel(label);
  marker.setTitle(title);
  if (selected) {
    marker.setIcon(selectedIcon);
  } else {
    marker.setIcon(icon);
  }
};

const formatSpiderfyAwareMarker = (
  marker,
  markerSpiderfierStatus,
  clusterCount,
  omsInstance,
) => {
  const {
    OverlappingMarkerSpiderfier: {
      markerStatus: { SPIDERFIABLE, SPIDERFIED, UNSPIDERFIABLE },
    },
  } = omsInstance;

  if (markerSpiderfierStatus === SPIDERFIABLE || marker.isTopMarkerInCluster) {
    formatSpiderfiableTopMarkerInCluster(marker, clusterCount);
  }
  if (markerSpiderfierStatus === SPIDERFIABLE) {
    formatSpiderfiableMarker(marker);
  }

  if (
    markerSpiderfierStatus === SPIDERFIED ||
    markerSpiderfierStatus === UNSPIDERFIABLE
  ) {
    formatSpiderfiedOrUnspiderfiedMarker(marker);
  }

  return true;
};

const onUnspiderfyFormatter = (marker, getClusterCallback) => {
  const {
    markerOptions: { id },
  } = marker;

  if (marker.isTopMarkerInCluster) {
    const clusterCount = getClusterCallback(id).length;
    formatSpiderfiableTopMarkerInCluster(marker, clusterCount);
  } else {
    marker.setLabel(" ");
  }

  formatSpiderfiableMarker(marker);
};

export default {
  formatSpiderfyAwareMarker,
  formatSpiderfiableMarker,
  onUnspiderfyFormatter,
  formatSpiderfiableTopMarkerInCluster,
  formatSpiderfiedOrUnspiderfiedMarker,
};
