import { handleSetBoundingBox } from "state/helpers/map_helpers";

const constructHullsQueryParams = (mapInstance, options) => {
  const currentBounds = mapInstance.getBounds();
  const newPolygonBounds = handleSetBoundingBox(
    currentBounds,
    currentBounds,
    true,
  );

  return {
    currentBounds,
    polygons: newPolygonBounds,
    options,
  };
};

export default constructHullsQueryParams;
