/**
 * @flow
 * @relayHash 11103bad03b10ffd34b0d6ee3433f221
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type dataProviderQueryVariables = {||};
export type dataProviderQueryResponse = {|
  +me: ?{|
    +id: string,
    +username: string,
    +firstName: string,
    +lastName: string,
    +email: string,
    +dateJoined: any,
    +isSuperuser: boolean,
    +isActive: boolean,
  |}
|};
export type dataProviderQuery = {|
  variables: dataProviderQueryVariables,
  response: dataProviderQueryResponse,
|};
*/


/*
query dataProviderQuery {
  me {
    id
    username
    firstName
    lastName
    email
    dateJoined
    isSuperuser
    isActive
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "me",
    "storageKey": null,
    "args": null,
    "concreteType": "UserType",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "username",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "dateJoined",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isSuperuser",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isActive",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "dataProviderQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "dataProviderQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "dataProviderQuery",
    "id": null,
    "text": "query dataProviderQuery {\n  me {\n    id\n    username\n    firstName\n    lastName\n    email\n    dateJoined\n    isSuperuser\n    isActive\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '67b20c58a6805bbf120816b9c6e4838d';
module.exports = node;
