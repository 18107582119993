import React from "react";
import PropTypes from "prop-types";
import { CollapseList } from "components/";
import { priceFormatter } from "utilities";

import styles from "../listing_details.module.css";

const previousPricesFormatter = priceForDate => {
  return (
    <div className={styles.yearElement}>
      <span>{`${priceForDate.date}:`}</span>
      {`£${priceFormatter(priceForDate.price, true)}`}
    </div>
  );
};

const PreviousSalePrices = ({
  data: {
    hullListing: { previousSalePrices },
  },
}) => (
  <CollapseList
    title="Show Previous Sale Prices"
    formatter={previousPricesFormatter}
    list={previousSalePrices}
  />
);

PreviousSalePrices.propTypes = {
  data: PropTypes.shape({
    hullListing: PropTypes.shape({
      previousSalePrices: PropTypes.arrayOf(
        PropTypes.objectOf(PropTypes.string),
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export default PreviousSalePrices;
