import React from "react";
import PropTypes from "prop-types";
import styles from "../map.module.css";

/**
 * We need this to avoid loader blinking between
 * API requests
 */
let timeout = null;
const Loader = ({ loading }) => {
  const [visible, setVisible] = React.useState(false);
  React.useEffect(() => {
    if (loading === false) {
      clearTimeout(timeout);
      timeout = setTimeout(() => setVisible(false), 2000);
    } else if (visible === false) {
      setVisible(true);
    }
    return () => {
      clearTimeout(timeout);
      timeout = null;
    };
  }, [loading, visible]);
  if (!visible) return null;
  return (
    <div className={styles.loader}>
      <div className={styles.pin} />
      <div className={styles.pulse} />
    </div>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Loader;
