import { createSelector } from "reselect";

import mapFilterFactories from "state/global/factories/map_filter_factories";
import siblingSelectors from "..";

const selectors = {
  ...mapFilterFactories.generateFilterSelectors(state =>
    siblingSelectors.selectShortletListingFilters(state),
  ),
};

export default selectors;
