const sharedConfig = {
  bands: ["A", "B", "C", "D", "E"],
  listingBeds: { min: 1, max: 1 },
  propertyTypes: ["flat", "house", "room"],
};

const longletPropertyRevenueFilterOptions = {
  1: { label: "0", value: 0 },
  2: { label: "5k", value: 5000 },
  3: { label: "10k", value: 10000 },
  4: { label: "15k", value: 15000 },
  5: { label: "20k", value: 20000 },
  6: { label: "25k", value: 25000 },
  7: { label: "30k", value: 30000 },
  8: { label: "35k", value: 35000 },
  9: { label: "70k", value: 70000 },
  10: { label: "No Limit", value: Number.MAX_SAFE_INTEGER },
};

const longletFreshnessFilterOptions = {
  1: { label: "all time", value: Number.MIN_SAFE_INTEGER },
  2: { label: "2y", value: -730 },
  3: { label: "1y", value: -365 },
  4: { label: "6mo", value: -180 },
  5: { label: "3mo", value: -90 },
  6: { label: "2mo", value: -60 },
  7: { label: "1mo", value: -30 },
  8: { label: "now", value: 0 },
};

const salesDealsFreshnessFilterOptions = {
  1: { label: "all time", value: Number.MIN_SAFE_INTEGER },
  2: { label: "3mo", value: -90 },
  3: { label: "1mo", value: -30 },
  4: { label: "2w", value: -14 },
  5: { label: "1w", value: -7 },
  6: { label: "1d", value: -1 },
  7: { label: "now", value: 0 },
};

export default {
  sharedConfig,
  longletPropertyRevenueFilterOptions,
  longletFreshnessFilterOptions,
  salesDealsFreshnessFilterOptions,
};
