import dataCollectionFactories from "state/global/factories/data_collections_factories";
import {
  actionTypes,
  actionTypePrefix,
} from "state/global/actions/data_collections/map/sales/sales_deal_stages_actions";

const reducer = dataCollectionFactories.generateCollectionReducer(
  actionTypes,
  actionTypePrefix,
  "pipelineId",
);

export default reducer;
