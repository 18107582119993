/**
 * @flow
 * @relayHash 334dd3bda1bd7fd8288fc20dd6d9b53b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type usersQueryVariables = {||};
export type usersQueryResponse = {|
  +users: ?$ReadOnlyArray<?{|
    +id: string,
    +username: string,
    +firstName: string,
    +lastName: string,
    +email: string,
    +dateJoined: any,
    +isSuperuser: boolean,
    +isActive: boolean,
  |}>
|};
export type usersQuery = {|
  variables: usersQueryVariables,
  response: usersQueryResponse,
|};
*/


/*
query usersQuery {
  users {
    id
    username
    firstName
    lastName
    email
    dateJoined
    isSuperuser
    isActive
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "users",
    "storageKey": null,
    "args": null,
    "concreteType": "UserType",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "username",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "dateJoined",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isSuperuser",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isActive",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "usersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "usersQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "usersQuery",
    "id": null,
    "text": "query usersQuery {\n  users {\n    id\n    username\n    firstName\n    lastName\n    email\n    dateJoined\n    isSuperuser\n    isActive\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7f774064feccaf9874cef759519bbf88';
module.exports = node;
