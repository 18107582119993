import React from "react";
import PropTypes from "prop-types";

import styles from "./list_element.module.css";

const PropertyAttribute = ({ value, name }) => {
  return (
    <div className={styles.detail}>
      <b>{value}</b>
      {name}
    </div>
  );
};

const PropertyAttributes = ({ details }) => {
  return (
    <div className={styles.details}>
      <PropertyAttribute value={details.bedrooms} name="bedrooms" />
      <PropertyAttribute value={details.max_guests} name="max sleeps" />
      <PropertyAttribute value={details.property_type} name="type" />
    </div>
  );
};

PropertyAttributes.propTypes = {
  details: PropTypes.shape().isRequired,
};
PropertyAttribute.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
};

export default PropertyAttributes;
