import React from "react";
import PropTypes from "prop-types";

import { Segment } from "components";

const PropertyHullDetails = ({ data: { propertyHull } }) => (
  <div>
    <Segment title="Income band" text={propertyHull.incomeBand} />
    <Segment title="Bedrooms" text={propertyHull.bedrooms} />
    <Segment
      title="City/District"
      text={propertyHull.district ?? propertyHull.city}
    />
    <Segment title="Property type" text={propertyHull.propertyType} />
  </div>
);

PropertyHullDetails.propTypes = {
  data: PropTypes.shape({
    propertyHull: PropTypes.shape({
      incomeBand: PropTypes.string.isRequired,
      bedrooms: PropTypes.number.isRequired,
      city: PropTypes.string,
      district: PropTypes.string,
      propertyType: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default PropertyHullDetails;
