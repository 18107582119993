import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";
import styles from "./expansion.module.css";

class Expansion extends PureComponent {
  state = { isOpen: false };

  // this function is triggered by the "onClickOutside" wrapper
  handleClickOutside = () => {
    const { onClose } = this.props;
    onClose();
    this.setState({ isOpen: false });
  };

  setIsOpen = isOpen => this.setState({ isOpen });

  closeList = () => this.setState({ isOpen: false });

  render() {
    const { controller, children } = this.props;
    const { isOpen } = this.state;

    const list =
      typeof children === "function" ? children(this.closeList) : children;

    return (
      <div className={styles.container}>
        {controller(() => this.setIsOpen(!isOpen), isOpen)}
        <div className={styles.children} data-testid="expansion-list">
          {isOpen && list}
        </div>
      </div>
    );
  }
}

Expansion.defaultProps = {
  children: null,
  controller: () => null,
  onClose: () => null,
};

Expansion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.func,
  ]),
  controller: PropTypes.func,
  onClose: PropTypes.func,
};

export default onClickOutside(Expansion);
