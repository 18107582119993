/* eslint-disable prefer-destructuring */
import React from "react";
import PropTypes from "prop-types";

import error_img from "assets/images/error.svg";
import styles from "./fetching_error.module.css";

const getErrorMessage = error => {
  let message = "Server error";

  if (typeof error === "string") message = error;

  if (error && error.message) message = error.message;

  return message;
};

const getHttpStatusCode = httpStatusCode => {
  let message = 500;

  if (typeof httpStatusCode === "number") message = httpStatusCode;

  return message;
};

const getHttpStatusColor = httpStatusCode => {
  let color = "#f00000";

  if (httpStatusCode && httpStatusCode < 500) color = "#f0c200";

  if (httpStatusCode && httpStatusCode < 300) color = "#7b7a7a";

  return { color };
};

const getErrorMessages = httpStatusCode => {
  let messages = {
    top: "Error occurred while fetching the data.",
    bottom: "Please report it to administration",
  };

  if (httpStatusCode) {
    const errorMessages = {
      401: {
        top: "You are not allowed to get this data",
        bottom: "",
      },
      404: {
        top: "Data you are trying to reach is not existing",
        bottom: "Please report it to administration",
      },
      500: {
        top: "Server error occurred",
        bottom: "Please report it to administration",
      },
      502: {
        top: "Server error occurred",
        bottom: "Please report it to administration",
      },
    }[httpStatusCode];

    if (errorMessages) messages = errorMessages;
  }

  return messages;
};

const FetchingError = ({ error, status, className }) => {
  const errorMessage = getErrorMessage(error);
  const errorMessages = getErrorMessages(status);
  const httpStatusCode = getHttpStatusCode(status);

  return (
    <div className={`${styles.container} ${className}`}>
      <img className={styles.image} src={error_img} alt="" />
      <div className={styles.errorText}>
        <span style={getHttpStatusColor(httpStatusCode)}>{httpStatusCode}</span>
        {errorMessage}
      </div>
      <div className={styles.text}>{errorMessages.top}</div>
      <div className={styles.smallText}>{errorMessages.bottom}</div>
    </div>
  );
};

FetchingError.defaultProps = {
  error: null,
  status: null,
  className: "",
};

FetchingError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default FetchingError;
