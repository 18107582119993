import React from "react";

import { SaleListingDetails, SaleListingIncomeStatistics } from "../components";

import styles from "../listing_details.module.css";

const SaleListingDetailsPanel = ({ listing }) => (
  <div className={styles.body}>
    <SaleListingDetails listing={listing} />
    <div className={styles.title}>Statistics</div>
    <SaleListingIncomeStatistics listing={listing} />
  </div>
);

export default SaleListingDetailsPanel;
