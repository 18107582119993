import queryStringUpdateCallback from "pages/map/components/helpers/query_string_update";

const handleListingFiltering = ({
  filterActions,
  filterName,
  value,
  shouldReplaceFilterValue,
  filterSelector,
  updateQueryStringCallback = null,
  queryParamPrefix = "",
}) => {
  let togglePromise;

  if (shouldReplaceFilterValue) {
    togglePromise = filterActions.setFilterValue(filterName, value);
  } else {
    togglePromise = filterActions.toggleCollectionFilter(filterName, value);
  }

  if (updateQueryStringCallback) {
    togglePromise.then(globalState =>
      queryStringUpdateCallback(
        globalState,
        filterSelector,
        filterName,
        updateQueryStringCallback,
        queryParamPrefix,
      ),
    );
  }
};

export default handleListingFiltering;
