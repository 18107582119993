import { combineReducers } from "redux";

import dataRequestFactories from "state/global/factories/data_request_factories";
import { sendToHubspotColumn } from "state/global/actions/data_requests/send_to_hubspot_request_actions";

const statusReducer = dataRequestFactories.generateStatusReducer(
  sendToHubspotColumn.actionTypes,
  sendToHubspotColumn.actionTypePrefix,
);

const sendToHubspotColumnRequestReducer = (state = {}, action) => {
  return {
    ...statusReducer(state, action),
  };
};

export default combineReducers({
  sendToHubspotColumn: sendToHubspotColumnRequestReducer,
});
