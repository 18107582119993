/* eslint-disable import/named */
import React, { useReducer, useMemo, useEffect } from "react";

import { getPropertiesList } from "api/properties";
import {
  useQuery,
  Pagination,
  Loader,
  NoContent,
  FetchingError,
} from "components";
import { getPagination } from "utilities";
import statisticsListActions from "state/local/actions/statistics/statistics_list_actions";
import statisticsListReducer from "state/local/reducers/statistics/statistics_list_reducer";
import { ListElement } from "./components";

import styles from "./statistics_list.module.css";

const initialState = {
  properties: [],
  fetching: true,
  error: null,
  pagination: getPagination({}),
};

const StatisticsList = () => {
  const [state, dispatch] = useReducer(statisticsListReducer, initialState);
  const { request, failure, success } = useMemo(
    () => statisticsListActions(dispatch),
    [dispatch],
  );

  const { querySearch } = useQuery();

  useEffect(() => {
    async function fetchData() {
      request();
      const [payload, error] = await getPropertiesList(querySearch);
      if (payload) {
        success(payload);
      } else {
        failure(error);
      }
    }

    fetchData();
  }, [querySearch, request, failure, success]);

  const { fetching, properties, pagination, error } = state;
  const isValidResult = Boolean(properties.length);

  return (
    <div className="container">
      {fetching && <Loader absolute type="primary" size="big" />}
      {isValidResult && (
        <>
          <div className={styles.title}>Statistics list</div>
          <div className={styles.pagination}>
            <Pagination {...pagination} />
          </div>
          <div className={styles.list}>
            {properties.map(property => (
              <ListElement key={property.id} property={property} />
            ))}
          </div>
        </>
      )}
      {!fetching && !error && !isValidResult && <NoContent />}
      {error && !isValidResult && <FetchingError />}
    </div>
  );
};

export default StatisticsList;
