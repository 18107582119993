const sharedConfig = {
  pageSizes: {
    listings: 200,
    hulls: 200,
    amenities: 200,
    clusters: 2000,
  },
};

const mapTypeNames = {
  longlet: "long_let",
  shortlet: "short_let",
  sales: "sales",
};

const zoomLevels = {
  clusters: { min: 9, max: 10 },
  listings: 11,
  hulls: 14,
  amenities: 14,
  maximumMapZoom: 22,
};

const filterDebounce = {
  listings: 800,
  amenities: 800,
};

export default {
  sharedConfig,
  mapTypeNames,
  zoomLevels,
  filterDebounce,
};
