/**
 * @flow
 * @relayHash c4b86d02f41884ffc7d50d6e5ee3c08b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type shortletQueriesHullStatisticsQueryVariables = {|
  id: string
|};
export type shortletQueriesHullStatisticsQueryResponse = {|
  +shortletPropertyHullStatistics: ?{|
    +id: string,
    +propertyHull: {|
      +bedrooms: number,
      +propertyType: string,
      +city: string,
      +incomeBand: string,
    |},
    +averageIncome: number,
    +numberOfProperties: ?number,
    +averageZooplaPrice: ?number,
    +titleKeywordsWithIncomes: ?any,
    +descriptionKeywordsWithIncomes: ?any,
    +previousMonthsAvgSoldPrices: ?any,
    +previousMonthsNumPropertiesSold: ?any,
  |}
|};
export type shortletQueriesHullStatisticsQuery = {|
  variables: shortletQueriesHullStatisticsQueryVariables,
  response: shortletQueriesHullStatisticsQueryResponse,
|};
*/


/*
query shortletQueriesHullStatisticsQuery(
  $id: ID!
) {
  shortletPropertyHullStatistics(id: $id) {
    id
    propertyHull {
      bedrooms
      propertyType
      city
      incomeBand
      id
    }
    averageIncome
    numberOfProperties
    averageZooplaPrice
    titleKeywordsWithIncomes
    descriptionKeywordsWithIncomes
    previousMonthsAvgSoldPrices
    previousMonthsNumPropertiesSold
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "bedrooms",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "propertyType",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "city",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "incomeBand",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "averageIncome",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "numberOfProperties",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "averageZooplaPrice",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "titleKeywordsWithIncomes",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "descriptionKeywordsWithIncomes",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "previousMonthsAvgSoldPrices",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "previousMonthsNumPropertiesSold",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "shortletQueriesHullStatisticsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "shortletPropertyHullStatistics",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ShortletPropertyHullStatisticNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "propertyHull",
            "storageKey": null,
            "args": null,
            "concreteType": "ShortletPropertyHullNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ]
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "shortletQueriesHullStatisticsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "shortletPropertyHullStatistics",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ShortletPropertyHullStatisticNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "propertyHull",
            "storageKey": null,
            "args": null,
            "concreteType": "ShortletPropertyHullNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/)
            ]
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "shortletQueriesHullStatisticsQuery",
    "id": null,
    "text": "query shortletQueriesHullStatisticsQuery(\n  $id: ID!\n) {\n  shortletPropertyHullStatistics(id: $id) {\n    id\n    propertyHull {\n      bedrooms\n      propertyType\n      city\n      incomeBand\n      id\n    }\n    averageIncome\n    numberOfProperties\n    averageZooplaPrice\n    titleKeywordsWithIncomes\n    descriptionKeywordsWithIncomes\n    previousMonthsAvgSoldPrices\n    previousMonthsNumPropertiesSold\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '02133d6b8737b134fe3df9758a7cb695';
module.exports = node;
