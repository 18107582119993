import { createReducer } from "@reduxjs/toolkit";

import { actionTypes } from "state/global/actions/ui_control/map/map_content_view_actions";

const initialSelectorState = {
  selectedListingId: null,
  selectedListingType: null,
  selectedPropertyHullId: null,
  selectedPropertyHullType: null,
  selectedAmenityHullId: null,
  selectedAmenityHullType: null,
};

const initialHighlightState = {
  highlightedAmenityType: null,
};

const initialState = {
  ...initialSelectorState,
  ...initialHighlightState,
  isMapLoaded: false,
  searchMarker: {},
};

const reducer = createReducer(initialState, {
  [actionTypes.SET_IS_MAP_LOADED]: (draftState, action) => {
    return {
      ...draftState,
      isMapLoaded: action.payload,
    };
  },
  [actionTypes.SET_SEARCH_MARKER]: (draftState, action) => {
    return {
      ...draftState,
      searchMarker: action.payload,
    };
  },
  [actionTypes.DESELECT_ALL]: (draftState, action) => {
    return {
      ...draftState,
      ...initialSelectorState,
      ...initialHighlightState,
    };
  },
  [actionTypes.SELECT_LISTING]: (draftState, action) => {
    return {
      ...draftState,
      selectedListingId: action.payload.listingId,
      selectedListingType: action.payload.listingType,
    };
  },
  [actionTypes.DESELECT_LISTING]: (draftState, action) => {
    return {
      ...draftState,
      selectedListingId: null,
      selectedListingType: null,
    };
  },
  [actionTypes.SELECT_PROPERTY_HULL]: (draftState, action) => {
    return {
      ...draftState,
      selectedPropertyHullId: action.payload.propertyHullId,
      selectedPropertyHullType: action.payload.propertyHullType,
    };
  },
  [actionTypes.DESELECT_PROPERTY_HULL]: (draftState, action) => {
    return {
      ...draftState,
      selectedPropertyHullId: null,
      selectedPropertyHullType: null,
    };
  },
  [actionTypes.SELECT_AMENITY_HULL]: (draftState, action) => {
    return {
      ...draftState,
      selectedAmenityHullId: action.payload.amenityHullId,
      selectedAmenityHullType: action.payload.amenityHullType,
    };
  },
  [actionTypes.DESELECT_AMENITY_HULL]: (draftState, action) => {
    return {
      ...draftState,
      selectedAmenityHullId: null,
      selectedAmenityHullType: null,
    };
  },
  [actionTypes.HIGHLIGHT_AMENITY_TYPE]: (draftState, action) => {
    return {
      ...draftState,
      highlightedAmenityType: action.payload.amenityType,
    };
  },
});

//   selectedListing: null,
//   selectedPropertyHull: null,
//   selectedAmenityHull: null,
//   highlightedAmenityType: null,

// selectListing = listing => {
//
//   const selected = {
//     selectedListing: null,
//     selectedAmenityHull: null,
//     selectedPropertyHull: null,
//     highlightedAmenityType: null,
//   };
//
//   this.setState(state => {
//     if (!listing) return selected;
//     if (state.selectedListing && state.selectedListing.id === listing.id) {
//       return selected;
//     }
//     return {
//       selectedListing: listing,
//       selectedAmenityHull: null,
//       selectedPropertyHull: null,
//       highlightedAmenityType: null,
//     };
//   });
// };

export default reducer;
