import dataRequestFactories from "state/global/factories/data_collections_factories";

const actionTypePrefix = "LONGLET_PROPERTY_HULLS";
const actionTypes = dataRequestFactories.generateCollectionActionTypes(
  actionTypePrefix,
);
const actions = dataRequestFactories.generateCollectionActions(
  actionTypes,
  actionTypePrefix,
);

export { actionTypePrefix, actionTypes, actions };
