const deserializeSaleListings = serializedListings =>
  serializedListings.map(listing => {
    const {
      id,
      hubspot_pipeline_internal_id: pipeline_id,
      scraped_sale_listing: {
        first_published: published_at,
        recepts: receptions,
        details_url: property_url,
        bedrooms,
        bathrooms,
        image_urls,
        price,
        address,
        source_site_code,
        area: area_square_feet,
        floor_plans_url = [],
        updated_at: date_taken_off_market,
        description,
        postcode,
        gbp_per_square_feet,
      },
      hubspot_deal_stage_internal_id,
      pipeline_name,
      hubspot_id,
      hashed_id,
      ast_revenue_assignment_result,
      estimated_gross_yield,
      property_type,
      point,
      net_yield_completion_ast,
    } = listing;

    return {
      id,
      bedrooms,
      image_urls: [...image_urls, ...floor_plans_url],
      price,
      address,
      property_url,
      source_site_code,
      hubspot_id,
      bathrooms,
      published_at,
      point,
      pipeline_id,
      estimated_gross_yield,
      hubspot_deal_stage_internal_id,
      pipeline_name,
      hashed_id,
      ...ast_revenue_assignment_result,
      property_type,
      net_yield_completion_ast,
      receptions,
      area_square_feet,
      description,
      postcode,
      gbp_per_square_feet,
      date_taken_off_market,
    };
  });

export default deserializeSaleListings;
