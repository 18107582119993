import { createSelector } from "reselect";

import dataRequestFactories from "state/global/factories/data_request_factories";
import siblingSelectors from ".";

const fetchShortletAmenityHullsRequestSelectors = {
  ...dataRequestFactories.generateStatusSelectors(
    state =>
      siblingSelectors.selectMapDataRequestGroup(state)
        .fetchShortletAmenityHullsRequest,
  ),
};

export { fetchShortletAmenityHullsRequestSelectors };
