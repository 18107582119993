import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./button.module.css";
import Loader from "../loader/loader";

const Button = ({
  type,
  onClick,
  to,
  className,
  btnType,
  size,
  padding,
  disabled,
  full,
  children,
  loading,
}) => {
  const sizeStyle = {
    tiny: styles.tiny,
    small: styles.small,
    medium: styles.medium,
    big: styles.big,
  }[size];
  const paddingStyle = {
    none: styles.paddingNone,
    small: styles.paddingSmall,
    medium: styles.paddingMedium,
    big: styles.paddingBig,
  }[padding];
  const typeStyle = {
    primary: styles.primary,
    secondary: styles.secondary,
    default: styles.default,
  }[type];
  const widthStyle = full ? styles.full : "";

  const buttonProps = {
    disabled,
    type: btnType,
    onClick: to ? () => null : onClick,
    className: [
      styles.btn,
      sizeStyle,
      typeStyle,
      widthStyle,
      paddingStyle,
      className,
    ].join(" "),
  };

  const loaderStyle = type === "primary" ? "secondary" : "primary";

  return (
    <button type="button" {...buttonProps}>
      {to && <Link to={to} />}
      {children}
      {loading && <Loader type={loaderStyle} size="small" />}
    </button>
  );
};

Button.defaultProps = {
  size: "medium",
  padding: "medium",
  type: "default",
  btnType: "button",
  className: "",
  to: "",
  disabled: false,
  children: "",
  full: false,
  loading: false,
  onClick: () => null,
};

Button.propTypes = {
  size: PropTypes.oneOf(["tiny", "small", "medium", "big"]),
  padding: PropTypes.oneOf(["none", "small", "medium", "big"]),
  type: PropTypes.oneOf(["primary", "secondary", "default"]),
  btnType: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  full: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Button;
