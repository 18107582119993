import listingConfigs from "configs/listing_configs";
import { findKey, startCase, camelCase } from "lodash";

const {
  sharedConfig: {
    listingTypes: {
      longletListing: { type: longletListingType },
      hubspotPushedSaleListing: { type: salesListingType },
    },
  },
} = listingConfigs;

const dealStageInternalIdToName = (
  dealStageInternalId,
  dealStageNamesToDealStageInternalIds,
) => {
  const stageName = findKey(
    dealStageNamesToDealStageInternalIds,
    stageId => stageId === dealStageInternalId,
  );

  return startCase(camelCase(stageName));
};

const buildLongLetListingStandardizePayload = ({ payload, revenue }) => {
  const {
    longletHullListingsStatistics: {
      average12MonthIncome,
      imageUrls: image_urls,
      hullListing,
      hullListing: { isSourceZoopla, propertyUrl: property_url },
    },
    property_type,
  } = payload;

  return {
    ...hullListing,
    property_type,
    monthlyIncomeInfo: [
      {
        label: "Asking pcm",
        value: average12MonthIncome,
        valuePrefix: "£",
      },
      {
        label: "DS estimate pcm",
        value: revenue ?? MISSING_PRICE_PLACEHOLDER,
        valuePrefix: revenue ? "£" : "",
      },
    ],
    source_site_code: isSourceZoopla ? "zoopla" : "rightmove",
    property_url,
    image_urls,
  };
};

const standardizeSaleListingPayload = ({
  payload,
  dealStageNamesToDealStageInternalIds,
}) => ({
  ...payload,
  additionalInfo: {
    value: dealStageInternalIdToName(
      payload.hubspot_deal_stage_internal_id,
      dealStageNamesToDealStageInternalIds,
    ),
    valuePrefix: "",
  },
});

export const standardizeListingPayload = ({
  payload,
  listingType,
  revenue = null,
  dealStageNamesToDealStageInternalIds,
}) =>
  ({
    [longletListingType]: buildLongLetListingStandardizePayload,
    [salesListingType]: standardizeSaleListingPayload,
  }[listingType]({
    payload,
    revenue,
    dealStageNamesToDealStageInternalIds,
  }));

export const MISSING_PRICE_PLACEHOLDER = "n/a";
