import { createSelector } from "reselect";

import parentSelectors from "../root_selectors";

const selectors = {
  selectFetchUsersRequest: state =>
    parentSelectors.selectDataRequests(state).fetchUsersRequest,
  selectMapDataRequestGroup: state =>
    parentSelectors.selectDataRequests(state).mapDataRequestGroup,
  selectSendToHubspotColumnRequest: state =>
    parentSelectors.selectDataRequests(state).sendToHubspotColumnRequest,
  selectDeclineHubspotDealRequest: state =>
    parentSelectors.selectDataRequests(state).declineHubspotDealRequest,
};

export default selectors;
