import { Environment, Network, RecordSource, Store } from "relay-runtime";
import RelayNetworkLogger from "relay-runtime/lib/RelayNetworkLogger";
import fetchQuery from "./fetchQuery";

const network =
  process.env.REACT_APP_ENV === "production"
    ? Network.create(fetchQuery)
    : Network.create(RelayNetworkLogger.wrapFetch(fetchQuery, () => ""));

const source = new RecordSource();
const store = new Store(source);

const env = new Environment({
  network,
  store,
});

export default env;
