import React, { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import Calendar from "react-calendar/dist/entry.nostyle";
import { format } from "date-fns";

import Tooltip from "./tooltip";
import Tile from "./tile";

import "./calendar.css";

const getDayValue = (days, value, key) => {
  const dateName = format(value, "YYYY-MM-DD");
  let dayValue;
  if (days[dateName]) {
    dayValue = days[dateName][key];
  }
  return dayValue;
};

const TileComponent = ({ days, value }) => {
  const booked = useMemo(() => getDayValue(days, value.date, "booked"), [
    days,
    value.date,
  ]);
  const price = useMemo(() => getDayValue(days, value.date, "avgPrice"), [
    days,
    value.date,
  ]);
  const userPrice = useMemo(() => getDayValue(days, value.date, "userPrice"), [
    days,
    value.date,
  ]);

  return (
    <Fragment>
      <Tile price={price} booked={booked} userPrice={userPrice} />
      <Tooltip booked={booked} />
    </Fragment>
  );
};

const CalendarComponent = ({ year, month, primary, days, onClickDay }) => {
  const date = new Date(year, month - 1, 1);

  return (
    <div className={`react-calendar-container ${primary ? "primary" : ""}`}>
      <div className="react-calendar-title">{format(date, "MMMM YYYY")}</div>
      <Calendar
        showNavigation={false}
        onChange={() => null}
        value={date}
        locale="en-En"
        tileContent={tile => <TileComponent value={tile} days={days} />}
        onClickDay={onClickDay}
      />
    </div>
  );
};

CalendarComponent.defaultProps = {
  primary: false,
};

CalendarComponent.propTypes = {
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  primary: PropTypes.bool,
  onClickDay: PropTypes.func.isRequired,
  days: PropTypes.shape().isRequired,
};

TileComponent.propTypes = {
  days: PropTypes.shape().isRequired,
  value: PropTypes.shape().isRequired,
};

export default CalendarComponent;
