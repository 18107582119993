/* eslint-disable no-shadow */
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import kebabCase from "lodash/kebabCase";

import shortletMainButtonStyles from "pages/map/left_panel/map_type_tabs/shortlet_tab/main_button_styles.module.css";
import longletMainButtonStyles from "pages/map/left_panel/map_type_tabs/longlet_tab/main_button_styles.module.css";
import salesMainButtonStyles from "pages/map/left_panel/map_type_tabs/sales_tab/main_button_styles.module.css";

import mapTypeFiltersSelectors from "state/global/selectors/user_input/map/filters/map_type_filters_selectors";
import activeMapTabSelectors from "state/global/selectors/ui_control/map/active_tab_selectors";
import { camelCase } from "lodash";
import { actions as activeMapTabsActions } from "state/global/actions/ui_control/map/active_tab_actions";
import { useQuery } from "components";
import mapConfigs from "configs/map_configs";
import { ShortletTab, SalesTab, LongleTab } from "./map_type_tabs";

import styles from "./container.module.css";

const {
  mapTypeNames: { longlet: longletMap, sales: salesMap, shortlet: shortletMap },
} = mapConfigs;

const LeftPanelContainer = ({
  currentMapTypes,
  activeTab,
  activeTabOptions,
  activeMapTabsActions,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const { updateQueryString } = useQuery();

  const handleToggleMenu = () => setIsOpen(!isOpen);

  React.useEffect(() => {
    updateQueryString({ activeTab });
  }, [activeTab]);

  return (
    <div className={`${styles.container} ${!isOpen ? styles.panelClosed : ""}`}>
      <HamburgerMenuButton handleToggleMenu={handleToggleMenu} />
      <ListingTabs
        tabNames={activeTabOptions}
        setTab={activeMapTabsActions}
        tab={activeTab}
        currentMapTypes={currentMapTypes.map(type => camelCase(type))}
      />
    </div>
  );
};

const HamburgerMenuButton = ({ handleToggleMenu }) => (
  <button type="button" className={styles.toggleBtn} onClick={handleToggleMenu}>
    {[1, 2, 3].map(element => (
      <span key={element} />
    ))}
  </button>
);

const isTabEnabled = (tabName, currentMapTypes) =>
  currentMapTypes?.includes(tabName);

const tabContent = tabNames => {
  const tabComponents = [SalesTab, ShortletTab, LongleTab];

  return Object.assign(...tabNames.map((k, i) => ({ [k]: tabComponents[i] })));
};

const getTabClassName = (value, tab, currentMapTypes) => {
  if (value === tab && isTabEnabled(value, currentMapTypes)) {
    return "active";
  }
  if (value === tab && !isTabEnabled(value, currentMapTypes)) {
    return "selected";
  }

  return "regular";
};

const mainButtonStylesByTabName = {
  [`${camelCase(shortletMap)}`]: shortletMainButtonStyles,
  [`${camelCase(longletMap)}`]: longletMainButtonStyles,
  [salesMap]: salesMainButtonStyles,
};

const ListingTabs = ({ tabNames, setTab, tab, currentMapTypes }) => (
  <>
    <div className={styles.tabs}>
      {tabNames.map(name => (
        <button
          type="button"
          className={
            mainButtonStylesByTabName[name][
              getTabClassName(name, tab, currentMapTypes)
            ]
          }
          disabled={!isTabEnabled(name, currentMapTypes)}
          onClick={() => setTab.setValue(name)}
          key={`${name}-tab-btn`}
          id={mainButtonStylesByTabName[name][`${kebabCase(name)}-tab-btn`]}
        >
          {name}
        </button>
      ))}
    </div>
    <div className={styles.content}>
      {React.createElement(tabContent(tabNames)[tab], {
        tabDisabled: !isTabEnabled(tab, currentMapTypes),
      })}
    </div>
  </>
);

const mapPropsToState = state => ({
  currentMapTypes: mapTypeFiltersSelectors.selectCurrent(state).mapTypes,
  activeTab: activeMapTabSelectors.selectCurrent(state).activeTab,
  activeTabOptions: activeMapTabSelectors.selectOptions(state).activeTabOptions,
});

const mapDispatchToProps = dispatch => ({
  activeMapTabsActions: bindActionCreators(activeMapTabsActions, dispatch),
});

export default connect(
  mapPropsToState,
  mapDispatchToProps,
)(LeftPanelContainer);
