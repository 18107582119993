import React from "react";
import PropTypes from "prop-types";

import { Segment, Carousel } from "components";
import {
  buildListingGoogleMapLink,
  buildListingExternalLink,
  buildHubspotLink,
} from "pages/map/components/helpers/listing_external_links";
import HubSpotButtons from "../hubspot_buttons";
import ExternalLinkIcons from "../external_link_icons";

const SaleListingDetails = ({ listing }) => {
  return (
    <div>
      <ExternalLinkIcons
        googleMapsUrl={buildListingGoogleMapLink(listing)}
        listingLink={buildListingExternalLink(listing)}
        hubspotLink={buildListingExternalLink({
          sourceSiteCode: "HubSpot",
          propertyUrl: buildHubspotLink(listing.hubspot_id),
        })}
      />
      <Carousel
        images={listing.image_urls}
        imageHeight={232}
        imageWidth={349}
      />
      <HubSpotButtons listing={listing} />
      <Segment
        title="Number of bedrooms"
        text={listing.bedrooms}
        info="Number of bedrooms of the property."
      />
      <Segment title="Price" text={listing.price?.toLocaleString()} />
      <Segment title="Property Type" text={listing.property_type} />
      <Segment
        title="Address"
        text={listing.address}
        info="The address code of the property."
      />
      <Segment
        title="Number of bathrooms"
        text={listing.bathrooms}
        info="Number of bathrooms of the property."
      />
      <Segment
        title="Receptions"
        text={listing.receptions}
        info="Number of receptions of the property."
      />
    </div>
  );
};

SaleListingDetails.propTypes = {
  listing: PropTypes.shape({
    bedrooms: PropTypes.number,
    price: PropTypes.number,
    address: PropTypes.string,
    receptions: PropTypes.any,
    property_type: PropTypes.string,
    hubspot_id: PropTypes.string,
    image_urls: PropTypes.arrayOf(PropTypes.string),
    bathrooms: PropTypes.number,
  }).isRequired,
};

export default SaleListingDetails;
