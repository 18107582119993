import {
  LISTING_ID,
  HULL_ID,
  AMENITY_ID,
  RESET_IDS,
} from "state/local/action_types/map/map_details_action_types";

const mapDetailsReducer = (state, { type, id, initialState }) => {
  switch (type) {
    case LISTING_ID:
      return { ...initialState, listingId: id, title: "Listing details" };
    case HULL_ID:
      return { ...initialState, hullId: id, title: "Hull details" };
    case AMENITY_ID:
      return { ...initialState, amenityId: id, title: "Amenity details" };
    case RESET_IDS:
      return { ...initialState, title: state.title };
    default:
      throw new Error("Unexpected action {{" + type + "}}");
  }
};

export default mapDetailsReducer;
