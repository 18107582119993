import React from "react";
import PropTypes from "prop-types";
import tickImg from "../../../assets/images/tick_white.png";
import styles from "./checkbox.module.css";

const Checkbox = ({ checked, onClick, className, label, background }) => {
  return (
    <button
      className={[styles.container, className || ""].join(" ")}
      type="button"
      onClick={onClick}
    >
      <div
        className={styles.checkbox}
        style={{ background, borderColor: background }}
      >
        <input type="checkbox" checked={checked} value={checked} readOnly />
        {checked && <img src={tickImg} alt="" />}
      </div>
      {label && <span>{label}</span>}
    </button>
  );
};

Checkbox.defaultProps = {
  background: null,
  className: "",
  label: "",
  onClick: () => null,
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  background: PropTypes.string,
};

export default Checkbox;
