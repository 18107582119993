import { createSelector } from "reselect";

import dataRequestFactories from "state/global/factories/data_request_factories";
import siblingSelectors from ".";

const sendToHubspotColumnRequestSelectors = {
  ...dataRequestFactories.generateStatusSelectors(
    state =>
      siblingSelectors.selectSendToHubspotColumnRequest(state)
        .sendToHubspotColumn,
  ),
};

export { sendToHubspotColumnRequestSelectors };
