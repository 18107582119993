import { getPagination } from "utilities";
import {
  SUCCESS,
  REQUEST,
  FAILURE,
} from "state/local//action_types/statistics/statistics_list_action_types";

const statisticsListReducer = (state, { type, payload, error }) => {
  switch (type) {
    case REQUEST:
      return { ...state, fetching: true };
    case SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        properties: payload.results,
        pagination: getPagination(payload),
      };
    case FAILURE:
      return {
        ...state,
        fetching: false,
        error: { error },
      };
    default:
      throw new Error("Unexpected action {{" + type + "}}");
  }
};

export default statisticsListReducer;
