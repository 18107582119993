import React from "react";
import { QueryRenderer } from "react-relay";
import PropTypes from "prop-types";
import Environment from "./environment";

/**
 * Component takes graphql query and returns function with payload
 * and loading status.
 * Usage:
 * ```javascript
 * <RelayRenderer query={<< query here >>} variables={<< variables here >>}>
 * {(payload, loading) => (
 *   <div>...</div>
 * )}
 * </RelayRenderer>
 * ```
 * @param {object} param0
 */
const RelayRenderer = ({ query, variables, children, cacheConfig }) =>
  query ? (
    <QueryRenderer
      environment={Environment}
      query={query}
      variables={variables}
      cacheConfig={cacheConfig}
      render={({ error, props }) => {
        if (error) console.error(error);
        const loading = !error && !props;
        if (error) {
          return <span>{error.toString()}</span>;
        }
        return children(props, loading);
      }}
    />
  ) : null; // prevent sending undefined up the component tree (warn via props)

RelayRenderer.defaultProps = {
  variables: {},
  cacheConfig: null,
};

RelayRenderer.propTypes = {
  query: PropTypes.func.isRequired,
  variables: PropTypes.shape(),
  cacheConfig: PropTypes.shape(),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default RelayRenderer;
