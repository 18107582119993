const deserializeLongletRentals = serializedLongletRentals =>
  serializedLongletRentals.map(longletRental => {
    const {
      rental,
      comparable_match_quality,
      bathrooms,
      driveway,
      garden_or_patio,
      new_build,
      newly_refurbished,
      recepts,
    } = longletRental;

    return {
      ...rental,
      longletHullListingsStatistics: {
        ...rental.longletHullListingsStatistics,
        imageUrls: rental.image_urls,
        average12MonthIncome: rental.revenue,
      },
      comparable_match_quality,
      comps: {
        bathrooms,
        driveway,
        garden_or_patio,
        new_build,
        newly_refurbished,
        recepts,
      },
    };
  });

export default deserializeLongletRentals;
