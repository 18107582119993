import { isPlainObject } from "lodash";
import { capitalize } from "utilities";

const formatQueryParam = (prefix, name) =>
  prefix ? `${prefix}${capitalize(name)}` : name;

const shouldSetMinAndMax = newParamValues => {
  // check if new params are range values i.e min/max or leftBand/rightBand
  return isPlainObject(newParamValues);
};

const constructUpdatedQueryParams = ({
  queryParams,
  filterName,
  newFilterState,
}) => {
  let updatedQueryParams;

  const { paramPrefix } = queryParams;
  const formattedQueryParam = formatQueryParam(paramPrefix, filterName);
  const newParamValues = newFilterState[filterName];

  if (shouldSetMinAndMax(newParamValues)) {
    updatedQueryParams = {
      [`${formattedQueryParam}Min`]:
        newParamValues.leftBand ?? newParamValues.min,
      [`${formattedQueryParam}Max`]:
        newParamValues.rightBand ?? newParamValues.max,
    };
  } else {
    updatedQueryParams = { [formattedQueryParam]: newParamValues };
  }

  return updatedQueryParams;
};

const queryStringUpdateCallback = (
  globalState,
  filtersSelectors,
  filterName,
  updateQueryStringCallback,
  queryParamPrefix,
) => {
  const newFilterState = filtersSelectors.selectCurrent(globalState);
  const updatedQueryParams = constructUpdatedQueryParams({
    queryParams: { paramPrefix: queryParamPrefix },
    filterName,
    newFilterState,
  });

  updateQueryStringCallback(updatedQueryParams);
};

export default queryStringUpdateCallback;
