import {
  hullStatisticsQuery as shortletHullStatisticsQuery,
  listingStatisticsQuery as shortletListingStatisticsQuery,
  amenityStatisticsQuery as shortletAmenityStatisticsQuery,
} from "./shortlet_queries";

import {
  hullStatisticsQuery as longletHullStatisticsQuery,
  listingStatisticsQuery as longletListingStatisticsQuery,
} from "./longlet_queries";

import { hubspotPushedSaleListingsQuery } from "./sale_listing_queries";

export default {
  shortlet: {
    shortletHullStatisticsQuery,
    shortletListingStatisticsQuery,
    shortletAmenityStatisticsQuery,
  },
  longlet: { longletHullStatisticsQuery, longletListingStatisticsQuery },
  hubspotPushedSaleListingsQuery,
};
