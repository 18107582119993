import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { Link } from "react-router-dom";
import loginBackground from "assets/images/login.jpg";
import { InputField, Button } from "components";
import logo from "assets/images/skwire-Logo-2.2.svg";
import { getToken } from "api/authentication";
import { tokenStorageField } from "configuration";
import styles from "./login_panel.module.css";

/**
 * Sends mutation to graphql
 * @param {string} username
 * @param {string} password
 */

const initialValues = { username: "", password: "" };

const validate = values => {
  const errors = {};
  if (!values.username) {
    errors.username = "Username is required";
  }
  if (!values.password) {
    errors.password = "Password is required";
  }
  return errors;
};

const LoginPanel = ({ history }) => {
  React.useEffect(() => {
    /**
     * removes token on mount, so redirect to /login will cause logout
     */
    localStorage.removeItem(tokenStorageField);
  }, []);

  async function handleLogin(values, { setErrors, setSubmitting }) {
    const [payload, error] = await getToken(values);
    if (error) {
      setErrors({
        message:
          error.detail ||
          "Error occurred, please report this to administration",
      });
      setSubmitting(false);
    } else {
      localStorage.setItem(tokenStorageField, payload.access);
      localStorage.setItem("refreshToken", payload.refresh);
      history.push("/");
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <img className={styles.background} src={loginBackground} alt="" />
        <img className={styles.logo} src={logo} alt="skwire logo" />
      </div>
      <div className={styles.panel}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleLogin}
          validate={validate}
        >
          {({ errors, isSubmitting }) => (
            <Form className={styles.form}>
              <img className={styles.logo} src={logo} alt="skwire logo" />
              <div className={styles.title}>Great to see you!</div>
              <div className={styles.error}>{errors.message}</div>
              <div>
                <Field
                  component={InputField}
                  name="username"
                  placeholder="Username"
                  noLabel
                />
                <Field
                  component={InputField}
                  name="password"
                  placeholder="Password"
                  type="password"
                  noLabel
                />
                <div className={styles.buttons}>
                  <Button
                    btnType="submit"
                    type="primary"
                    full
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Login
                  </Button>
                </div>
                <div className={styles.links}>
                  <Link to="/reset-password">Forgot Password?</Link>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

LoginPanel.defaultProps = {
  errors: null,
};

LoginPanel.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  errors: PropTypes.shape({
    password: PropTypes.string,
    message: PropTypes.string,
    username: PropTypes.string,
  }),
};

export default LoginPanel;
