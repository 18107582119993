import React from "react";
import PropTypes from "prop-types";

import { Segment, CollapseList } from "components";
import { roundNumber } from "utilities";

import styles from "../listing_details.module.css";

const yearsFormater = year => {
  const yearName = Object.keys(year);
  return (
    <div className={styles.yearElement}>
      <span>{`${yearName}:`}</span>
      {year[yearName]}
    </div>
  );
};

const SeasonStatistics = ({ data }) => {
  return (
    <div>
      <Segment
        title="Average number of reservations"
        text={roundNumber(data.avgNumberOfReservations)}
        info="The number of reservations the property got in the last 12 months."
      />
      <CollapseList
        title="Show all years"
        list={data.numberOfReservations.yearsList}
        formatter={yearsFormater}
        hide
      />
      <Segment
        title="Average reservation days"
        text={roundNumber(data.avgReservationDays)}
        info="The number of days the property was reserved in the last 12 months."
      />
      <CollapseList
        title="Show all years"
        list={data.reservationDays.yearsList}
        formatter={yearsFormater}
        hide
      />
      <Segment
        title="Average blocked days"
        text={roundNumber(data.avgBlockedDays)}
        info="The number of days the property was blocked in the last 12 months."
      />
      <CollapseList
        title="Show all years"
        list={data.blockedDays.yearsList}
        formatter={yearsFormater}
        hide
      />
      <Segment
        title="Average available days"
        text={roundNumber(data.avgAvailableDays)}
        info="The number of days the property was available on the market but not booked in the
                    last 12 months."
      />
      <CollapseList
        title="Show all years"
        list={data.availableDays.yearsList}
        formatter={yearsFormater}
        hide
      />
    </div>
  );
};

SeasonStatistics.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default SeasonStatistics;
