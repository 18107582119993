/**
 * @flow
 * @relayHash 753b6f14b497ccdadde58699156354dc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type shortletQueriesListingStatisticsQueryVariables = {|
  id: string
|};
export type shortletQueriesListingStatisticsQueryResponse = {|
  +hullListingsStatistics: ?{|
    +hullListing: {|
      +postcode: ?string,
      +point: any,
      +airbnbId: number,
      +bedrooms: ?number,
      +imageUrl: ?string,
      +numSleeps: ?number,
    |},
    +all12MonthIncomes: ?$ReadOnlyArray<?number>,
    +average12MonthIncome: ?number,
    +maxPrice12: ?number,
    +maxPrice15: ?number,
    +maxPrice18: ?number,
    +numberOfReservations: ?any,
    +reservationDays: ?any,
    +blockedDays: ?any,
    +reviewToReservationRatio: ?number,
    +availableDays: ?any,
    +avgBlockedDays: ?number,
    +avgAvailableDays: ?number,
    +avgReservationDays: ?number,
    +avgNumberOfReservations: ?number,
  |}
|};
export type shortletQueriesListingStatisticsQuery = {|
  variables: shortletQueriesListingStatisticsQueryVariables,
  response: shortletQueriesListingStatisticsQueryResponse,
|};
*/


/*
query shortletQueriesListingStatisticsQuery(
  $id: ID!
) {
  hullListingsStatistics(id: $id) {
    hullListing {
      postcode
      point
      airbnbId
      bedrooms
      imageUrl
      numSleeps
      id
    }
    all12MonthIncomes
    average12MonthIncome
    maxPrice12
    maxPrice15
    maxPrice18
    numberOfReservations
    reservationDays
    blockedDays
    reviewToReservationRatio
    availableDays
    avgBlockedDays
    avgAvailableDays
    avgReservationDays
    avgNumberOfReservations
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "postcode",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "point",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "airbnbId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "bedrooms",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "imageUrl",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "numSleeps",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "all12MonthIncomes",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "average12MonthIncome",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "maxPrice12",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "maxPrice15",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "maxPrice18",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "numberOfReservations",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reservationDays",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "blockedDays",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reviewToReservationRatio",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "availableDays",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avgBlockedDays",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avgAvailableDays",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avgReservationDays",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avgNumberOfReservations",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "shortletQueriesListingStatisticsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hullListingsStatistics",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "HullListingStatisticNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "hullListing",
            "storageKey": null,
            "args": null,
            "concreteType": "HullListingNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ]
          },
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "shortletQueriesListingStatisticsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hullListingsStatistics",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "HullListingStatisticNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "hullListing",
            "storageKey": null,
            "args": null,
            "concreteType": "HullListingNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v22/*: any*/)
            ]
          },
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "shortletQueriesListingStatisticsQuery",
    "id": null,
    "text": "query shortletQueriesListingStatisticsQuery(\n  $id: ID!\n) {\n  hullListingsStatistics(id: $id) {\n    hullListing {\n      postcode\n      point\n      airbnbId\n      bedrooms\n      imageUrl\n      numSleeps\n      id\n    }\n    all12MonthIncomes\n    average12MonthIncome\n    maxPrice12\n    maxPrice15\n    maxPrice18\n    numberOfReservations\n    reservationDays\n    blockedDays\n    reviewToReservationRatio\n    availableDays\n    avgBlockedDays\n    avgAvailableDays\n    avgReservationDays\n    avgNumberOfReservations\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0aed9d7e2dbb35ddd990ba0d623b993d';
module.exports = node;
