import fetch from "./middleware/api_middleware";
import { refreshTokenMiddleware } from "./middleware/refresh_token_middleware";

const fetchUsingRefreshTokenMiddleware = refreshTokenMiddleware(fetch);

export const getPropertiesList = (queryParams = "") =>
  fetchUsingRefreshTokenMiddleware({
    method: "GET",
    url: `/shortlet/statistics/${queryParams}`,
  });

export const getPropertyStatistics = propertyId =>
  fetchUsingRefreshTokenMiddleware({
    method: "GET",
    url: `/shortlet/statistics/${propertyId}/`,
  });

export const getPropertyStatisticsUserPrices = propertyId =>
  fetchUsingRefreshTokenMiddleware({
    method: "GET",
    url: `/shortlet/statistics/pricing-algorithm-estimates/${propertyId}/`,
  });

export const postPropertyStatisticUserPrice = (propertyId, data) =>
  fetchUsingRefreshTokenMiddleware({
    method: "POST",
    url: `/shortlet/statistics/pricing-algorithm-estimates/${propertyId}/`,
    data,
  });
