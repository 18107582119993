import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

import { routes } from "routing_configuration";

import styles from "./top_panel.module.css";

function checkIsActiveLink(link, isExact, location) {
  let className = "";

  if (!isExact && location.pathname.includes(link)) {
    className = styles.activeLink;
  }

  if (isExact && location.pathname === link) {
    className = styles.activeLink;
  }

  return className;
}

const NavbarRoutesMenu = ({ isMenuOpen, children }) => {
  return (
    <div
      className={`navbar-collapse collapse ${styles.menu} ${
        isMenuOpen ? "show" : ""
      }`}
      id="navbarToggleExternalContent"
    >
      {children}
    </div>
  );
};

const RoutesAsLinks = () => {
  const location = useLocation();

  return routes
    .filter(route => route.name)
    .map(route => (
      <div
        key={route.name}
        className={`${styles.link} ${checkIsActiveLink(
          route.path,
          route.path === "/",
          location,
        )}`}
      >
        <Link to={route.path}>{route.name}</Link>
      </div>
    ));
};

const LinksSection = ({ isMenuOpen }) => {
  return (
    <NavbarRoutesMenu isMenuOpen={isMenuOpen}>
      <RoutesAsLinks />
    </NavbarRoutesMenu>
  );
};

NavbarRoutesMenu.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  children: PropTypes.shape().isRequired,
};
LinksSection.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};

export default LinksSection;
