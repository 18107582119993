import React from "react";
import PropTypes from "prop-types";

import { Switch } from "components/";
import useQuery from "components/hooks/use_query";
import camelCase from "lodash/camelCase";
import propertyHullsDisabledSelectors from "state/global/selectors/user_input/map/filters/property_hulls_disabled_selectors";
import handlePropertyHullFiltering from "../../../filter_handlers/property_hulls_filter";

const HullsVisibilitySwitch = ({
  propertyHullsDisabledFiltersCurrent,
  disabled,
  propertyHullsDisabledFiltersActions,
  mapType,
}) => {
  const { updateQueryString } = useQuery();

  return (
    <Switch
      value={
        !propertyHullsDisabledFiltersCurrent.propertyHullsDisabled.includes(
          camelCase(mapType),
        ) && !disabled
      }
      onChange={() =>
        handlePropertyHullFiltering(
          updateQueryString,
          propertyHullsDisabledFiltersActions,
          propertyHullsDisabledSelectors,
          "propertyHullsDisabled",
          camelCase(mapType),
        )
      }
      label="Show property hulls"
    />
  );
};

HullsVisibilitySwitch.propTypes = {
  disabled: PropTypes.bool.isRequired,
  propertyHullsDisabledFiltersActions: PropTypes.shape().isRequired,
  propertyHullsDisabledFiltersCurrent: PropTypes.shape({
    propertyHullsDisabled: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  mapType: PropTypes.string.isRequired,
};

export default HullsVisibilitySwitch;
