/* eslint-disable no-shadow */
import React from "react";
import PropTypes from "prop-types";

import { LongletListingPanel } from "pages/map/left_panel/listings_panel";
import { LongletHullsPanel } from "pages/map/left_panel/hulls_panel";

import styles from "pages/map/left_panel/map_type_tabs/index.module.css";

const LongletTab = ({ tabDisabled }) => {
  const [tab, setTab] = React.useState(1);

  return (
    <div
      className={`${styles.container} ${
        tabDisabled ? styles["panel-disabled"] : ""
      }`}
    >
      <div className={styles.tabs}>
        <button
          type="button"
          disabled={tabDisabled}
          className={getTabClassName(1, tab, tabDisabled)}
          onClick={() => setTab(1)}
        >
          Listings
        </button>
        <button
          type="button"
          disabled={tabDisabled}
          className={getTabClassName(2, tab, tabDisabled)}
          onClick={() => setTab(2)}
        >
          Hulls
        </button>
      </div>

      <div className={styles.items}>
        <div className={styles.spacer} />
      </div>

      <div className={styles.content}>
        {tab === 1 && <LongletListingPanel disabled={tabDisabled} />}
        {tab === 2 && <LongletHullsPanel disabled={tabDisabled} />}
      </div>
    </div>
  );
};

LongletTab.propTypes = {
  tabDisabled: PropTypes.bool.isRequired,
};

const getTabClassName = (value, tab, tabDisabled) => {
  if (value === tab && !tabDisabled) {
    return styles.active;
  }
  if (value === tab && tabDisabled) {
    return styles.selected;
  }
  return "";
};

export default LongletTab;
