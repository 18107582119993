import { createReducer } from "@reduxjs/toolkit";

const generateStatusActionTypes = actionTypePrefix => {
  return {
    [`${actionTypePrefix}_ACTION_REQUEST_IN_PROGRESS`]: `${actionTypePrefix}_ACTION_REQUEST_IN_PROGRESS`,
    [`${actionTypePrefix}_ACTION_REQUEST_SUCCESSFUL`]: `${actionTypePrefix}_ACTION_REQUEST_SUCCESSFUL`,
    [`${actionTypePrefix}_ACTION_REQUEST_FAILED`]: `${actionTypePrefix}_ACTION_REQUEST_FAILED`,
    [`${actionTypePrefix}_RESET_REQUEST_STATUS`]: `${actionTypePrefix}_RESET_REQUEST_STATUS`,
  };
};

const generateStatusActions = (actionTypes, actionTypePrefix) => {
  const actions = {
    requestInProgress: () => {
      return (dispatch, getState) => {
        const actionObject = {
          type: actionTypes[`${actionTypePrefix}_ACTION_REQUEST_IN_PROGRESS`],
        };

        dispatch(actionObject);

        return Promise.resolve(getState());
      };
    },
    requestSuccess: responseData => {
      return (dispatch, getState) => {
        const actionObject = {
          type: actionTypes[`${actionTypePrefix}_ACTION_REQUEST_SUCCESSFUL`],
          payload: {
            responseData,
          },
        };

        dispatch(actionObject);

        return Promise.resolve(getState());
      };
    },
    requestCancelled: (error, responseData = null) => {
      return (dispatch, getState) => {
        const actionObject = {
          type: actionTypes[`${actionTypePrefix}_ACTION_REQUEST_CANCELLED`],
          payload: {
            error,
            responseData,
          },
        };

        dispatch(actionObject);

        return Promise.resolve(getState());
      };
    },
    requestFailure: (error, responseData = null) => {
      return (dispatch, getState) => {
        const actionObject = {
          type: actionTypes[`${actionTypePrefix}_ACTION_REQUEST_FAILED`],
          payload: {
            error,
            responseData,
          },
        };

        dispatch(actionObject);

        return Promise.resolve(getState());
      };
    },
    resetRequestStatus: () => (dispatch, getState) => {
      const actionObject = {
        type: actionTypes[`${actionTypePrefix}_RESET_REQUEST_STATUS`],
      };

      dispatch(actionObject);

      return Promise.resolve(getState());
    },
  };

  return actions;
};

const generateStatusReducer = (actionTypes, actionTypePrefix) => {
  const initialState = {
    requestInProgress: false,
    requestCancelled: false,
    requestFailed: false,
    requestSuccessful: false,
    responseData: null,
    error: null,
  };

  const reducer = createReducer(initialState, {
    [actionTypes[`${actionTypePrefix}_ACTION_REQUEST_IN_PROGRESS`]]: (
      draftState,
      action,
    ) => ({
      ...initialState,
      requestInProgress: true,
    }),
    [actionTypes[`${actionTypePrefix}_ACTION_REQUEST_SUCCESSFUL`]]: (
      draftState,
      action,
    ) => {
      return {
        ...initialState,
        requestSuccessful: true,
        responseData: action.payload.responseData,
      };
    },
    [actionTypes[`${actionTypePrefix}_ACTION_REQUEST_CANCELLED`]]: (
      draftState,
      action,
    ) => {
      return {
        ...initialState,
        requestCancelled: true,
        responseData: action.payload.responseData,
        error: action.payload.error,
      };
    },
    [actionTypes[`${actionTypePrefix}_ACTION_REQUEST_FAILED`]]: (
      draftState,
      action,
    ) => {
      return {
        ...initialState,
        requestFailed: true,
        responseData: action.payload.responseData,
        error: action.payload.error,
      };
    },
    [actionTypes[`${actionTypePrefix}_RESET_REQUEST_STATUS`]]: (
      draftState,
      action,
    ) => ({ ...initialState }),
  });

  return reducer;
};

const generateStatusSelectors = requestStateSelector => {
  const selectors = {
    selectAll: state => requestStateSelector(state),
    selectRequestInProgressFlag: state =>
      requestStateSelector(state).requestInProgress,
    selectRequestFailedFlag: state => requestStateSelector(state).requestFailed,
    selectRequestSuccessfulFlag: state =>
      requestStateSelector(state).requestSuccessful,
    selectResponseData: state => requestStateSelector(state).responseData,
    selectError: state => requestStateSelector(state).error,
  };

  return selectors;
};

export default {
  generateStatusActionTypes,
  generateStatusActions,
  generateStatusReducer,
  generateStatusSelectors,
};
