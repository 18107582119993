import React from "react";
import PropTypes from "prop-types";
import Input from "../input/input";
import Switch from "../switch/switch";
import Checkbox from "../checkbox/checkbox";

const InputField = ({
  field,
  form,
  type = "text",
  autoComplete = "off",
  value,
  onChange,
  multiple,
  ...rest
}) => {
  const { touched, errors, setFieldValue, values } = form;
  function checkboxAdd() {
    if (
      Array.isArray(values[field.name]) &&
      !values[field.name].includes(field.value)
    ) {
      setFieldValue(field.name, [...values[field.name], field.value]);
    } else if (Array.isArray(values[field.name])) {
      const newValues = values[field.name].filter(
        el => JSON.stringify(el) !== JSON.stringify(field.value),
      );
      setFieldValue(field.name, [...newValues]);
    } else {
      setFieldValue(field.name, []);
    }
    if (onChange) onChange();
  }

  const handleChange = multiple
    ? checkboxAdd
    : () => setFieldValue(field.name, !values[field.name]);

  const checked = (() => {
    if (multiple) {
      return field && Array.isArray(values[field.name])
        ? values[field.name].includes(field.value)
        : false;
    }
    return values[field.name] === true;
  })();

  if (type === "switch")
    return <Switch {...rest} field={field} touched={touched} errors={errors} />;
  if (type === "checkbox")
    return (
      <Checkbox
        {...rest}
        field={field}
        onClick={handleChange}
        checked={checked}
      />
    );

  if (type === "switch")
    return <Switch {...rest} field={field} touched={touched} errors={errors} />;

  return (
    <Input
      {...rest}
      type={type}
      autoComplete={autoComplete}
      field={field}
      onChange={onChange || field.onChange}
      setFieldValue={form.setFieldValue}
      touched={touched}
      errors={errors}
    />
  );
};

InputField.defaultProps = {
  field: undefined,
  form: {},
  onChange: undefined,
  type: undefined,
  autoComplete: "off",
  value: undefined,
  multiple: undefined,
};

InputField.propTypes = {
  form: PropTypes.shape(),
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  field: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
    name: PropTypes.string,
    onChange: PropTypes.func,
  }),
  multiple: PropTypes.bool,
};

export default InputField;
