import React from "react";
import { useDispatch } from "react-redux";
import { tokenStorageField } from "configuration";
import { actions as currentSessionActions } from "state/global/actions/user_session/current_session_actions";

const LoginPanel = ({ history }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    localStorage.removeItem(tokenStorageField);
    dispatch(currentSessionActions.resetLoginStore());
    history.push("/login");
  }, []);
  return null;
};

export default LoginPanel;
