/* eslint-disable no-shadow */
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import listingConfigs from "configs/listing_configs";

import { actions as mapContentViewActions } from "state/global/actions/ui_control/map/map_content_view_actions";
import salesDealsListingsSelectors from "state/global/selectors/data_collections/map/sales/deals_listings_selectors";
import salesClustersSelectors from "state/global/selectors/data_collections/map/sales/sales_clusters_selectors";
import salesDealStagesSelectors from "state/global/selectors/data_collections/map/sales/sales_deal_stages_selectors";
import mapContentViewSelectors from "state/global/selectors/ui_control/map/map_content_view_selectors";
import listingsDisabledFiltersSelectors from "state/global/selectors/user_input/map/filters/listings_disabled_selectors";
import salesDealsListingFiltersSelectors from "state/global/selectors/user_input/map/filters/sales_deals_filter_selectors";
import amenityHullFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/amenity_hull_filters_selectors";
import propertyHullFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/property_hull_filters_selectors";
import useMapContentLogic from "../hooks/use_map_content_logic";
import VisibleChips from "../visible_chips";

const SalesDealsComponents = ({
  // ### params
  enabled,
  showListings,
  listings,
  mapContentViewState,
  propertyHullFiltersCurrent,
  amenityHullFiltersCurrent,
  listingsDisabledFiltersCurrent,
  clustersInfo,
  mapContentViewActions,
  hubspotPipelinesToDealStagesMapping,
}) => {
  // ### mapStateToProps // ### mapDispatchToProps
  const {
    createParameterizedWrapper,
    checkIfSelectedListing,
  } = useMapContentLogic({
    mapContentViewState,
    propertyHullFiltersCurrent,
    amenityHullFiltersCurrent,
  });

  const currentListingType =
    listingConfigs.sharedConfig.listingTypes.hubspotPushedSaleListing.type;

  const checkIfListingVisible = listing => true;

  const handleSelectListing = listing => {
    mapContentViewActions.deselectAll();
    mapContentViewActions.selectListing(listing.id, currentListingType);
  };

  return (
    enabled && (
      <>
        <VisibleChips
          showListings={showListings}
          listings={listings}
          clustersInfo={clustersInfo}
          getPosition={getPosition}
          handleSelectListing={handleSelectListing}
          listingsDisabled={listingsDisabledFiltersCurrent.listingsDisabled.includes(
            "sales",
          )}
          hubspotPipelinesToDealStagesMapping={
            hubspotPipelinesToDealStagesMapping
          }
          isListingVisible={checkIfListingVisible}
          isSelectedListing={createParameterizedWrapper(
            checkIfSelectedListing,
            currentListingType,
          )}
          currentListingType={currentListingType}
        />
      </>
    )
  );
};

SalesDealsComponents.propTypes = {
  enabled: PropTypes.bool.isRequired,
  showListings: PropTypes.bool.isRequired,
};

const getPosition = ({ point: { coordinates } }) => ({
  lat: coordinates[1],
  lng: coordinates[0],
});

const mapStateToProps = (state, props) => ({
  listings: salesDealsListingsSelectors.selectAll(state),

  mapContentViewState: mapContentViewSelectors.selectAll(state),
  propertyHullFiltersCurrent: propertyHullFiltersSelectors.selectCurrent(state),
  amenityHullFiltersCurrent: amenityHullFiltersSelectors.selectCurrent(state),

  salesDealsListingFiltersCurrent: salesDealsListingFiltersSelectors.selectCurrent(
    state,
  ),
  listingsDisabledFiltersCurrent: listingsDisabledFiltersSelectors.selectCurrent(
    state,
  ),
  clustersInfo: salesClustersSelectors.selectAll(state),
  hubspotPipelinesToDealStagesMapping: salesDealStagesSelectors.selectAllByKey(
    state,
  ),
});

const mapDispatchToProps = dispatch => ({
  mapContentViewActions: bindActionCreators(mapContentViewActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SalesDealsComponents);
