/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import { actions } from "state/global/actions/data_collections/map/sales/sales_deals_decline_reasons_actions";
import { fetchSalesDealsDeclineReasons } from "api/fetchers/sales/sales_deals_decline_reasons";
import salesDealsDeclineReasonsSelectors from "state/global/selectors/data_collections/map/sales/sales_deals_decline_reasons_selectors";
import DeclineReasonsSelect from "./decline_reasons_select";

import styles from "../../send_to_hubspot_column_modal/index.module.css";
import decline_styles from "../index.module.css";

const DeclineSalesDealModalContent = ({
  columnName,
  salesDealsDeclineReasonsActions,
  handleClose,
  handleOnSend,
  reasons,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [fetched, setFetched] = React.useState(false);
  const [declineReason, setDeclineReason] = React.useState("Not Available");

  const fetchDeclineReasons = () => {
    setIsLoading(true);
    setTimeout(() => {
      fetchSalesDealsDeclineReasons(salesDealsDeclineReasonsActions);
      setIsLoading(false);
      setFetched(true);
    }, 1000);
  };

  React.useEffect(() => {
    fetchDeclineReasons();
  }, []);

  return (
    <>
      <span className={styles.close} onClick={handleClose}>
        x
      </span>
      <div className={decline_styles.title}>
        Send to HubSpot&apos;s &apos;
        {columnName}
        &apos; column?
      </div>
      <DeclineReasonsSelect
        {...{
          reasons,
          fetched,
          isLoading,
          declineReason,
          setDeclineReason,
          onFetch: fetchDeclineReasons,
        }}
      />
      <button
        type="button"
        onClick={() => handleOnSend(declineReason)}
        className={styles["send-button"]}
        disabled={isLoading || !fetched}
      >
        Send
      </button>
    </>
  );
};

DeclineSalesDealModalContent.propTypes = {
  columnName: PropTypes.string.isRequired,
  handleOnSend: PropTypes.func.isRequired,
  salesDealsDeclineReasonsActions: PropTypes.shape({
    setRecords: PropTypes.func.isRequired,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
    }),
  ).isRequired,
};

const mapStateToProps = (state, props) => ({
  reasons: salesDealsDeclineReasonsSelectors.selectAll(state),
});

const mapDispatchToProps = dispatch => ({
  salesDealsDeclineReasonsActions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeclineSalesDealModalContent);
