import React from "react";
import PropTypes from "prop-types";
import RelayRenderer from "relay/relay_renderer";

import { priceFormatter } from "utilities";
import { withMapContextStore } from "state/local/stores/contexts/map_context_store";
import { Loader, Segment, FetchingError } from "components";
import queries from "../queries/index";

import styles from "./amenity_details.module.css";

const AmenityDetailsPanel = ({ id }) => {
  const {
    shortlet: { shortletAmenityStatisticsQuery },
  } = queries;

  return (
    <RelayRenderer query={shortletAmenityStatisticsQuery} variables={{ id }}>
      {(payload, loading) => {
        const data = payload && payload.amenityHullStatistic;

        return (
          <>
            {loading && <Loader type="primary" size="big" absolute />}
            {!loading && data && (
              <div className={styles.body}>
                <div className={styles.title}>Details</div>
                <Segment
                  title="Name"
                  text={data.amenityHull.amenity.split("_").join(" ")}
                />
                <Segment title="City" text={data.amenityHull.city} />
                <Segment
                  title="Income band"
                  text={data.amenityHull.incomeBand}
                />
                <div className={styles.title}>Statistics</div>
                <Segment
                  title="Average income"
                  text={`£${priceFormatter(data.averageIncome)}`}
                  info="The average of all 12 month incomes across all the properties present in the given Amenity Hull."
                />
                <Segment
                  title="Properties number"
                  text={data.numberProperties}
                  info="The number of properties present in the given Amenity Hull."
                />
              </div>
            )}
            {!loading && !data && <FetchingError />}
          </>
        );
      }}
    </RelayRenderer>
  );
};

AmenityDetailsPanel.defaultProps = {
  id: null,
};
AmenityDetailsPanel.propTypes = {
  id: PropTypes.string,
};

export default withMapContextStore(AmenityDetailsPanel);
