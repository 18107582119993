import React from "react";
import PropTypes from "prop-types";
import styles from "./loader.module.css";

const Loader = ({ type, className, size, opacity }) => {
  const sizeStyle = {
    tiny: styles.tiny,
    small: styles.small,
    medium: styles.medium,
    big: styles.big,
  }[size];
  const typeStyle = {
    primary: styles.primary,
    secondary: styles.secondary,
    default: styles.default,
  }[type];

  const loaderProps = {
    className: [styles.circle, sizeStyle, typeStyle, className].join(" "),
    style: { opacity },
  };

  return (
    <div className={styles.frame}>
      <div {...loaderProps}>
        {new Array(12).fill(0).map((el, index) => (
          <div
            key={Math.random()}
            className={[styles[`circle${index + 1}`], styles.child].join(" ")}
          />
        ))}
      </div>
    </div>
  );
};

Loader.defaultProps = {
  size: "medium",
  type: "default",
  className: "",
  opacity: 1,
};

Loader.propTypes = {
  size: PropTypes.oneOf(["tiny", "small", "medium", "big"]),
  type: PropTypes.oneOf(["primary", "secondary", "default"]),
  className: PropTypes.string,
  opacity: PropTypes.number,
};

export default Loader;
