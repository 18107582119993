import { createReducer } from "@reduxjs/toolkit";

import { actionTypes } from "state/global/actions/user_session/current_session_actions";

const initialState = {
  userData: null,
};

const userReducer = createReducer(initialState, {
  [actionTypes.LOGIN_SUCCESS]: (draftState, action) => ({
    ...draftState,
    userData: action.payload.userData,
  }),
  [actionTypes.RESET_SESSION]: () => initialState,
});

export default userReducer;
