/* eslint-disable */
export const fundApiRoot = {
  development: "https://airbnb-fund-api.skwire.co.uk",
  test: "http://localhost:8000",
  production: "https://airbnb-fund-api.skwire.co.uk",
};

const appEnv = process.env.REACT_APP_ENV || Cypress.env("REACT_APP_ENV");

export const fundApiPaths = {
  restEndpoint: fundApiRoot[appEnv],
  graphqlEndpoint: fundApiRoot[appEnv] + "/graphql",
};
