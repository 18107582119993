import { createSelector } from "reselect";

import parentSelectors from "..";

const selectors = {
  selectAmenityTypes: state => parentSelectors.selectMap(state).amenityTypes,
  selectShortletListings: state =>
    parentSelectors.selectMap(state).shortletListings,
  selectShortletAmenityHulls: state =>
    parentSelectors.selectMap(state).shortletAmenityHulls,
  selectShortletPropertyHulls: state =>
    parentSelectors.selectMap(state).shortletPropertyHulls,
  selectLongletListings: state =>
    parentSelectors.selectMap(state).longletListings,
  selectLongletAmenityHulls: state =>
    parentSelectors.selectMap(state).longletAmenityHulls,
  selectLongletPropertyHulls: state =>
    parentSelectors.selectMap(state).longletPropertyHulls,
  selectSalesDealsListings: state =>
    parentSelectors.selectMap(state).salesDealsListings,
  selectSalesClustersInfo: state =>
    parentSelectors.selectMap(state).salesClustersInfo,
  selectLongletListingsClustersInfo: state =>
    parentSelectors.selectMap(state).longletListingsClustersInfo,
  selectShortletListingsClustersInfo: state =>
    parentSelectors.selectMap(state).shortletListingsClustersInfo,
  selectSalesDealsDeclineReasons: state =>
    parentSelectors.selectMap(state).salesDealsDeclineReasons,
  selectSalesDealStages: stage =>
    parentSelectors.selectMap(stage).hubspotPipelinesDealStages,
};

export default selectors;
