import {
  getShortletListings,
  getAllHulls,
  getAllAmenities,
  getAmenityTypes,
} from "api/map";
import { buildQueryParamsString } from "state/helpers/map_helpers";

import dataRequestFactories from "state/global/factories/data_request_factories";
import mapConfigs from "configs/map_configs";

async function fetchShortletAmenityHulls(
  currentBounds,
  amenityTypes,
  hasMapMoved,
) {
  // const initialState = {
  //   fetching: false,
  //   fetchingAmenityTypes: false,
  //   fetchedAmenityTypes: [],
  //   previousMapBounds: null,
  // };
  // const newAmenities = amenityTypes;
  // if (! (hasMapMoved || newAmenities.length )) return true;
  // requestAmenities(true);
  // const [payload, error, { isCanceled }] = await getAllAmenities(
  //   buildQueryParamsString(
  //     mapConfigs.sharedConfig.pageSizes.amenities,
  //     false,
  //     currentBounds,
  //     amenityTypes,
  //   ),
  // );
  // requestAmenities(false);
  // if (!error && !isCanceled) {
  //   // setAmenityHulls(payload, SHORTLET);
  //   setAmenityHulls(payload);
  //   setFetchedAmenityTypes(amenityTypes);
  //   return true;
  // }
  //
  // console.error(error);
  // return false;
}

const fetchShortletAmenityHullsRequestActions = (() => {
  const actionTypePrefix = "FETCH_SHORTLET_AMENITIES";

  const statusActionTypes = dataRequestFactories.generateStatusActionTypes(
    actionTypePrefix,
  );
  const statusActions = dataRequestFactories.generateStatusActions(
    statusActionTypes,
    actionTypePrefix,
  );

  const actionTypes = {
    ...statusActionTypes,
  };

  const actions = {
    ...statusActions,
    triggerRequest: (currentBounds, amenityTypes) => {
      return (dispatch, getState) => {
        dispatch(statusActions.requestInProgress());

        const requestPromise = getAllAmenities(
          buildQueryParamsString({
            page_size: mapConfigs.sharedConfig.pageSizes.amenities,
            polygons: false,
            currentBounds,
            amenityTypes,
          }),
        );

        return requestPromise
          .then(requestResultData => {
            const [payload, error, { isCanceled }] = requestResultData;

            let resultPromise;

            if (!(error || isCanceled)) {
              dispatch(statusActions.requestSuccess(payload.results));
              resultPromise = Promise.resolve(getState());
            } else if (error) {
              dispatch(statusActions.requestFailure(error, payload));
              resultPromise = Promise.error(error, isCanceled, getState());
            } else if (isCanceled) {
              dispatch(statusActions.requestCancelled(error, payload));
              resultPromise = Promise.error(error, isCanceled, getState());
            } else {
              console.error({ requestResultData });
              // eslint-disable-next-line no-throw-literal
              throw "Something went wrong with request processing";
            }

            return resultPromise;
          })
          .catch(error => {
            dispatch(statusActions.requestFailure(error));
          });
      };
    },
  };

  return {
    actionTypePrefix,
    actionTypes,
    actions,
  };
})();

export { fetchShortletAmenityHullsRequestActions };
