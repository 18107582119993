import React from "react";
import PropTypes from "prop-types";
import { number, object } from "yup";
import { Formik, Form, Field } from "formik";

import { InputField, Button } from "components";
import FormPickedDates from "./dates";

import styles from "./form.module.css";
import "./form_calendar.css";

const PreviousStepButton = ({ setStep, isSubmitting }) => (
  <Button
    btnType="button"
    type="secondary"
    full
    onClick={() => setStep(1)}
    disabled={isSubmitting}
  >
    Back
  </Button>
);

const SubmitButton = ({ isSubmitting }) => (
  <Button
    btnType="submit"
    type="primary"
    full
    disabled={isSubmitting}
    loading={isSubmitting}
  >
    Send
  </Button>
);

const validationSchema = object().shape({
  price: number()
    .min(1, "Price must be greater than 0")
    .typeError("Price must be a number")
    .required("Price is required"),
});

const FormStepTwo = ({ range, handleSubmit, setStep }) => {
  return (
    <div className={styles.form}>
      <Formik onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ errors, isSubmitting }) => (
          <Form className={styles.form}>
            <div className={styles.content}>
              <FormPickedDates range={range} />
              <Field
                component={InputField}
                name="price"
                placeholder="Price for the given dates"
                label="Price"
                error={errors.price}
              />
            </div>
            <div className={styles.buttons}>
              <PreviousStepButton
                isSubmitting={isSubmitting}
                setStep={setStep}
              />
              <SubmitButton isSubmitting={isSubmitting} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

FormStepTwo.propTypes = {
  range: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
};

PreviousStepButton.propTypes = {
  setStep: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

SubmitButton.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
};

export default FormStepTwo;
