const getYearsValues = valuesObject => {
  if (!valuesObject) return { currentYear: null, yearsList: [] };
  const currentYear = new Date().getFullYear();
  const yearsList = Object.keys(valuesObject)
    .sort((a, b) => b - a)
    .map(year => ({
      [year]: valuesObject[year],
    }));

  return {
    currentYear: valuesObject[currentYear] || "--",
    yearsList,
  };
};

const transformShortletListingData = data => {
  if (!data) return data;

  const newData = { ...data };
  newData.numberOfReservations = getYearsValues(
    JSON.parse(data.numberOfReservations),
  );
  newData.reservationDays = getYearsValues(JSON.parse(data.reservationDays));
  newData.blockedDays = getYearsValues(JSON.parse(data.blockedDays));
  newData.availableDays = getYearsValues(JSON.parse(data.availableDays));

  return newData;
};

export default transformShortletListingData;
