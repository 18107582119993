import graphql from "babel-plugin-relay/macro";

export const listingStatisticsQuery = graphql`
  query shortletQueriesListingStatisticsQuery($id: ID!) {
    hullListingsStatistics(id: $id) {
      hullListing {
        postcode
        point
        airbnbId
        bedrooms
        imageUrl
        numSleeps
      }
      all12MonthIncomes
      average12MonthIncome
      maxPrice12
      maxPrice15
      maxPrice18
      numberOfReservations
      reservationDays
      blockedDays
      reviewToReservationRatio
      availableDays
      avgBlockedDays
      avgAvailableDays
      avgReservationDays
      avgNumberOfReservations
    }
  }
`;

export const hullStatisticsQuery = graphql`
  query shortletQueriesHullStatisticsQuery($id: ID!) {
    shortletPropertyHullStatistics(id: $id) {
      id
      propertyHull {
        bedrooms
        propertyType
        city
        incomeBand
      }
      averageIncome
      numberOfProperties
      averageZooplaPrice
      titleKeywordsWithIncomes
      descriptionKeywordsWithIncomes
      previousMonthsAvgSoldPrices
      previousMonthsNumPropertiesSold
    }
  }
`;

export const amenityStatisticsQuery = graphql`
  query shortletQueriesAmenityStatisticsQuery($id: ID!) {
    amenityHullStatistic(id: $id) {
      amenityHull {
        incomeBand
        city
        amenity
      }
      numberProperties
      averageIncome
    }
  }
`;
