import React from "react";
import PropTypes from "prop-types";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { numberFormatter } from "utilities";
import { SliderRail, Handle, Track, Tick } from "./components";
import styles from "./slider.module.css";

const sliderStyle = {
  position: "relative",
  width: "100%",
};

const SliderComponent = ({
  togglePriceFilter,
  values,
  title,
  step,
  mode,
  disabled,
  domain,
  prefix,
  suffix,
  initialValues,
}) => {
  function onChange(val) {
    togglePriceFilter(val);
  }

  return (
    <div className={styles.container} data-testid="slider">
      <div className={styles.label}>
        {title}
        <span>
          {!disabled &&
            `${prefix}${numberFormatter(
              values[0],
            )}${suffix} - ${prefix}${numberFormatter(values[1])}${suffix}`}
        </span>
      </div>
      <div className={styles.slider}>
        <Slider
          mode={mode}
          step={step}
          domain={initialValues}
          rootStyle={sliderStyle}
          onChange={onChange}
          values={values}
        >
          <Rail>
            {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map((handle, index) => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={domain}
                    getHandleProps={getHandleProps}
                    testId={
                      index ? "slider-right-handle" : "slider-left-handle"
                    }
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
          <Ticks count={4}>
            {({ ticks }) => (
              <div className="slider-ticks">
                {ticks.map(tick => (
                  <Tick
                    key={tick.id}
                    tick={{ ...tick, value: numberFormatter(tick.value) }}
                    count={ticks.length}
                  />
                ))}
              </div>
            )}
          </Ticks>
        </Slider>
      </div>
    </div>
  );
};

SliderComponent.defaultProps = {
  title: "",
  prefix: "",
  suffix: "",
  step: 1,
  mode: 2,
  domain: [0, 30000],
  initialValues: [0, 150000],
};

SliderComponent.propTypes = {
  togglePriceFilter: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ).isRequired,
  title: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  step: PropTypes.number,
  mode: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
  domain: PropTypes.arrayOf(PropTypes.number),
  initialValues: PropTypes.arrayOf(PropTypes.number),
};

export default SliderComponent;
