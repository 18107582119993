/* eslint-disable react/destructuring-assignment */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import immer from "immer";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import { isMarkerPointWithinBounds } from "utilities";

import { actions as longletListingFiltersActions } from "state/global/actions/user_input/map/filters/longlet/listing_filters_actions";

import { actions as shortletListingFiltersActions } from "state/global/actions/user_input/map/filters/shortlet/listing_filters_actions";

import { actions as propertyHullFiltersActions } from "state/global/actions/user_input/map/filters/shortlet/property_hull_filters_actions";

import { actions as amenityHullFiltersActions } from "state/global/actions/user_input/map/filters/amenity_hull_filters_actions";

import { actions as mapTypeFiltersActions } from "state/global/actions/user_input/map/filters/map_type_filters_actions";

import shortletListingFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/listing_filters_selectors";

import propertyHullFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/property_hull_filters_selectors";

import amenityHullFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/amenity_hull_filters_selectors";

export const MapContext = React.createContext({});

class MapContextStore extends React.Component {
  query = queryString.parse(this.props.location.search);

  // state = {
  //   // listings: {},
  //   shortletListings: [],
  //   longletListings: [],
  //   hulls: {},
  //   amenities: [], "amenityHulls" THESE ARE AMENITY HULLS WITH POLYGON DATA
  //   amenitiesList: [], "amenityTypes" THESE ARE AMENITY OPTIONS ON THE FILTER
  //   selectedListing: null,
  //   selectedPropertyHull: null,
  //   selectedAmenityHull: null,
  //   highlightedAmenityType: null,
  //   filters: {
  //     narrowing: true,
  //   },
  //   listingFilters: {},
  //   propertyHullFilters: {},
  //   mapType: mapTypes[0],
  // };

  // selectListing = listing => {
  //
  //   const selected = {
  //     selectedListing: null,
  //     selectedAmenityHull: null,
  //     selectedPropertyHull: null,
  //     highlightedAmenityType: null,
  //   };
  //
  //   this.setState(state => {
  //     if (!listing) return selected;
  //     if (state.selectedListing && state.selectedListing.id === listing.id) {
  //       return selected;
  //     }
  //     return {
  //       selectedListing: listing,
  //       selectedAmenityHull: null,
  //       selectedPropertyHull: null,
  //       highlightedAmenityType: null,
  //     };
  //   });
  // };

  // saveListings = ({ edges, pageInfo }) => {
  //   this.setState(() => ({
  //     listings: edges,
  //     hasNextPage: pageInfo.hasNextPage,
  //     cursor: edges[edges.length - 1].cursor,
  //   }));
  // };
  //
  // toggleBedFilter = value => {
  //   this.setState(state => {
  //     if (state.filters.beds.includes(value)) {
  //       return {
  //         filters: {
  //           ...state.filters,
  //           beds: state.filters.beds.filter(val => val !== value),
  //         },
  //       };
  //     }
  //     return {
  //       filters: {
  //         ...state.filters,
  //         beds: [...state.filters.beds, value],
  //       },
  //     };
  //   });
  // };

  // togglePropertyHullFilter = () => {
  //   this.setState(state => ({
  //     filters: {
  //       ...state.filters,
  //       propertyHulls: !state.filters.propertyHulls,
  //     },
  //   }));
  // };
  //
  // togglePriceFilter = price => {
  //   this.setState(state => ({
  //     filters: {
  //       ...state.filters,
  //       price,
  //     },
  //   }));
  // };

  // toggleFilter = (type, filter, value, callback, replaceValue) => {
  //   this.setState(
  //     state =>
  //       immer(state, draft => {
  //         if (replaceValue) {
  //           draft[type][filter] = value;
  //         } else {
  //           this.handleArrayFilter(draft, type, filter, value, state);
  //         }
  //       }),
  //     () => this.handleFilterCallback(type, filter, callback),
  //   );
  // };

  // toggleFilter = (type, filter, value, callback, replaceValue) => {
  //   // this.setState(
  //   //   state =>
  //   //     immer(state, draft => {
  //   //       if (replaceValue) {
  //   //         this.setFilterValue(type, filter, value, callback)
  //   //       } else {
  //   //         this.toggleArrayFilter(type, filter, value, callback)
  //   //       }
  //   //     }),
  //   //   () => this.handleFilterCallback(type, filter, callback),
  //   // );
  //
  // };

  // handleFilterCallback = (type, filter, callback) => {
  //   // eslint-disable-next-line react/destructuring-assignment
  //   if (callback) callback(this.state[type][filter]);
  // };

  // handleArrayFilter = (draft, type, filter, value, state) => {
  //   if (value === null || value === undefined) {
  //     draft[type][filter] = [];
  //   } else if (!state[type][filter].includes(String(value))) {
  //     draft[type][filter] = [...state[type][filter], String(value)];
  //   } else {
  //     draft[type][filter] = state[type][filter].filter(
  //       el => el !== String(value),
  //     );
  //   }
  // };

  // toggleHullFilter = (filter, value, callback, replaceValue) => {
  //   // this.toggleFilter("propertyHullFilters", filter, value, callback, replaceValue);
  //   if (replaceValue) {
  //     this.props.propertyHullFiltersActions.setFilterValue(
  //       filter, value
  //     )
  //   } else {
  //     this.props.propertyHullFiltersActions.toggleCollectionFilter(
  //       filter, value
  //     )
  //   }
  // };

  // toggleListingFilter = (filter, value, replaceValue) => {
  //
  //   if (replaceValue) {
  //     this.props.shortletListingFiltersActions.setFilterValue(
  //       filter, value
  //     )
  //   } else {
  //     this.props.shortletListingFiltersActions.toggleCollectionFilter(
  //       filter, value
  //     )
  //   }
  //
  // };
  //
  // toggleNarrowingFilter = () => {
  //   this.setState(state => ({
  //     filters: {
  //       ...state.filters,
  //       narrowing: !state.filters.narrowing,
  //     },
  //   }));
  // };

  /**
   * function saves the selected propertyHull and calculates its center
   * based on coordinates
   */
  // selectPropertyHull = propertyHull => {
  //   if (!propertyHull) {
  //     this.setState({
  //       selectedAmenityHull: null,
  //       selectedListing: null,
  //       selectedPropertyHull: null,
  //       highlightedAmenityType: null,
  //     });
  //     return;
  //   }
  //   this.setState({
  //     selectedAmenityHull: null,
  //     selectedListing: null,
  //     selectedPropertyHull: propertyHull,
  //   });
  // };

  /**
   * function saves the selected amenityHull and calculates its center
   * based on coordinates
   */

  // selectAmenityHull = amenityHull => {
  //   if (!amenityHull) {
  //     this.setState({
  //       selectedAmenityHull: null,
  //       selectedListing: null,
  //       selectedPropertyHull: null,
  //       highlightedAmenityType: null,
  //     });
  //     return;
  //   }
  //   this.setState({
  //     selectedAmenityHull: amenityHull,
  //     selectedListing: null,
  //     selectedPropertyHull: null,
  //     highlightedAmenityType: null,
  //   });
  // };

  // highlightAmenityType = amenityType => {
  //   if (!amenityType) {
  //     this.setState({
  //       selectedAmenityHull: null,
  //       selectedListing: null,
  //       selectedPropertyHull: null,
  //       highlightedAmenityType: null,
  //     });
  //     return;
  //   }
  //   this.setState({
  //     selectedAmenityHull: null,
  //     selectedListing: null,
  //     selectedPropertyHull: null,
  //     highlightedAmenityType: amenityType,
  //   });
  // };
  //
  // clearSelections = () => {
  //   this.setState({
  //     selectedListing: null,
  //     selectedPropertyHull: null,
  //     selectedAmenityHull: null,
  //   });
  // };
  //
  // clearElements = () => {
  //   this.setState({
  //     shortletListings: {},
  //     longletListings: {},
  //     hulls: {},
  //     amenities: [],
  //     amenityTypes: [],
  //     selectedListing: null,
  //     selectedPropertyHull: null,
  //     selectedAmenityHull: null,
  //   });
  // };

  // setMapType = type => {
  //   this.setState({ mapType: type });
  // };

  // mergeListings = (dataListings, type, bounds,) => {
  //   const { listings } = this.state;
  //   if (dataListings === null) return;
  //   const elements = listings[type] || [];
  //   const listingsIds = elements.map(el => el.id);
  //   this.setState(p => {
  //     const oldListings = p.listings[type] || [];
  //
  //     return {
  //       listings: {
  //         ...p.listings,
  //         [type]: oldListings
  //           .filter(el => (isMarkerPointWithinBounds(el, bounds)))
  //           .concat(
  //             dataListings.results.filter(el => !listingsIds.includes(el.id)),
  //           ),
  //       },
  //     };
  //   });
  // };

  // mergeHulls = (dataHulls, type) => {
  //   if (dataHulls === null) return;
  //
  //   this.setState(previous => ({
  //     hulls: { ...previous.hulls, [type]: dataHulls.results },
  //   }));
  // };

  // setAmenityHulls = dataAmenities => {
  //   if (dataAmenities === null) return;
  //
  //   this.setState({
  //     amenities: dataAmenities.results,
  //   });
  // };

  // replaceHistory = newQuery => {
  //   const { history, location } = this.props;
  //   const query = queryString.parse(location.search);
  //   history.replace({
  //     search: queryString.stringify(
  //       {
  //         ...query,
  //         ...newQuery,
  //       },
  //       { arrayFormat: "comma" },
  //     ),
  //   });
  // };
  //
  // copyFilters = copiedFrom => {
  //   this.setState(state =>
  //     immer(state, draft => {
  //       if (copiedFrom === "hulls") {
  //         const {
  //           propertyHullFilters: { hullBeds, hullTypes },
  //         } = state;
  //         draft.listingFilters.listingBeds = state.propertyHullFilters.hullBeds;
  //         draft.listingFilters.listingTypes = state.propertyHullFilters.hullTypes;
  //         this.replaceHistory({
  //           listingBeds: hullBeds,
  //           listingTypes: hullTypes,
  //         });
  //       } else {
  //         const {
  //           listingFilters: { listingBeds, listingTypes },
  //         } = state;
  //         draft.propertyHullFilters.hullBeds = state.listingFilters.listingBeds;
  //         draft.propertyHullFilters.hullTypes = state.listingFilters.listingTypes;
  //         this.replaceHistory({
  //           hullBeds: listingBeds,
  //           hullTypes: listingTypes,
  //         });
  //       }
  //     }),
  //   );
  // };

  // UNSAFE_componentWillMount() {
  //   this.setInitialFilters();
  // }

  render() {
    // const {
    //   listings,
    //   hulls,
    //   amenities,
    //   selectedListing,
    //   selectedPropertyHull,
    //   selectedAmenityHull,
    //   filters,
    //   propertyHullFilters,
    //   listingFilters,
    //   amenityTypes,
    //   highlightedAmenityType,
    // } = this.state;
    const { children } = this.props;

    return (
      <MapContext.Provider
        value={
          {
            // listings,
            // hulls,
            // amenities,
            // amenityTypes,
            // highlightedAmenityType,
            // selectedListing,
            // selectedPropertyHull,
            // selectedAmenityHull,
            // filters,
            // propertyHullFilters,
            // listingFilters,
            // mergeListings: this.mergeListings, // reducer
            // mergeHulls: this.mergeHulls, // reducer
            // setAmenityHulls: this.setAmenityHulls, // reducer
            // setAmenityList: this.setAmenityList, // reducer
            // selectListing: this.selectListing, // reducer
            // selectAmenityHull: this.selectAmenityHull, // reducer
            // highlightAmenityType: this.highlightAmenityType, // reducer
            // saveListings: this.saveListings, // reducer
            // toggleBedFilter: this.toggleBedFilter, // reducer
            // togglePropertyHullFilter: this.togglePropertyHullFilter, // reducer
            // togglePriceFilter: this.togglePriceFilter, // reducer
            // selectPropertyHull: this.selectPropertyHull, // reducer
            // setMapType: this.setMapType, // reducer
            // clearSelections: this.clearSelections, // reducer
            // clearElements: this.clearElements, // reducer
            // copyFilters: this.copyFilters, // handler
            // toggleHullFilter: this.toggleHullFilter, // handler
            // toggleListingFilter: this.toggleListingFilter, // handler
            // toggleNarrowingFilter: this.toggleNarrowingFilter, // handler
            // checkIfTheSameFilters: this.checkIfTheSameFilters, // helper
            // setInitialFilters: this.setInitialFilters, // reducer / handler / initial state setter
            // mapType: this.state.mapType, // state value
          }
        }
      >
        {children}
      </MapContext.Provider>
    );
  }
}

MapContextStore.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired,
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  children: PropTypes.oneOfType([PropTypes.shape(), PropTypes.array])
    .isRequired,
};

export const withMapContextStore = Component => {
  return props => (
    <MapContext.Consumer>
      {mapContextStore => (
        <Component {...props} mapContextStore={mapContextStore} />
      )}
    </MapContext.Consumer>
  );
};

function mapStateToProps(state, props) {
  return {
    // listings: rootSelectors.selectShortletListings(state),
    // hulls: rootSelectors.selectHulls(state),
    // amenityHulls: rootSelectors.selectAmenityHulls(state),
    //
    // amenityHullsEnabled: rootSelectors.selectAmenityHullsEnabled(state),
    // amenityBands: rootSelectors.selectAmenitiesBands(state),
    //
    // selectedAmenityHull: rootSelectors.selectSelectedAmenityHull(state),
    // highlightedAmenityType: rootSelectors.selectHighlightedAmenityType(state),
    // shortletListingFiltersDefaults: shortletListingFiltersSelectors.selectDefaults(state),
    // propertyHullFiltersDefaults: propertyHullFiltersSelectors.selectDefaults(state),
    // amenityHullFiltersDefaults: amenityHullFiltersSelectors.selectDefaults(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    longletListingFiltersActions: bindActionCreators(
      longletListingFiltersActions,
      dispatch,
    ),
    shortletListingFiltersActions: bindActionCreators(
      shortletListingFiltersActions,
      dispatch,
    ),
    propertyHullFiltersActions: bindActionCreators(
      propertyHullFiltersActions,
      dispatch,
    ),
    amenityHullFiltersActions: bindActionCreators(
      amenityHullFiltersActions,
      dispatch,
    ),
    mapTypeFiltersActions: bindActionCreators(mapTypeFiltersActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(MapContextStore));
