import * as apiMapRequests from "api/map";

import mapConfigs from "configs/map_configs";
import fetchPropertyHulls from "../property_hulls";
import fetchListingsClusteringInfo from "../listings_clustering_info";
import fetchListings from "../listings";
import { constructShortletListingsQueryParams } from "./construct_shortlet_listings_query_params";
import constructHullsQueryParams from "../property_hulls/construct_hull_query_params";

const fetchShortletElements = async (
  {
    listings: {
      shortletListingFiltersCurrent,
      areListingsEnabled,
      shortletListingsActions,
    },
    amenities: {
      areAmenityHullsEnabled,
      fetchShortletAmenityHullsRequestActions,
      fetchShortletAmenityHullsRequestSelectors,
      shortletAmenityHullsActions,
    },
    hulls: { shortletPropertyHullsActions, areHullsEnabled },
    clusters: { areClustersEnabled, shortletListingsClustersActions },
  },
  hasMapMoved,
  setPreviousMapBounds,
  mapContext,
) => {
  let req1;
  let req2;
  let req3;
  let req4;

  const currentBounds = mapContext.getBounds();

  const {
    sharedConfig: {
      pageSizes: { clusters: clustersPageSize, hulls: hullsPageSize },
    },
  } = mapConfigs;

  if (areListingsEnabled) {
    req1 = fetchListings({
      listingsActions: shortletListingsActions,
      request: apiMapRequests.getShortletListings,
      queryParams: constructShortletListingsQueryParams(
        shortletListingFiltersCurrent,
        mapContext,
      ),
    });
  }
  if (areHullsEnabled) {
    req2 = fetchPropertyHulls({
      hullActions: shortletPropertyHullsActions,
      request: apiMapRequests.getAllHulls,
      queryParams: constructHullsQueryParams(mapContext, {
        page_size: hullsPageSize,
      }),
    });
  }

  if (areClustersEnabled) {
    req4 = fetchListingsClusteringInfo({
      request: apiMapRequests.getShortletListingsClusteringInfo,
      clustersActions: shortletListingsClustersActions,
      queryParams: constructShortletListingsQueryParams(
        shortletListingFiltersCurrent,
        mapContext,
        clustersPageSize,
      ),
    });
  }
  if (areAmenityHullsEnabled) {
    if (hasMapMoved || shortletListingFiltersCurrent.amenityTypes.length) {
      req3 = fetchShortletAmenityHullsRequestActions
        .triggerRequest(
          currentBounds,
          shortletListingFiltersCurrent.amenityTypes,
        )
        .then(globalState => {
          const responseData = fetchShortletAmenityHullsRequestSelectors.selectResponseData(
            globalState,
          );

          shortletAmenityHullsActions.setRecords(responseData);
        });
    }
  }

  const filteredRequests = [req1, req2, req3, req4].filter(Boolean);
  if (!filteredRequests.length) return;

  const [res1] = await Promise.all(filteredRequests);

  if (req1 && res1) {
    setPreviousMapBounds(currentBounds);
  }
};

export default fetchShortletElements;
