import { availableLongletRentalComps } from "configs/available_longlet_rental_comps";

export const compsQueryParamsFilter = query =>
  Object.entries(query).reduce((acc, [key, value]) => {
    /*
  example input: { comps[recepts]: 1, comps[bathrooms]: 2  bedrooms: 2 }
  example output: { comps[recepts]: 1, comps[bathrooms]: 2 }
  */
    if (key.match(/^comps\[/g)) {
      acc[key] = value;
    }
    return acc;
  }, {});

export const translateCompsQueryParamsNames = compsQuery => {
  /* 
    example input: { comps[recepts]: 1, comps[bathrooms]: 2 }
    example output: { recepts: 1, bathrooms: 2 }
    */
  return Object.keys(compsQuery).reduce((acc, key) => {
    acc[availableLongletRentalComps[key]] = compsQuery[key];

    return acc;
  }, {});
};
