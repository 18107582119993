import mapTabFactories from "state/global/factories/active_map_tab_factories";
import siblingSelectors from ".";

const selectors = {
  ...mapTabFactories.generateSelectors(state =>
    siblingSelectors.selectActiveMapTab(state),
  ),
};

export default selectors;
