import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { roundNumber } from "utilities";

const ValidValueInfo = ({ booked }) => (
  <>
    <div className="react-calendar-tooltip-text">Properties booked:</div>
    <div className="react-calendar-tooltip-value">
      {`${roundNumber(booked * 100)}%`}
    </div>
  </>
);

const setTooltipMarginsEffect = tooltipRef => {
  const node = tooltipRef.current;

  if (node) {
    node.style.display = "block";
    const position = node.getBoundingClientRect();
    node.style.display = "";

    if (position.left < 10) {
      node.style.marginLeft = `${Math.abs(10 - position.left)}px`;
    }

    if (position.right > window.innerWidth - 10) {
      node.style.marginLeft = `-${Math.abs(
        window.innerWidth - 10 - position.right,
      )}px`;
    }
  }
};

const Tooltip = ({ booked }) => {
  const tooltipRef = useRef(null);

  const isValidValue = typeof booked === "number";

  useEffect(() => setTooltipMarginsEffect(tooltipRef), [tooltipRef]);

  return (
    isValidValue && (
      <div className="react-calendar-tooltip" ref={tooltipRef}>
        <ValidValueInfo booked={booked} />
      </div>
    )
  );
};

Tooltip.defaultProps = {
  booked: null,
};

Tooltip.propTypes = {
  booked: PropTypes.number,
};

ValidValueInfo.propTypes = {
  booked: PropTypes.number.isRequired,
};

export default Tooltip;
