import React from "react";
import PropTypes from "prop-types";
import closeImg from "assets/images/close.png";
import styles from "./chip.module.css";

const Chip = ({
  children,
  onDelete,
  color,
  background,
  onClick,
  selected,
  disabled,
}) => {
  function handleDelete(e) {
    e.stopPropagation();
    onDelete();
  }
  const border = `4px solid ${background}`;
  const backgroundColor = selected ? "transparent" : background;
  const fontColor = selected ? "black" : color;
  const disabledOpacity = disabled && 0.3;
  const selectedOpacity = selected && 1;
  const opacity = disabledOpacity || selectedOpacity || "";
  const boxShadow = selected ? `0px 0px 7px 0px ${background}` : null;

  return (
    <div className={styles.container}>
      <div
        className={styles.chip}
        style={{
          background: backgroundColor,
          opacity,
          border,
          boxShadow,
        }}
      >
        <button
          type="button"
          onClick={onClick}
          className={styles.overlay}
          disabled={disabled}
          style={{
            color: fontColor,
            background: backgroundColor,
          }}
        >
          {children}
        </button>
      </div>
      <button type="button" className={styles.delete} onClick={handleDelete}>
        <img src={closeImg} alt="" />
      </button>
    </div>
  );
};

Chip.defaultProps = {
  onClick: () => null,
  onDelete: () => null,
  color: "#000",
  background: "#e0e0e0",
  disabled: false,
  selected: false,
};

Chip.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  color: PropTypes.string,
  background: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
};

export default Chip;
