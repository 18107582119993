import React from "react";
import PropTypes from "prop-types";

import styles from "./top_panel.module.css";

const HamburgerButton = ({ isMenuOpen, handleToggleMenu }) => {
  return (
    <button
      className={`navbar-toggler ${styles.menuBtn} ${
        isMenuOpen ? styles.menuOpen : ""
      }`}
      type="button"
      onClick={handleToggleMenu}
    >
      {[1, 2, 3].map(element => (
        <span key={element} />
      ))}
    </button>
  );
};

HamburgerButton.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  handleToggleMenu: PropTypes.func.isRequired,
};

export default HamburgerButton;
