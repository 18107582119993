import React from "react";
import PropTypes from "prop-types";

import { transformLongletRentalData } from "../data_transformations";

import {
  HullListingDetails,
  LongletPricesStatistics,
  LongletListingYieldsPerPrice,
} from "../components";
import PreviousSalePrices from "../components/previous_sale_prices";

import styles from "../listing_details.module.css";

const LongletListingDetailsPanel = ({ listing }) => {
  const data = transformLongletRentalData(listing);

  if (data) {
    return (
      <div className={styles.body}>
        <HullListingDetails data={data} />
        <div className={styles.title}>Statistics</div>
        <LongletPricesStatistics
          data={data}
          yield_per_price={() => LongletListingYieldsPerPrice(data.hullListing)}
        />
        <PreviousSalePrices data={data} />
      </div>
    );
  }
  return null;
};

LongletListingDetailsPanel.propTypes = {
  listing: PropTypes.shape({
    bedrooms: PropTypes.number.isRequired,
    derived_from: PropTypes.oneOf([
      "ScrapedRentalListing",
      "LongletHullListing",
    ]).isRequired,
    id: PropTypes.number.isRequired,
    image_urls: PropTypes.arrayOf(PropTypes.string).isRequired,
    property_type: PropTypes.string.isRequired,
    published_at: PropTypes.string.isRequired,
    revenue: PropTypes.number.isRequired,
    longletHulllistingStatistics: PropTypes.shape({
      average12MonthIncome: PropTypes.number.isRequired,
      hullListing: PropTypes.shape({
        address: PropTypes.string.isRequired,
        postcode: PropTypes.string.isRequired,
        isSourceZoopla: PropTypes.bool.isRequired,
        price: PropTypes.number.isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

export default LongletListingDetailsPanel;
