import { createReducer } from "@reduxjs/toolkit";

import mapFilterFactories from "state/global/factories/map_filter_factories";
import {
  actionTypes,
  actionTypePrefix,
} from "state/global/actions/user_input/map/filters/sales/deals_filters_actions";

import filterConfigs from "configs/filter_configs";

const filterOptions = {
  dealStages: [
    "Sourced",
    "Appraising",
    "Checking",
    "Declined",
    "Approved",
    "Viewing",
    "Rejected",
    "Offering",
    "Conveyancing",
    "Completed",
    "Forfeited",
  ],
  listingTypes: filterConfigs.sharedConfig.propertyTypes,
  listingBeds: filterConfigs.sharedConfig.listingBeds,
};

const filterDefaults = {
  priceRange: {
    max: 15000000,
    min: 0,
  },
  listingTypes: filterConfigs.sharedConfig.propertyTypes,
  listingBeds: filterConfigs.sharedConfig.listingBeds,
  freshness: {
    rightBand: 0,
    leftBand: -7,
  },
  estimatedYieldRange: {
    min: 0.0,
    max: 100.0,
  },
  dealStages: filterOptions.dealStages.filter(stage => stage !== "Sourced"),
  listingsEnabled: true,
  netYieldRange: {
    min: 0.0,
    max: 100.0,
  },
};

const allowedFilterKeys = [
  "dealStages",
  "freshness",
  "listingsEnabled",
  "listingTypes",
  "listingBeds",
  "priceRange",
  "estimatedYieldRange",
  "netYieldRange",
];

const reducer = mapFilterFactories.generateFilterReducer(
  actionTypes,
  actionTypePrefix,
  filterOptions,
  filterDefaults,
  allowedFilterKeys,
);

export default reducer;
export { filterDefaults };
