import { createReducer } from "@reduxjs/toolkit";

import mapFilterFactories from "state/global/factories/map_filter_factories";
import {
  actionTypes,
  actionTypePrefix,
} from "state/global/actions/user_input/map/filters/shortlet/property_hull_filters_actions";

import filterConfigs from "configs/filter_configs";

const filterOptions = {
  hullBands: filterConfigs.sharedConfig.bands,
  hullTypes: filterConfigs.sharedConfig.propertyTypes,
  hullBeds: filterConfigs.sharedConfig.listingBeds,
};

const filterDefaults = {
  hullBands: filterConfigs.sharedConfig.bands,
  hullTypes: filterConfigs.sharedConfig.propertyTypes,
  hullBeds: filterConfigs.sharedConfig.listingBeds,
};

const allowedFilterKeys = ["hullBands", "hullTypes", "hullBeds"];

const reducer = mapFilterFactories.generateFilterReducer(
  actionTypes,
  actionTypePrefix,
  filterOptions,
  filterDefaults,
  allowedFilterKeys,
);

export default reducer;
