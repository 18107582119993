/* eslint-disable import/order */
import React from "react";
import { Route } from "react-router-dom";

import { AuthRoute } from "components";
import MapContainer from "./pages/map";
import LoginPanel from "./pages/login";
import ResetPassword from "./pages/reset_password";
import ResetPasswordConfirm from "./pages/reset_password_confirm";
import Logout from "./pages/logout/logout";
import Users from "./pages/users/list/users";
import UsersAdd from "./pages/users/form/add_data_provider";
import UsersEdit from "./pages/users/form/edit_data_provider";
import Statistics from "./pages/statistics/details";
import StatisticsList from "./pages/statistics/list";

export function RouteWithSubRoutes(route) {
  const RouteComponent = route.auth ? AuthRoute : Route;
  return (
    <RouteComponent
      withTopPanel={route.withTopPanel}
      exact={route.exact}
      path={route.path}
      component={route.component}
    />
  );
}

export const routes = [
  {
    path: "/",
    component: MapContainer,
    name: "Map",
    exact: true,
    auth: true,
    withTopPanel: true,
  },
  {
    path: "/users",
    component: Users,
    name: "Users",
    exact: true,
    auth: true,
    withTopPanel: true,
  },
  {
    path: "/users/add",
    component: UsersAdd,
    exact: true,
    auth: true,
    withTopPanel: true,
  },
  {
    path: "/users/:userId/edit",
    component: UsersEdit,
    exact: true,
    auth: true,
    withTopPanel: true,
  },
  {
    path: "/statistics/:propertyId",
    component: Statistics,
    auth: true,
    exact: true,
    withTopPanel: true,
  },
  {
    path: "/statistics",
    component: StatisticsList,
    name: "Statistics",
    exact: true,
    auth: true,
    withTopPanel: true,
  },
  {
    path: "/login",
    component: LoginPanel,
    exact: true,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    exact: true,
  },
  {
    path: "/reset-password/:uid/:token",
    component: ResetPasswordConfirm,
    exact: true,
  },
  {
    path: "/logout",
    component: Logout,
    exact: true,
  },
  {
    path: "*",
    component: MapContainer,
    exact: true,
    auth: true,
    withTopPanel: true,
  },
];
