import { createReducer } from "@reduxjs/toolkit";

const generateActionTypes = actionTypePrefix => ({
  [`${actionTypePrefix}_ACTION_INITIALIZE_VALUE`]: `${actionTypePrefix}_ACTION_INITIALIZE_VALUE`,
  [`${actionTypePrefix}_ACTION_SET_VALUE`]: `${actionTypePrefix}_ACTION_SET_VALUE`,
});

const generateActions = (actionTypes, actionTypePrefix) => {
  const actions = {
    initializeValue: initialValue => ({
      type: actionTypes[`${actionTypePrefix}_ACTION_INITIALIZE_VALUE`],
      payload: { activeTab: initialValue },
    }),
    setValue: activeTab => (dispatch, getState) => {
      const actionObject = {
        type: actionTypes[`${actionTypePrefix}_ACTION_SET_VALUE`],
        payload: { activeTab },
      };

      dispatch(actionObject);

      return Promise.resolve(getState());
    },
  };

  return actions;
};

const generateReducer = (
  actionTypes,
  actionTypePrefix,
  activeTabOptions,
  activeTabDefault,
) => {
  const initialState = {
    options: {
      ...activeTabOptions,
    },
    defaults: {
      activeTabDefault,
    },
    current: {
      activeTab: activeTabDefault,
    },
  };

  const reducer = createReducer(initialState, {
    [actionTypes[`${actionTypePrefix}_ACTION_INITIALIZE_VALUE`]]: (
      draftState,
      action,
    ) => ({
      ...initialState,
      current: {
        ...initialState.current,
        ...action.payload.activeTab,
      },
    }),
    [actionTypes[`${actionTypePrefix}_ACTION_SET_VALUE`]]: (
      draftState,
      action,
    ) => ({
      ...draftState,
      current: { ...draftState.current, ...action.payload },
    }),
  });

  return reducer;
};

const generateSelectors = stateSlector => {
  const selectors = {
    selectOptions: state => stateSlector(state).options,
    selectDefaults: state => stateSlector(state).defaults,
    selectCurrent: state => stateSlector(state).current,
  };

  return selectors;
};

export default {
  generateSelectors,
  generateActions,
  generateReducer,
  generateActionTypes,
};
