import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import sendToHubspotAppraisalColumnIcon from "assets/images/hubspot_approve.png";
import sendToHubspotDeclinedColumnIcon from "assets/images/hubspot_decline.png";
import SetHubSpotStageButton from "pages/map/components/chip_hover/components/set_hubspot_stage_button";

import salesDealStagesSelectors from "state/global/selectors/data_collections/map/sales/sales_deal_stages_selectors";
import {
  isListingDeclinable,
  isListingSourced,
} from "state/helpers/map_helpers";

import styles from "./index.module.css";

const HubSpotButtons = ({ listing, hubspotPipelinesToDealStagesMapping }) => {
  const hubspotStageNamesToHubspotStageInternalIDs =
    hubspotPipelinesToDealStagesMapping[listing.pipeline_id]?.stages;

  return (
    <div className={styles["hubspot-buttons"]}>
      <SetHubSpotStageButton
        listing={listing}
        disabled={
          !isListingDeclinable(
            {
              hubspot_deal_stage_internal_id:
                listing.hubspot_deal_stage_internal_id,
            },
            hubspotStageNamesToHubspotStageInternalIDs,
          )
        }
        columnName="Declined"
        buttonIcon={sendToHubspotDeclinedColumnIcon}
        disabledText="Deal not declinable"
      />
      <SetHubSpotStageButton
        listing={listing}
        disabled={
          !isListingSourced(
            {
              hubspot_deal_stage_internal_id:
                listing.hubspot_deal_stage_internal_id,
            },
            hubspotStageNamesToHubspotStageInternalIDs,
          )
        }
        columnName="Appraising"
        buttonIcon={sendToHubspotAppraisalColumnIcon}
        disabledText="Only for 'sourced' deals."
      />
    </div>
  );
};

HubSpotButtons.propTypes = {
  listing: PropTypes.shape({
    hubspot_deal_stage_internal_id: PropTypes.string,
    pipeline_id: PropTypes.string,
  }).isRequired,
  hubspotPipelinesToDealStagesMapping: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      pipelineId: PropTypes.string.isRequired,
      stages: PropTypes.objectOf(PropTypes.string),
    }),
  ).isRequired,
};

const mapStateToProps = state => ({
  hubspotPipelinesToDealStagesMapping: salesDealStagesSelectors.selectAllByKey(
    state,
  ),
});

export default connect(
  mapStateToProps,
  {},
)(HubSpotButtons);
