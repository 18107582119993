import React from "react";
import PropTypes from "prop-types";
import styles from "./components.module.css";

export default function Tick({ tick, count, format }) {
  return (
    <div>
      <div
        className={styles.tick}
        style={{
          left: `${tick.percent}%`,
        }}
      />
      <div
        className={styles.tickLabel}
        style={{
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {format(tick.value)}
      </div>
    </div>
  );
}

Tick.propTypes = {
  tick: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  count: PropTypes.number.isRequired,
  format: PropTypes.func,
};

Tick.defaultProps = {
  format: d => d,
};
