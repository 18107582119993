import React from "react";
import PropTypes from "prop-types";

import decline_styles from "../index.module.css";

const DeclineReasonsSelect = ({
  reasons,
  onFetch,
  fetched,
  isLoading,
  declineReason,
  setDeclineReason,
}) => (
  <div className={decline_styles["reasons-container"]}>
    <span>Reason:</span>
    <select
      className={decline_styles["reasons-options"]}
      onChange={event => setDeclineReason(event.target.value)}
      value={declineReason}
    >
      {fetched ? (
        reasons.map(reason => <option key={reason.id}>{reason.value}</option>)
      ) : (
        <option>Not Available</option>
      )}
    </select>
    <button
      type="button"
      onClick={onFetch}
      className={
        isLoading
          ? `${decline_styles["fetch-reasons-button"]} ${
              decline_styles["button-spinner"]
            }`
          : decline_styles["fetch-reasons-button"]
      }
      disabled={isLoading}
    />
  </div>
);

DeclineReasonsSelect.propTypes = {
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
    }),
  ).isRequired,
  onFetch: PropTypes.func.isRequired,
  fetched: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setDeclineReason: PropTypes.func.isRequired,
  declineReason: PropTypes.string.isRequired,
};

export default DeclineReasonsSelect;
