/* eslint-disable no-shadow */

import React, { useEffect, useState } from "react";
import { connect, s } from "react-redux";
import { bindActionCreators } from "redux";
import { camelCase, inRange, isEmpty } from "lodash";
import PropTypes from "prop-types";

import { useQuery } from "components";
import * as apiMapRequests from "api/map";

import { actions as amenityTypesActions } from "state/global/actions/data_collections/map/amenity_types_actions";
import { actions as shortletAmenityHullsActions } from "state/global/actions/data_collections/map/shortlet/amenity_hulls_actions";
import { actions as shortletListingsActions } from "state/global/actions/data_collections/map/shortlet/listings_actions";
import { actions as longletListingsActions } from "state/global/actions/data_collections/map/longlet/listings_actions";
import { actions as salesDealsListingsActions } from "state/global/actions/data_collections/map/sales/deals_listings_actions";
import { actions as salesDealStagesActions } from "state/global/actions/data_collections/map/sales/sales_deal_stages_actions";
import { actions as salesClustersActions } from "state/global/actions/data_collections/map/sales/sales_clusters_actions";
import { actions as longletListingsClustersActions } from "state/global/actions/data_collections/map/longlet/listings_clusters_actions";
import { actions as shortletListingsClustersActions } from "state/global/actions/data_collections/map/shortlet/listings_clusters_actions";
import { actions as shortletPropertyHullsActions } from "state/global/actions/data_collections/map/shortlet/property_hulls_actions";
import { actions as longletPropertyHullsActions } from "state/global/actions/data_collections/map/longlet/property_hulls_actions";
import { fetchShortletAmenityHullsRequestActions } from "state/global/actions/data_requests/map_data_request_group_actions";
import shortletListingFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/listing_filters_selectors";
import longletListingFiltersSelectors from "state/global/selectors/user_input/map/filters/longlet/listing_filters_selectors";
import salesDealsListingFiltersSelectors from "state/global/selectors/user_input/map/filters/sales_deals_filter_selectors";
import propertyHullsDisabledFiltersSelectors from "state/global/selectors/user_input/map/filters/property_hulls_disabled_selectors";
import listingsDisabledFiltersSelectors from "state/global/selectors/user_input/map/filters/listings_disabled_selectors";
import compsFiltersSelectors from "state/global/selectors/user_input/map/filters/comps_selectors";
import amenityHullFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/amenity_hull_filters_selectors";
import mapTypeFiltersSelectors from "state/global/selectors/user_input/map/filters/map_type_filters_selectors";
import amenityTypesSelectors from "state/global/selectors/data_collections/map/amenity_types_selectors";
import shortletListingsSelectors from "state/global/selectors/data_collections/map/shortlet/listings_selectors";
import longletListingsSelectors from "state/global/selectors/data_collections/map/longlet/listings_selectors";
import salesDealStagesSelectors from "state/global/selectors/data_collections/map/sales/sales_deal_stages_selectors";
import salesDealsListingsSelectors from "state/global/selectors/data_collections/map/sales/deals_listings_selectors";
import salesClustersInfoSelectors from "state/global/selectors/data_collections/map/sales/sales_clusters_selectors";
import longletListingsClustersInfoSelectors from "state/global/selectors/data_collections/map/longlet/listings_clusters_selectors";
import shortletListingsClustersInfoSelectors from "state/global/selectors/data_collections/map/shortlet/listings_clusters_selectors";
import { fetchShortletAmenityHullsRequestSelectors } from "state/global/selectors/data_requests/map_data_request_group_selectors";

import {
  handleSetBoundingBox,
  checkBoundsUnion,
} from "state/helpers/map_helpers";
import { snakeCaseToCamelCase } from "utilities";
import mapConfigs from "configs/map_configs";
import fetchShortletElements from "api/fetchers/shortlet";
import fetchLongletElements from "api/fetchers/longlet";
import fetchSalesDealsElements from "api/fetchers/sales";
import utilities, { fetchWithDebounce } from "api/fetchers/utilities";
import { fetchSalesDealStages } from "api/fetchers/sales/sales_deal_stages";

import { MapContext } from "state/local/stores/contexts/map_context_store";
import SalesDealsComponents from "./components/sales";
import Loader from "./map_ui/loader";
import ZoomMessages from "./map_ui/zoom_messages";
import MapTypeSelect from "./map_ui/map_type_select";
import SearchLocation from "./map_ui/search_location";
import SearchBar from "./map_ui/search_bar";
import LongletComponents from "./components/longlet";
import ShortletComponents from "./components/shortlet";

let timer = null;
let timerListings = null;
const debounceListings = 800;
let timerAmenities = null;
const debounceAmenities = 800;

const MapElements = ({
  handleSearch,
  searchMarker,

  // ### mapStateToProps

  shortletListingFiltersCurrent,
  longletListingsFiltersCurrent,
  salesDealsListingsFiltersCurrent,
  propertyHullDisabledFiltersCurrent,
  listingsDisabledFiltersCurrent,
  amenityHullFiltersCurrent,
  mapTypeFiltersCurrent,
  allAmenityTypes,
  allShortletListings,
  allSalesDealsListings,
  allSalesClustersInfo,
  allLongletClustersInfo,
  allShortletClustersInfo,
  shortletAmenityHullsRequestData,
  hubspotPipelinesToDealStagesMapping,
  compsFiltersCurrent,

  // ### mapDispatchToProps

  amenityTypesActions,
  shortletListingsActions,
  shortletPropertyHullsActions,
  shortletAmenityHullsActions,
  longletListingsActions,
  longletPropertyHullsActions,
  salesDealsListingsActions,
  salesDealStagesActions,
  fetchShortletAmenityHullsRequestActions,
  salesClustersActions,
  longletListingsClustersActions,
  shortletListingsClustersActions,
}) => {
  const { query } = useQuery();
  const { mapInstance, fetchingMapData, setFetchingMapData } = React.useContext(
    MapContext,
  );

  const [previousMapBounds, setPreviousMapBounds] = useState(null);
  const [fetchingAmenityTypes, setFetchingAmenityTypes] = useState(false);

  const zoom = mapInstance.getZoom();
  const [prevZoom, setPrevZoom] = useState(zoom);
  const {
    mapTypeNames: {
      longlet: longletMapType,
      shortlet: shortletMapType,
      sales: salesMapType,
    },
    zoomLevels: {
      clusters: { min: clustersAppearMinZoom, max: clustersAppearMaxZoom },
      listings: listingsAppearZoom,
      hulls: hullsAppearZoom,
      amenities: amenitiesAppearZoom,
    },
  } = mapConfigs;

  const enableListingsFetching = zoom >= listingsAppearZoom;
  const enablePropertyHullsFetching = zoom >= hullsAppearZoom;
  const enableAmenityHullsFetching = zoom >= amenitiesAppearZoom;
  const enableClustersInfoFetching = inRange(
    zoom,
    clustersAppearMinZoom,
    clustersAppearMaxZoom + 1,
  );

  const [prevShowingClusters, setPrevShowingClusters] = useState(
    enableClustersInfoFetching,
  );

  const isShortletEnabled = mapTypeFiltersCurrent.mapTypes.includes(
    snakeCaseToCamelCase(shortletMapType),
  );
  const isLongletEnabled = mapTypeFiltersCurrent.mapTypes.includes(
    snakeCaseToCamelCase(longletMapType),
  );
  const isSalesEnabled = mapTypeFiltersCurrent.mapTypes.includes(salesMapType);

  // Map did mount event
  useEffect(() => {
    const currentBounds = mapInstance.getBounds();
    const newPolygonBounds = handleSetBoundingBox(
      currentBounds,
      currentBounds,
      true,
    );
    fetchWithDebounce(
      () => fetchAllMapElements(newPolygonBounds, currentBounds, false),
      debounceListings,
    );
    setPrevZoom(zoom);
    return () => {
      clearTimeout(timer);
      clearTimeout(timerListings);
      clearTimeout(timerAmenities);
      timer = null;
      timerListings = null;
      timerAmenities = null;
    };
  }, []);

  // Map did update event
  useEffect(() => {
    const currentBounds = mapInstance.getBounds();
    // const newDiff = handleSetBoundingBox(
    const newPolygonBounds = handleSetBoundingBox(
      currentBounds,
      checkBoundsUnion(currentBounds, previousMapBounds),
      checkBoundsUnion(currentBounds, previousMapBounds, true, false),
    );
    clearTimeout(timerAmenities);
    clearTimeout(timerListings);
    // prevent re-fetching map elements when map is zoomed in
    // re-fetch when zoomed in but swtiched between showing clusters and listings
    if (
      prevZoom >= zoom ||
      enableClustersInfoFetching !== prevShowingClusters
    ) {
      fetchWithDebounce(
        () => fetchAllMapElements(newPolygonBounds, currentBounds, true),
        debounceListings,
      );
    }

    setPrevZoom(zoom);
    setPrevShowingClusters(enableClustersInfoFetching);
  }, [
    query.zoom,
    query.lat,
    query.lng,
    mapTypeFiltersCurrent.mapTypes,
    hubspotPipelinesToDealStagesMapping,
  ]);

  // Amenity hulls filtering
  useEffect(() => {
    if (amenityHullFiltersCurrent.amenityTypes.length) {
      const currentBounds = mapInstance.getBounds();

      clearTimeout(timerAmenities);

      const newAmenityTypes = amenityHullFiltersCurrent.amenityTypes.filter(
        el => !allAmenityTypes.includes(el),
      );

      if (!newAmenityTypes.length) return;

      if (!timer) {
        timerAmenities = setTimeout(() => {
          fetchShortletAmenityHullsRequestActions
            .triggerRequest(currentBounds, newAmenityTypes)
            .then(() => {
              shortletAmenityHullsActions.setRecords(
                shortletAmenityHullsRequestData,
              );
            });
        }, debounceAmenities);
      }
    } else {
      shortletAmenityHullsActions.setRecords([]);
    }
  }, [query.amenityTypes]);

  // Shortlet Amenity Types
  useEffect(() => {
    async function fetchAmenityTypes() {
      setFetchingAmenityTypes(true);

      const [
        payload,
        error,
        { isCanceled },
      ] = await apiMapRequests.getAmenityTypes();

      setFetchingAmenityTypes(false);

      if (!error && !isCanceled) {
        if (payload !== null && payload.length) {
          amenityTypesActions.setRecords(payload);
        }
        return true;
      }
      console.error(error);
      return false;
    }

    if (isShortletEnabled) {
      fetchAmenityTypes();
    }
  }, []);

  // Sales Deal Stages
  useEffect(() => {
    setFetchingMapData(true);

    if (isSalesEnabled) {
      fetchSalesDealStages(salesDealStagesActions);
    }
    setFetchingMapData(false);
  }, [isSalesEnabled, enableClustersInfoFetching]);

  /**
   * fetchAllMapElements function handles requesting for map content. It's
   * based on zoom lvl settings. Promise function stands for bounds change,
   * to make sure, we update them only when all of the data is received and
   * map is synchronized with backend.
   */
  async function fetchAllMapElements(
    newPolygonBounds,
    boundsValue,
    hasMapMoved,
  ) {
    setFetchingMapData(true);

    const isNewView = !!(newPolygonBounds && newPolygonBounds.length);
    const areAnyAmenityHullsSelected = !!(
      amenityHullFiltersCurrent.amenityTypes &&
      amenityHullFiltersCurrent.amenityTypes.length
    );

    const areListingsEnabled = !!(enableListingsFetching && isNewView);
    const areHullsEnabled = !!enablePropertyHullsFetching;
    const areAmenityHullsEnabled = !!(
      enableAmenityHullsFetching && areAnyAmenityHullsSelected
    );

    if (isShortletEnabled) {
      const shortletHullsEnabled = !propertyHullDisabledFiltersCurrent.propertyHullsDisabled.includes(
        camelCase(shortletMapType),
      );

      await fetchShortletElements(
        {
          listings: {
            shortletListingFiltersCurrent,
            shortletListingsActions,
            areListingsEnabled,
          },
          amenities: {
            areAmenityHullsEnabled,
            fetchShortletAmenityHullsRequestActions,
            fetchShortletAmenityHullsRequestSelectors,
            shortletAmenityHullsActions,
          },
          clusters: {
            areClustersEnabled: enableClustersInfoFetching,
            shortletListingsClustersActions,
          },
          hulls: {
            areHullsEnabled: areHullsEnabled && shortletHullsEnabled,
            shortletPropertyHullsActions,
          },
        },
        hasMapMoved,
        setPreviousMapBounds,
        mapInstance,
      );
    }
    if (isLongletEnabled) {
      const longletHullsEnabled = !propertyHullDisabledFiltersCurrent.propertyHullsDisabled.includes(
        camelCase(longletMapType),
      );

      await fetchLongletElements(
        {
          listings: {
            longletListingsFiltersCurrent,
            areListingsEnabled,
            longletListingsActions,
          },
          hulls: {
            areHullsEnabled: areHullsEnabled && longletHullsEnabled,
            longletPropertyHullsActions,
          },
          clusters: {
            areClustersEnabled: enableClustersInfoFetching,
            longletListingsClustersActions,
          },
          compsFiltersCurrent,
        },
        mapInstance,
        setPreviousMapBounds,
      );
    }

    if (isSalesEnabled && !isEmpty(hubspotPipelinesToDealStagesMapping)) {
      await fetchSalesDealsElements(
        {
          listings: {
            salesDealsListingsFiltersCurrent,
            areListingsEnabled,
            salesDealsListingsActions,
          },
          clusters: {
            areClustersEnabled: enableClustersInfoFetching,
            salesClustersActions,
          },
        },
        mapInstance,
        setPreviousMapBounds,
        hubspotPipelinesToDealStagesMapping,
      );
    }

    setFetchingMapData(false);
  }

  const clearElements = () => {};

  return (
    <>
      <ShortletComponents
        enabled={isShortletEnabled}
        showListings={enableListingsFetching}
        showPropertyHulls={enablePropertyHullsFetching}
        showAmenityHulls={enableAmenityHullsFetching}
      />
      <LongletComponents
        enabled={isLongletEnabled}
        showListings={enableListingsFetching}
        showPropertyHulls={enablePropertyHullsFetching}
        showAmenityHulls={enableAmenityHullsFetching}
      />
      <SalesDealsComponents
        enabled={isSalesEnabled}
        showListings={enableListingsFetching}
      />
      <SearchBar map={mapInstance} handleSearch={handleSearch} />
      <SearchLocation marker={searchMarker} />
      <ZoomMessages
        zoom={zoom}
        enableListingsFetching={enableListingsFetching}
        listingsEnabledInUserInterface={
          listingsDisabledFiltersCurrent.listingsDisabled !== []
        }
        enableClustersInfoFetching={enableClustersInfoFetching}
        enablePropertyHullsFetching={enablePropertyHullsFetching}
        hullsEnabledInUserInterface={
          propertyHullDisabledFiltersCurrent.propertyHullsDisabled !== []
        }
      />
      <MapTypeSelect clearElements={clearElements} />
      <Loader
        loading={
          (enableListingsFetching ||
            enableClustersInfoFetching ||
            enablePropertyHullsFetching) &&
          (fetchingMapData || fetchingAmenityTypes)
        }
      />
    </>
  );
};

MapElements.defaultProps = {
  shortletAmenityHullsRequestData: undefined,
};

MapElements.propTypes = {
  shortletListingFiltersCurrent: PropTypes.shape({
    listingAmenities: PropTypes.arrayOf(PropTypes.string).isRequired,
    amenityTypes: PropTypes.arrayOf(PropTypes.string),
    listingBeds: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }),
  }).isRequired,
  longletListingsFiltersCurrent: PropTypes.shape({
    listingBeds: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }),
  }).isRequired,
  salesDealsListingsFiltersCurrent: PropTypes.shape({
    listingBeds: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }),
    dealStages: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  handleSearch: PropTypes.func.isRequired,
  searchMarker: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  propertyHullDisabledFiltersCurrent: PropTypes.shape({
    propertyHullsDisabled: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  listingsDisabledFiltersCurrent: PropTypes.shape({
    listingsDisabled: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  amenityHullFiltersCurrent: PropTypes.shape({
    amenityTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  mapTypeFiltersCurrent: PropTypes.shape({
    mapTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  allAmenityTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  allShortletListings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  allSalesDealsListings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  shortletAmenityHullsRequestData: PropTypes.shape(),
  amenityTypesActions: PropTypes.shape().isRequired,
  shortletListingsActions: PropTypes.shape({
    setRecords: PropTypes.func.isRequired,
  }).isRequired,
  salesClustersActions: PropTypes.shape({
    setRecords: PropTypes.func.isRequired,
  }).isRequired,
  longletListingsClustersActions: PropTypes.shape({
    setRecords: PropTypes.func.isRequired,
  }).isRequired,
  longletListingsActions: PropTypes.shape({
    setRecords: PropTypes.func.isRequired,
  }).isRequired,
  shortletPropertyHullsActions: PropTypes.shape({
    updateRecords: PropTypes.func.isRequired,
  }).isRequired,
  shortletAmenityHullsActions: PropTypes.shape({
    setRecords: PropTypes.func.isRequired,
  }).isRequired,
  salesDealsListingsActions: PropTypes.shape({
    setRecords: PropTypes.func.isRequired,
  }).isRequired,
  longletPropertyHullsActions: PropTypes.shape({
    updateRecords: PropTypes.func.isRequired,
  }).isRequired,
  fetchShortletAmenityHullsRequestActions: PropTypes.shape({
    triggerRequest: PropTypes.func.isRequired,
  }).isRequired,
  allShortletClustersInfo: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  allSalesClustersInfo: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  allLongletClustersInfo: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  shortletListingsClustersActions: PropTypes.shape({
    updateRecords: PropTypes.func.isRequired,
  }).isRequired,
  salesDealStagesActions: PropTypes.shape({
    setRecords: PropTypes.func.isRequired,
  }).isRequired,
  hubspotPipelinesToDealStagesMapping: PropTypes.objectOf(PropTypes.object)
    .isRequired,
  compsFiltersCurrent: PropTypes.shape({
    compsQuality: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }),
  }).isRequired,
};

const mapStateToProps = (state, props) => ({
  shortletListingFiltersCurrent: shortletListingFiltersSelectors.selectCurrent(
    state,
  ),
  longletListingsFiltersCurrent: longletListingFiltersSelectors.selectCurrent(
    state,
  ),
  salesDealsListingsFiltersCurrent: salesDealsListingFiltersSelectors.selectCurrent(
    state,
  ),
  propertyHullDisabledFiltersCurrent: propertyHullsDisabledFiltersSelectors.selectCurrent(
    state,
  ),
  listingsDisabledFiltersCurrent: listingsDisabledFiltersSelectors.selectCurrent(
    state,
  ),
  amenityHullFiltersCurrent: amenityHullFiltersSelectors.selectCurrent(state),
  mapTypeFiltersCurrent: mapTypeFiltersSelectors.selectCurrent(state),
  allAmenityTypes: amenityTypesSelectors.selectAll(state),
  allShortletListings: shortletListingsSelectors.selectAll(state),
  allLongletListings: longletListingsSelectors.selectAll(state),
  allSalesDealsListings: salesDealsListingsSelectors.selectAll(state),
  allSalesClustersInfo: salesClustersInfoSelectors.selectAll(state),
  allLongletClustersInfo: longletListingsClustersInfoSelectors.selectAll(state),
  allShortletClustersInfo: shortletListingsClustersInfoSelectors.selectAll(
    state,
  ),
  hubspotPipelinesToDealStagesMapping: salesDealStagesSelectors.selectAllByKey(
    state,
  ),
  compsFiltersCurrent: compsFiltersSelectors.selectCurrent(state),
});

const mapDispatchToProps = dispatch => ({
  amenityTypesActions: bindActionCreators(amenityTypesActions, dispatch),
  shortletListingsActions: bindActionCreators(
    shortletListingsActions,
    dispatch,
  ),
  shortletPropertyHullsActions: bindActionCreators(
    shortletPropertyHullsActions,
    dispatch,
  ),
  shortletAmenityHullsActions: bindActionCreators(
    shortletAmenityHullsActions,
    dispatch,
  ),
  longletListingsActions: bindActionCreators(longletListingsActions, dispatch),
  longletPropertyHullsActions: bindActionCreators(
    longletPropertyHullsActions,
    dispatch,
  ),
  salesDealsListingsActions: bindActionCreators(
    salesDealsListingsActions,
    dispatch,
  ),
  salesClustersActions: bindActionCreators(salesClustersActions, dispatch),
  fetchShortletAmenityHullsRequestActions: bindActionCreators(
    fetchShortletAmenityHullsRequestActions.actions,
    dispatch,
  ),
  longletListingsClustersActions: bindActionCreators(
    longletListingsClustersActions,
    dispatch,
  ),
  shortletListingsClustersActions: bindActionCreators(
    shortletListingsClustersActions,
    dispatch,
  ),
  salesDealStagesActions: bindActionCreators(salesDealStagesActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MapElements);
