const MARKER_SVG_COLORS = {
  SHORT_LET: {
    very_low: "#C41434",
    low: "#941751",
    medium: "#FF9300",
    high: "#FF5501",
    very_high: "#F9D907",
    grey: "#565656",
  },
  LONG_LET: {
    very_low: "#C41434",
    low: "#941751",
    medium: "#FF5501",
    high: "#FF9300",
    very_high: "#F9D907",
    perfect: "#00E278",
  },
  SALES: {
    dark: "#0000AA",
    medium: "#006EFF",
    light: "#00CCFF",
    grey: "#565656",
  },
};

const SHORTLET_LISTING_SVG_PATH = "M0,-16 L16,0 L0,16 L-16,0 Z";

const SPIDERFIABLE_LISTING_SVG_PATH =
  "M16,0 L8,16 L-8,16 L-16,0 L-8,-16 L8,-16 Z";

const LISTINGS_SVG_PATHS = shape => ({
  LONGLET_LISTING: {
    path: shape,
    colorKeyName: "longletColor",
  },
  HUBSPOT_PUSHED_SALE_LISTING: {
    path: shape,
    colorKeyName: "color",
  },
  SHORTLET_LISTING: {
    path: SHORTLET_LISTING_SVG_PATH,
    colorKeyName: "shortletColor",
    scale: 1,
  },
});

const markerIconSizes = {
  shortlet: { width: 32, height: 32 },
  longlet: { width: 32, height: 32 },
  sales: { width: 32, height: 32 },
};

const INCOME_BANDS_LABEL_COLORS = {
  "-": {
    shortletColor: MARKER_SVG_COLORS.SHORT_LET.grey,
    longletColor: MARKER_SVG_COLORS.LONG_LET.very_low,
  },
  E: {
    shortletColor: MARKER_SVG_COLORS.SHORT_LET.low,
    longletColor: MARKER_SVG_COLORS.LONG_LET.low,
  },
  D: {
    shortletColor: MARKER_SVG_COLORS.SHORT_LET.very_low,
    longletColor: MARKER_SVG_COLORS.LONG_LET.very_low,
  },
  C: {
    shortletColor: MARKER_SVG_COLORS.SHORT_LET.medium,
    longletColor: MARKER_SVG_COLORS.LONG_LET.medium,
  },
  B: {
    shortletColor: MARKER_SVG_COLORS.SHORT_LET.high,
    longletColor: MARKER_SVG_COLORS.LONG_LET.high,
  },
  A: {
    shortletColor: MARKER_SVG_COLORS.SHORT_LET.very_high,
    longletColor: MARKER_SVG_COLORS.LONG_LET.very_high,
  },
};

const shorletIncomeBandsForGivenBedCount = {
  0: [
    {
      ...INCOME_BANDS_LABEL_COLORS["-"],
      from: 0,
      to: 1200,
      label: "-",
    },
    {
      from: 1200,
      to: 5900,
      label: "E",
      ...INCOME_BANDS_LABEL_COLORS.E,
    },
    {
      from: 5900,
      to: 10700,
      label: "D",
      ...INCOME_BANDS_LABEL_COLORS.D,
    },
    {
      from: 10700,
      to: 15400,
      label: "C",
      ...INCOME_BANDS_LABEL_COLORS.C,
    },
    {
      from: 15400,
      to: 20200,
      label: "B",
      ...INCOME_BANDS_LABEL_COLORS.B,
    },
    {
      from: 20200,
      to: 24900,
      label: "A",
      ...INCOME_BANDS_LABEL_COLORS.A,
    },
    {
      from: 24900,
      to: Number.MAX_SAFE_INTEGER,
      label: "-",
      ...INCOME_BANDS_LABEL_COLORS["-"],
    },
  ],
  1: [
    {
      from: 0,
      to: 900,
      label: "-",
      ...INCOME_BANDS_LABEL_COLORS["-"],
    },
    {
      from: 900,
      to: 10600,
      label: "E",
      ...INCOME_BANDS_LABEL_COLORS.E,
    },
    {
      from: 10600,
      to: 20300,
      label: "D",
      ...INCOME_BANDS_LABEL_COLORS.D,
    },
    {
      from: 20300,
      to: 29900,
      label: "C",
      ...INCOME_BANDS_LABEL_COLORS.C,
    },
    {
      from: 29900,
      to: 39600,
      label: "B",
      ...INCOME_BANDS_LABEL_COLORS.B,
    },
    {
      from: 39600,
      to: 49300,
      label: "A",
      ...INCOME_BANDS_LABEL_COLORS.A,
    },
    {
      from: 49300,
      to: Number.MAX_SAFE_INTEGER,
      label: "-",
      ...INCOME_BANDS_LABEL_COLORS["-"],
    },
  ],
  2: [
    {
      from: 0,
      to: 1200,
      label: "-",
      ...INCOME_BANDS_LABEL_COLORS["-"],
    },
    {
      from: 1200,
      to: 12900,
      label: "E",
      ...INCOME_BANDS_LABEL_COLORS.E,
    },
    {
      from: 12900,
      to: 24500,
      label: "D",
      ...INCOME_BANDS_LABEL_COLORS.D,
    },
    {
      from: 24500,
      to: 36200,
      label: "C",
      ...INCOME_BANDS_LABEL_COLORS.C,
    },
    {
      from: 36200,
      to: 47800,
      label: "B",
      ...INCOME_BANDS_LABEL_COLORS.B,
    },
    {
      from: 47800,
      to: 59500,
      label: "A",
      ...INCOME_BANDS_LABEL_COLORS.A,
    },
    {
      from: 59500,
      to: Number.MAX_SAFE_INTEGER,
      label: "-",
      ...INCOME_BANDS_LABEL_COLORS["-"],
    },
  ],
  3: [
    {
      from: 0,
      to: 1100,
      label: "-",
      ...INCOME_BANDS_LABEL_COLORS["-"],
    },
    {
      from: 1100,
      to: 14600,
      label: "E",
      ...INCOME_BANDS_LABEL_COLORS.E,
    },
    {
      from: 14600,
      to: 28000,
      label: "D",
      ...INCOME_BANDS_LABEL_COLORS.D,
    },
    {
      from: 28000,
      to: 41500,
      label: "C",
      ...INCOME_BANDS_LABEL_COLORS.C,
    },
    {
      from: 41500,
      to: 54900,
      label: "B",
      ...INCOME_BANDS_LABEL_COLORS.B,
    },
    {
      from: 54900,
      to: 68400,
      label: "A",
      ...INCOME_BANDS_LABEL_COLORS.A,
    },
    {
      from: 68400,
      to: Number.MAX_SAFE_INTEGER,
      label: "-",
      ...INCOME_BANDS_LABEL_COLORS["-"],
    },
  ],
};

export {
  markerIconSizes,
  MARKER_SVG_COLORS,
  shorletIncomeBandsForGivenBedCount,
  LISTINGS_SVG_PATHS,
  SHORTLET_LISTING_SVG_PATH,
  SPIDERFIABLE_LISTING_SVG_PATH,
};
