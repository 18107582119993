/**
 * @flow
 * @relayHash 14ac446c4a487aba746373073071ce70
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type resetPasswordConfirmMutationVariables = {|
  token: string,
  uid: string,
  password: string,
  passwordConfirmation: string,
|};
export type resetPasswordConfirmMutationResponse = {|
  +passwordReset: ?{|
    +message: string
  |}
|};
export type resetPasswordConfirmMutation = {|
  variables: resetPasswordConfirmMutationVariables,
  response: resetPasswordConfirmMutationResponse,
|};
*/


/*
mutation resetPasswordConfirmMutation(
  $token: String!
  $uid: String!
  $password: String!
  $passwordConfirmation: String!
) {
  passwordReset(token: $token, uid: $uid, password: $password, passwordConfirmation: $passwordConfirmation) {
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "token",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "uid",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "password",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "passwordConfirmation",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "passwordReset",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "passwordConfirmation",
        "variableName": "passwordConfirmation"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      },
      {
        "kind": "Variable",
        "name": "uid",
        "variableName": "uid"
      }
    ],
    "concreteType": "PasswordReset",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "message",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "resetPasswordConfirmMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "resetPasswordConfirmMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "resetPasswordConfirmMutation",
    "id": null,
    "text": "mutation resetPasswordConfirmMutation(\n  $token: String!\n  $uid: String!\n  $password: String!\n  $passwordConfirmation: String!\n) {\n  passwordReset(token: $token, uid: $uid, password: $password, passwordConfirmation: $passwordConfirmation) {\n    message\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c4e702c27566a08f9810a124e2e29551';
module.exports = node;
