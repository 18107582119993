import { createSelector } from "reselect";

import siblingSelectors from ".";

const selectors = {
  selectUserData: state =>
    siblingSelectors.selectCurrentSession(state).userData,
};

export default selectors;
