import immer from "immer";

// ### helper functions

// ### exports

const setFilterValue = (filterState, filterKey, filterValue) => {
  const newFilterState = immer(filterState, draft => {
    draft[filterKey] = filterValue;
  });
  return newFilterState;
};

const filterValuesChanged = (filterState, filterKey, filterValues) =>
  !filterState[filterKey].every(filter => filterValues.includes(filter));

const filterLengthChanged = (filterState, filterKey, filterValues) =>
  !(filterState[filterKey].length === filterValues.length);

const hasFilterChanged = (filterState, filterKey, filterValues) =>
  filterValuesChanged(filterState, filterKey, filterValues) ||
  filterLengthChanged(filterState, filterKey, filterValues);

const newCollectionFiltersState = (filterState, filterKey, filterValues) =>
  immer(filterState, draft => {
    draft[filterKey] = filterValues;
  });

const toggleCollectionFilters = (filterState, filterKey, filterValues) =>
  hasFilterChanged(filterState, filterKey, filterValues)
    ? newCollectionFiltersState(filterState, filterKey, filterValues)
    : filterState;

const newCollectionFilterState = (filterState, filterKey, filterValue) =>
  immer(filterState, draft => {
    if (!filterState[filterKey].includes(String(filterValue))) {
      draft[filterKey] = [...filterState[filterKey], String(filterValue)];
    } else {
      draft[filterKey] = filterState[filterKey].filter(
        el => el !== String(filterValue),
      );
    }
  });

const toggleCollectionFilter = (filterState, filterKey, filterValue) =>
  newCollectionFilterState(filterState, filterKey, filterValue);

const validateFilterKey = (allowedFilterKeys, filterKey) => {
  if (!allowedFilterKeys.includes(filterKey)) {
    // eslint-disable-next-line no-throw-literal
    throw "Provided filterKey is not valid";
  }
};

export default {
  toggleCollectionFilter,
  toggleCollectionFilters,
  setFilterValue,
  validateFilterKey,
};
