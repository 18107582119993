import React from "react";
import PropTypes from "prop-types";
import { inRange } from "lodash";

import { MapContext } from "state/local/stores/contexts/map_context_store";
import mapConfigs from "configs/map_configs";
import SpiderfiedMakers from "./markers/spiderfied_markers";
import ClusteredMarkers from "./markers/clustered_markers";

const {
  zoomLevels: { clusters },
} = mapConfigs;

const areClustersVisible = mapInstance =>
  inRange(mapInstance.getZoom(), clusters.min, clusters.max + 1);

const VisibleChips = React.memo(props => {
  const { mapInstance } = React.useContext(MapContext);
  const { currentListingType, getPosition, clustersInfo } = props;
  const [showingListingInfo, setShowingListingInfo] = React.useState(null);
  const clustersVisible = areClustersVisible(mapInstance);

  const [
    currentlyShowingClusters,
    setCurrentlyShowingClusters,
  ] = React.useState(clustersVisible);

  React.useEffect(() => {
    if (currentlyShowingClusters !== clustersVisible) {
      setShowingListingInfo(null);
    }
    setCurrentlyShowingClusters(clustersVisible);
  }, [mapInstance.getZoom()]);

  return currentlyShowingClusters ? (
    <ClusteredMarkers
      {...{
        currentListingType,
        getPosition,
        clustersInfo,
      }}
    />
  ) : (
    <SpiderfiedMakers
      {...props}
      {...{ showingListingInfo, setShowingListingInfo }}
    />
  );
});

// eslint-disable-next-line consistent-return
const hasPropertyTypeProperValue = (props, propName, componentName) => {
  const availablePropertyTypes = ["flat", "house", "room"];

  if (!availablePropertyTypes.includes(props[propName])) {
    return new Error(
      `Invalid prop ${propName} passed to ${componentName}.
      Expected a property_type value is one of ${availablePropertyTypes}.
      Received value: ${props[propName]}
      ID of listing with invalid value: ${props.id}`,
    );
  }
};

VisibleChips.defaultProps = {
  currentListingType: null,
  hubspotPipelinesToDealStagesMapping: {},
};

VisibleChips.propTypes = {
  showListings: PropTypes.bool.isRequired,
  listings: PropTypes.arrayOf(
    PropTypes.shape({
      property_type: hasPropertyTypeProperValue,
    }),
  ).isRequired,
  hubspotPipelinesToDealStagesMapping: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      pipelineId: PropTypes.string.isRequired,
      stages: PropTypes.objectOf(PropTypes.string),
    }),
  ),
  clustersInfo: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
  isSelectedListing: PropTypes.func.isRequired,
  getPosition: PropTypes.func.isRequired,
  handleSelectListing: PropTypes.func.isRequired,
  isListingVisible: PropTypes.func.isRequired,
  listingsDisabled: PropTypes.bool.isRequired,
  currentListingType: PropTypes.string,
};

export default VisibleChips;
