import React from "react";
import { useQuery } from "components";

import { compsQueryParamsFilter } from "state/helpers/comparable_helpers";
import MapFiltersInitializer from "./initializers/map_filters_initializer";

const MapInitializer = () => {
  const { query } = useQuery();

  return (
    <React.Fragment>
      <MapFiltersInitializer compsQueryParams={compsQueryParamsFilter(query)} />
    </React.Fragment>
  );
};

export default MapInitializer;
