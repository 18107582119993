import React from "react";
import PropTypes from "prop-types";
import camelCase from "lodash/camelCase";

import useQuery from "components/hooks/use_query";
import { Expansion, Checkbox } from "components/";
import { getIncomeBandColor } from "utilities";
import handlePropertyHullFiltering from "../../../filter_handlers/property_hulls_filter";

import styles from "../../hulls_panel.module.css";

const HullsIcomeBandExpansion = ({
  disabled,
  propertyHullFiltersOptions,
  propertyHullFiltersActions,
  propertyHullFiltersCurrent,
  propertyHullFiltersSelectors,
  mapType,
}) => {
  const { updateQueryString } = useQuery();

  return (
    <Expansion
      controller={toggle => (
        <button
          type="button"
          disabled={disabled}
          className={styles.btn}
          onClick={toggle}
        >
          Income bands
          <span />
        </button>
      )}
    >
      <div className={styles.list}>
        {propertyHullFiltersOptions.hullBands.map(band => (
          <Checkbox
            key={band}
            className={styles.element}
            onClick={() =>
              handlePropertyHullFiltering(
                updateQueryString,
                propertyHullFiltersActions,
                propertyHullFiltersSelectors,
                "hullBands",
                band,
                camelCase(mapType),
              )
            }
            checked={propertyHullFiltersCurrent.hullBands.includes(band)}
            label={`${band}`}
            background={getIncomeBandColor(band)}
          />
        ))}
      </div>
    </Expansion>
  );
};

HullsIcomeBandExpansion.propTypes = {
  disabled: PropTypes.bool.isRequired,
  propertyHullFiltersActions: PropTypes.shape().isRequired,
  propertyHullFiltersCurrent: PropTypes.shape({
    hullBands: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  propertyHullFiltersOptions: PropTypes.shape({
    hullBands: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  mapType: PropTypes.string.isRequired,
  propertyHullFiltersSelectors: PropTypes.shape().isRequired,
};

export default HullsIcomeBandExpansion;
