const actionTypes = {
  RESET_SESSION: "RESET_SESSION",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
};

const actions = {
  loginAction: userData => ({
    type: actionTypes.LOGIN_SUCCESS,
    payload: {
      userData,
    },
  }),

  resetLoginStore: () => ({
    type: actionTypes.RESET_SESSION,
  }),
};

export { actionTypes, actions };
