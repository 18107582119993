import * as apiMapRequests from "api/map";

export const fetchSalesDealsDeclineReasons = async salesDealsDeclineReasonsActions => {
  const [
    payload,
    error,
    { isCanceled },
  ] = await apiMapRequests.fetchHubspotDeclineReasons();

  if (!(error || isCanceled)) {
    if (payload !== null) {
      const results = payload.decline_reasons.map((reason, index) => ({
        id: index,
        value: reason,
      }));

      salesDealsDeclineReasonsActions.setRecords(results);
    }

    return true;
  }

  console.error(error);

  return false;
};
