import { createReducer } from "@reduxjs/toolkit";
// import selectors from "../selectors/root_selectors";

const generateCollectionActionTypes = actionTypePrefix => {
  return {
    [`${actionTypePrefix}_COLLECTION_ACTION_UPDATE_RECORDS`]: `${actionTypePrefix}_COLLECTION_ACTION_UPDATE_RECORDS`,
    [`${actionTypePrefix}_COLLECTION_ACTION_SET_RECORDS`]: `${actionTypePrefix}_COLLECTION_ACTION_SET_RECORDS`,
    [`${actionTypePrefix}_COLLECTION_ACTION_UPDATE_SINGLE_RECORD`]: `${actionTypePrefix}_COLLECTION_ACTION_UPDATE_SINGLE_RECORD`,
  };
};

const generateCollectionActions = (actionTypes, actionTypePrefix) => {
  const actions = {
    updateRecords: recordCollection => ({
      type: actionTypes[`${actionTypePrefix}_COLLECTION_ACTION_UPDATE_RECORDS`],
      payload: { recordCollection },
    }),
    setRecords: recordCollection => ({
      type: actionTypes[`${actionTypePrefix}_COLLECTION_ACTION_SET_RECORDS`],
      payload: { recordCollection },
    }),
    updateSingleRecord: (recordId, updatedDetails) => ({
      type:
        actionTypes[
          `${actionTypePrefix}_COLLECTION_ACTION_UPDATE_SINGLE_RECORD`
        ],
      payload: { recordId, updatedDetails },
    }),
  };

  return actions;
};

const generateCollectionReducer = (
  actionTypes,
  actionTypePrefix,
  uniqueKeyName = "id",
) => {
  const initialState = {
    byKey: {},
  };

  const reducer = createReducer(initialState, {
    [actionTypes[`${actionTypePrefix}_COLLECTION_ACTION_UPDATE_RECORDS`]]: (
      draftState,
      action,
    ) => {
      const recordsByKey = {};

      action.payload.recordCollection.forEach(singleRecord => {
        recordsByKey[singleRecord[uniqueKeyName]] = singleRecord;
      });

      return {
        ...draftState,
        byKey: {
          ...draftState.byKey,
          ...recordsByKey,
        },
      };
    },
    [actionTypes[`${actionTypePrefix}_COLLECTION_ACTION_SET_RECORDS`]]: (
      draftState,
      action,
    ) => {
      const recordsByKey = {};

      action.payload.recordCollection.forEach(singleRecord => {
        recordsByKey[singleRecord[uniqueKeyName]] = singleRecord;
      });

      return {
        ...draftState,
        byKey: {
          ...recordsByKey,
        },
      };
    },
    [actionTypes[
      `${actionTypePrefix}_COLLECTION_ACTION_UPDATE_SINGLE_RECORD`
    ]]: (draftState, action) => {
      const updatedRecords = {
        ...draftState.byKey,
        [`${action.payload.recordId}`]: {
          ...draftState.byKey[`${action.payload.recordId}`],
          ...action.payload.updatedDetails,
        },
      };

      return {
        ...draftState,
        byKey: {
          ...updatedRecords,
        },
      };
    },
  });

  return reducer;
};

const generateCollectionSelectors = collectionStateSelector => {
  const selectors = {
    selectAll: state => {
      return Object.values(collectionStateSelector(state).byKey);
    },
    selectAllByKey: state => {
      return collectionStateSelector(state).byKey;
    },
  };

  return selectors;
};

export default {
  generateCollectionActionTypes,
  generateCollectionActions,
  generateCollectionReducer,
  generateCollectionSelectors,
};
