/* eslint-disable no-shadow */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import camelCase from "lodash/camelCase";

import { actions as mapContentViewActions } from "state/global/actions/ui_control/map/map_content_view_actions";
import listingsDisabledFiltersSelectors from "state/global/selectors/user_input/map/filters/listings_disabled_selectors";
import longletListingsSelectors from "state/global/selectors/data_collections/map/longlet/listings_selectors";
import mapContentViewSelectors from "state/global/selectors/ui_control/map/map_content_view_selectors";
import propertyHullFiltersSelectors from "state/global/selectors/user_input/map/filters/longlet/property_hull_filters_selectors";
import longletClustersSelectors from "state/global/selectors/data_collections/map/longlet/listings_clusters_selectors";
import amenityHullFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/amenity_hull_filters_selectors";
import longletPropertyHullsSelectors from "state/global/selectors/data_collections/map/longlet/property_hulls_selectors";

import listingConfigs from "configs/listing_configs";
import mapConfigs from "configs/map_configs";
import { sortBy } from "lodash";
import useMapContentLogic from "../hooks/use_map_content_logic";
import mapContentHelpers from "../helpers/map_content_helpers";
import VisibleChips from "../visible_chips";
import PropertyHulls from "../property_hulls";

const getPosition = listing => ({
  lat: listing.location_point.coordinates[1],
  lng: listing.location_point.coordinates[0],
});

const {
  mapTypeNames: { longlet: longletMap },
} = mapConfigs;

const LongletComponents = ({
  // ### params
  enabled,
  showListings,

  // ### mapStateToProps
  listings,
  mapContentViewState,
  propertyHulls,
  showPropertyHulls,
  clustersInfo,
  propertyHullFiltersCurrent,
  amenityHullFiltersCurrent,
  listingsDisabledFiltersCurrent,

  // ### mapDispatchToProps
  mapContentViewActions,
}) => {
  const {
    createParameterizedWrapper,
    checkIfSelectedListing,
    handleSelectPropertyHull,
    checkIfSelectedPropertyHull,
    checkIfPropertyHullVisible,
  } = useMapContentLogic({
    mapContentViewState,
    propertyHullFiltersCurrent,
    amenityHullFiltersCurrent,
  });

  const checkIfListingVisible = listing => true;

  const handleSelectListing = listing => {
    mapContentViewActions.deselectAll();
    mapContentViewActions.selectListing(listing.id, currentListingType);
  };

  const listingsSortedByComprableMatchQuality = sortBy(listings, [
    "comparable_match_quality",
  ]);

  return (
    enabled && (
      <>
        <VisibleChips
          showListings={showListings}
          listings={listingsSortedByComprableMatchQuality}
          getPosition={getPosition}
          handleSelectListing={handleSelectListing}
          listingsDisabled={listingsDisabledFiltersCurrent.listingsDisabled.includes(
            camelCase(longletMap),
          )}
          isListingVisible={checkIfListingVisible}
          isSelectedListing={createParameterizedWrapper(
            checkIfSelectedListing,
            currentListingType,
          )}
          clustersInfo={clustersInfo}
          currentListingType={currentListingType}
        />
        <PropertyHulls
          showPropertyHulls={showPropertyHulls}
          propertyHulls={propertyHulls}
          handleSelectPropertyHull={createParameterizedWrapper(
            handleSelectPropertyHull,
            currentPropertyHullType,
          )}
          mapType={camelCase(longletMap)}
          coordsToGeometryTransformer={
            mapContentHelpers.transformCoordsToPolygons
          }
          isPropertyHullVisible={checkIfPropertyHullVisible}
          isSelectedPropertyHull={createParameterizedWrapper(
            checkIfSelectedPropertyHull,
            currentPropertyHullType,
          )}
        />
      </>
    )
  );
};

LongletComponents.propTypes = {
  showListings: PropTypes.bool.isRequired,
  showPropertyHulls: PropTypes.bool.isRequired,
  propertyHulls: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const {
  sharedConfig: {
    listingTypes: {
      longletListing: { type: currentListingType },
    },
    hullTypes: {
      longletPropertyHulls: { type: currentPropertyHullType },
    },
  },
} = listingConfigs;

const mapStateToProps = (state, props) => ({
  listings: longletListingsSelectors.selectAll(state),
  propertyHulls: longletPropertyHullsSelectors.selectAll(state),
  mapContentViewState: mapContentViewSelectors.selectAll(state),
  propertyHullFiltersCurrent: propertyHullFiltersSelectors.selectCurrent(state),
  amenityHullFiltersCurrent: amenityHullFiltersSelectors.selectCurrent(state),

  listingsDisabledFiltersCurrent: listingsDisabledFiltersSelectors.selectCurrent(
    state,
  ),
  clustersInfo: longletClustersSelectors.selectAll(state),
});

const mapDispatchToProps = dispatch => ({
  mapContentViewActions: bindActionCreators(mapContentViewActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LongletComponents);
