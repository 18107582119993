/* eslint-disable prefer-promise-reject-errors */
import { format, addDays } from "date-fns";
import { range } from "lodash";
import queryString from "query-string";

import { MARKER_SVG_COLORS } from "configs/marker_configs";

const { SALES: salesDealStageColors } = MARKER_SVG_COLORS;

// returns income band color based on income band - A, B, C ...
export function getIncomeBandColor(incomeBand) {
  switch (incomeBand) {
    case "A":
      return "#f4d94a";
    case "B":
      return "#f19737";
    case "C":
      return "#ed612b";
    case "D":
      return "#b42c39";
    case "E":
      return "#882650";
    default:
      return "#999999";
  }
}

export function getDealStageColor(dealStage) {
  switch (dealStage) {
    case "Sourced":
      return salesDealStageColors.light;
    case "Appraising":
      return salesDealStageColors.light;
    case "Checking":
      return salesDealStageColors.light;
    case "Approved":
      return salesDealStageColors.medium;
    case "Offering":
      return salesDealStageColors.medium;
    case "Viewing":
      return salesDealStageColors.medium;
    case "Conveyancing":
      return salesDealStageColors.dark;
    case "Completed":
      return salesDealStageColors.dark;
    case "Forfeited":
      return salesDealStageColors.grey;
    case "Rejected":
      return salesDealStageColors.grey;
    case "Declined":
      return salesDealStageColors.grey;
    default:
      return "#999999";
  }
}
/*
 * function gets font color based on background color - result is white or black color
 * example - if background is black it will return white color
 */
export function getFontColor(
  background,
  darkColor = "black",
  lightColor = "white",
  defaultColor = "black",
) {
  if (!background) return defaultColor;
  const color =
    background.charAt(0) === "#" ? background.substring(1, 7) : background;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map(col => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return (col + 0.055) / 1.055 ** 2.4;
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? darkColor : lightColor;
}

// Used for big values to make them shorter
// numberFormatter(150000) => 150k
export const numberFormatter = num => {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
};

// Parse any number to price value format
// 1# priceFormatter(1) "1.00"
// 2# priceFormatter(123456) "123,456.00"
// 2# priceFormatter(12345.67) "12,345.67"
export const priceFormatter = (num, noDecimal) => {
  let number = Number(num)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  if (noDecimal) {
    const priceWithoutDecimal = 0;
    number = number.split(".")[priceWithoutDecimal];
  }
  return number;
};

export const yieldFormatter = (num, numberOfDecimal = 2) =>
  Number(num).toFixed(numberOfDecimal) + "%";

/**
 * flattens django errors {email: ['content'], items: [{name: ['required']}]}
 * to flat object: {email: 'content', items: [{name: 'required'}]}
 */
export function flattenErrors(error) {
  if (!error) return {};

  const errors = {};

  if (Array.isArray(error)) {
    const [errTxt] = error;
    errors.nonFieldErrors = error;
    errors.nonFieldError = errTxt;
    return errors;
  }

  Object.keys(error).forEach(key => {
    if (typeof error[key] === "string") {
      errors[key] = error[key];
    } else if (error[key] instanceof Array) {
      if (error[key][0] instanceof Object) {
        errors[key] = error[key].map(err => flattenErrors(err));
      } else {
        const [errTxt] = error[key];
        errors[key] = errTxt;
      }
    }
  });

  Object.defineProperty(errors, "$any", {
    value: errors[Object.keys(errors)[0]],
    enumerable: false,
  });

  return errors;
}

/**
 * Function for checking if polygon is inside map viewport
 * It's used to delete polygons from map, for memory saving
 */

export function containsPolygon(polygon, bounds) {
  const element = new window.google.maps.Polygon({
    paths: polygon.geometry,
  });
  return element
    .getPaths()
    .getArray()
    .every(path => path.getArray().every(coord => bounds.contains(coord)));
}

/**
 * Function for checking if point is inside map viewport
 * It's used to delete points from map, for memory saving
 */

export function isMarkerPointWithinBounds(marker, bounds) {
  const markerLocation = marker.point || marker.location_point;
  if (
    !bounds.contains ||
    !bounds.contains({
      lat: markerLocation.coordinates[1],
      lng: markerLocation.coordinates[0],
    })
  )
    return false;
  return true;
}

/**
 * Rounds number
 */

export const roundNumber = number => {
  if (typeof number !== "number") return number;
  return Math.round(number);
};

export const spaceSeparatedCamelCase = string =>
  string.replace(/([a-z])([A-Z])/g, "$1 $2");

export const snakeCaseToCamelCase = string =>
  string.toLowerCase().replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace("-", " ")
      .replace("_", " "),
  );

export const capitalize = string =>
  typeof string !== "string"
    ? ""
    : string.charAt(0).toUpperCase() + string.slice(1);

// /**
//  * function returns reducer function for redux store
//  * @param {object} initialState
//  * @param {object} handlers
//  */
// export function createReducer(
//   initialState: {},
//   handlers: {},
//   config: {} = {},
// ): {} {
//   return function reducer(state = initialState, action) {
//     let newState = state;
//     // if (!config.blockReset) {
//     //   handlers.RESET_SESSION = () => initialState;
//     // }
//     if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
//       newState = handlers[action.type](state, action);
//     }
//     return newState;
//   };
// }

/**
 * Function to get pagination object for easier implementation of list views
 */
export function getPagination({
  next,
  previous,
  count = 1,
  limit = 1,
  page_size,
  pages_amount,
}) {
  function getPageSize() {
    return count && limit ? Math.ceil(count / limit / 10) * 10 : 1;
  }

  const nextPage = parseInt(queryString.parse(next).page, 10) || null;
  const prevPage = previous
    ? parseInt(queryString.parse(previous).page, 10) || 1
    : null;

  const page = (() => {
    if (nextPage) return nextPage - 1;
    if (prevPage) return prevPage + 1;
    return 1;
  })();
  const pageSize = page_size || getPageSize();

  return {
    next: nextPage,
    prev: prevPage,
    count,
    page,
    limit,
    pageSize,
    pageCount: pages_amount,
  };
}

export async function handleFetches(fetches) {
  const promises = fetches.map(request => request());
  const results = await Promise.all(promises);
  const error = results.find(res => res[1]);
  const isCanceled = results.find(res => res[2].isCanceled);
  const status = results.map(res => res[2].status).sort((a, b) => b > a)[0];
  return [results.map(res => res[0]), error, { isCanceled, status }];
}

export const zipObject = (keys, values) =>
  keys.reduce((o, k, _i) => ({ ...o, [k]: values }), {});

export const cancellablePromise = promise => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      value => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
      error => reject({ isCanceled, error }),
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCanceled = true;
    },
  };
};

export const noop = () => {};

export const delay = n => new Promise(resolve => setTimeout(resolve, n));
export const validateUrlParams = (queryObject, paramName, validOptions) => {
  const params = queryObject[paramName];

  if (!params) {
    return;
  }

  const valid = params.split(",").every(param => validOptions.includes(param));

  if (!valid) {
    console.error(
      `Invalid URL Param ${paramName}.
      Expected value to be one of ${validOptions}.
      Received value(s): ${queryObject[paramName]}`,
    );
  }
};

export const arrayFromMinMaxValues = ({ min, max }) =>
  Array.from(range(min, parseInt(max, 10) + 1), value => `${value}`);

export const convertFreshnessDaysToCalendarDate = days => {
  const now = Date.now();

  const freshnessDate = addDays(now, days);
  const formattedDate = format(freshnessDate, "MM/DD/YYYY");

  return formattedDate === "Invalid Date" ? undefined : formattedDate;
};
