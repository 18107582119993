import React from "react";
import PropTypes from "prop-types";

import { withMapContextStore } from "state/local/stores/contexts/map_context_store";
import { Segment } from "components";
import KeywordsList from "./keywords_list";

const KeywordStatistics = ({ data }) => {
  return (
    <div>
      <Segment
        title="Title keywords"
        text={<KeywordsList data={data.titleKeywordsWithIncomes} />}
      />
      <Segment
        title="Description keywords"
        text={<KeywordsList data={data.descriptionKeywordsWithIncomes} />}
      />
    </div>
  );
};

KeywordStatistics.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default withMapContextStore(KeywordStatistics);
