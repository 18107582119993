import React from "react";
import PropTypes from "prop-types";

import { withMapContextStore } from "state/local/stores/contexts/map_context_store";
import { Segment } from "components";

const GeneralStatistics = ({ data }) => {
  return (
    <Segment
      title="Listing count"
      text={data.numberOfProperties}
      info="The number of listings present in the given Amenity Hull."
    />
  );
};

GeneralStatistics.propTypes = {
  data: PropTypes.shape({
    numberOfProperties: PropTypes.number,
  }).isRequired,
};

export default withMapContextStore(GeneralStatistics);
