import { arrayFromMinMaxValues } from "utilities";

const transformCoordsToMultiPolygons = hull =>
  hull.geometry.coordinates.map((polygon, index) => ({
    id: index,
    path: polygon.map(coords =>
      coords.map(point => ({ lat: point[1], lng: point[0] })),
    )[0],
  }));
const transformCoordsToPolygons = hull =>
  hull.geometry.coordinates.map((polygon, index) => ({
    id: index,
    path: polygon.map(point => ({ lat: point[1], lng: point[0] })),
  }));
const isShortletListingVisible = (listing, shortletListingFilters) => {
  const {
    average_12_month_income,
    property_type,
    bedrooms,
    review_to_reservation_ratio,
    avg_number_of_reservations,
    avg_reservation_days,
    avg_available_days,
    avg_blocked_days,
  } = listing;

  const {
    listingBeds,
    listingTypes,
    listingPrice,
    reservationRatio,
    avgReservations,
    avgReservationsDays,
    avgBlocked,
    avgAvailableDays,
  } = shortletListingFilters;

  const isWithinFilterRange = (value, filterRange) => {
    const isInRange =
      filterRange[0] <= Number(value) && filterRange[1] >= Number(value);
    return isInRange;
  };

  const reviewToReservation = review_to_reservation_ratio * 100;

  let visible = true;
  const allowedListingBedroomValues = arrayFromMinMaxValues(listingBeds);

  if (!allowedListingBedroomValues.includes(String(bedrooms))) {
    visible = false;
  }

  if (!listingTypes.includes(property_type)) {
    visible = false;
  }

  if (!isWithinFilterRange(average_12_month_income, listingPrice)) {
    visible = false;
  }

  if (!isWithinFilterRange(reviewToReservation, reservationRatio)) {
    visible = false;
  }

  if (!isWithinFilterRange(avg_number_of_reservations, avgReservations)) {
    visible = false;
  }

  if (!isWithinFilterRange(avg_reservation_days, avgReservationsDays)) {
    visible = false;
  }

  if (!isWithinFilterRange(avg_blocked_days, avgBlocked)) {
    visible = false;
  }

  if (!isWithinFilterRange(avg_available_days, avgAvailableDays)) {
    visible = false;
  }

  return visible;
};

const adjustToTaxRateBand = value =>
  [125000, 250000, 925000].includes(value) ? value + 1 : value;

const isPropertyHullVisible = (propertyHull, propertyHullFilters) => {
  let visible = true;

  const { hullBands, hullBeds, hullTypes } = propertyHullFilters;

  if (!hullBands.includes(propertyHull.income_band)) {
    visible = false;
  }

  const allowedHullBedroomValues = arrayFromMinMaxValues(hullBeds);

  if (!allowedHullBedroomValues.includes(String(propertyHull.bedrooms))) {
    visible = false;
  }

  if (!hullTypes.includes(propertyHull.property_type)) {
    visible = false;
  }

  return visible;
};

const isAmenityHullVisible = (amenityHull, amenityFilters) => {
  let visible = true;

  const { amenityBands, amenityTypes } = amenityFilters;

  if (!amenityBands.includes(amenityHull.income_band)) {
    visible = false;
  }
  if (!amenityTypes.includes(amenityHull.amenity)) {
    visible = false;
  }

  return visible;
};

export default {
  transformCoordsToPolygons,
  transformCoordsToMultiPolygons,
  isShortletListingVisible,
  isPropertyHullVisible,
  isAmenityHullVisible,
};
