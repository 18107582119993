import isEqual from "lodash/isEqual";
import React from "react";

import Spiderfy from "pages/map/spiderfy";
import MarkerDisplayOptionsFactory from "pages/map/factories/icons/marker_display_options_factory";
import { Marker } from "@react-google-maps/api";
import { useQuery } from "components/";

const getCluster = (id, getPosition, currentListingType) => {
  const positionOfCurrentListing = getPosition(
    global[currentListingType].find(li => li.id === id),
  );

  const cluster = global[currentListingType].filter(l =>
    isEqual(getPosition(l), positionOfCurrentListing),
  );

  return cluster;
};

const shouldShowMarkers = (listingsDisabled, showListings) =>
  !listingsDisabled && showListings;

const SpiderfiedMakers = ({
  handleSelectListing,
  setShowingListingInfo,
  showingListingInfo,
  currentListingType,
  getPosition,
  listings,
  isSelectedListing,
  isListingVisible,
  listingsDisabled,
  showListings,
  hubspotPipelinesToDealStagesMapping,
}) => {
  const visibleListings = listings.filter(listing => isListingVisible(listing));
  const { query: queryParams } = useQuery();

  global[currentListingType] = visibleListings;

  const showMakers = shouldShowMarkers(listingsDisabled, showListings);

  return (
    <Spiderfy
      {...{
        handleSelectListing,
        setShowingListingInfo,
        showingListingInfo,
        getCluster: id => getCluster(id, getPosition, currentListingType),
      }}
    >
      {mapListingsToMarkers({
        listings,
        getPosition,
        isSelectedListing,
        currentListingType,
        visibleListings,
        showMakers,
        queryParams,
        hubspotPipelinesToDealStagesMapping,
      })}
    </Spiderfy>
  );
};

const mapListingsToMarkers = ({
  listings,
  getPosition,
  isSelectedListing,
  currentListingType,
  visibleListings,
  showMarkers,
  queryParams,
  hubspotPipelinesToDealStagesMapping,
}) =>
  listings.map(listing => {
    const listingSelected = isSelectedListing(listing);

    const displayOptions = MarkerDisplayOptionsFactory(
      currentListingType,
      {
        selected: listingSelected,
        listing,
        shape: window.google.maps.SymbolPath.CIRCLE,
      },
      hubspotPipelinesToDealStagesMapping,
      queryParams,
    );

    return (
      <Marker
        key={listing.id}
        position={getPosition(listing)}
        animation={listingSelected ? window.google.maps.Animation.BOUNCE : null}
        visible={visibleListings.includes(listing) && showMarkers}
        options={{
          markerOptions: { ...displayOptions, id: listing.id },
        }}
        currentListingType={currentListingType}
        listing={listing}
      />
    );
  });

export default SpiderfiedMakers;
