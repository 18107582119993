/**
 * @flow
 * @relayHash 519be6b2e88b4076ff404d4080d5e2ea
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type longletQueriesHullListingStatisticsQueryVariables = {|
  id: string
|};
export type longletQueriesHullListingStatisticsQueryResponse = {|
  +longletHullListingsStatistics: ?{|
    +id: string,
    +hullListing: {|
      +id: string,
      +propertyUrl: ?string,
      +isSourceZoopla: ?boolean,
      +locationPoint: any,
      +bedrooms: ?number,
      +previousSalePrices: ?any,
      +postcode: ?string,
    |},
    +maxPrice12PercYield: ?number,
    +maxPrice18PercYield: ?number,
    +maxPrice15PercYield: ?number,
    +all12MonthIncomes: ?any,
    +imageUrls: ?$ReadOnlyArray<?string>,
    +average12MonthIncome: ?number,
  |}
|};
export type longletQueriesHullListingStatisticsQuery = {|
  variables: longletQueriesHullListingStatisticsQueryVariables,
  response: longletQueriesHullListingStatisticsQueryResponse,
|};
*/


/*
query longletQueriesHullListingStatisticsQuery(
  $id: ID!
) {
  longletHullListingsStatistics(id: $id) {
    id
    hullListing {
      id
      propertyUrl
      isSourceZoopla
      locationPoint
      bedrooms
      previousSalePrices
      postcode
    }
    maxPrice12PercYield
    maxPrice18PercYield
    maxPrice15PercYield
    all12MonthIncomes
    imageUrls
    average12MonthIncome
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "longletHullListingsStatistics",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "LongletHullListingStatisticsNode",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hullListing",
        "storageKey": null,
        "args": null,
        "concreteType": "LongletHullListingNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "propertyUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isSourceZoopla",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "locationPoint",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "bedrooms",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "previousSalePrices",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "postcode",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "maxPrice12PercYield",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "maxPrice18PercYield",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "maxPrice15PercYield",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "all12MonthIncomes",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "imageUrls",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "average12MonthIncome",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "longletQueriesHullListingStatisticsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "longletQueriesHullListingStatisticsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "longletQueriesHullListingStatisticsQuery",
    "id": null,
    "text": "query longletQueriesHullListingStatisticsQuery(\n  $id: ID!\n) {\n  longletHullListingsStatistics(id: $id) {\n    id\n    hullListing {\n      id\n      propertyUrl\n      isSourceZoopla\n      locationPoint\n      bedrooms\n      previousSalePrices\n      postcode\n    }\n    maxPrice12PercYield\n    maxPrice18PercYield\n    maxPrice15PercYield\n    all12MonthIncomes\n    imageUrls\n    average12MonthIncome\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b25fce1b769f02b5d2b08301f5fcccf1';
module.exports = node;
