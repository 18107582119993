import React from "react";
import PropTypes from "prop-types";
import styles from "../map.module.css";

const ZoomMessages = ({
  enableListingsFetching,
  listingsEnabledInUserInterface,
  enablePropertyHullsFetching,
  hullsEnabledInUserInterface,
  enableClustersInfoFetching,
}) => {
  if (
    !enableListingsFetching &&
    !enablePropertyHullsFetching &&
    listingsEnabledInUserInterface &&
    hullsEnabledInUserInterface &&
    !enableClustersInfoFetching
  )
    return (
      <ZoomMessage message="Please zoom in to show hulls, clusters and listings" />
    );
  if (
    !enablePropertyHullsFetching &&
    !enableListingsFetching &&
    hullsEnabledInUserInterface &&
    listingsEnabledInUserInterface
  ) {
    return <ZoomMessage message="Please zoom in to show hulls and listings" />;
  }
  if (!enableListingsFetching && listingsEnabledInUserInterface)
    return <ZoomMessage message="Please zoom in to show listings" />;
  if (
    !enablePropertyHullsFetching &&
    hullsEnabledInUserInterface &&
    !enableClustersInfoFetching
  )
    return <ZoomMessage message="Please zoom in to show hulls" />;
  return null;
};

const ZoomMessage = ({ message }) => (
  <div className={styles.messages}>
    <div className={styles.message}>
      <span>i</span>
      {message}
    </div>
  </div>
);

ZoomMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

ZoomMessages.propTypes = {
  enableListingsFetching: PropTypes.bool.isRequired,
  listingsEnabledInUserInterface: PropTypes.bool.isRequired,
  enablePropertyHullsFetching: PropTypes.bool.isRequired,
  hullsEnabledInUserInterface: PropTypes.bool.isRequired,
  enableClustersInfoFetching: PropTypes.bool.isRequired,
};

export default ZoomMessages;
