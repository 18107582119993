import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import { QueryProvider } from "components/hooks/use_query";
import { routes, RouteWithSubRoutes } from "routing_configuration";
import mainGlobalStore from "state/global/stores/main_global_store";
import DataProvider from "components/utils/data_provider/data_provider";

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={mainGlobalStore}>
        <DataProvider>
          <QueryProvider>
            <Switch>
              {routes.map(route => (
                <RouteWithSubRoutes key={route.path} {...route} />
              ))}
            </Switch>
          </QueryProvider>
        </DataProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
