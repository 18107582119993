const deserializeSaleClusters = serializedClusters =>
  serializedClusters.map(cluster => {
    const {
      id,
      geometry,
      hubspot_deal_stage_internal_id,
      hubspot_pipeline_internal_id: pipeline_id,
    } = cluster;

    return {
      id,
      geometry,
      hubspot_deal_stage_internal_id,
      pipeline_id,
    };
  });

export default deserializeSaleClusters;
