import zooplaIcon from "assets/images/external_links_icons/zoopla_icon.png";
import rightMoveIcon from "assets/images/external_links_icons/rightmove_icon.png";
import hubspotIcon from "assets/images/hubspot_icon.png";
import capitalize from "lodash/capitalize";

export const buildListingGoogleMapLink = listing => {
  const point = listing.point ?? listing.locationPoint;

  return `https://www.google.pl/maps/search/?api=1&query=${
    point.coordinates[1]
  },${point.coordinates[0]}`;
};

export const buildAirBnBLink = ({ airbnbId }) =>
  "https://www.airbnb.co.uk/rooms/" + airbnbId;

export const buildHubspotLink = hubspotId => {
  return `https://app.hubspot.com/contacts/5700322/deal/${hubspotId}`;
};

export const buildListingExternalLink = ({ sourceSiteCode, propertyUrl }) => {
  const displayText = propertyUrl
    ? `Open in ${capitalize(sourceSiteCode)}`
    : "External Link Not Available";

  return {
    zoopla: {
      url: propertyUrl,
      displayText,
      icon: zooplaIcon,
    },
    rightmove: {
      url: propertyUrl,
      displayText,
      icon: rightMoveIcon,
    },
    hubspot: {
      url: propertyUrl,
      displayText,
      icon: hubspotIcon,
    },
  }[sourceSiteCode?.toLowerCase()];
};
