import React from "react";
import PropTypes from "prop-types";
import styles from "./switch.module.css";

const Switch = ({ label, name, disabled, value, onChange }) => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div
          className={`${styles.checkMark} ${value ? styles.activeMark : ""}`}
        />
        <input
          name={name}
          onChange={() => onChange(!value)}
          type="checkbox"
          className={styles.input}
          disabled={disabled}
          checked={Boolean(value)}
          value={Boolean(value)}
        />
        <label
          htmlFor="switch"
          className={styles.label}
          onClick={() => onChange(!value)}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

Switch.defaultProps = {
  value: null,
  label: null,
  name: null,
  disabled: null,
  onChange: () => null,
};

Switch.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Switch;
