import React from "react";
import PropTypes from "prop-types";
import camelCase from "lodash/camelCase";

import { Expansion, Checkbox } from "components/";
import useQuery from "components/hooks/use_query";
import handleListingFiltering from "../../filter_handlers/listings_filter";

import styles from "../index.module.css";

const ListingTypeFilter = ({
  listingFiltersOptions,
  listingFiltersCurrent,
  listingFiltersActions,
  disabled,
  mapType,
  listingFilterSelector,
}) => {
  const { updateQueryString } = useQuery();

  return (
    <Expansion
      controller={toggle => (
        <button
          type="button"
          disabled={disabled}
          className={styles.btn}
          onClick={toggle}
        >
          Property types
          <span />
        </button>
      )}
    >
      <div className={styles.list}>
        {listingFiltersOptions.listingTypes.map(type => (
          <Checkbox
            key={type}
            className={styles.element}
            onClick={() =>
              handleListingFiltering({
                filterActions: listingFiltersActions,
                filterName: "listingTypes",
                value: type,
                shouldReplaceFilterValue: false,
                filterSelector: listingFilterSelector,
                updateQueryStringCallback: updateQueryString,
                queryParamPrefix: camelCase(mapType),
              })
            }
            checked={listingFiltersCurrent.listingTypes.includes(type)}
            label={`${type}`}
          />
        ))}
      </div>
    </Expansion>
  );
};

ListingTypeFilter.propTypes = {
  listingFiltersOptions: PropTypes.shape({
    listingTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  listingFiltersCurrent: PropTypes.shape({
    listingTypes: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  listingFiltersActions: PropTypes.shape({
    setFilterValue: PropTypes.func.isRequired,
    toggleCollectionFilter: PropTypes.func.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  mapType: PropTypes.string.isRequired,
  listingFilterSelector: PropTypes.shape().isRequired,
};

export default ListingTypeFilter;
