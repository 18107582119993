import queryString from "query-string";

/**
 * Group of functions for finding bounds difference polygon after map moving
 */
function boundsToPath(bounds) {
  const valuesBounds = getBoundsValues(bounds);
  return [
    { lat: valuesBounds[0][0], lng: valuesBounds[1][0] },
    { lat: valuesBounds[0][1], lng: valuesBounds[1][0] },
    { lat: valuesBounds[0][1], lng: valuesBounds[1][1] },
    { lat: valuesBounds[0][0], lng: valuesBounds[1][1] },
  ];
}

function getDiffArea(polygon) {
  const coords = polygon.getCoordinates().map(coord => {
    return { lat: coord.x, lng: coord.y };
  });

  return coords;
}

function createJSTSPolygon(geometryFactory, polygon) {
  const path = polygon.getPath();
  const coordinates = path.getArray().map(function name(coord) {
    return new window.jsts.geom.Coordinate(coord.lat(), coord.lng());
  });
  coordinates.push(coordinates[0]);
  const shell = geometryFactory.createLinearRing(coordinates);
  return geometryFactory.createPolygon(shell);
}

// Google maps frequently change some of their api keys
export function getBoundsValues(bounds) {
  const boundPositionKeys = Object.keys(bounds);
  return boundPositionKeys.map(key => Object.values(bounds[key]));
}

export function checkBoundsChanged(bounds, previousMapBounds) {
  const valuesBoundsNew = getBoundsValues(bounds);
  const valuesBoundsOld = getBoundsValues(previousMapBounds);
  return (
    valuesBoundsNew[0][0] !== valuesBoundsOld[0][0] &&
    valuesBoundsNew[0][1] !== valuesBoundsOld[0][1] &&
    valuesBoundsNew[1][0] !== valuesBoundsOld[1][0] &&
    valuesBoundsNew[1][1] !== valuesBoundsOld[1][1]
  );
}

function getBoundingPolygon(bounds, previousMapBounds, union) {
  const oldCoords = boundsToPath(previousMapBounds);
  const newCoords = boundsToPath(bounds);
  const oldPoly = new window.google.maps.Polygon({
    paths: oldCoords,
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
  });
  const newPoly = new window.google.maps.Polygon({
    paths: newCoords,
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
  });

  const geometryFactory = new window.jsts.geom.GeometryFactory();
  const oldPolygon = createJSTSPolygon(geometryFactory, oldPoly);
  const newPolygon = createJSTSPolygon(geometryFactory, newPoly);
  let diff = newPolygon.difference(oldPolygon);
  if (union) {
    diff = newPolygon.union(oldPolygon);
  }
  return getDiffArea(diff);
}

export const handleSetBoundingBox = (bounds, previousMapBounds, getUnion) => {
  if (
    previousMapBounds &&
    bounds &&
    checkBoundsChanged(bounds, previousMapBounds)
  ) {
    // map change polygon
    return getBoundingPolygon(bounds, previousMapBounds);
  }
  if (bounds && getUnion) {
    // initial map polygon
    return getBoundingPolygon(bounds, bounds, getUnion);
  }
  return null;
};

/**
 * Function for extending map bounds with additional padding
 */

export function fitBoundsWithPadding(map, bounds, paddingXY) {
  const projection = map.getProjection();
  if (projection) {
    const paddings = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    };

    if (paddingXY.left) {
      paddings.left = paddingXY.left;
    } else if (paddingXY.x) {
      paddings.left = paddingXY.x;
      paddings.right = paddingXY.x;
    }

    if (paddingXY.right) {
      paddings.right = paddingXY.right;
    }

    if (paddingXY.top) {
      paddings.top = paddingXY.top;
    } else if (paddingXY.y) {
      paddings.top = paddingXY.y;
      paddings.bottom = paddingXY.y;
    }

    if (paddingXY.bottom) {
      paddings.bottom = paddingXY.bottom;
    }

    // copying the bounds object, since we will extend it
    const newBounds = new window.google.maps.LatLngBounds(
      bounds.getSouthWest(),
      bounds.getNorthEast(),
    );

    // SW
    let point1 = projection.fromLatLngToPoint(bounds.getSouthWest());

    let point2 = new window.google.maps.Point(
      (typeof paddings.left === "number" ? paddings.left : 0) /
        2 ** map.getZoom() || 0,
      (typeof paddings.bottom === "number" ? paddings.bottom : 0) /
        2 ** map.getZoom() || 0,
    );

    let newPoint = projection.fromPointToLatLng(
      new window.google.maps.Point(point1.x - point2.x, point1.y + point2.y),
    );

    newBounds.extend(newPoint);

    // NE
    point1 = projection.fromLatLngToPoint(bounds.getNorthEast());
    point2 = new window.google.maps.Point(
      (typeof paddings.right === "number" ? paddings.right : 0) /
        2 ** map.getZoom() || 0,
      (typeof paddings.top === "number" ? paddings.top : 0) /
        2 ** map.getZoom() || 0,
    );
    newPoint = projection.fromPointToLatLng(
      new window.google.maps.Point(point1.x + point2.x, point1.y - point2.y),
    );

    newBounds.extend(newPoint);

    return newBounds;
  }
  return null;
}

/**
 * Returns bounds or old bounds based of their position and union
 */

export const checkBoundsUnion = (
  bounds,
  previousMapBounds,
  valueBounds,
  valueOldBounds,
) => {
  const boundsValue = valueBounds !== undefined ? valueBounds : bounds;
  const previousMapBoundsValue =
    valueOldBounds !== undefined ? valueOldBounds : previousMapBounds;
  if (!previousMapBounds) return boundsValue;
  const valuesBoundsNew = getBoundsValues(bounds);
  const valuesBoundsOld = getBoundsValues(previousMapBounds);
  const pointsOldBounds = [
    { lng: valuesBoundsOld[0][0], lat: valuesBoundsOld[1][0] },
    { lng: valuesBoundsOld[0][0], lat: valuesBoundsOld[1][1] },
    { lng: valuesBoundsOld[0][1], lat: valuesBoundsOld[1][1] },
    { lng: valuesBoundsOld[0][1], lat: valuesBoundsOld[1][0] },
  ];
  const pointsBounds = [
    { lng: valuesBoundsNew[0][0], lat: valuesBoundsNew[1][0] },
    { lng: valuesBoundsNew[0][0], lat: valuesBoundsNew[1][1] },
    { lng: valuesBoundsNew[0][1], lat: valuesBoundsNew[1][1] },
    { lng: valuesBoundsNew[0][1], lat: valuesBoundsNew[1][0] },
  ];
  if (
    previousMapBoundsValue &&
    (pointsOldBounds.find(point => bounds.contains(point)) ||
      pointsBounds.find(point => bounds.contains(point)))
  ) {
    return previousMapBoundsValue;
  }
  return boundsValue;
};

export function convertPolygonsToGeoJSONQueryString(polygons) {
  if (!(polygons && polygons.length)) return "";
  const newPolygons = [...polygons];
  const length = newPolygons.length - 1;
  if (
    JSON.stringify(newPolygons[0]) !== JSON.stringify(newPolygons[length][0])
  ) {
    newPolygons[length].lat += 0.0000000001;
    newPolygons[length].lng -= 0.0000000001;
    newPolygons.push(newPolygons[0]);
    newPolygons[length + 1].lat += 0.0000000001;
    newPolygons[length + 1].lng -= 0.0000000001;
    newPolygons.push(newPolygons[0]);
  }
  return `&area={"type":"Polygon", "coordinates": [[${newPolygons.map(
    point => `[${point.lng}, ${point.lat}]`,
  )}]]}`;
}

export function convertMapBoundsToQueryParams(queryBounds, polygons) {
  if (!queryBounds || (polygons && polygons.length)) return {};
  const boundsValues = getBoundsValues(queryBounds);
  return {
    xMin: boundsValues[0][0],
    yMin: boundsValues[1][0],
    xMax: boundsValues[0][1],
    yMax: boundsValues[1][1],
  };
}

export const buildQueryParamsString = ({
  polygons,
  currentBounds,
  options,
  comps,
}) => {
  const queryParams = convertMapBoundsToQueryParams(currentBounds, polygons);
  const polygonsString = convertPolygonsToGeoJSONQueryString(polygons);
  const newQuerySearch = queryString.stringify(
    { ...options, ...queryParams, comps },
    { arrayFormat: "comma" },
  );

  return "?" + newQuerySearch + polygonsString;
};

export const isListingSourced = (
  { hubspot_deal_stage_internal_id },
  dealStageNamesToDealStageInternalIds,
) =>
  hubspot_deal_stage_internal_id ===
  dealStageNamesToDealStageInternalIds.SOURCED;

export const isListingDeclinable = (
  { hubspot_deal_stage_internal_id },
  dealStageNamesToDealStageInternalIds,
) => {
  const {
    SOURCED,
    APPRAISING,
    REVENUE_PREDICTING,
    REVENUE_PREDICTED,
    VIEWING,
  } = dealStageNamesToDealStageInternalIds;

  const declinableStages = [
    SOURCED,
    APPRAISING,
    REVENUE_PREDICTED,
    REVENUE_PREDICTING,
    VIEWING,
  ];

  return declinableStages.includes(hubspot_deal_stage_internal_id);
};
