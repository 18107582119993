import { createSelector } from "reselect";

import siblingSelectors from ".";

const selectors = {
  selectAll: state => siblingSelectors.selectMapContentView(state),
  selectIsMapLoaded: state =>
    siblingSelectors.selectMapContentView(state).isMapLoaded,
  selectSearchMarker: state =>
    siblingSelectors.selectMapContentView(state).searchMarker,
  selectSelectedListingId: state =>
    siblingSelectors.selectMapContentView(state).selectedListingId,
  selectSelectedListingType: state =>
    siblingSelectors.selectMapContentView(state).selectedListingType,
  selectSelectedPropertyHullId: state =>
    siblingSelectors.selectMapContentView(state).selectedPropertyHullId,
  selectSelectedPropertyHullType: state =>
    siblingSelectors.selectMapContentView(state).selectedPropertyHullType,
  selectSelectedAmenityHullId: state =>
    siblingSelectors.selectMapContentView(state).selectedAmenityHullId,
  selectSelectedAmenityHullType: state =>
    siblingSelectors.selectMapContentView(state).selectedAmenityHullType,
  selectHighlightedAmenityType: state =>
    siblingSelectors.selectMapContentView(state).highlightedAmenityType,
};

export default selectors;
