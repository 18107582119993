import React from "react";
import PropTypes from "prop-types";
import Info from "../info/info";
import styles from "./segment.module.css";

const Segment = ({ title, text, info }) => {
  const txt = text === null ? "-" : text;
  return (
    <div className={styles.segment}>
      <div className={styles["segment-title"]}>
        {title}
        <Info info={info} />
      </div>
      <div className={styles["segment-text"]}>{txt}</div>
    </div>
  );
};

Segment.defaultProps = {
  text: "-",
  info: "",
};

Segment.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  info: PropTypes.string,
};

export default Segment;
