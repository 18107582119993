/**
 * @flow
 * @relayHash 21aa6a223b2f043b245b0a9e5d5fa864
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type editDataProviderQueryVariables = {|
  id: string
|};
export type editDataProviderQueryResponse = {|
  +user: ?{|
    +id: string,
    +username: string,
    +firstName: string,
    +lastName: string,
    +email: string,
  |}
|};
export type editDataProviderQuery = {|
  variables: editDataProviderQueryVariables,
  response: editDataProviderQueryResponse,
|};
*/


/*
query editDataProviderQuery(
  $id: ID!
) {
  user(id: $id) {
    id
    username
    firstName
    lastName
    email
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "user",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "UserType",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "username",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "editDataProviderQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "editDataProviderQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "editDataProviderQuery",
    "id": null,
    "text": "query editDataProviderQuery(\n  $id: ID!\n) {\n  user(id: $id) {\n    id\n    username\n    firstName\n    lastName\n    email\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b36aac754162f4f46d1d6646022eb11e';
module.exports = node;
