import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { format, startOfDay, endOfDay } from "date-fns";

import { Button } from "components";
import currentSessionSelectors from "state/global/selectors/user_session/current_session_selectors";
import {
  handleSetDates,
  handleSubmit,
  handleSetSingleDate,
  handleNextStep,
  dateFormat,
  formStepTitles,
} from "./form_helpers";
import FormStepOne from "./form_step_one";
import FormStepTwo from "./form_step_two";

import styles from "./form.module.css";
import "./form_calendar.css";

const CloseModalButton = ({ handleClose, isSending }) => (
  <Button
    className={styles.exit}
    onClick={handleClose}
    padding="small"
    size="tiny"
    disabled={isSending}
  >
    ×
  </Button>
);

const FormComponent = ({
  handleClose,
  modalDate,
  setSendForm,
  isSending,
  details,
  updatePrices,

  // ### mapStateToProps
  userData,
}) => {
  const [step, setStep] = useState(1);
  const [startValue, setStartValue] = useState(null);
  const [range, setRange] = useState([format(modalDate, dateFormat)]);
  const [value, setValue] = useState([
    new Date(startOfDay(modalDate)),
    new Date(endOfDay(modalDate)),
  ]);

  // const user = useSelector(state => state.user.userData);

  const submitDetails = {
    range,
    details,
    user: userData,
    setSendForm,
    updatePrices,
    handleClose,
  };

  const formStepOneProps = {
    value,
    range,
    handleSetDates: dates =>
      handleSetDates({ dates, setStartValue, setValue, setRange }),
    handleNextStep: () => handleNextStep({ setStep, startValue, setValue }),
    handleSetSingleDate: date =>
      handleSetSingleDate(date, { setStartValue, setRange }),
  };

  const formStepTwoProps = {
    range,
    setStep,
    handleSubmit: (values, helpers) =>
      handleSubmit(values, helpers, submitDetails),
  };

  return (
    <div className={styles.container}>
      <CloseModalButton handleClose={handleClose} isSending={isSending} />
      <div className={styles.title}>{formStepTitles[step]}</div>
      {step === 1 && <FormStepOne {...formStepOneProps} />}
      {step === 2 && <FormStepTwo {...formStepTwoProps} />}
    </div>
  );
};

FormComponent.defaultProps = {
  modalDate: null,
};

FormComponent.propTypes = {
  modalDate: PropTypes.shape(),
  details: PropTypes.shape().isRequired,
  handleClose: PropTypes.func.isRequired,
  setSendForm: PropTypes.func.isRequired,
  updatePrices: PropTypes.func.isRequired,
  isSending: PropTypes.bool.isRequired,
  userData: PropTypes.shape().isRequired,
};
CloseModalButton.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isSending: PropTypes.bool.isRequired,
};

function mapStateToProps(state, props) {
  return {
    userData: currentSessionSelectors.selectUserData(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormComponent);
