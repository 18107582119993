import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";
import styles from "./info.module.css";

const Info = ({ info, value, style, offset, position, arrow }) => {
  if (info)
    return (
      <div className={styles.info}>
        <Tooltip
          html={<div className={styles.tooltip}>{info}</div>}
          position={position}
          size="small"
          arrow={arrow}
          arrowSize="small"
          distance={7}
          offset={offset}
          sticky
        >
          <div className={styles.mark} style={style}>
            {value}
          </div>
        </Tooltip>
      </div>
    );
  return null;
};

Info.defaultProps = {
  info: "",
  value: "?",
  style: {},
  offset: 20,
  position: "top-start",
  arrow: false,
};

Info.propTypes = {
  info: PropTypes.string,
  position: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  offset: PropTypes.number,
  style: PropTypes.shape(),
  arrow: PropTypes.bool,
};

export default Info;
