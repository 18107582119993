/* eslint-disable no-restricted-globals */
import React from "react";
import PropTypes from "prop-types";

import { priceFormatter } from "utilities";
import { withMapContextStore } from "state/local/stores/contexts/map_context_store";
import { CollapseList, Segment } from "components";

import styles from "../hull_details.module.css";

const monthsValueFormatter = (array, month, isPrice) => {
  let value = array[month];

  if (isPrice) {
    value = `£${priceFormatter(value, true)}`;
  }

  return (
    <div className={styles.monthsElement}>
      <span>{`Last ${month} months: `}</span>
      {value}
    </div>
  );
};

const PriceStatistics = ({ data }) => {
  const previousMonthsAvgSoldPrices = data.previousMonthsAvgSoldPrices ?? {};
  const previousMonthsNumPropertiesSold =
    data.previousMonthsNumPropertiesSold ?? {};

  return (
    <div>
      <Segment
        title="Average income"
        text={
          isNaN(data.averageIncome)
            ? "-"
            : `£${priceFormatter(data.averageIncome, true)}`
        }
        info="The average of all 12 month incomes across all the properties present in the given
                    Property Hull."
      />
      <Segment
        title="Average Zoopla price"
        text={
          isNaN(data.averageZooplaPrice)
            ? "-"
            : `£${priceFormatter(data.averageZooplaPrice)}`
        }
        info="The average price of a property on Zoopla in this Property Hull."
      />
      <Segment
        title="Sold properties in last 6 months"
        text={previousMonthsNumPropertiesSold["6"]}
      />
      <CollapseList
        title="Show more"
        list={Object.keys(previousMonthsNumPropertiesSold)}
        formatter={month =>
          monthsValueFormatter(previousMonthsNumPropertiesSold, month)
        }
      />
      <Segment
        title="Average prices of sold properties in last 6 months"
        text={
          isNaN(previousMonthsAvgSoldPrices["6"])
            ? "-"
            : `£${priceFormatter(previousMonthsAvgSoldPrices["6"], true)}`
        }
      />
      <CollapseList
        title="Show more"
        list={Object.keys(previousMonthsAvgSoldPrices)}
        formatter={month =>
          monthsValueFormatter(previousMonthsAvgSoldPrices, month, true)
        }
      />
    </div>
  );
};

PriceStatistics.propTypes = {
  data: PropTypes.shape({
    averageIncome: PropTypes.number,
    averageZooplaPrice: PropTypes.number,
    previousMonthsAvgSoldPrices: PropTypes.shape(),
    previousMonthsNumPropertiesSold: PropTypes.shape(),
  }).isRequired,
};

export default withMapContextStore(PriceStatistics);
