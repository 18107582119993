import graphql from "babel-plugin-relay/macro";

export const hubspotPushedSaleListingsQuery = graphql`
  query saleListingQueriesHubspotPushedSaleListingQuery($id: ID!) {
    hubspotPushedSaleListings(id: $id) {
      id
      hubspotId
      pushedAt
      updatedAt
      hubspotPipelineInternalId
      hubspotDealStageInternalId
      propertyType
      scrapedSaleListing {
        id
        point
        bedrooms
        price
        bathrooms
        address
        recepts
        propertyUrl
        sourceSiteCode
        imageUrls
      }
    }
  }
`;
