/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import camelCase from "lodash/camelCase";

import { Button, Expansion, useQuery, Checkbox } from "components";

// import { mapTypes } from "state/local/stores/map_context_store";

import mapTypeFiltersSelectors from "state/global/selectors/user_input/map/filters/map_type_filters_selectors";
import { actions as mapTypeFiltersActions } from "state/global/actions/user_input/map/filters/map_type_filters_actions";
import { actions as activeMapTabsActions } from "state/global/actions/ui_control/map/active_tab_actions";
import styles from "../map.module.css";

const MapTypeSelect = ({
  // ### props

  clearElements,

  // ### mapStateToProps

  mapTypeFiltersOptions,
  mapTypeFiltersCurrent,

  // ### mapDispatchToProps

  mapTypeFiltersActions,
  activeMapTabsActions,
}) => {
  const { updateQueryString } = useQuery();

  const [checkboxValues, setCheckboxValues] = useState(
    buildState(mapTypeFiltersOptions, mapTypeFiltersCurrent),
  );

  useEffect(() => {
    updateQueryString({
      mapTypes: mapTypeFiltersCurrent.mapTypes.map(mapType =>
        camelCase(mapType),
      ),
    });
  }, [mapTypeFiltersCurrent.mapTypes]);

  const controller = () => (toggle, isOpen) =>
    isOpen ? completeButton(toggle) : rollDownButton(toggle);

  return (
    <div className={styles.selectContainer}>
      <Expansion controller={controller(clearElements, checkboxValues)}>
        <div className={styles.selectList}>
          {checkboxesFromFilterOptions(
            mapTypeFiltersOptions,
            checkboxValues,
            setCheckboxValues,
            clearElements,
            mapTypeFiltersActions,
            activeMapTabsActions,
            updateQueryString,
          )}
        </div>
      </Expansion>
    </div>
  );
};

const buildState = (mapTypeFiltersOptions, mapTypeFiltersCurrent) =>
  mapTypeFiltersOptions.mapTypes.reduce((stateStructure, type) => {
    stateStructure[type] = mapTypeFiltersCurrent.mapTypes.includes(type);

    return stateStructure;
  }, {});

const checkboxesFromFilterOptions = (
  mapTypeFiltersOptions,
  checkboxValues,
  setCheckboxValues,
  clearElements,
  mapTypeFiltersActions,
  activeMapTabsActions,
  updateQueryString,
) =>
  mapTypeFiltersOptions.mapTypes.map(type => (
    <Checkbox
      key={type}
      checked={checkboxValues[type]}
      onClick={() => {
        setCheckboxValues(previousState => {
          const checkboxValues = {
            ...previousState,
            [type]: !previousState[type],
          };
          toggleMapFilters(
            clearElements,
            checkboxValues,
            mapTypeFiltersActions,
          );

          switchToTab(
            previousState,
            type,
            activeMapTabsActions,
            updateQueryString,
          );

          return checkboxValues;
        });
      }}
      label={type}
    />
  ));

const switchToTab = (
  prevState,
  tabName,
  actions,
  queryStringUpdateCallback,
) => {
  if (!prevState[tabName]) {
    actions.setValue(camelCase(tabName));
    queryStringUpdateCallback({ activeTab: camelCase(tabName) });
  }
};

const rollDownButton = toggle => (
  <button type="button" className={styles.selectBtn} onClick={toggle}>
    Map Type
    <span />
  </button>
);

const completeButton = toggle => (
  <Button
    type="primary"
    className={styles.selectBtmOk}
    onClick={() => {
      toggle();
    }}
  >
    OK
  </Button>
);

const toggleMapFilters = (
  clearElements,
  checkboxValues,
  mapTypeFiltersActions,
) => {
  const filterValues = Object.keys(checkboxValues).filter(
    key => checkboxValues[key],
  );
  mapTypeFiltersActions.toggleCollectionFilters("mapTypes", filterValues);
  clearElements();
};

MapTypeSelect.propTypes = {
  clearElements: PropTypes.func.isRequired,
  mapTypeFiltersCurrent: PropTypes.shape({
    mapTypes: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  mapTypeFiltersActions: PropTypes.shape({
    toggleCollectionFilters: PropTypes.func.isRequired,
  }).isRequired,
  mapTypeFiltersOptions: PropTypes.shape({
    mapTypes: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  activeMapTabsActions: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state, props) => ({
  mapTypeFiltersOptions: mapTypeFiltersSelectors.selectOptions(state),
  mapTypeFiltersCurrent: mapTypeFiltersSelectors.selectCurrent(state),
});

const mapDispatchToProps = dispatch => ({
  mapTypeFiltersActions: bindActionCreators(mapTypeFiltersActions, dispatch),
  activeMapTabsActions: bindActionCreators(activeMapTabsActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MapTypeSelect);
