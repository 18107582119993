/* eslint-disable no-shadow */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { camelCase } from "lodash";

import { actions as propertyHullFiltersActions } from "state/global/actions/user_input/map/filters/longlet/property_hull_filters_actions";
import { actions as mapContentViewActions } from "state/global/actions/ui_control/map/map_content_view_actions";
import { actions as longletPropertyHullsActions } from "state/global/actions/data_collections/map/longlet/property_hulls_actions";
import { actions as propertyHullsDisabledFiltersActions } from "state/global/actions/user_input/map/filters/property_hulls_disabled_filters_actions";

import useComponentDidUpdate from "components/hooks/use_component_did_update";
import mapConfigs from "configs/map_configs";
import * as apiMapRequests from "api/map";
import fetchPropertyHulls from "api/fetchers/property_hulls";
import constructHullsQueryParams from "api/fetchers/property_hulls/construct_hull_query_params";
import { MapContext } from "state/local/stores/contexts/map_context_store";

import propertyHullFiltersSelectors from "state/global/selectors/user_input/map/filters/longlet/property_hull_filters_selectors";
import propertyHullsDisabledFiltersSelectors from "state/global/selectors/user_input/map/filters/property_hulls_disabled_selectors";
import longletListingFiltersSelectors from "state/global/selectors/user_input/map/filters/longlet/listing_filters_selectors";
import mapContentViewSelectors from "state/global/selectors/ui_control/map/map_content_view_selectors";

import { MinMaxFilter } from "components/";
import HullsVisibilitySwitch from "../components/property_hull_filters/hulls_visibility_switch";
import HullsIcomeBandExpansion from "../components/property_hull_filters/hulls_income_band_expansion";
import HullsTypesExpansion from "../components/property_hull_filters/hulls_types_expansion";

import styles from "../hulls_panel.module.css";

const {
  mapTypeNames: { longlet: longletMapType },
  sharedConfig: {
    pageSizes: { hulls: hullPageSize },
  },
} = mapConfigs;

const PropertyHullFilters = props => {
  const {
    propertyHullsDisabledFiltersCurrent,
    disabled,
    propertyHullFiltersActions,
    propertyHullFiltersCurrent,
    longletPropertyHullsActions,
    propertyHullsDisabledFiltersActions,
  } = props;

  const { mapInstance, setFetchingMapData } = React.useContext(MapContext);

  useComponentDidUpdate(() => {
    const hullsEnabled = !propertyHullsDisabledFiltersCurrent.propertyHullsDisabled.includes(
      camelCase(longletMapType),
    );

    if (hullsEnabled) {
      setFetchingMapData(true);

      const fetchLongletHulls = () =>
        fetchPropertyHulls({
          hullActions: longletPropertyHullsActions,
          request: apiMapRequests.getLongletPropertyHulls,
          queryParams: constructHullsQueryParams(mapInstance, {
            page_size: hullPageSize,
          }),
        });

      fetchLongletHulls().then(() => setFetchingMapData(false));
    }
  }, [propertyHullsDisabledFiltersCurrent]);

  return (
    <div className={styles.heading}>
      <div className={styles.title}>Property hull filters</div>
      <div className={styles.subtitle}>
        Use filters to change the shown property hulls
      </div>
      <div className={styles.filters}>
        <div className={styles.grid}>
          <HullsVisibilitySwitch
            disabled={disabled}
            propertyHullsDisabledFiltersActions={
              propertyHullsDisabledFiltersActions
            }
            propertyHullsDisabledFiltersCurrent={
              propertyHullsDisabledFiltersCurrent
            }
            mapType={longletMapType}
          />
        </div>
        <div className={styles.spacer} />
        <MinMaxFilter
          disabled={disabled}
          mapType={camelCase(longletMapType)}
          filterName="hullBeds"
          filterActions={propertyHullFiltersActions}
          filterSelectors={propertyHullFiltersSelectors}
          currentValues={propertyHullFiltersCurrent}
          shouldUpdateQueryString
          title="Bedroooms"
        />
        <Expansions {...props} />
      </div>
    </div>
  );
};

PropertyHullFilters.propTypes = {
  propertyHullFiltersOptions: PropTypes.shape({
    hullBands: PropTypes.arrayOf(PropTypes.string).isRequired,
    hullTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    hullBeds: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }).isRequired,
  }).isRequired,
  propertyHullFiltersCurrent: PropTypes.shape({
    hullBeds: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }).isRequired,
    hullTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    hullBands: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  longletListingFiltersCurrent: PropTypes.shape({
    listingBeds: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }).isRequired,
    listingTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  mapContentViewState: PropTypes.shape({
    highlightedAmenityType: PropTypes.string,
  }).isRequired,
  propertyHullFiltersActions: PropTypes.shape({
    setFilterValue: PropTypes.func.isRequired,
    toggleCollectionFilter: PropTypes.func.isRequired,
  }).isRequired,
  mapContentViewActions: PropTypes.shape({
    highlightAmenityType: PropTypes.func.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  propertyHullsDisabledFiltersCurrent: PropTypes.shape({
    propertyHullsDisabled: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  propertyHullsDisabledFiltersActions: PropTypes.shape({
    setFilterValue: PropTypes.func.isRequired,
    toggleCollectionFilter: PropTypes.func.isRequired,
  }).isRequired,
  longletPropertyHullsActions: PropTypes.shape({
    updateRecords: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state, props) => ({
  longletListingFiltersCurrent: longletListingFiltersSelectors.selectCurrent(
    state,
  ),
  propertyHullFiltersOptions: propertyHullFiltersSelectors.selectOptions(state),
  propertyHullFiltersCurrent: propertyHullFiltersSelectors.selectCurrent(state),
  mapContentViewState: mapContentViewSelectors.selectAll(state),
  propertyHullsDisabledFiltersCurrent: propertyHullsDisabledFiltersSelectors.selectCurrent(
    state,
  ),
});

const mapDispatchToProps = dispatch => ({
  propertyHullFiltersActions: bindActionCreators(
    propertyHullFiltersActions,
    dispatch,
  ),
  mapContentViewActions: bindActionCreators(mapContentViewActions, dispatch),
  propertyHullsDisabledFiltersActions: bindActionCreators(
    propertyHullsDisabledFiltersActions,
    dispatch,
  ),
  longletPropertyHullsActions: bindActionCreators(
    longletPropertyHullsActions,
    dispatch,
  ),
});

const Expansions = ({
  disabled,
  propertyHullFiltersActions,
  propertyHullFiltersCurrent,
  propertyHullFiltersOptions,
}) => (
  <>
    <HullsIcomeBandExpansion
      propertyHullFiltersActions={propertyHullFiltersActions}
      propertyHullFiltersCurrent={propertyHullFiltersCurrent}
      disabled={disabled}
      mapType={longletMapType}
      propertyHullFiltersOptions={propertyHullFiltersOptions}
      propertyHullFiltersSelectors={propertyHullFiltersSelectors}
    />
    <HullsTypesExpansion
      propertyHullFiltersActions={propertyHullFiltersActions}
      propertyHullFiltersCurrent={propertyHullFiltersCurrent}
      propertyHullFiltersOptions={propertyHullFiltersOptions}
      disabled={disabled}
      mapType={longletMapType}
      propertyHullFiltersSelectors={propertyHullFiltersSelectors}
    />
  </>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyHullFilters);
