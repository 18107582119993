import React from "react";

const useComponentDidUpdate = (effect, dependencies = []) => {
  const hasMounted = React.useRef(false);

  React.useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }
    effect();
  }, dependencies);
};

export default useComponentDidUpdate;
