/* eslint-disable no-shadow */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import camelCase from "lodash/camelCase";

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Switch, Slider, MinMaxFilter } from "components";
import useQuery from "components/hooks/use_query";

import { actions as shortletListingFiltersActions } from "state/global/actions/user_input/map/filters/shortlet/listing_filters_actions";
import { actions as listingsDisabledFiltersActions } from "state/global/actions/user_input/map/filters/listings_disabled_filters_actions";

import { priceFormatter } from "utilities";
import mapConfigs from "configs/map_configs";
import ListingIncomeIconFactory from "pages/map/factories/icons/listing_income_icon_factory";
import { shorletIncomeBandsForGivenBedCount } from "configs/marker_configs";

import shortletListingFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/listing_filters_selectors";
import propertyHullFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/property_hull_filters_selectors";
import amenityTypesSelectors from "state/global/selectors/data_collections/map/amenity_types_selectors";
import shortletListingsSelectors from "state/global/selectors/data_collections/map/shortlet/listings_selectors";
import listingsDisabledFiltersSelectors from "state/global/selectors/user_input/map/filters/listings_disabled_selectors";
import AmenitiesTypeFilter from "../components/amenity_types_filter";
import ListingTypeFilter from "../components/listing_types_filter";

import handleListingFiltering from "../filter_handlers/listings_filter";

import styles from "./listings_panel.module.css";
import { ListingsVisibilitySwitch } from "./components";

const {
  mapTypeNames: { shortlet: shortletMap },
} = mapConfigs;

const ListingsPanel = React.memo(
  ({
    shortletListingFiltersOptions,
    shortletListingFiltersCurrent,
    propertyHullFiltersCurrent,
    shortletListings,
    allAmenityTypes,
    shortletListingFiltersActions,
    disabled,
    listingsDisabledFiltersCurrent,
    listingsDisabledFiltersActions,
  }) => {
    const { updateQueryString } = useQuery();
    const [
      shortletListingIncomeData,
      setShortletListingIncomeData,
    ] = React.useState([]);

    /**
     * compute data to order it by bedroom number and by income range
     */
    React.useEffect(() => {
      const computed = shortletListings.reduce(groupListingDataByBedrooms, {
        ...shorletIncomeBandsForGivenBedCount,
      });
      setShortletListingIncomeData(Object.entries(computed));
    }, [shortletListings]);

    return (
      <div className={styles.container} data-testid="listings-panel">
        <div className={styles.heading}>
          <div className={styles.title}>Listing filters</div>
          <div className={styles.subtitle}>
            Use filters to change the shown listings
          </div>
          <div className={styles.filters}>
            <div className={styles.grid}>
              <ListingsVisibilitySwitch
                filterActions={listingsDisabledFiltersActions}
                filterSelector={listingsDisabledFiltersSelectors}
                filtersCurrent={listingsDisabledFiltersCurrent}
                mapType={shortletMap}
                mapTypeDisabled={disabled}
              />
            </div>
            <div className={styles.spacer} />
            <MinMaxFilter
              disabled={disabled}
              mapType={camelCase(shortletMap)}
              filterSelectors={shortletListingFiltersSelectors}
              filterActions={shortletListingFiltersActions}
              shouldUpdateQueryString
              filterName="listingBeds"
              title="Bedrooms"
              currentValues={shortletListingFiltersCurrent}
            />
            <ListingTypeFilter
              disabled={disabled}
              mapType={shortletMap}
              listingFilterSelector={shortletListingFiltersSelectors}
              listingFiltersActions={shortletListingFiltersActions}
              listingFiltersCurrent={shortletListingFiltersCurrent}
              listingFiltersOptions={shortletListingFiltersOptions}
            />
            <AmenitiesTypeFilter
              disabled={disabled}
              allAmenityTypes={allAmenityTypes}
              shortletListingFiltersActions={shortletListingFiltersActions}
              shortletListingFiltersCurrent={shortletListingFiltersCurrent}
            />
            <Slider
              title="Income range"
              togglePriceFilter={price =>
                handleListingFiltering({
                  filterActions: shortletListingFiltersActions,
                  value: price,
                  filterName: "listingPrice",
                  shouldReplaceFilterValue: true,
                })
              }
              values={
                disabled ? [] : shortletListingFiltersCurrent.listingPrice
              }
              initialValues={shortletListingFiltersOptions.listingPrice}
              prefix="£"
              disabled={disabled}
            />
            <Slider
              title="Review to reservation ratio"
              togglePriceFilter={price =>
                handleListingFiltering({
                  filterActions: shortletListingFiltersActions,
                  filterName: "reservationRatio",
                  value: price,
                  shouldReplaceFilterValue: true,
                })
              }
              values={
                disabled ? [] : shortletListingFiltersCurrent.reservationRatio
              }
              initialValues={shortletListingFiltersOptions.reservationRatio}
              suffix="%"
              disabled={disabled}
            />
            <Slider
              title="Average number of reservations"
              togglePriceFilter={price =>
                handleListingFiltering({
                  filterActions: shortletListingFiltersActions,
                  filterName: "avgReservations",
                  value: price,
                  shouldReplaceFilterValue: true,
                })
              }
              values={
                disabled ? [] : shortletListingFiltersCurrent.avgReservations
              }
              disabled={disabled}
              initialValues={shortletListingFiltersOptions.avgReservations}
            />
            <Slider
              title="Average reservation days"
              togglePriceFilter={price =>
                handleListingFiltering({
                  filterActions: shortletListingFiltersActions,
                  filterName: "avgReservationsDays",
                  value: price,
                  shouldReplaceFilterValue: true,
                })
              }
              values={
                disabled
                  ? []
                  : shortletListingFiltersCurrent.avgReservationsDays
              }
              disabled={disabled}
              initialValues={shortletListingFiltersOptions.avgReservationsDays}
            />
            <Slider
              title="Average blocked days"
              togglePriceFilter={price =>
                handleListingFiltering({
                  filterActions: shortletListingFiltersActions,
                  value: price,
                  filterName: "avgBlocked",
                  shouldReplaceFilterValue: true,
                })
              }
              disabled={disabled}
              values={disabled ? [] : shortletListingFiltersCurrent.avgBlocked}
              initialValues={shortletListingFiltersOptions.avgBlocked}
            />
            <Slider
              title="Average available days"
              togglePriceFilter={price =>
                handleListingFiltering({
                  filterActions: shortletListingFiltersActions,
                  value: price,
                  filterName: "avgAvailableDays",
                  shouldReplaceFilterValue: true,
                })
              }
              disabled={disabled}
              values={
                disabled ? [] : shortletListingFiltersCurrent.avgAvailableDays
              }
              initialValues={shortletListingFiltersOptions.avgAvailableDays}
            />
          </div>
        </div>
        <div className={styles.heading}>
          <div className={styles.title}>Listing details</div>
          <div className={styles.subtitle}>Listing incomes and counts</div>
          <div className={styles.filters}>
            <div className={styles.label}>Listing incomes</div>
            {shortletListingIncomeData.map(([beds, sections]) => (
              <div key={beds}>
                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemButton className={styles["accordion-button"]}>
                      <div className={styles["heading-title"]}>
                        <span>
                          {`${beds} `}
                          bedroom
                          {beds !== "1" && "s"}
                        </span>
                        <span className={styles.count}>
                          {`(${getPropertiesCount(sections)})`}
                        </span>
                      </div>
                    </AccordionItemButton>
                    <AccordionItemPanel>
                      <div>
                        {sections.map(section => (
                          <div key={section.from}>
                            <div className={styles["accordion-button-inset"]}>
                              <ListingIncomeIconFactory
                                beds={beds}
                                color={section.shortletColor}
                              />
                              {`${priceFormatter(section.from)} - £`}
                              <span>{priceFormatter(section.to)}</span>
                              <span
                                className={styles["accordion-button-count"]}
                              >
                                {`(${section.nodes || 0})`}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  },
);

ListingsPanel.propTypes = {
  shortletListingFiltersOptions: PropTypes.shape({
    listingBeds: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }).isRequired,
    listingTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    listingPrice: PropTypes.arrayOf(PropTypes.number).isRequired,
    reservationRatio: PropTypes.arrayOf(PropTypes.number).isRequired,
    avgReservations: PropTypes.arrayOf(PropTypes.number).isRequired,
    avgReservationsDays: PropTypes.arrayOf(PropTypes.number).isRequired,
    avgBlocked: PropTypes.arrayOf(PropTypes.number).isRequired,
    avgAvailableDays: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  shortletListingFiltersCurrent: PropTypes.shape({
    listingBeds: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }).isRequired,
    listingTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    listingPrice: PropTypes.arrayOf(PropTypes.number).isRequired,
    reservationRatio: PropTypes.arrayOf(PropTypes.number).isRequired,
    avgReservations: PropTypes.arrayOf(PropTypes.number).isRequired,
    avgReservationsDays: PropTypes.arrayOf(PropTypes.number).isRequired,
    avgBlocked: PropTypes.arrayOf(PropTypes.number).isRequired,
    avgAvailableDays: PropTypes.arrayOf(PropTypes.number).isRequired,
    listingAmenities: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  propertyHullFiltersCurrent: PropTypes.shape({
    hullBeds: PropTypes.shape({
      min: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      max: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
    }).isRequired,
    hullTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  shortletListings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  allAmenityTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  shortletListingFiltersActions: PropTypes.shape({
    setFilterValue: PropTypes.func.isRequired,
    toggleCollectionFilter: PropTypes.func.isRequired,
  }).isRequired,
  listingsDisabledFiltersActions: PropTypes.shape({
    setFilterValue: PropTypes.func.isRequired,
    toggleCollectionFilter: PropTypes.func.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  listingsDisabledFiltersCurrent: PropTypes.shape({
    listingsDisabled: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const groupListingDataByBedrooms = (acc, element, index) => {
  const bedroomsCount = element.bedrooms;
  if (!index)
    Object.keys(acc).forEach(function resetNodes(i) {
      acc[i] = acc[i].map(el => ({ ...el, nodes: 0 }));
      acc[i].nodes = 0;
    });

  const section = acc[bedroomsCount].findIndex(
    listing =>
      element.average_12_month_income >= listing.from &&
      element.average_12_month_income <= listing.to,
  );
  if (section === -1) {
    return acc;
  }
  if (!acc[bedroomsCount].nodes) {
    acc[bedroomsCount].nodes = 0;
  }
  acc[bedroomsCount].nodes += 1;

  if (!acc[bedroomsCount][section].nodes) {
    acc[bedroomsCount][section].nodes = 0;
  }
  acc[bedroomsCount][section].nodes += 1;

  return acc;
};

const getPropertiesCount = sections => {
  if (!sections) return 0;
  return sections
    .map(el => (el.nodes ? el.nodes : 0))
    .reduce((a, b) => a + b, 0);
};

const mapStateToProps = (state, props) => ({
  shortletListingFiltersOptions: shortletListingFiltersSelectors.selectOptions(
    state,
  ),
  shortletListingFiltersCurrent: shortletListingFiltersSelectors.selectCurrent(
    state,
  ),
  propertyHullFiltersCurrent: propertyHullFiltersSelectors.selectCurrent(state),
  allAmenityTypes: amenityTypesSelectors.selectAll(state),
  shortletListings: shortletListingsSelectors.selectAll(state),
  listingsDisabledFiltersCurrent: listingsDisabledFiltersSelectors.selectCurrent(
    state,
  ),
});

const mapDispatchToProps = dispatch => ({
  shortletListingFiltersActions: bindActionCreators(
    shortletListingFiltersActions,
    dispatch,
  ),
  listingsDisabledFiltersActions: bindActionCreators(
    listingsDisabledFiltersActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListingsPanel);
