/* eslint-disable no-shadow */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { actions as amenityHullFiltersActions } from "state/global/actions/user_input/map/filters/amenity_hull_filters_actions";
import amenityTypesSelectors from "state/global/selectors/data_collections/map/amenity_types_selectors";
import amenityHullFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/amenity_hull_filters_selectors";
import amenityHullsDisabledFiltersSelectors from "state/global/selectors/user_input/map/filters/amenity_hulls_disabled_selectors";
import { actions as amenityHullsDisabledFiltersActions } from "state/global/actions/user_input/map/filters/amenity_hulls_disabled_filters_actions";
import mapContentViewSelectors from "state/global/selectors/ui_control/map/map_content_view_selectors";
import { actions as mapContentViewActions } from "state/global/actions/ui_control/map/map_content_view_actions";
import shortletAmenityHullsSelectors from "state/global/selectors/data_collections/map/shortlet/amenity_hulls_selectors";
import shortletListingFiltersSelectors from "state/global/selectors/user_input/map/filters/shortlet/listing_filters_selectors";
import mapConfigs from "configs/map_configs";
import HullsVisibilitySwitch from "../components/amenities_hull_filters/hulls_visibity_switch";
import HullsIcomeBandExpansion from "../components/amenities_hull_filters/hulls_income_band_expansion";
import AmenitiesExpansion from "../components/amenities_hull_filters/amenities_expansion";
import SelectedAmenitiesChips from "../components/amenities_hull_filters/selected_amenities_chips";

import styles from "../hulls_panel.module.css";

const {
  mapTypeNames: { shortlet: shortletMap },
} = mapConfigs;

const AmenitiesHullsFilters = ({
  amenityHullFiltersCurrent,
  disabled,
  amenityHullFiltersOptions,
  amenityHullFiltersActions,
  allAmenityTypes,
  allShortletAmenityHulls,
  mapContentViewActions,
  mapContentViewState,
  amenityHullsDisabledFiltersCurrent,
  amenityHullsDisabledFiltersActions,
}) => {
  const allShortletAmenityHullsTypes = useMemo(() => {
    return allShortletAmenityHulls.map(el => el.amenity);
  }, [allShortletAmenityHulls]);

  return (
    <div className={styles.heading}>
      <div className={styles.title}>Amenity hull filters</div>
      <div className={styles.subtitle}>
        Use filters to change the shown amenity hulls
      </div>
      <div className={styles.filters}>
        <HullsVisibilitySwitch
          amenityHullsDisabledFiltersActions={
            amenityHullsDisabledFiltersActions
          }
          amenityHullsDisabledFiltersCurrent={
            amenityHullsDisabledFiltersCurrent
          }
          disabled={disabled}
          mapType={shortletMap}
        />
        <div className={styles.spacer} />
        <HullsIcomeBandExpansion
          amenityHullFiltersActions={amenityHullFiltersActions}
          amenityHullFiltersCurrent={amenityHullFiltersCurrent}
          disabled={disabled}
          amenityHullFiltersOptions={amenityHullFiltersOptions}
        />
        <AmenitiesExpansion
          amenityHullFiltersActions={amenityHullFiltersActions}
          disabled={disabled}
          allAmenityTypes={allAmenityTypes}
          amenityHullFiltersCurrent={amenityHullFiltersCurrent}
        />
        <SelectedAmenitiesChips
          mapContentViewActions={mapContentViewActions}
          mapContentViewState={mapContentViewState}
          amenityHullFiltersActions={amenityHullFiltersActions}
          amenityHullFiltersCurrent={amenityHullFiltersCurrent}
          allAmenityTypes={allAmenityTypes}
          allShortletAmenityHullsTypes={allShortletAmenityHullsTypes}
        />
      </div>
    </div>
  );
};

AmenitiesHullsFilters.propTypes = {
  disabled: PropTypes.bool.isRequired,
  allAmenityTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  allShortletAmenityHulls: PropTypes.arrayOf(PropTypes.object).isRequired,
  mapContentViewActions: PropTypes.shape().isRequired,
  mapContentViewState: PropTypes.shape().isRequired,
  amenityHullFiltersActions: PropTypes.shape().isRequired,
  amenityHullFiltersCurrent: PropTypes.shape().isRequired,
  amenityHullFiltersOptions: PropTypes.shape().isRequired,
  amenityHullsDisabledFiltersCurrent: PropTypes.shape({
    amenityHullsDisabled: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  amenityHullsDisabledFiltersActions: PropTypes.shape().isRequired,
};

const mapStateToProps = (state, props) => ({
  allAmenityTypes: amenityTypesSelectors.selectAll(state),
  allShortletAmenityHulls: shortletAmenityHullsSelectors.selectAll(state),
  shortletListingFiltersCurrent: shortletListingFiltersSelectors.selectCurrent(
    state,
  ),
  amenityHullFiltersOptions: amenityHullFiltersSelectors.selectOptions(state),
  amenityHullFiltersCurrent: amenityHullFiltersSelectors.selectCurrent(state),
  mapContentViewState: mapContentViewSelectors.selectAll(state),
  amenityHullsDisabledFiltersCurrent: amenityHullsDisabledFiltersSelectors.selectCurrent(
    state,
  ),
});

const mapDispatchToProps = dispatch => ({
  amenityHullFiltersActions: bindActionCreators(
    amenityHullFiltersActions,
    dispatch,
  ),
  mapContentViewActions: bindActionCreators(mapContentViewActions, dispatch),
  amenityHullsDisabledFiltersActions: bindActionCreators(
    amenityHullsDisabledFiltersActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AmenitiesHullsFilters);
