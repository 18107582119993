import { createReducer } from "@reduxjs/toolkit";

import mapFilterFactories from "state/global/factories/map_filter_factories";
import {
  actionTypes,
  actionTypePrefix,
} from "state/global/actions/user_input/map/filters/longlet/listing_filters_actions";

import filterConfigs from "configs/filter_configs";

const filterOptions = {
  listingTypes: filterConfigs.sharedConfig.propertyTypes,
  listingBeds: filterConfigs.sharedConfig.listingBeds,
};

const filterDefaults = {
  listingTypes: filterConfigs.sharedConfig.propertyTypes,
  listingBeds: filterConfigs.sharedConfig.listingBeds,
  listingPrice: [0, 150000],
  predictedRevenue: {
    min: 0,
    max: 70000,
  },
  averageRevenue: {
    min: 0,
    max: 70000,
  },
  freshness: {
    leftBand: -90,
    rightBand: 0,
  },
};

const allowedFilterKeys = [
  "listingTypes",
  "listingBeds",
  "listingPrice",
  "freshness",
  "predictedRevenue",
  "averageRevenue",
];

const reducer = mapFilterFactories.generateFilterReducer(
  actionTypes,
  actionTypePrefix,
  filterOptions,
  filterDefaults,
  allowedFilterKeys,
);

export default reducer;
export { filterDefaults };
