import { createSelector } from "reselect";

import parentSelectors from "../root_selectors";

const selectors = {
  selectUsers: state => parentSelectors.selectDataCollections(state).users,
  selectMap: state => parentSelectors.selectDataCollections(state).map,
};

export default selectors;
