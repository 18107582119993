import React from "react";
import PropTypes from "prop-types";
import camelCase from "lodash/camelCase";

import { Expansion, Checkbox } from "components/";
import useQuery from "components/hooks/use_query";
import handlePropertyHullFiltering from "../../../filter_handlers/property_hulls_filter";

import styles from "../../hulls_panel.module.css";

const HullsTypesExpansion = ({
  disabled,
  propertyHullFiltersOptions,
  propertyHullFiltersActions,
  propertyHullFiltersCurrent,
  propertyHullFiltersSelectors,
  mapType,
}) => {
  const { updateQueryString } = useQuery();

  return (
    <Expansion
      controller={toggle => (
        <button
          type="button"
          disabled={disabled}
          className={styles.btn}
          onClick={toggle}
        >
          Property types
          <span />
        </button>
      )}
    >
      <div className={styles.list}>
        {propertyHullFiltersOptions.hullTypes.map(type => (
          <Checkbox
            key={type}
            className={styles.element}
            onClick={() =>
              handlePropertyHullFiltering(
                updateQueryString,
                propertyHullFiltersActions,
                propertyHullFiltersSelectors,
                "hullTypes",
                type,
                camelCase(mapType),
              )
            }
            checked={propertyHullFiltersCurrent.hullTypes.includes(type)}
            label={`${type}`}
          />
        ))}
      </div>
    </Expansion>
  );
};

HullsTypesExpansion.propTypes = {
  disabled: PropTypes.bool.isRequired,
  propertyHullFiltersOptions: PropTypes.shape({
    hullBands: PropTypes.arrayOf(PropTypes.string).isRequired,
    hullTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  propertyHullFiltersCurrent: PropTypes.shape({
    hullTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    hullBands: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  propertyHullFiltersActions: PropTypes.shape({
    setFilterValue: PropTypes.func.isRequired,
    toggleCollectionFilter: PropTypes.func.isRequired,
  }).isRequired,
  mapType: PropTypes.string.isRequired,
  propertyHullFiltersSelectors: PropTypes.shape().isRequired,
};

export default HullsTypesExpansion;
