import longletMarkerConfigOptions from "configs/longlet_marker_config_options";
import {
  LISTINGS_SVG_PATHS,
  shorletIncomeBandsForGivenBedCount,
  SPIDERFIABLE_LISTING_SVG_PATH,
} from "configs/marker_configs";
import listingConfigs from "configs/listing_configs";
import { salesDealsMarkerMapping } from "configs/sales_deals_maker_mapping";

const {
  sharedConfig: {
    listingTypes: {
      longletListing: { type: longletListingType },
      shortletListing: { type: shortletListingType },
      hubspotPushedSaleListing: { type: hubspotPushedSaleListingType },
    },
  },
} = listingConfigs;

const INCOME_BANDS_RANGE = {
  min: 0,
  max: 3,
};

const MarkerDisplayOptionsFactory = (
  listingType,
  { selected, listing, shape = null },
  hubspotPipelinesToDealStagesMapping,
  queryParams,
) => {
  const displayOptions = MarkerOptions(
    listingType,
    listing,
    hubspotPipelinesToDealStagesMapping,
    queryParams,
  );

  const { path, colorKeyName, scale } = LISTINGS_SVG_PATHS(shape)[listingType];
  const SELECTED_LISTING_SVG_PATH =
    window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW;

  const icon = buildMarkerSVGIcon(path, displayOptions[colorKeyName], scale);
  const selectedIcon = buildMarkerSVGIcon(
    SELECTED_LISTING_SVG_PATH,
    displayOptions[colorKeyName],
    7,
  );
  const spiderfiableIcon = buildMarkerSVGIcon(
    SPIDERFIABLE_LISTING_SVG_PATH,
    displayOptions[colorKeyName],
    1,
  );

  return {
    icon: selected ? selectedIcon : icon,
    title: displayOptions.label,
    label: {
      text: String(listing.bedrooms),
      color: selected ? "black" : "white",
      fontWeight: "bold",
    },
    spiderfiableIcon,
    selected,
    selectedIcon,
  };
};

const buildMarkerSVGIcon = (path, fillColor, scale = 15) => ({
  path,
  fillColor,
  fillOpacity: 1,
  scale,
  strokeColor: "transparent",
});

const selectSafeBedroomsCount = (safeMin, bedrooms, safeMax) =>
  [safeMin, Number(bedrooms), safeMax].sort((a, b) => a - b)[1];

const MarkerOptions = (
  listingType,
  listing,
  hubspotPipelinesToDealStagesMapping,
  queryParams,
) => {
  const safeBedroomsCount = selectSafeBedroomsCount(
    INCOME_BANDS_RANGE.min,
    listing.bedrooms,
    INCOME_BANDS_RANGE.max,
  );

  const options = {
    [longletListingType]: () =>
      longletMarkerConfigOptions(listing, queryParams),
    [shortletListingType]: () =>
      shorletIncomeBandsForGivenBedCount[safeBedroomsCount].find(
        sec =>
          sec.from <= Number(listing.average_12_month_income) &&
          sec.to >= Number(listing.average_12_month_income),
      ),
    [hubspotPushedSaleListingType]: () =>
      salesDealsMarkerMapping(hubspotPipelinesToDealStagesMapping).find(
        ({ hubspot_deal_stage_internal_ids }) =>
          hubspot_deal_stage_internal_ids.includes(
            listing.hubspot_deal_stage_internal_id,
          ),
      ),
  };

  return options[listingType]();
};

export default MarkerDisplayOptionsFactory;
