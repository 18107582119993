import React from "react";
import PropTypes from "prop-types";

import listingConfigs from "configs/listing_configs";
import useClickPreventionOnDoubleClick from "components/hooks/use_prevent_click_on_double_click";
import { MapContext } from "state/local/stores/contexts/map_context_store";
import ChipHover from "../components/chip_hover/index";

import spiderfierFormatters from "./marker_formatters";

const oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);

const Spiderfy = React.memo(
  ({
    children,
    handleSelectListing,
    showingListingInfo,
    setShowingListingInfo,
    getCluster,
  }) => {
    const { mapInstance } = React.useContext(MapContext);

    const [markerInstance, setMarkerInstance] = React.useState(null);
    const [centerMarkerInstance, setCenterMarkerInstance] = React.useState(
      null,
    );

    const formatSpiderfyAwareMarkerCallback =
      spiderfierFormatters.formatSpiderfyAwareMarker;

    const spiderfier = React.useMemo(
      () =>
        getSpiderfierInstance(
          mapInstance,
          setCenterMarkerInstance,
          getCluster,
          formatSpiderfyAwareMarkerCallback,
        ),
      [mapInstance],
    );

    const [handleClick, handleDblClick] = useClickPreventionOnDoubleClick(
      handleOnClick,
      handleOnDblClickMarker,
    );

    React.useEffect(
      () => window.google.maps.event.trigger(centerMarkerInstance, "click"),
      [handleSelectListing, showingListingInfo],
    );

    handleOnMapClick(mapInstance, setShowingListingInfo);

    return React.Children.map(children, child => {
      const {
        props: { listing, currentListingType },
      } = child;

      return React.cloneElement(child, {
        onLoad: marker =>
          formatClustersForSpiderfier(
            marker,
            listing,
            spiderfier,
            handleClick,
            setMarkerInstance,
            setShowingListingInfo,
            getCluster,
            formatSpiderfyAwareMarkerCallback,
          ),
        children: [
          canShowHoverForListingByType(currentListingType) &&
            showingListingInfo === listing.id &&
            React.createElement(ChipHover, {
              position: markerInstance.position,
              listing,
              listingType: currentListingType,
              id: listing[listingIdField(currentListingType)],
              onClose: () => setShowingListingInfo(null),
              key: listing.id,
            }),
        ],
        onDblClick: e => handleDblClick(listing, handleSelectListing),
        onUnmount: marker => spiderfier.removeMarker(marker),
      });
    });
  },
);

Spiderfy.defaultProps = {
  showingListingInfo: null,
};

Spiderfy.propTypes = {
  handleSelectListing: PropTypes.func.isRequired,
  setShowingListingInfo: PropTypes.func.isRequired,
  showingListingInfo: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.shape).isRequired,
  getCluster: PropTypes.func.isRequired,
};

const SpiderfierOptions = {
  keepSpiderfied: true,
  nearbyDistance: 0.00001,
};

const handleOnClick = (
  marker,
  id,
  setMarkerInstance,
  setShowingListingInfo,
) => {
  setMarkerInstance(marker);
  setShowingListingInfo(id);
};

const handleOnDblClickMarker = (listing, handleSelectListing) => {
  handleSelectListing(listing);
};

const configureMarkerInCluster = (marker, cluster, id) => {
  if (cluster && [...cluster].pop()?.id === id) {
    marker.isTopMarkerInCluster = true;
  }
};

const configureMarkersNotInCluster = marker => {
  const {
    markerOptions: { selected, selectedIcon, icon, label },
  } = marker;
  if (selected) {
    marker.setIcon(selectedIcon);
    marker.setLabel(label);
  } else {
    marker.setIcon(icon);
    marker.setLabel(label);
  }
};

const formatClustersForSpiderfier = (
  marker,
  { id },
  spiderfier,
  handleClick,
  setMarkerInstance,
  setShowingListingInfo,
) => {
  spiderfier.addMarker(marker, () =>
    handleClick(marker, id, setMarkerInstance, setShowingListingInfo),
  );
};

const handleOnMapClick = (mapInstance, setShowingListingInfo) => {
  window.google.maps.event.addListener(mapInstance, "click", e =>
    setShowingListingInfo(null),
  );
};

const handleOnSpiderfy = (spiderfiedMarkers, setCenterMarkerInstance) => {
  const marker = spiderfiedMarkers.pop();
  setCenterMarkerInstance(marker);
};

const getSpiderfierInstance = (
  mapInstance,
  setCenterMarkerInstance,
  getClusterCallback,
  formatSpiderfyAwareMarkerCallback,
) => {
  const spiderfier = new oms.OverlappingMarkerSpiderfier(mapInstance, {
    ...SpiderfierOptions,
  });

  spiderfier.addListener(
    "spiderfy",
    (spiderfiedMarkers, _unspiderfiedMarkers) => {
      spiderfiedMarkers.forEach(marker =>
        spiderfierFormatters.formatSpiderfiedOrUnspiderfiedMarker(marker),
      );
      handleOnSpiderfy(spiderfiedMarkers, setCenterMarkerInstance);
    },
  );

  spiderfier.addListener(
    "unspiderfy",
    (spiderfiedMarkers, _unspiderfiedMarkers) => {
      spiderfiedMarkers.forEach(marker =>
        spiderfierFormatters.onUnspiderfyFormatter(marker, getClusterCallback),
      );
      setCenterMarkerInstance(null);
    },
  );

  spiderfier.addListener("format", (marker, status) => {
    const {
      markerOptions: { id },
    } = marker;

    const cluster = marker.visible && getClusterCallback(id);
    const count = cluster.length;

    if (count === 1) {
      // marker not in cluster
      configureMarkersNotInCluster(marker);
    } else {
      configureMarkerInCluster(marker, cluster, id);
    }

    formatSpiderfyAwareMarkerCallback(marker, status, count, oms);
  });

  return spiderfier;
};

const {
  sharedConfig: {
    listingTypes: {
      longletListing: { type: longletListingType },
      shortletListing: { type: shortletListingType },
      hubspotPushedSaleListing: { type: hubspotPushedSaleListingType },
    },
  },
} = listingConfigs;

export const listingIdField = currentListingType =>
  ({
    [longletListingType]: "id",
    [shortletListingType]: "hulllistingstatistic_id",
    [hubspotPushedSaleListingType]: "id",
  }[currentListingType]);

const canShowHoverForListingByType = currentListingType =>
  [longletListingType, hubspotPushedSaleListingType].includes(
    currentListingType,
  );

export default Spiderfy;
