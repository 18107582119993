/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { render } from "react-dom";
import styles from "./confirm_window.module.css";

function confirmWindow({ text, okText, cancelText, onClick }) {
  const container = document.getElementById("info-windows");

  const element = (
    <div className={styles.container}>
      <div className={styles.background} onClick={removeElement} />
      <div className={styles.window}>
        <div className={styles.question}>{text}</div>
        <div>
          <button type="button" onClick={handleClick}>
            {okText || "Ok"}
          </button>
          <button type="button" onClick={removeElement}>
            {cancelText || "Cancel"}
          </button>
        </div>
      </div>
    </div>
  );
  render(element, container);

  function handleClick() {
    onClick();
    removeElement();
  }

  function removeElement() {
    render(null, container);
  }
}

export default confirmWindow;
