import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";

import rootReducer from "../reducers/root_reducer";

const middlewareExtras = [];

const loggerMiddleware = createLogger(); // redux action logging in the console

if (process.env.REACT_APP_ENV === "development") {
  middlewareExtras.push(loggerMiddleware);
}

const mainStore = configureStore({
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware({
      // serializableCheck: false,
      serializableCheck: false,
      thunk: false, // disable redux-thunk included by @reduxjs/toolkit
    }),
    thunkMiddleware, // lets us dispatch() functions (dispatch) => {}, not just {} objects
  ].concat(middlewareExtras),
  devTools: process.env.REACT_APP_ENV !== "production",
  preloadedState: {
    // shortletListings: [],
    // longletListings: [],
    // hulls: {},
    // // amenities: [],
    // // amenityTypes: [],
    // selectedListing: null,
    // selectedPropertyHull: null,
    // highlightedAmenityType: null,
    // selectedAmenityHull: null,
    // filters: {
    //   narrowing: true,
    // },
  },
});

export default mainStore;
